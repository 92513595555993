import { useEffect, useState } from 'react';
import { IdType } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ProductDocument } from '../../../api/schemas';
import {
  activeProductSku,
  isConfirmArchiveProductDocs,
  isUploadAndLinkOpen,
  prodDocsToUnlink,
} from '../../../atoms';
import { Restricted } from '../../../shared/components';
import { PERMISSIONS } from '../../../shared/constants';
import { useFileDownload } from '../../../shared/hooks';
import { ActionButtons } from '../../document-repository';
import {
  useMoveToArchiveDocs,
  usePersistentProductDocs,
} from '../../document-repository/persistent-product/hooks';
import { PersistentProdDocsTable } from '../../document-repository/persistent-product/types';
import { Table } from '../../table';
import { useLinkDocsToProduct, useProductDetails, useProductDocumentsColumns } from '../hooks';
import { ACTION_BTNS } from './constants';
import DocumentsTabModals from './DocumentsTabModals';

export default function DocumentsTab(): React.ReactElement {
  const [selectedRows, setSelectedRows] = useState<
    Record<IdType<ProductDocument>, ProductDocument>
  >({});
  const [pageCount, setPageCount] = useState<number>(0);

  const gxp_sku = useRecoilValue(activeProductSku);
  const setIsUpload = useSetRecoilState(isUploadAndLinkOpen);
  const setDocsToUnlink = useSetRecoilState(prodDocsToUnlink);
  const setIsConfirmArchive = useSetRecoilState(isConfirmArchiveProductDocs);

  const {
    fetch,
    refetch,
    isLoading,
    isFetching,
    isSuccess,
    data: { documents, total_items, total_pages } = {},
  } = usePersistentProductDocs(gxp_sku);
  const { columns } = useProductDocumentsColumns(isSuccess);

  const { data: { sku = '' } = {} } = useProductDetails(gxp_sku, false);
  const { downloadSingle, downloadMultiple, isDownloading } = useFileDownload();
  const { moveToArchive, isArchiving, isMovedToArchive } = useMoveToArchiveDocs();
  const {
    linkDocs,
    isLoading: isUnlinking,
    isSuccess: isUnlinked,
  } = useLinkDocsToProduct('unlink', gxp_sku, sku);

  useEffect(() => {
    if (total_pages) {
      setPageCount(total_pages);
    }
  }, [total_pages]);

  useEffect(() => {
    if (isUnlinked || isMovedToArchive) {
      if (documents && total_items) {
        const itemsOnLastPage = total_items % 10 || 10;
        if (Object.keys(selectedRows).length >= itemsOnLastPage) {
          setPageCount(pageCount - 1);
        }

        setSelectedRows({});
      }
    }
  }, [isUnlinked, isMovedToArchive]);

  const handleDownload = (): void => {
    const ids = Object.keys(selectedRows);
    if (ids.length === 1) {
      const selectedId = ids[0];
      const { name, extension, url } = selectedRows[selectedId];
      const fileName = `${name}.${extension}`;

      return downloadSingle(url, fileName);
    }

    return downloadMultiple('/api/persistent-documents/download/', ids);
  };

  const handleArchive = () => {
    setIsConfirmArchive(false);
    moveToArchive(selectedRows);
  };

  const handleUnlink = () => {
    setDocsToUnlink(selectedRows);
  };

  return (
    <div className="product_documents_tab">
      <div className="heading">
        <Restricted to={PERMISSIONS.UPLOAD_DOCS}>
          <button
            className="upload"
            type="button"
            name="Upload and Link"
            onClick={() => setIsUpload(true)}
          >
            Upload & Link Product Documents
          </button>
        </Restricted>
        <ActionButtons
          buttons={ACTION_BTNS}
          actionHandlers={{
            download: handleDownload,
            archive: () => setIsConfirmArchive(true),
            unlink: handleUnlink,
          }}
          loading={{ download: isDownloading, archive: isArchiving, unlink: isUnlinking }}
          selectedRows={selectedRows}
        />
      </div>
      <div className="product_purchases">
        <Table<PersistentProdDocsTable>
          columns={columns}
          data={documents || []}
          isLoading={isLoading}
          isFetching={isFetching}
          fetchData={fetch}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          pageCount={pageCount}
          totalItemsCount={total_items}
        />
      </div>
      <DocumentsTabModals confirmArchive={handleArchive} linkDocs={linkDocs} refetch={refetch} />
    </div>
  );
}
