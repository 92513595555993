import { useMutation, useQueryClient } from 'react-query';

import { archiveOrderDocuments } from '../../../../api/mutations/order-documents';
import { OrderDocToArchive, OrderDocument } from '../../../../api/schemas';
import ServerStateKeys from '../../../../utils/server-state-keys';
import { UseArchiveOrderDocs } from './types';

export default function useArchiveOrderDocs(): UseArchiveOrderDocs {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    (docsToArchive: OrderDocToArchive[]) => archiveOrderDocuments(docsToArchive),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ServerStateKeys.OrderDocuments);
        queryClient.invalidateQueries(ServerStateKeys.OrderDocsOrderList);
      },
    }
  );

  const moveToArchive = (files: OrderDocument[]) => {
    const filesToArchive = files.map(({ id, order_id, name, label }) => ({
      id,
      order_id,
      name,
      label,
    }));

    mutate(filesToArchive);
  };

  return { moveToArchive, isLoading, isSuccess };
}
