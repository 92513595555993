import { Suspense, useCallback } from 'react';

import { ErrorBoundary } from '../../../shared/components';
import { ProductAdditionalDetails, ProductAdditionalDetailsLoading } from '../additional-details';
import { DocumentsTab } from '../documents';
import { ProductPurchases } from '../product-purchase-orders';
import { ProdDetailsTabs } from './constants';
import { DocumentationSpacesPropTypes } from './types';

export default function ProductDetailsSpaces({
  activeTab,
}: DocumentationSpacesPropTypes): React.ReactElement {
  const renderTab = useCallback((tab: ProdDetailsTabs) => {
    if (tab === ProdDetailsTabs.ORDERS) {
      return <ProductPurchases />;
    }

    if (tab === ProdDetailsTabs.DOCUMENTS) {
      return <DocumentsTab />;
    }

    return (
      <ErrorBoundary fallback={<>Couldn&#39;t load Product Details</>}>
        <Suspense fallback={<ProductAdditionalDetailsLoading />}>
          <ProductAdditionalDetails />
        </Suspense>
      </ErrorBoundary>
    );
  }, []);

  return <div className="documentation_space">{renderTab(activeTab)}</div>;
}
