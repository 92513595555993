import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { getUserInviteDetails } from '../../../api/queries';
import { UserInviteDetails } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseUserInviteDetails = {
  data?: UserInviteDetails;
  isLoading: boolean;
  isError: boolean;
  errorMsg?: string;
};

export default function useUserInviteDetails(code: string): UseUserInviteDetails {
  const [errorMsg, setErrorMsg] = useState<string>();

  const { data, isLoading, isError } = useQuery<
    AxiosResponse<UserInviteDetails>,
    AxiosError<string[]>
  >([ServerStateKeys.UserInviteDetails, code], () => getUserInviteDetails(code), {
    retry: false,
    refetchInterval: false,
    onError: ({ response }) => {
      if (response?.status === 400) {
        const error = response.data[0];

        if (error.includes(code)) {
          setErrorMsg(error.split(': ')[0]);
          return;
        }

        setErrorMsg(error);
      }
    },
  });

  return { data: data?.data, isLoading, isError, errorMsg };
}
