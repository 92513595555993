import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { useFeature } from '../../shared/hooks';
import { SIDEBAR_ITEMS } from './constants';
import SubmenuItems from './SubmenuItems';
import { ListItem, NavbarPropTypes } from './types';

export default function Navbar({ isOpen, toggle }: NavbarPropTypes): React.ReactElement {
  const [activeItem, setActiveItem] = useState<string>('');
  const { pathname } = useLocation();

  useEffect(() => {
    setActiveItem(pathname);
  }, [pathname]);

  const renderItems = () =>
    SIDEBAR_ITEMS.map(({ component: Icon, name, path, items }: ListItem): JSX.Element | null => {
      const { disabled } = useFeature(name);

      if (disabled) return null;

      const isSelected = activeItem === path;
      const colorToFill = isSelected ? '#03ABE3' : '#696969';

      return (
        <li className={isSelected ? 'nav__item selected' : 'nav__item'} key={name}>
          <SubmenuItems options={items}>
            <Link to={path} className="nav__link">
              <Icon width="24px" height="24px" fill={colorToFill} />
            </Link>
          </SubmenuItems>
        </li>
      );
    });

  return (
    <nav className={isOpen ? 'nav nav-open' : 'nav'}>
      <button type="button" className="menu-btn" onClick={toggle}>
        <CloseIcon width="18px" height="18px" fill="#696969" />
      </button>
      <div className="divider" />
      <ul className="nav__list">{renderItems()}</ul>
    </nav>
  );
}
