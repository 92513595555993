import { Location } from 'history';
import { cloneElement, useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';

import Modal from './Modal';

export type RouteGuardChildPropTypes = {
  closeModal?: () => void;
  confirmNavigation?: () => void;
};

type RouteLeavingGuardPropTypes = {
  when: boolean;
  children: React.ReactElement;
};

export default function RouteLeavingGuard({
  when,
  children,
}: RouteLeavingGuardPropTypes): React.ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const [lastLocation, setLastLocation] = useState(location as Location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation: Location) => {
    openModal();
    setLastLocation(nextLocation);
  };

  // react-router-dom uses outdated history 4.7.2 package that
  // doesn't recognize types from added history 5.1.0
  const handleBlockedRoute = (nextLocation: unknown): boolean => {
    if (!confirmedNavigation) {
      showModal(nextLocation as Location);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event: BeforeUnloadEvent) => {
      if (when && !shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = 'You are about to exit this page without saving.';
      }
      if (shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [when, shouldUnload]);

  return (
    <>
      <Prompt when={when} message={handleBlockedRoute} />
      <Modal show={isModalOpen} closeModal={closeModal}>
        {cloneElement(children, {
          confirmNavigation: handleConfirmNavigationClick,
        })}
      </Modal>
    </>
  );
}
