import { useState } from 'react';
import { Modal } from '../../shared/components';
import { AccountMmgmtTabs } from './constants';
import { InviteUsers } from './invite-users';

type ManageUsersTabsPropTypes = {
  activeTab: AccountMmgmtTabs;
  setActiveTab: (tab: AccountMmgmtTabs) => void;
};

export default function ManageUsersTabs({
  activeTab,
  setActiveTab,
}: ManageUsersTabsPropTypes): React.ReactElement {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="tabs_wrapper">
        <button
          type="button"
          className="invite_btn"
          name="Invite new user"
          onClick={() => setShow(true)}
        >
          Invite a New User
        </button>
        <div className="tabs_container">
          {Object.values(AccountMmgmtTabs).map(tab => {
            const isSelected = tab === activeTab;

            return (
              <button
                key={tab}
                type="button"
                className={`tab ${isSelected ? 'active' : ''}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </div>
      </div>
      <Modal show={show} closeModal={() => setShow(false)} header="Invite New Users">
        <InviteUsers />
      </Modal>
    </>
  );
}
