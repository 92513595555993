import { useCallback, useMemo } from 'react';
import { Row } from 'react-table';

type TableDataLoadingProps<DataType> = {
  data: DataType[];
  isLoading?: boolean;
  placeholderRows: number;
};

type UseTableDataLoading<DataType extends Record<string, unknown>> = {
  tableData: DataType[];
  getRowId: (originalRow: DataType, relativeIndex: number, parent?: Row<DataType>) => string;
};

export default function useTableDataLoading<DataType extends Record<string, unknown>>({
  placeholderRows,
  isLoading,
  data,
}: TableDataLoadingProps<DataType>): UseTableDataLoading<DataType> {
  const tableData: DataType[] = useMemo(
    () => (isLoading ? Array(placeholderRows).fill({}) : data),
    [isLoading, data]
  );

  // changes the way react-table detects unique rows and also how it constructs each row's underlying id property
  const getRowId = useCallback(
    (row, relativeIndex: number, parent?: Row<DataType>) => {
      if (isLoading) {
        return parent ? [parent.id, relativeIndex].join('.') : relativeIndex;
      }
      return row.id;
    },
    [isLoading]
  );

  return { tableData, getRowId };
}
