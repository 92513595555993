import { ReactComponent as AnalyticsIcon } from '../../assets/icons/analytics.svg';
import { ReactComponent as DocRepositoryIcon } from '../../assets/icons/doc-repository.svg';
import { ReactComponent as ProdCatalogIcon } from '../../assets/icons/prod-catalog.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';

import { ROUTE } from '../../routes/constants';
import { DOC_REP_TYPES } from '../document-repository/constants';
import { NOTIFICATION_PAGES } from '../notifications/constants';
import { NotificationsIcon } from '../notifications';
import { SETTINGS_PAGES } from '../settings/constants';
import { ListItem } from './types';
import { PERMISSIONS } from '../../shared/constants';

export const SIDEBAR_ITEMS: ListItem[] = [
  {
    component: HomeIcon,
    name: 'Home',
    path: ROUTE.HOMEPAGE,
    items: [{ name: 'Home', path: ROUTE.HOMEPAGE }],
  },
  {
    component: AnalyticsIcon,
    name: 'Dashboard',
    path: ROUTE.DASHBOARD,
    items: [{ name: 'Dashboard', path: ROUTE.DASHBOARD }],
  },
  {
    component: ProdCatalogIcon,
    name: 'Product Catalog',
    path: ROUTE.PROD_CATALOG,
    items: [{ name: 'Product Catalog', path: ROUTE.PROD_CATALOG }],
  },
  {
    component: DocRepositoryIcon,
    name: 'Document Repository',
    path: ROUTE.DOC_REP,
    items: [
      { name: DOC_REP_TYPES.CORP, path: `${ROUTE.DOC_REP}?type=Corporate+Agreements` },
      {
        name: DOC_REP_TYPES.PROD,
        path: `${ROUTE.DOC_REP}?type=Product+Documents`,
      },
      { name: DOC_REP_TYPES.ORDER, path: `${ROUTE.DOC_REP}?type=Order+Documents` },
      { name: DOC_REP_TYPES.ARCH, path: `${ROUTE.DOC_REP}?type=Archived+Documents` },
    ],
  },
  {
    component: NotificationsIcon,
    name: 'Notifications',
    path: ROUTE.NOTIFICATIONS,
    items: [
      { name: NOTIFICATION_PAGES.NOTIFICATIONS, path: `${ROUTE.NOTIFICATIONS}?type=Notifications` },
      {
        name: NOTIFICATION_PAGES.ARCH_NOTIFICATIONS,
        path: `${ROUTE.NOTIFICATIONS}?type=Archived+Notifications`,
      },
    ],
  },
  {
    component: SettingsIcon,
    name: 'Settings',
    path: ROUTE.SETTINGS,
    items: [
      { name: SETTINGS_PAGES.USER_PROFILE, path: `${ROUTE.SETTINGS}?type=User+Profile` },
      { name: SETTINGS_PAGES.CHANGE_PWD, path: `${ROUTE.SETTINGS}?type=Change+Password` },
      { name: SETTINGS_PAGES.ALERTS, path: `${ROUTE.SETTINGS}?type=Alerts` },
      { name: SETTINGS_PAGES.SERVICE_PLANS, path: `${ROUTE.SETTINGS}?type=Service+Plans` },
      {
        name: SETTINGS_PAGES.ACC_MGMT,
        path: `${ROUTE.SETTINGS}?type=Account+Management`,
        permission: PERMISSIONS.VIEW_REGULAR_USERS,
      },
    ],
  },
];
