import { MutableRefObject, useRef, useState } from 'react';
import { FilterValue } from 'react-table';

import { DatePicker, DateRange, RadioButton } from '.';

import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';

import { useOutsideClick } from '../hooks';

type FilterByDatePropTypes = {
  title: string;
  filterValue: FilterValue;
  setFilter: (value: FilterValue) => void;
};

export default function FilterByDate({
  title,
  filterValue,
  setFilter,
}: FilterByDatePropTypes): React.ReactElement {
  const [show, setShow] = useState(false);
  const [isSingleDate, setSingleDate] = useState(filterValue?.[0] === filterValue?.[1]);

  const ref = useRef<HTMLDivElement>();

  useOutsideClick(ref, () => setShow(false));

  const handleClearAll = () => {
    setFilter([]);
  };

  const toggleSelectMethod = () => {
    setSingleDate(!isSingleDate);
  };

  const handleSelectDate = (dateAfter: string, dateBefore: string) => {
    setFilter([dateAfter, dateBefore]);
  };

  const activeFilters = !!filterValue?.length;

  return (
    <div ref={ref as MutableRefObject<HTMLDivElement>} className="filter_by__container">
      <button
        type="button"
        className={`filter_toggler ${show ? 'open' : ''} ${activeFilters ? 'active' : ''}`}
        name="Category Filter"
        title="Toggle Filter By"
        onClick={() => setShow(!show)}
      >
        {title}
        <FilterIcon />
      </button>
      {show && (
        <div className="filter_menu">
          <h5>Filter By Date:</h5>
          <button
            className="clear_all"
            type="button"
            name="Clear All Filters"
            disabled={!activeFilters}
            onClick={handleClearAll}
          >
            Clear All Filters
          </button>
          <div className="divider" />
          <div className="filter_date__container">
            <h6>Choose a filtering method:</h6>
            <div className="select_filter_method">
              <div className="radio_btn__row">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>
                  <RadioButton isChecked={isSingleDate} handleChange={toggleSelectMethod} />
                  <span className="radio_btn__label">Single Date</span>
                </label>
              </div>
              <div className="radio_btn__row">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>
                  <RadioButton isChecked={!isSingleDate} handleChange={toggleSelectMethod} />
                  <span className="radio_btn__label">Date Range</span>
                </label>
              </div>
            </div>

            {isSingleDate ? (
              <div className="single_date__container">
                <DatePicker initialDate={filterValue?.[0]} handleSelectDate={handleSelectDate} />
              </div>
            ) : (
              <DateRange
                dateAfter={filterValue?.[0]}
                dateBefore={filterValue?.[1]}
                handleSelectDate={handleSelectDate}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
