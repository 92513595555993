import { useEffect, useMemo, useState } from 'react';
import { IdType } from 'react-table';
import { useRecoilState } from 'recoil';

import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';

import { ProductDocument } from '../../../../api/schemas';
import { filesToLinkToSku } from '../../../../atoms';
import LinkActionBtn from '../../../document-repository/document-edit/unlink-products/LinkActionBtn';
import { InfiniteScrollTable } from '../../../table';
import { useProductDocsInfiniteScroll, useFilesToLinkColumns } from '../../hooks';

export default function ChooseFilesToLink(): React.ReactElement {
  const [search, setSearch] = useState({ name: '' });

  const [selectedRows, setSelectedRows] = useState<
    Record<IdType<ProductDocument>, ProductDocument>
  >({});
  const [filesToLink, setFilesToLink] = useRecoilState(filesToLinkToSku);

  const { data, isLoading, isFetching, isSuccess, hasNextPage, fetch, fetchNextPage } =
    useProductDocsInfiniteScroll();
  const { columns } = useFilesToLinkColumns(isSuccess);

  const handleSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
    setSearch({ name: value });

  const addFilesToLink = () => {
    setFilesToLink({ ...filesToLink, ...selectedRows });
    setSearch({ name: '' });
    setSelectedRows({});
  };

  const productDocs = useMemo(() => {
    if (data) return data.filter(({ id }) => !selectedRows[id] && !filesToLink[id]);
    return [];
  }, [data, filesToLink, selectedRows]);

  useEffect(() => {
    // fetch next page when most of the items from current pages were filtered out
    if (data && productDocs.length < 5 && !isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [productDocs]);

  return (
    <div>
      <div className="product_to_unlink">
        <div className="add_product_record__container">
          <div className="searching">
            <label>
              Search a document name, label or type:
              <input
                type="text"
                value={search.name}
                placeholder="Enter Doc Name, Label or Type"
                onChange={handleSearch}
              />
            </label>
          </div>
          <InfiniteScrollTable<ProductDocument>
            data={productDocs}
            pinnedRows={Object.values(selectedRows)}
            columns={columns}
            isLoading={isLoading}
            isFetching={isFetching}
            hasNextPage={hasNextPage}
            loadingMessage="Loading more documents..."
            fetchData={fetch}
            update={fetchNextPage}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            searchParams={search}
            hasInitialData={!!data?.length}
          />
        </div>
      </div>
      <LinkActionBtn name="Link" icon={LinkIcon} action={addFilesToLink} />
    </div>
  );
}
