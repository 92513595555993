import { useMutation, useQueryClient } from 'react-query';

import { changeUserRole } from '../../../api/mutations';
import { UserRole } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseChangeRole = {
  changeRole: (id: number, role: string) => void;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function useChangeRole(): UseChangeRole {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    ({ id, payload }: { id: number; payload: UserRole }) => changeUserRole(id, payload),
    {
      onSuccess: () => {
        queryClient.cancelQueries(ServerStateKeys.ActiveUsers);
        queryClient.invalidateQueries(ServerStateKeys.ActiveUsers);
      },
    }
  );

  const changeRole = (id: number, role: string) => {
    mutate({ id, payload: { name: role } });
  };

  return { changeRole, isLoading, isSuccess };
}
