import { memo, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as ArchiveIcon } from '../../../assets/icons/bold-archive.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/bold-download.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/bold-upload.svg';

import { isUploadingFilesToOrder } from '../../../atoms';
import { Restricted, Tooltip } from '../../../shared/components';
import { PERMISSIONS } from '../../../shared/constants';
import { useFileDownload } from '../../../shared/hooks';
import { useArchiveOrderDocs, usePurchaseDocs } from './hooks';
import { OrderDocsActionBtnsPropTypes } from './types';

enum ACTION_TYPE {
  ARCHIVE = 'archive',
  DOWNLOAD = 'download',
}

function OrderDocsActionButtons({
  orderId,
  has_documents,
}: OrderDocsActionBtnsPropTypes): React.ReactElement {
  const [action, setAction] = useState('' as ACTION_TYPE);

  const setOrderIdToUpload = useSetRecoilState(isUploadingFilesToOrder);

  const { data, isLoading, fetch } = usePurchaseDocs();
  const { downloadMultiple, isDownloading } = useFileDownload();
  const { moveToArchive, isLoading: isArchiving } = useArchiveOrderDocs();

  useEffect(() => {
    if (data?.documents.length && !(isDownloading || isArchiving)) {
      if (action === ACTION_TYPE.DOWNLOAD) {
        const ids = data.documents.map(({ id }) => id);
        downloadMultiple('/api/order-documents/download/', ids);
      }

      if (action === ACTION_TYPE.ARCHIVE) {
        moveToArchive(data.documents);
      }

      setAction('' as ACTION_TYPE);
    }
  }, [data]);

  const handleUpload = () => {
    setOrderIdToUpload(orderId);
  };

  const handleDownloadSet = () => {
    setAction(ACTION_TYPE.DOWNLOAD);
    fetch(orderId);
  };

  const handleArchiveSet = () => {
    setAction(ACTION_TYPE.ARCHIVE);
    fetch(orderId);
  };

  return (
    <div className="action_btns__container">
      <Restricted to={PERMISSIONS.UPLOAD_ORDER_DOCS}>
        <Tooltip content="Upload">
          <button className="upload_btn" type="button" name="Upload" onClick={handleUpload}>
            <UploadIcon stroke="#FFF" />
          </button>
        </Tooltip>
      </Restricted>
      {(isLoading && action === ACTION_TYPE.DOWNLOAD) || isDownloading ? (
        <button type="button" name="Download Loading">
          <div className="loader" />
        </button>
      ) : (
        <Tooltip content="Download Set">
          <button
            type="button"
            name="Download Set"
            disabled={!has_documents}
            onClick={handleDownloadSet}
          >
            <DownloadIcon stroke="#FFF" />
          </button>
        </Tooltip>
      )}
      {(isLoading && action === ACTION_TYPE.ARCHIVE) || isArchiving ? (
        <button type="button" name="Archive Loading">
          <div className="loader" />
        </button>
      ) : (
        <Restricted to={PERMISSIONS.ARCHIVE_ORDER_DOCS}>
          <Tooltip content="Archive Set">
            <button
              type="button"
              name="Archive Set"
              disabled={!has_documents}
              onClick={handleArchiveSet}
            >
              <ArchiveIcon stroke="#FFF" />
            </button>
          </Tooltip>
        </Restricted>
      )}
    </div>
  );
}

export default memo(OrderDocsActionButtons);
