import { atom } from 'recoil';

import { SkuType } from '../components/document-repository/persistent-product/hooks/types';

export const isUploadProductDocs = atom({
  key: 'isUploadProductDocs',
  default: false,
});

export const isConfirmArchiveProductDocs = atom({
  key: 'isConfirmArchiveProductDocs',
  default: false,
});

export const isViewingAllSkus = atom({
  key: 'isViewingAllSkus',
  default: {} as { skuType?: SkuType; skuIds: string[]; documentName: string },
});
