import { useState } from 'react';
import { Cell, Column, HeaderProps } from 'react-table';

import { FilterByDate, FilterByMenu } from '../../../shared/components';
import { DateCell, DescriptionCell, VisitCell } from '../cell';
import { NTF_FILTER_OPTIONS } from '../constants';
import { NotificationsTableData } from '../types';
import { NotificationsColumnsPropTypes, UseNotificationsColumns } from './types';

export default function useNotificationsColumns({
  openReadMoreModal,
}: NotificationsColumnsPropTypes): UseNotificationsColumns {
  const [columns] = useState<Column<NotificationsTableData>[]>([
    {
      Header({ column: { filterValue, setFilter } }: HeaderProps<NotificationsTableData>) {
        return (
          <FilterByDate title="Notification Date" filterValue={filterValue} setFilter={setFilter} />
        );
      },
      accessor: 'event_date',
      disableSortBy: true,
      className: 'ntf_date__container',
      Cell: ({
        row: {
          original: { event_date, was_read },
        },
      }: Cell<NotificationsTableData>) => <DateCell event_date={event_date} was_read={was_read} />,
    },
    {
      Header({ column: { filterValue, setFilter } }: HeaderProps<NotificationsTableData>) {
        return (
          <FilterByMenu
            title="Type"
            filterOptions={NTF_FILTER_OPTIONS}
            filterValue={filterValue}
            setFilter={setFilter}
          />
        );
      },
      accessor: 'notification_type',
      disableSortBy: true,
      Cell: ({ value }: Cell<NotificationsTableData>) =>
        NTF_FILTER_OPTIONS.find(({ id }) => id === value)?.label || '',
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
      className: 'ntf_description__container',
      Cell({
        row: {
          original: { id, was_read, description },
        },
      }: Cell<NotificationsTableData>) {
        return (
          <DescriptionCell
            id={id}
            isRead={was_read}
            openReadMoreModal={openReadMoreModal}
            description={description}
          />
        );
      },
    },
    {
      Header: 'Visit Notification',
      accessor: 'actions',
      disableSortBy: true,
      Cell: VisitCell,
    },
  ]);

  return { columns };
}
