import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ArchiveDocs,
  CorpAgreement,
  OrderDocuments,
  ProductDocuments,
  Spaces,
} from '../../components/document-repository';
import { DOC_REP_SPACES, DOC_REP_TYPES } from '../../components/document-repository/constants';
import { ROUTE } from '../../routes/constants';
import { PageRoute, PageSidebar } from '../../shared/components';
import { useFeature } from '../../shared/hooks';

const extractDocTypeFromUrl = (urlQuery: string) => new URLSearchParams(urlQuery).get('type') || '';

export default function DocRep(): React.ReactElement {
  const { search } = useLocation();

  const [currentDocType, setCurrentDocType] = useState<string>(extractDocTypeFromUrl(search));

  const { disabled, isLoading } = useFeature(currentDocType);

  useEffect(() => {
    const docType = extractDocTypeFromUrl(search);
    setCurrentDocType(docType);
  }, [search]);

  const renderScreen = (): React.ReactElement | null => {
    if (currentDocType === DOC_REP_TYPES.CORP) return <CorpAgreement />;
    if (currentDocType === DOC_REP_TYPES.ORDER) return <OrderDocuments />;
    if (currentDocType === DOC_REP_TYPES.ARCH) return <ArchiveDocs />;
    if (currentDocType === DOC_REP_TYPES.PROD) {
      if (isLoading) return null;
      if (!disabled) return <ProductDocuments />;
    }

    return <CorpAgreement />;
  };

  if (currentDocType) {
    return (
      <>
        <PageRoute
          linkTo={[ROUTE.HOMEPAGE, ROUTE.DOC_REP]}
          linkLabel={['Home', 'Document Repository Home']}
          currentPage={currentDocType}
        />
        <section className="doc_rep">
          <h2>Document repository</h2>
          <div className="doc_rep__container">
            <PageSidebar
              options={DOC_REP_SPACES}
              baseRoute={ROUTE.DOC_REP}
              currentOption={currentDocType}
            />
            <div className="doc_rep__content">{renderScreen()}</div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <PageRoute linkTo={ROUTE.HOMEPAGE} linkLabel="Home" currentPage="Document Repository Home" />
      <section className="doc_rep">
        <h2>Document repository</h2>
        <Spaces />
      </section>
    </>
  );
}
