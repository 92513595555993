import { SyntheticEvent, useState } from 'react';

import fallbackImg from '../../assets/images/placeholder-image.png';
import { ProductImgPropTypes } from './types';

export default function ProductImg({ src, alt, ...rest }: ProductImgPropTypes): JSX.Element {
  const [isImageLoaded, setImageLoaded] = useState(false);

  const addDefaultSrc = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = fallbackImg;
  };

  return (
    <div className="smooth_image_wrapper">
      <img
        src={src}
        alt="Product"
        className={`smooth-image ${isImageLoaded ? 'visible' : 'hidden'}`}
        onError={addDefaultSrc}
        onLoad={() => setImageLoaded(true)}
        loading="lazy"
        {...rest}
      />

      {!isImageLoaded && (
        <div className="product_cell__loading">
          <div className="loading" />
        </div>
      )}
    </div>
  );
}
