import { AxiosResponse } from 'axios';

import API from '../../utils/api';
import {
  DocumentLabel,
  OrderDocsParams,
  OrderDocumentsResponse,
  OrderPositionInListRequest,
  OrderStatus,
  PurchaseOrdersResponse,
} from '../schemas';

export const getOrderDocLabels = (): Promise<AxiosResponse<DocumentLabel[]>> =>
  API.get<DocumentLabel[]>('/api/order-documents/labels/');

export const getPurchaseOrders = (
  params: OrderDocsParams
): Promise<AxiosResponse<PurchaseOrdersResponse>> =>
  API.get<PurchaseOrdersResponse>('/api/order-documents/orders/', { params });

export const getOrderDocuments = (id: string): Promise<AxiosResponse<OrderDocumentsResponse>> =>
  API.get<OrderDocumentsResponse>(`/api/order-documents/order/${id}/`);

export const getOrderDocPositionInList = (
  params: OrderPositionInListRequest
): Promise<AxiosResponse<{ page: number }>> =>
  API.get('/api/order-documents/orders/position-in-list/', { params });

export const getOrderStatuses = (): Promise<AxiosResponse<OrderStatus[]>> =>
  API.get('/api/order-documents/orders-statuses');
