import { useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as PersistentProduct } from '../../../../assets/icons/persistent-product.svg';

import { Modal, WarningWindow } from '../../../../shared/components';
import { ModalChildPropTypes } from '../../../../shared/components/Modal';
import { DragNDrop, FileList, Footer } from '../../file-upload';
import { WARN_CONTENT } from '../../file-upload/constants';
import { useOrderFileUpload } from '../hooks';
import { OrderDocsPropTypes } from '../types';
import DocumentTypes from './DocumentTypes';

type UploadFilesPropTypes = ModalChildPropTypes & OrderDocsPropTypes;

export default function UploadFiles({
  orderId,
  closeModal,
}: UploadFilesPropTypes): React.ReactElement {
  const [showAlert, setShowAlert] = useState(false);

  const { loadingStatuses, upload } = useOrderFileUpload(orderId);

  const handleFileUploading = (filesToUpload: FileList | null): void => {
    if (filesToUpload) {
      const fileList = Object.values(filesToUpload);
      upload(fileList);
    }
  };

  const handleClose = (): void => {
    const isLoading = Object.values(loadingStatuses).find(({ loading }) => loading);
    if (isLoading) {
      return setShowAlert(true);
    }

    return closeModal?.();
  };

  return (
    <>
      <div className="header">
        <div>
          <h1>Upload Files</h1>
          <h5>Drag and drop or click the link below to add files via the finder.</h5>
        </div>
        <button type="button" name="Close Modal" onClick={handleClose}>
          <CloseIcon fill="#fff" />
        </button>
      </div>

      <div className="upload_file">
        <label className="add_btn">
          <input
            type="file"
            name="document"
            multiple
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              handleFileUploading(target.files);

              // eslint-disable-next-line no-param-reassign
              target.value = ''; // reset value manually in order to allow uploading exactly same files
            }}
            data-testid="file-uploader"
          />
          + <span>Add files from your computer</span>
        </label>
        <div className="boxes">
          <DragNDrop handleDropFile={handleFileUploading}>
            <>
              <p>Drag & drop files here to upload</p>
              <div>
                <PersistentProduct width="195px" height="202px" fill="#F3F5F8" />
              </div>
            </>
          </DragNDrop>
          <FileList
            loadingStatuses={loadingStatuses}
            orderId={orderId}
            documentTypes={DocumentTypes}
          />
        </div>
        <Footer loadingStatuses={loadingStatuses} closeModal={closeModal} />
      </div>
      <Modal show={showAlert} closeModal={() => setShowAlert(false)}>
        <WarningWindow content={WARN_CONTENT} confirm={() => closeModal?.()} />
      </Modal>
    </>
  );
}
