import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CreateAccountForm } from '../../components/welcome';
import { ROUTE } from '../../routes/constants';
import { useAuth, useQueryParams } from '../../shared/hooks';

const extractInviteCode = (params: URLSearchParams) => params.get('code') || '';

export default function WelcomePage(): React.ReactElement {
  const params = useQueryParams();

  const history = useHistory();
  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      history.replace(ROUTE.DASHBOARD);
    }
  }, [token]);

  const inviteCode = extractInviteCode(params);

  return (
    <div className="welcome_wrapper">
      <div className="welcome_header">
        <div className="login_header-logo" />
      </div>
      <div className="welcome_content">
        <div className="description">
          <h2>Welcome To</h2>
          <h1>EXPEDI.ACCESS</h1>
          <h2>The GoExpedi Vendor Portal</h2>
        </div>
        <p className="provide_info">
          Please provide the following information below to finish creating your account:
        </p>
        <CreateAccountForm code={inviteCode} />
      </div>
    </div>
  );
}
