import { AxiosResponse, AxiosError } from 'axios';
import { MutateOptions } from 'react-query';

import { FileData, LoadingStatus, UploadServerError } from '../types';

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!bytes) return '';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const extractFileNameFromUrl = (url: string): string => {
  const fileNameRegExp = /\/([.\w-]+)\?/;
  const match = url.match(fileNameRegExp);

  if (match && match.length > 1) {
    return match[1];
  }

  return '';
};

export const updateObjFromIndex = <T>(
  originalList: T[],
  objIndex: number,
  dataToUpdate: Partial<T>
): T[] => {
  if (objIndex === -1) {
    return originalList;
  }

  // make new object with updated data
  const updatedObj = { ...originalList[objIndex], ...dataToUpdate };
  // make new final array by combining updated object
  return [...originalList.slice(0, objIndex), updatedObj, ...originalList.slice(objIndex + 1)];
};

export const updateLoadingStatuses = (
  { id, name, extension }: FileData,
  existingFiles: LoadingStatus[]
): LoadingStatus[] => {
  let fileIndex: number;
  const fullFileName = `${name}.${extension}`;

  const appendedId = /^(.*) \(\d+\)$/;
  const match = name.match(appendedId); // match for appended (id) at the end of a duplicate file

  if (!match) {
    // uniq id was not appended
    fileIndex = existingFiles.findIndex(
      ({ name: existingFileName }) => existingFileName === fullFileName
    );

    return updateObjFromIndex(existingFiles, fileIndex, {
      id,
      loading: false,
    });
  }

  const nameWithoutId = match[1];
  // find matching name that was not loaded yet
  fileIndex = existingFiles.findIndex(
    ({ name: existingFileName, loading }) => loading && existingFileName.includes(nameWithoutId)
  );

  return updateObjFromIndex(existingFiles, fileIndex, {
    id,
    name: fullFileName,
    loading: false,
  });
};

export const handleUploadFile = (
  files: File[],
  validate: (type: string) => boolean,
  setLoadingStatus: (value: React.SetStateAction<LoadingStatus[]>) => void,
  mutate: (
    variables: FormData,
    options?: MutateOptions<AxiosResponse<FileData>, AxiosError<UploadServerError>, FormData>
  ) => void
): void => {
  files.forEach((file: File) => {
    const { name, type } = file;

    if (!validate(type)) {
      setLoadingStatus(prevStatuses => [
        ...prevStatuses,
        {
          name,
          loading: false,
          error: new Error('Unsupported file type, will not be added to the repository.'),
        },
      ]);

      return;
    }

    setLoadingStatus(prevStatuses => [...prevStatuses, { name, loading: true }]);

    const formPayload = new FormData();
    formPayload.append('document', file);

    mutate(formPayload, {
      onError: ({ response }) => {
        const errorMsg = response?.data?.document[0] || 'Something went wrong.';

        setLoadingStatus(prevStatuses => {
          const fileIndex = prevStatuses.findIndex(
            ({ name: existingName }) => existingName === name
          );

          return updateObjFromIndex(prevStatuses, fileIndex, {
            error: new Error(errorMsg),
            loading: false,
          });
        });
      },
    });
  });
};
