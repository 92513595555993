import { ChangeEvent } from 'react';
import { useSetRecoilState } from 'recoil';

import { changedProductDetails } from '../../../atoms';

import { DetailsFieldPropTypes } from './types';

export default function DetailsField({
  field,
  value,
  isEdit,
  isAllowedToEdit,
}: DetailsFieldPropTypes): React.ReactElement {
  const setEditedProductDetails = useSetRecoilState(changedProductDetails);

  const handleFieldValueChange = (newValue: string) => {
    const fieldInfo = {
      name: field,
      old_value: value ?? '',
      new_value: newValue,
    };

    setEditedProductDetails(prevEditedField => ({
      ...prevEditedField,
      [field]: fieldInfo,
    }));
  };

  if (isAllowedToEdit) {
    if (isEdit) {
      return (
        <input
          className="edit_info_input"
          type="text"
          defaultValue={value}
          onChange={({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) =>
            handleFieldValueChange(newValue)
          }
          placeholder="Add info"
        />
      );
    }

    if (!value) {
      return (
        <button
          className="add_info_btn"
          type="button"
          name="Add Info"
          onClick={() => handleFieldValueChange(value?.toString() || '')}
        >
          Add Info*
        </button>
      );
    }
  }

  if (!value && field === 'ytd_search_queries') {
    return <span>Coming Soon</span>;
  }

  if (!value && field === 'ytd_market_share') {
    return <span>0%</span>;
  }

  return <span>{value || 'N/A'}</span>;
}
