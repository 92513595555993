import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as RestoreIcon } from '../../../assets/icons/restore.svg';

import { PERMISSIONS } from '../../../shared/constants';
import { ActionButton } from '../types';

export const WARN_CONTENT = {
  main: 'Are you sure you want to delete the file(s)? Deleted files cannot be retrieved!',
  confirm: 'Yes, Delete!',
  cancel: 'Do Not Delete',
};

export const CONFIRM_RESTORE = {
  main: 'Are you sure you want to retrieve the file(s)?',
  confirm: 'Restore File(s)',
  cancel: 'Close, Do Not Restore',
};

export const ACTION_BTNS: ActionButton[] = [
  {
    title: 'Delete',
    icon: DeleteIcon,
    action: 'delete',
    withRows: true,
    permission: PERMISSIONS.SOFT_DELETE_DOCS,
  },
  {
    title: 'Restore',
    icon: function WrappedResporeIcon({
      stroke,
    }: React.SVGProps<SVGSVGElement>): React.ReactElement {
      return <RestoreIcon fill={stroke} />;
    },
    action: 'restore',
    withRows: true,
    permission: PERMISSIONS.RESTORE_DELETED_DOCS,
  },
];
