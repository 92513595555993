import { useQuery } from 'react-query';

import { getLatestUpdateMessage } from '../../../api/queries';
import ServerStateKeys from '../../../utils/server-state-keys';

export default function useLatestUpdateMessage(): {
  data?: { text: string };
  isLoading: boolean;
} {
  const { data, isLoading } = useQuery(
    [ServerStateKeys.LatestUpdateMessage],
    getLatestUpdateMessage,
    {
      staleTime: 60000,
    }
  );

  return { data: data?.data, isLoading };
}
