import { useQuery } from 'react-query';
import { getHelpSectionDescription } from '../../../api/queries';
import { HelpSection } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseHelpSectionDescription = {
  data?: HelpSection;
  isLoading: boolean;
};

export default function useHelpSectionDescription(sectionId: number): UseHelpSectionDescription {
  const { data, isLoading } = useQuery(
    [ServerStateKeys.HelpSectionDescription, sectionId],
    () => getHelpSectionDescription(sectionId),
    {
      staleTime: 60000,
    }
  );

  return { data: data?.data, isLoading };
}
