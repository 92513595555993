import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-arrows.svg';

import { activeProductSku, uploadAndLinkLoadingStatus } from '../../../atoms';
import { WarningWindow } from '../../../shared/components';
import Modal, { ModalChildPropTypes } from '../../../shared/components/Modal';
import EditSubMenu from '../../document-repository/document-edit/EditSideMenu';
import { WARN_CONTENT } from '../../document-repository/file-upload/constants';
import { useProductDetails } from '../hooks';
import { UploadMenus, UPLOAD_AND_LINK_SPACES } from './constants';
import { LinkFiles } from './link-files';
import { UploadFiles } from './upload-files';

export default function UploadAndLinkDocs({ closeModal }: ModalChildPropTypes): React.ReactElement {
  const [showAlert, setShowAlert] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(UploadMenus.UPLOAD);

  const gxp_sku = useRecoilValue(activeProductSku);
  const { data: { sku, name } = {} } = useProductDetails(gxp_sku, false);
  const loadingStatuses = useRecoilValue(uploadAndLinkLoadingStatus);

  const handleClose = (): void => {
    const isLoading = Object.values(loadingStatuses).find(({ loading }) => loading);
    if (isLoading) {
      return setShowAlert(true);
    }

    return closeModal?.();
  };

  const renderEditDocContent = (menu: UploadMenus) => {
    if (menu === UploadMenus.LINK) {
      return <LinkFiles />;
    }
    return <UploadFiles closeModal={handleClose} />;
  };

  return (
    <>
      <div className="header">
        <UploadIcon fill="#fff" />
        <div>
          <h1>
            Add Documents To: SKU {sku} - GXP SKU {gxp_sku} - {name}
          </h1>
        </div>
        <button type="button" name="Close Modal" onClick={handleClose}>
          <CloseIcon fill="#fff" />
        </button>
      </div>
      <div className="edit_doc__modal">
        <EditSubMenu
          options={UPLOAD_AND_LINK_SPACES}
          currentMenu={currentMenu}
          selectMenu={setCurrentMenu}
        />
        <div className="edit_doc__content">
          {renderEditDocContent(currentMenu)}
          {currentMenu === UploadMenus.LINK && (
            <button type="button" name="Close" className="close_btn" onClick={handleClose}>
              Close
            </button>
          )}
        </div>
      </div>
      <Modal show={showAlert} closeModal={() => setShowAlert(false)}>
        <WarningWindow content={WARN_CONTENT} confirm={() => closeModal?.()} />
      </Modal>
    </>
  );
}
