import { HelpQuestionRow } from '.';
import { FaqsPropTypes } from './types';

export default function FAQs({ name, faqs }: FaqsPropTypes): React.ReactElement {
  return (
    <div>
      <h3 className="section_title">{name} FAQ&apos;s</h3>
      {faqs.map(({ question, answer, short_answer }) => (
        <HelpQuestionRow
          key={question}
          question={question}
          answer={answer}
          short_answer={short_answer}
        />
      ))}
    </div>
  );
}
