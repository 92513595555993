import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

type FormInputPropTypes = {
  id: string;
  label: string;
  defaultValue?: string;
  error?: FieldError | string;
  hasError?: boolean;
  placeholder?: string;
  required?: boolean;
  type?: string;
  register: () => UseFormRegisterReturn;
};

export default function FormInput({
  id,
  label,
  defaultValue,
  error,
  hasError,
  placeholder,
  required,
  type = 'text',
  register,
}: FormInputPropTypes): React.ReactElement {
  const errorMsg = typeof error === 'string' ? error : error?.message;

  return (
    <div>
      <label htmlFor={id} className="form_input_label">
        {label}
        {required && <span className="asterix">*</span>}
        {errorMsg && <p className="form_input_error">{errorMsg}</p>}
      </label>
      <input
        className={hasError || errorMsg ? 'form_input invalid' : 'form_input'}
        id={id}
        type={type}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...register()}
      />
    </div>
  );
}
