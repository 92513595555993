import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { ReactComponent as EditIcon } from '../../../assets/icons/bold-edit.svg';
import { ReactComponent as SaveIcon } from '../../../assets/icons/save.svg';

import { changedProductDetails, isEditMode } from '../../../atoms';
import { Modal, RouteLeavingGuard, WarningWindow } from '../../../shared/components';
import { useEditProductDetails } from '../hooks';
import BlockNavigationWindow from './BlockNavigationWindow';
import { WARNING_CONTENT } from './constants';
import Notification from './Notification';
import { EditDetailsButtonPropTypes } from './types';

export default function EditDetailsButton({
  productId,
}: EditDetailsButtonPropTypes): React.ReactElement {
  const [showNotification, setShowNotification] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const [isEdit, setEdit] = useRecoilState(isEditMode);
  const [editedFields, setEditedFields] = useRecoilState(changedProductDetails);

  const { editDetails, isLoading, isSuccess, isError } = useEditProductDetails();

  useEffect(() => {
    if (isSuccess || isError) setShowNotification(true);
  }, [isSuccess, isError]);

  const handleSaveDetails = () => {
    setEdit(false);
    setEditedFields({});
    editDetails(productId, editedFields);
  };

  const handleCancelClick = () => {
    if (Object.keys(editedFields).length) {
      return setShowWarning(true);
    }
    return setEdit(false);
  };

  const handleConfirmCancel = () => {
    setEdit(false);
    setEditedFields({});
    setShowWarning(false);
  };

  const isEditing = isEdit || !!Object.keys(editedFields).length;

  if (isLoading) {
    return (
      <button type="button" name="Save Product Details" className="edit_details loader">
        <div />
      </button>
    );
  }

  return (
    <>
      {isEditing ? (
        <div className="edit_details_btns">
          <button
            type="button"
            name="Save Product Details"
            className="edit_details save"
            onClick={handleSaveDetails}
          >
            <SaveIcon stroke="#FFF" width="12px" height="12px" /> Save Product Details
          </button>
          <button
            type="button"
            name="Cancel Edit"
            className="cancel_edit"
            onClick={handleCancelClick}
          >
            Cancel Edit
          </button>
        </div>
      ) : (
        <div className="edit_details_btns">
          <button
            type="button"
            name="Edit Product Details"
            className="edit_details"
            onClick={() => setEdit(true)}
          >
            <EditIcon stroke="#FFF" width="12px" height="12px" /> Edit Product Details
          </button>
        </div>
      )}

      <Modal show={showWarning} closeModal={() => setShowWarning(false)}>
        <WarningWindow content={WARNING_CONTENT} confirm={handleConfirmCancel} />
      </Modal>

      {showNotification && (
        <Notification onDelete={() => setShowNotification(false)} autoClose>
          {isSuccess ? (
            <div className="success_alert">
              <h3>Thank You!</h3>
              <h5>Your updates have been saved.</h5>
              <p>We’ll review the updates and publish once approved.</p>
            </div>
          ) : (
            <div className="error_alert">
              <h3>Something went wrong. </h3>
              <h5>Your changes could not be processed.</h5>
            </div>
          )}
        </Notification>
      )}

      <RouteLeavingGuard when={isEditing}>
        <BlockNavigationWindow save={handleSaveDetails} />
      </RouteLeavingGuard>
    </>
  );
}
