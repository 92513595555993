import { Cell } from 'react-table';

import { NOT_APPLICABLE } from '../constants';
import { FileData } from '../types';

export default function LabelCell<D extends FileData>({ value }: Cell<D>): React.ReactElement {
  return (
    <div className="doc_label">
      {value || <span className="not_applicable">{NOT_APPLICABLE}</span>}
    </div>
  );
}
