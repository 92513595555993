import { useEffect, useState } from 'react';

import { HIDEABLE_COLUMNS } from '../table-settings/constants';
import { UseHiddenColumns } from './types';

export default function useHiddenColumns(): UseHiddenColumns {
  const [hiddenColumns, setHiddenColumns] = useState<Record<string, boolean>>(
    JSON.parse(localStorage.getItem('hiddenColumns') || '{}')
  );

  useEffect(() => {
    localStorage.setItem('hiddenColumns', JSON.stringify(hiddenColumns));
  }, [hiddenColumns]);

  const toggleColumn = (column: string) => {
    setHiddenColumns(prevColumns => {
      if (prevColumns[column]) {
        const { [column]: value, ...restCategories } = prevColumns;
        return restCategories;
      }

      return { ...prevColumns, [column]: true };
    });
  };

  const toggleAllColumns = (value: boolean) => {
    if (value) {
      return setHiddenColumns({});
    }

    return setHiddenColumns(HIDEABLE_COLUMNS.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}));
  };

  return { hiddenColumns, toggleColumn, toggleAllColumns };
}
