import { useEffect, useState } from 'react';

import { FrequencyTypes, WeekDays } from '../../../api/schemas';
import { ConfirmationWindow, Modal, SubmitButton } from '../../../shared/components';
import {
  CONFIRM_CONTENT,
  EMAIL_FREQUENCY_SECTION,
  IS_ENABLED_SECTION,
  WEEK_DAYS_SECTION,
} from '../constants';
import { useAlertSettings, useUpdateNotifications } from '../hooks';
import AlertsMenuSection from './AlertsMenuSection';

export default function AlertsSettingsForm(): React.ReactElement {
  const { data } = useAlertSettings();
  const { updateNotifications, isLoading, isSuccess, isError } = useUpdateNotifications();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [enabled, setEnabled] = useState<boolean>(data?.enabled || false);
  const [frequency, setFrequency] = useState<FrequencyTypes>(
    data?.frequency || FrequencyTypes.REALTIME
  );
  const [weekDay, setWeekDay] = useState(data?.frequency_weekly_day || WeekDays.MONDAY);

  useEffect(() => {
    if (isSuccess) {
      setShowConfirmation(true);
    }
  }, [isSuccess]);

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateNotifications(enabled, frequency, weekDay);
  };

  const areValuesSame =
    enabled === data?.enabled &&
    frequency === data?.frequency &&
    weekDay === data.frequency_weekly_day;

  const isSubmitDisabled = areValuesSame || (!enabled && !data?.frequency);

  return (
    <>
      <form onSubmit={submitForm}>
        <AlertsMenuSection
          title="Turn on email notifications:"
          currentSelect={enabled}
          options={IS_ENABLED_SECTION}
          select={setEnabled}
        />
        <AlertsMenuSection
          title="Select your email frequency:"
          currentSelect={frequency}
          options={EMAIL_FREQUENCY_SECTION}
          disabled={!enabled}
          select={setFrequency}
        />
        <div className="weekdays_submenu">
          <AlertsMenuSection
            currentSelect={weekDay}
            options={WEEK_DAYS_SECTION}
            disabled={!enabled || frequency !== FrequencyTypes.WEEKLY}
            select={setWeekDay}
          />
        </div>
        <SubmitButton name="Save Changes" isLoading={isLoading} isDisabled={isSubmitDisabled} />
      </form>
      {isError && <p className="error_msg">Sorry. Your changes couldn&#39;t be processed</p>}
      <Modal show={showConfirmation} closeModal={() => setShowConfirmation(false)} customClose>
        <ConfirmationWindow content={CONFIRM_CONTENT} iterable={false} />
      </Modal>
    </>
  );
}
