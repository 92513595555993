export const CHANGE_PWD_CONFIRM = {
  message: 'Successful Password Reset!',
  action: 'Go to landing page',
};

export const RESET_PWD_CONFIRM = {
  message: 'Successful Password Reset!',
  action: 'Go to login',
};

export const REQUEST_RESET_PASSWORD_CONFIRM = {
  message: 'We Have E-mailed Your Password Reset Link!',
  action: 'Close',
};
