import { Cell } from 'react-table';

import Checkbox from './Checkbox';

export default function RowCheckbox<D extends Record<string, unknown>>({
  row,
}: Cell<D>): JSX.Element {
  return (
    <div className="row_checkbox">
      <Checkbox {...row.getToggleRowSelectedProps()} />
    </div>
  );
}
