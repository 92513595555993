import { useQuery } from 'react-query';

import { getProductLabels } from '../../../../../api/queries';
import ServerStateKeys from '../../../../../utils/server-state-keys';
import { UseDocumentLabels } from './types';

export default function useDocumentLabels(): UseDocumentLabels {
  const { data } = useQuery(ServerStateKeys.DocumentLabels, getProductLabels, { staleTime: 60000 });

  return { labels: data?.data || [] };
}
