import { useQuery } from 'react-query';

import { getUnreadCount } from '../../../api/queries';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UseNotificationsCount } from './types';

export default function useNotificationsCount(): UseNotificationsCount {
  const { data } = useQuery([ServerStateKeys.UnreadNotificationsCount], getUnreadCount, {
    staleTime: 10000,
    refetchInterval: 15000,
  });

  return { data: data?.data };
}
