import { MutableRefObject, useRef, useState } from 'react';
import { HeaderProps } from 'react-table';

import { ReactComponent as ArrowIcon } from '../../assets/icons/polygon.svg';
import { useOutsideClick } from '../../shared/hooks';
import Checkbox from './Checkbox';
import { DESELECT_ALL } from './constants';

export default function HeaderCheckbox<D extends Record<string, unknown>>({
  dispatch,
  toggleAllRowsSelected,
  getToggleAllRowsSelectedProps,
}: HeaderProps<D>): JSX.Element {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>();
  const { onChange, checked, indeterminate, ...rest } = getToggleAllRowsSelectedProps();

  useOutsideClick(ref, () => {
    setMenuOpen(false);
  });

  const deselectDisabled = !checked && !indeterminate;

  return (
    <div ref={ref as MutableRefObject<HTMLDivElement>} className="header_checkbox">
      <Checkbox checked={checked} indeterminate={indeterminate} onChange={onChange} {...rest} />
      <ArrowIcon fill="#03ABE3" onClick={() => setMenuOpen(!isMenuOpen)} />
      <div className={isMenuOpen ? 'header_checkbox__content open' : 'header_checkbox__content'}>
        <ul>
          <li className={checked ? '' : 'active'}>
            <button type="button" disabled={checked} onClick={() => toggleAllRowsSelected(true)}>
              Select All
            </button>
          </li>
          <li className={deselectDisabled ? '' : 'active'}>
            <button
              type="button"
              disabled={deselectDisabled}
              onClick={() => dispatch({ type: DESELECT_ALL })}
            >
              Deselect All
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
