import { OverviewPropTypes } from './types';

export default function Overview({ name, description }: OverviewPropTypes): React.ReactElement {
  return (
    <div className="help_overview">
      <h3 className="section_title">{name} Overview</h3>
      {/* eslint-disable-next-line react/no-danger */}
      <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}
