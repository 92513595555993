import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { registerUserRequest } from '../../../api/mutations';
import { RegisterUserRequest } from '../../../api/schemas';

type UseRegisterUser = {
  registerUser: (payload: RegisterUserRequest) => void;
  serverError?: Record<keyof RegisterUserRequest, string[]>;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function useRegisterUser(): UseRegisterUser {
  const { mutate, error, isLoading, isSuccess } = useMutation<
    AxiosResponse<void>,
    AxiosError<Record<keyof RegisterUserRequest, string[]>>,
    RegisterUserRequest
  >((payload: RegisterUserRequest) => registerUserRequest(payload));

  return { registerUser: mutate, serverError: error?.response?.data, isLoading, isSuccess };
}
