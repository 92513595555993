import { ProductDetailsField } from './types';

export const DETAILS_LIST: ProductDetailsField[] = [
  { id: 'sku', label: 'Supplier SKU' },
  { id: 'gxp_sku', label: 'GXP SKU', nonEditable: true },
  { id: 'manufacturer', label: 'Manufacturer' },
  { id: 'mpn', label: 'MPN' },
  { id: 'upc', label: 'UPC' },
  { id: 'hts', label: 'HTS' },
  { id: 'eccn', label: 'ECCN' },
  { id: 'category', label: 'Product Category' },
  { id: 'price', label: 'Vendor Price' },
  { id: 'lead_time_days', label: 'Vendor Lead Time' },
  { id: 'vendor_quantity_uom', label: 'Vendor Quantity UOM' },
  { id: 'price_expiration', label: 'Price Expiration' },
];

export const GXP_RANK_DESCRIPTION =
  'Represents where your products stand amongst other vendor products. Priority 1 means you are the first choice when a particular product needs to be ordered.';

export const COMPLETE_PERC_DESCRIPTION =
  'How many information fields such as images, technical specs, description etc. GoExpedi has on hand about your product. The more information is supplied, the less confusion a buyer may have when considering your product.';
