import { InviteMessagePropTypes } from './types';

export default function InviteMessage({
  id,
  label,
  placeholder,
  register,
}: InviteMessagePropTypes): React.ReactElement {
  return (
    <>
      <label htmlFor="invitation_message" className="form_input_label">
        {label}:
      </label>
      <textarea id={id} className="invitation_message" placeholder={placeholder} {...register()} />
    </>
  );
}
