import { object, ref, string } from 'yup';

export const CreateAccSchema = object({
  first_name: string().required('First Name is required.'),
  last_name: string().required('Last Name is required.'),
  phone_number: string().matches(/^(\+\d{1,2}[\s.-])?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, {
    excludeEmptyString: true,
    message: 'Phone number is not valid',
  }),
  department: string().required('Department is required.'),
  position: string().required('Position is required.'),
  password: string()
    .required('New Password is required.')
    .min(8, 'Must contain at least 8 characters.')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/, 'Must be alphanumeric.'),
  confirm_password: string()
    .required('Password Confirmation is required.')
    .min(8, 'Must contain at least 8 characters.')
    .oneOf([ref('password'), null], 'Passwords must match.'),
});
