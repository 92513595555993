import { useState } from 'react';

import { HelpPageContent, HelpTabs } from '../../components/help';

import { ROUTE } from '../../routes/constants';
import { PageRoute } from '../../shared/components';

export default function HelpPage(): React.ReactElement {
  const [activeTab, setActiveTab] = useState<number | null>(null);

  return (
    <>
      <PageRoute
        linkTo={ROUTE.HOMEPAGE}
        linkLabel="Home"
        currentPage="Vendor Portal Help & Resources"
      />
      <section className="help_page">
        <h2>Vendor Portal Help & Resources</h2>
        <div>
          <h1>Browse Below For More Information:</h1>
          <HelpTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <HelpPageContent activeTab={activeTab} />
        </div>
      </section>
    </>
  );
}
