import { useRecoilValue } from 'recoil';

import { activeProductSku, changedProductDetails, isEditMode } from '../../atoms';
import { PERMISSIONS } from '../../shared/constants';
import { usePermission } from '../../shared/hooks';
import { DetailsField, EditDetailsButton, ProductDescriptionField } from './edit-details';
import { useProductDetails } from './hooks';
import { DETAILS_LIST } from './constants';
import ProductImg from './ProductImg';
import ProductRanking from './ProductRanking';

export default function ProductDescription(): React.ReactElement {
  const gxp_sku = useRecoilValue(activeProductSku);
  const isEdit = useRecoilValue(isEditMode);
  const editedFields = useRecoilValue(changedProductDetails);

  const { data } = useProductDetails(gxp_sku);
  const { isAllowed } = usePermission(PERMISSIONS.EDIT_PRODUCTS);

  const { name, image_url, gxp_priority, percents_complete } = data || {};

  return (
    <>
      <div className="product_description">
        {image_url && <ProductImg src={image_url} alt="Product" />}
        <div className="product_description__content">
          <h3>{name}</h3>
          <ProductRanking priority={gxp_priority} percents={percents_complete} />
          <div className="details">
            <h6>Key Product Details:</h6>
            <ul>
              {DETAILS_LIST.map(({ id, label, nonEditable }) => (
                <li key={id}>
                  {label}:{' '}
                  <DetailsField
                    field={id}
                    value={data?.[id]}
                    isAllowedToEdit={!nonEditable && isAllowed}
                    isEdit={isEdit || !!editedFields[id]}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="description_container">
            <h6>Product Description:</h6>
            <ProductDescriptionField isEdit={isEdit} description={data?.description || ''} />
          </div>
        </div>
      </div>
      {isAllowed && <EditDetailsButton productId={gxp_sku} />}
    </>
  );
}
