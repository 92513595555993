import { useMutation, useQueryClient } from 'react-query';

import { reactivateUser } from '../../../api/mutations';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseReactivateUser = {
  reactivateUser: (id: number) => void;
};

export default function useReactivateUser(): UseReactivateUser {
  const queryClient = useQueryClient();

  const { mutate } = useMutation((id: number) => reactivateUser(id), {
    onSuccess: () => {
      queryClient.cancelQueries(ServerStateKeys.DeactivatedUsers);
      queryClient.invalidateQueries(ServerStateKeys.DeactivatedUsers);
    },
  });

  return { reactivateUser: mutate };
}
