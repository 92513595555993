import { IdType } from 'react-table';
import { atom } from 'recoil';

import {
  PatchedDocumentUpdateRequest,
  Product,
  ProductDocument,
  ProductDocumentSKURequest,
} from '../api/schemas';

export const productSkusToLink = atom({
  key: 'productSkusToLink',
  default: {} as Record<IdType<Product>, Product>,
});

export const documentToEdit = atom({ key: 'documentToEdit', default: {} as ProductDocument });

export const productSkusToUnlink = atom({
  key: 'productSkusToUnlink',
  default: {} as Record<IdType<ProductDocumentSKURequest>, ProductDocumentSKURequest>,
});

export const editedDocName = atom({
  key: 'editedDocName',
  default: {} as PatchedDocumentUpdateRequest,
});

export const isUploadingFilesToOrder = atom({
  key: 'isUploadingFilesToOrder',
  default: '',
});
