import { LoadingStatus } from '../types';
import { FileListPropTypes } from './types';
import UploadingStatus from './UploadingStatus';

export default function FileList({
  orderId,
  loadingStatuses,
  documentTypes: DocumentTypes,
}: FileListPropTypes): React.ReactElement {
  const renderRow = ({ id, name, loading, error, labelId }: LoadingStatus) => {
    return (
      <li key={name}>
        <UploadingStatus loading={loading} error={error} />
        <p className="file_name">{name}</p>
        {!error && (
          <DocumentTypes
            fileId={id}
            fileName={name}
            orderId={orderId as string}
            labelId={labelId}
          />
        )}
      </li>
    );
  };

  return (
    <div className="files">
      {loadingStatuses.length ? (
        <ul>{loadingStatuses.map(fileStatus => renderRow(fileStatus))}</ul>
      ) : (
        <div className="no-files">
          <p>NO FILES UPLOADED YET</p>
        </div>
      )}
    </div>
  );
}
