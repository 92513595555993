import { AxiosResponse } from 'axios';

import API from '../../utils/api';
import {
  ArchiveNotificationPayload,
  NotificationSettings,
  ReadNotificationPayload,
} from '../schemas';

export const updateSettings = (
  payload: NotificationSettings
): Promise<AxiosResponse<NotificationSettings>> =>
  API.put<NotificationSettings>('/api/notifications/settings/', payload);

export const archiveNotification = ({
  notifications_ids,
  action,
}: ArchiveNotificationPayload): Promise<void> =>
  API.patch('/api/notifications/archive-restore/', { notifications_ids, action });

export const markReadStatus = ({ mark_as, marked_ids }: ReadNotificationPayload): Promise<void> =>
  API.post('/api/notifications/mark-read-status/', { mark_as, marked_ids });

export const dismissLatestUpdateMessage = (): Promise<void> =>
  API.post('/api/notifications/latest-update-message/');
