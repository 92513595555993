import { memo, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as ArchiveIcon } from '../../../assets/icons/bold-archive.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/bold-download.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/bold-edit.svg';
import { ReactComponent as UnlinkIcon } from '../../../assets/icons/unlink.svg';

import { ProductDocument } from '../../../api/schemas';
import { documentToEdit, prodDocsToUnlink } from '../../../atoms';
import { Modal, Restricted, Tooltip, WarningWindow } from '../../../shared/components';
import { PERMISSIONS } from '../../../shared/constants';
import { useFileDownload } from '../../../shared/hooks';
import { useMoveToArchiveDocs } from '../../document-repository/persistent-product/hooks';
import { WARN_CONTENT_CONFIRM_ARCHIVE } from '../../document-repository/persistent-product/constants';

function ActionButtonsCell(documentData: ProductDocument): React.ReactElement {
  const { id, name, extension, url } = documentData;

  const [isConfirmArchive, setIsConfirmArchive] = useState(false);

  const setDocumentToEdit = useSetRecoilState(documentToEdit);
  const setDocToUnlink = useSetRecoilState(prodDocsToUnlink);

  const { downloadSingle, isDownloading } = useFileDownload();
  const { moveToArchive, isArchiving } = useMoveToArchiveDocs();

  const handleArchive = () => {
    setIsConfirmArchive(false);
    moveToArchive({ [id]: documentData });
  };

  return (
    <div className="action_btns__container">
      {isDownloading ? (
        <button type="button" name="Download Loading">
          <div className="loader" />
        </button>
      ) : (
        <Tooltip content="Download">
          <button
            type="button"
            name="Download"
            onClick={() => downloadSingle(url, `${name}.${extension}`)}
          >
            <DownloadIcon stroke="#FFF" />
          </button>
        </Tooltip>
      )}
      {isArchiving ? (
        <button type="button" name="Archive Loading">
          <div className="loader" />
        </button>
      ) : (
        <Restricted to={PERMISSIONS.ARCHIVE_DOCS}>
          <Tooltip content="Archive">
            <button type="button" name="Archive" onClick={() => setIsConfirmArchive(true)}>
              <ArchiveIcon stroke="#FFF" width="12px" height="12px" />
            </button>
          </Tooltip>
        </Restricted>
      )}
      <Restricted to={PERMISSIONS.EDIT_DOCS}>
        <>
          <Tooltip content="Edit">
            <button type="button" name="Edit" onClick={() => setDocumentToEdit(documentData)}>
              <EditIcon stroke="#FFF" width="12px" height="12px" />
            </button>
          </Tooltip>
          <Tooltip content="Unlink">
            <button
              type="button"
              name="Unlink"
              onClick={() => setDocToUnlink({ [id]: documentData })}
            >
              <UnlinkIcon fill="#FFF" width="15px" height="15px" />
            </button>
          </Tooltip>
        </>
      </Restricted>

      <Modal show={isConfirmArchive} closeModal={() => setIsConfirmArchive(false)}>
        <WarningWindow content={WARN_CONTENT_CONFIRM_ARCHIVE} confirm={handleArchive} />
      </Modal>
    </div>
  );
}

export default memo(ActionButtonsCell);
