import { ProdDetailsTabs } from './constants';
import { TabsPropTypes } from './types';

export default function SelectTabs({ activeTab, setActiveTab }: TabsPropTypes): React.ReactElement {
  return (
    <div className="tabs_container">
      {Object.values(ProdDetailsTabs).map(tab => {
        const isSelected = tab === activeTab;

        return (
          <button
            key={tab}
            type="button"
            className={`tab ${isSelected ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        );
      })}
    </div>
  );
}
