import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { InfiniteData, useInfiniteQuery } from 'react-query';

import { searchProductsByField } from '../../../../../api/queries';
import { Product, ProductPaginated } from '../../../../../api/schemas';
import { QueryParamsFilter } from '../../../../../shared/types';
import ServerStateKeys from '../../../../../utils/server-state-keys';
import { UseSearchProductsByField } from './types';

const concatPages = (data?: InfiniteData<AxiosResponse<ProductPaginated>>) =>
  data?.pages.reduce(
    (allPages, { data: { products } }) => allPages.concat(...products),
    [] as Product[]
  );

export default function useSearchProductsByField(): UseSearchProductsByField {
  const [params, setParams] = useState<QueryParamsFilter<Product>>({});

  const { data, isLoading, isFetching, isSuccess, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [ServerStateKeys.SearchedProductsByField, params],
    ({ pageParam = 1 }) => searchProductsByField({ ...params, page: pageParam }),
    {
      staleTime: 30000,
      enabled: !!Object.keys(params).length,
      // append ID properties to products in order to indentify them
      select: ({ pages, ...response }) => ({
        ...response,
        pages: pages.map(page => ({
          ...page,
          data: {
            ...page.data,
            products: page.data.products.map(product => ({ ...product, id: product.gxp_sku })),
          },
        })),
      }),
      getNextPageParam: ({ data: { page, total_pages } }) =>
        total_pages > page ? page + 1 : undefined,
    }
  );

  const fetch = useCallback((parameters?: QueryParamsFilter<Product>) => {
    if (parameters) {
      setParams(parameters);
    }
    fetchNextPage();
  }, []);

  return {
    fetch,
    fetchNextPage,
    data: concatPages(data),
    isLoading,
    isFetching,
    hasNextPage,
    isSuccess,
  };
}
