export enum PERMISSIONS {
  VIEW_CORP_AGREEMENTS = 'corporate_agreements.view_corporateagreement',
  VIEW_CORP_AGREEMENTS_FILES = 'corporate_agreements.view_file_url',
  VIEW_PRODUCT_DOCS = 'persistent_documents.view_product_docs',
  UPLOAD_DOCS = 'persistent_documents.upload_document',
  EDIT_DOCS = 'persistent_documents.edit_document',
  ARCHIVE_DOCS = 'persistent_documents.archive_documents',
  UPLOAD_ORDER_DOCS = 'order_documents.upload_document',
  EDIT_ORDER_DOCS = 'order_documents.edit_document',
  ARCHIVE_ORDER_DOCS = 'order_documents.archive_documents',
  SOFT_DELETE_DOCS = 'documents_archive.delete_document',
  RESTORE_DELETED_DOCS = 'documents_archive.restore_document',
  EDIT_PRODUCTS = 'products.edit_products',
  ARCHIVE_NOTIFICATIONS = 'notifications.change_companynotification',
  VIEW_REGULAR_USERS = 'accounts.view_regularuser',
  CHANGE_REGULAR_USERS = 'accounts.change_regularuser',
}
