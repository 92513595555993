import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { uploadOrderDocument } from '../../../../api/mutations';
import ServerStateKeys from '../../../../utils/server-state-keys';
import { FileData, LoadingStatus, UploadServerError } from '../../types';
import { handleUploadFile, updateLoadingStatuses } from '../../utils/helpers';
import isFileTypeValid from '../validations';
import { UseOrderFileUpload } from './types';

export default function useOrderFileUpload(orderId: string): UseOrderFileUpload {
  const [loadingStatuses, setLoadingStatuses] = useState<LoadingStatus[]>([]);

  const queryClient = useQueryClient();

  const { mutate } = useMutation<AxiosResponse<FileData>, AxiosError<UploadServerError>, FormData>(
    (file: FormData) => uploadOrderDocument(orderId, file),
    {
      onSuccess: ({ data }) => {
        setLoadingStatuses(prevStatuses => updateLoadingStatuses(data, prevStatuses));

        // refetch linked order files
        queryClient.cancelQueries([ServerStateKeys.OrderDocsOrderList, orderId]);
        queryClient.invalidateQueries([ServerStateKeys.OrderDocsOrderList, orderId]);
      },
    }
  );

  const upload = (files: File[]) => {
    handleUploadFile(files, isFileTypeValid, setLoadingStatuses, mutate);
  };

  return { loadingStatuses, upload };
}
