import { useCallback } from 'react';
import { Column } from 'react-table';
import { useRecoilState } from 'recoil';

import { ProductDocumentSKURequest } from '../../../../api/schemas';
import { productSkusToUnlink } from '../../../../atoms';
import { useAddProductRecordsColumns } from '../../persistent-product/file-upload/hooks';
import SelectedProductsTable from './SelectedProductsTable';

export default function SelectedProductsToUnlink(): React.ReactElement {
  const [prodsToUnlink, setProdsToUnlink] = useRecoilState(productSkusToUnlink);

  const { columns } = useAddProductRecordsColumns({ isLoaded: true, isTitleLink: true });

  const handleUnselect = useCallback((selectedRows: Record<string, ProductDocumentSKURequest>) => {
    setProdsToUnlink(selectedRows);
  }, []);

  return (
    <div className="selected_products_wrapper">
      <SelectedProductsTable
        title="SKUs to unlink:"
        data={Object.values(prodsToUnlink)}
        columns={columns as Column<ProductDocumentSKURequest>[]}
        setSelectedRows={handleUnselect}
      />
    </div>
  );
}
