import { memo } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as DoubleArrowIcon } from '../../assets/icons/double_arrow.svg';

type PaginationPropTypes = {
  currentPage: number;
  totalPages: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  previousPage: () => void;
  nextPage: () => void;
};

function Pagination({
  currentPage,
  totalPages,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
}: PaginationPropTypes): React.ReactElement | null {
  const getPages = () => {
    let startPage: number;
    let endPage: number;

    if (totalPages <= 5) {
      // less than 5 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= 3) {
      // more than 5 total pages so calculate start and end pages
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 1 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }

    return Array.from({ length: endPage + 1 - startPage }, (_, i) => startPage + i);
  };

  if (totalPages === 1) {
    return null;
  }

  return (
    <div className="pagination">
      {totalPages > 5 && (
        <button
          className="page_step prev_page"
          type="button"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <DoubleArrowIcon width="14px" height="10px" fill="#03ABE3" />
        </button>
      )}
      <button
        className="page_step prev_page"
        type="button"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        <ArrowIcon width="14px" height="10px" fill="#03ABE3" />
      </button>
      {getPages().map(
        (page: number): JSX.Element => (
          <button
            key={page}
            className={currentPage === page ? 'pagination_item active' : 'pagination_item'}
            type="button"
            onClick={() => gotoPage(page - 1)}
          >
            {page}
          </button>
        )
      )}
      <button
        className="page_step"
        type="button"
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        <ArrowIcon width="14px" height="10px" fill="#03ABE3" />
      </button>
      {totalPages > 5 && (
        <button
          className="page_step"
          type="button"
          onClick={() => gotoPage(totalPages - 1)}
          disabled={!canNextPage}
        >
          <DoubleArrowIcon width="14px" height="10px" fill="#03ABE3" />
        </button>
      )}
    </div>
  );
}

export default memo(Pagination);
