import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow-right.svg';
import { useOutsideClick } from '../../../../shared/hooks';
import { DocumentTypesPropTypes } from '../../file-upload/types';
import { useEditOrderDoc, useOrderLabels } from '../hooks';

export default function DocumentTypes({
  fileName,
  fileId,
  orderId,
}: DocumentTypesPropTypes): React.ReactElement {
  const [fileLabel, setFileLabel] = useState<number>();
  const [isOpen, setOpen] = useState(false);

  const { labels } = useOrderLabels();
  const { editFile } = useEditOrderDoc(orderId);

  const referenceRef = useRef<HTMLDivElement>();
  const popperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (orderId && fileId && fileLabel) {
      const name = fileName.replace(/\.[^/.]+$/, '');

      editFile({ fileId, fileName: name, fileLabelId: fileLabel });
    }
  }, [fileId, fileLabel]);

  const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [65, 0],
        },
      },
      {
        name: 'hide',
        enabled: true,
      },
    ],
  });

  useOutsideClick(referenceRef, () => {
    setOpen(false);
  });

  const handleSelectType = (selectedType: number): void => {
    setFileLabel(selectedType);
    setOpen(false);
  };

  return (
    <div ref={referenceRef as MutableRefObject<HTMLDivElement>} className="doc_types">
      <button
        type="button"
        className="doc_types__toggler"
        name="select label"
        onClick={() => setOpen(!isOpen)}
      >
        <div className="type_text">
          {labels.find(({ id }) => id === fileLabel)?.name || 'Add Doc Type'}
        </div>
        <ArrowIcon width="10px" height="6px" fill="#006298" />
      </button>
      <div
        id="popper"
        data-show={isOpen}
        ref={popperRef}
        style={styles.popper}
        {...attributes.popper}
      >
        <div style={styles.offset} className="doc_types__content">
          {labels.map(({ id, name }) => (
            <button
              type="button"
              className="doc_types__type"
              key={id}
              onClick={() => handleSelectType(id)}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
