type TotalItemsCountPropTypes = {
  currentPage: number;
  pageSize: number;
  totalItems?: number;
  totalPages: number;
};

export default function TotalItemsCount({
  currentPage,
  pageSize,
  totalItems,
  totalPages,
}: TotalItemsCountPropTypes): React.ReactElement | null {
  if (!!totalItems && totalPages > 1)
    return (
      <div className="showing_total_items">
        Items {currentPage * pageSize + 1} to {Math.min((currentPage + 1) * pageSize, totalItems)}
        {' of '}
        {totalItems} total
      </div>
    );

  return null;
}
