import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { Checkbox } from '../../shared/components';
import { useInsideClick, useUserData } from '../../shared/hooks';
import { useTermsConditionsText, useTermsAccept } from './hooks';

type TermsConditionsPropTypes = {
  acceptedTerms: () => void;
};

export default function TermsConditions({
  acceptedTerms,
}: TermsConditionsPropTypes): React.ReactElement {
  const acceptCheckbox = useRef<HTMLLabelElement>();
  const termsInnerRef = useRef<HTMLDivElement>();

  const [isChecked, setChecked] = useState(false);
  const [scrolledToEnd, setScrolledToEnd] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const { data: { accepted_at_least_once_terms_and_conditions } = {} } = useUserData();
  const { data: { text } = {} } = useTermsConditionsText();
  const { accept, isLoading, isSuccess } = useTermsAccept();

  const onScroll = () => {
    if (termsInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = termsInnerRef.current;
      if (scrollTop + clientHeight + 10 >= scrollHeight) {
        setScrolledToEnd(true);
        setShowHint(false);
      }
    }
  };

  useEffect(() => {
    if (text) {
      onScroll();
    }
  }, [text]);

  useEffect(() => {
    if (isSuccess) {
      acceptedTerms();
    }
  }, [isSuccess]);

  const handleAcceptTerms = () => {
    accept();
  };

  const showErrorHint = () => {
    if (!scrolledToEnd) {
      setShowHint(true);
    }
  };

  useInsideClick(acceptCheckbox, showErrorHint);

  return (
    <div className="terms_conditions__container">
      <div className="content">
        <p>
          {accepted_at_least_once_terms_and_conditions
            ? 'Our Terms & Conditions have Changed! Please review and accept our latest Terms & Conditions below before continuing:'
            : 'You must read and agree to the Terms & Conditions to access the Vendor Portal:'}
        </p>
        <div
          className="terms_conditions"
          onScroll={onScroll}
          ref={termsInnerRef as MutableRefObject<HTMLDivElement>}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: text as string }}
        />
        {showHint && <p className="error">Please read Terms & Conditions before accepting it.</p>}
        <div className="actions">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            ref={acceptCheckbox as MutableRefObject<HTMLLabelElement>}
            className={scrolledToEnd ? 'clickwrap_checkbox enabled' : 'clickwrap_checkbox'}
          >
            <Checkbox
              isChecked={isChecked}
              handleChange={() => setChecked(!isChecked)}
              disabled={!scrolledToEnd}
            />
            <span>I have read and accept the Terms & Conditions.</span>
          </label>
          {isLoading ? (
            <button className="continue loader" type="button">
              <div />
            </button>
          ) : (
            <button
              className="continue"
              type="button"
              name="Continue"
              disabled={!isChecked}
              onClick={handleAcceptTerms}
            >
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
