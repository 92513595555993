export enum EditDocMenus {
  EDIT = 'edit',
  LINK = 'link',
  UNLINK = 'unlink',
}

export type EditDocNamePropTypes = { autoclose?: boolean };

export type EditDocNameInputs = {
  name: string;
};
