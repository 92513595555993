import API from '../../utils/api';
import { ChangedProductDetailsField, LinkDocumentsToProductRequest } from '../schemas';

export const editProductDetails = (
  sku: string,
  payload: ChangedProductDetailsField[]
): Promise<void> => API.patch(`/api/products/${sku}/edit`, { edited_fields: payload });

export const linkDocsToProduct = (payload: LinkDocumentsToProductRequest): Promise<void> =>
  API.post('/api/products/link-docs', payload);
