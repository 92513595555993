import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { archiveProductDocuments } from '../../../../api/mutations';
import { ProductDocToArchive } from '../../../../api/schemas';
import ServerStateKeys from '../../../../utils/server-state-keys';
import { FileData } from '../../types';
import { SelectedItems, UseMoveToArchiveDocs } from './types';

export default function useMoveToArchiveDocs(): UseMoveToArchiveDocs {
  const [itemsNotArchived, setItemsNotArchived] = useState({} as SelectedItems);
  let itemsToArchive = {} as SelectedItems;

  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    (docsToArchive: ProductDocToArchive[]) => archiveProductDocuments(docsToArchive),
    {
      onSuccess: ({ data: { errors } }) => {
        queryClient.cancelQueries(ServerStateKeys.PersistentDocuments);
        queryClient.invalidateQueries(ServerStateKeys.PersistentDocuments);

        const notArchived = errors.reduce((acc, errorId) => {
          return { ...acc, [errorId]: itemsToArchive[errorId] };
        }, {} as SelectedItems);

        setItemsNotArchived(notArchived);
      },
    }
  );

  const moveToArchive = (selectedDocs: SelectedItems) => {
    itemsToArchive = selectedDocs;
    const docsToArchive = Object.values(selectedDocs).map(({ id, name, label }: FileData) => ({
      id,
      name,
      label,
    }));

    mutate(docsToArchive);
  };

  return {
    itemsNotArchived,
    moveToArchive,
    isArchiving: isLoading,
    isMovedToArchive: isSuccess,
  };
}
