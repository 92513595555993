import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import { EmailImputPropTypes } from './types';

export default function EmailInput({
  id,
  label,
  error,
  placeholder,
  required,
  canDelete,
  register,
  removeUser,
}: EmailImputPropTypes): React.ReactElement {
  const errorMsg = typeof error === 'string' ? error : error?.message;

  return (
    <>
      <label htmlFor="email" className="form_input_label">
        {label}
        {required && <span className="asterix">*</span>}:
        {errorMsg && <p className="form_input_error">{errorMsg}</p>}
      </label>
      <div className="field__row">
        <div className={errorMsg ? 'email_input invalid' : 'email_input'}>
          <input
            id={id}
            type="email"
            className="email"
            placeholder={placeholder}
            autoComplete="off"
            {...register()}
          />
          {canDelete && (
            <button
              type="button"
              name="Remove Email"
              aria-label="Remove Email"
              className="remove_email"
              onClick={removeUser}
            >
              <CloseIcon fill="#676767" />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
