import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as UserProfileIcon } from '../../assets/icons/user.svg';
import { ReactComponent as ServicePlansIcon } from '../../assets/icons/service-plans.svg';
import { ReactComponent as AccMgmtIcon } from '../../assets/icons/account-management.svg';

import { FrequencyTypes, WeekDays } from '../../api/schemas';
import { RadioOption } from './types';
import { PERMISSIONS } from '../../shared/constants';

export const SETTINGS_PAGES = {
  CHANGE_PWD: 'Change Password',
  ALERTS: 'Alerts',
  USER_PROFILE: 'User Profile',
  SERVICE_PLANS: 'Service Plans',
  ACC_MGMT: 'Account Management',
};

export const SETTINGS_SPACES = [
  { icon: UserProfileIcon, title: SETTINGS_PAGES.USER_PROFILE },
  { icon: SettingsIcon, title: SETTINGS_PAGES.CHANGE_PWD },
  { icon: AlertIcon, title: SETTINGS_PAGES.ALERTS, className: 'strokedIcon' },
  { icon: ServicePlansIcon, title: SETTINGS_PAGES.SERVICE_PLANS },
  { icon: AccMgmtIcon, title: SETTINGS_PAGES.ACC_MGMT, permission: PERMISSIONS.VIEW_REGULAR_USERS },
];

export const IS_ENABLED_SECTION: RadioOption<boolean>[] = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const EMAIL_FREQUENCY_SECTION: RadioOption<FrequencyTypes>[] = [
  { value: FrequencyTypes.REALTIME, label: 'Realtime - Receive alerts as they happen' },
  { value: FrequencyTypes.DAILY, label: 'Daily - Receive aggregated email once daily' },
  { value: FrequencyTypes.WEEKLY, label: 'Weekly - Receive aggregated email once weekely' },
];

export const WEEK_DAYS_SECTION: RadioOption<WeekDays>[] = [
  { value: WeekDays.MONDAY, label: 'Monday' },
  { value: WeekDays.TUESDAY, label: 'Tuesday' },
  { value: WeekDays.WEDNESDAY, label: 'Wednesday' },
  { value: WeekDays.THURSDAY, label: 'Thursday' },
  { value: WeekDays.FRIDAY, label: 'Friday' },
];

export const CONFIRM_CONTENT = {
  title: 'Email Updates',
  description: 'Your settings have been successfully updated and saved.',
};
