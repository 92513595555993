import { ReactComponent as SuccessIcon } from '../../assets/icons/success-narrow.svg';
import { ReactComponent as DisabledIcon } from '../../assets/icons/close.svg';

import FeatureDescription from './FeatureDescription';
import { ServicePlanFeature } from '../../api/schemas';

type FeatureSectionPropTypes = {
  title: string;
  features: ServicePlanFeature[];
};

export default function FeatureSection({
  title,
  features,
}: FeatureSectionPropTypes): React.ReactElement {
  return (
    <div className="feature_section">
      <p className="section_name">{title}:</p>
      <ul className="feature_list">
        {features.map(({ name, tooltip, is_active }) => (
          <li key={name}>
            {is_active ? (
              <div className="feature_row">
                <SuccessIcon width="11px" height="8px" stroke="#006298" />
                <span className="feature_name">{name}</span>
                {tooltip && <FeatureDescription tooltip={tooltip} />}
              </div>
            ) : (
              <div className="feature_row disabled">
                <DisabledIcon width="9px" height="9px" fill="#939BAE" />
                <span className="feature_name">{name}</span>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
