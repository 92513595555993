import { Column } from 'react-table';

import {
  ProductDocument,
  ProductDocumentPaginated,
  ProductDocumentSKURequest,
} from '../../../../api/schemas';
import { AsyncQuery, QueryParams } from '../../../../shared/types';
import { PersistentProdDocsTable } from '../types';

export type SelectedItems = Record<string, ProductDocument>;

export type ProductDocsColumns = {
  columns: Column<PersistentProdDocsTable>[];
};

export type UseMoveToArchiveDocs = {
  itemsNotArchived: SelectedItems;
  moveToArchive: (selectedDocs: SelectedItems) => void;
  isArchiving: boolean;
  isMovedToArchive: boolean;
};

export enum SkuType {
  SKU = 'SKU',
  GXP_SKU = 'GXP SKU',
}

export type UseViewAllSku = {
  skuIds: string[];
  skuType?: SkuType;
  documentName?: string;
  viewAllSku: (skuId: string[], name: string, sku: SkuType) => void;
  closeWindow: () => void;
};

export type UseLinkProducts = {
  assingProducts: (
    docId: string,
    linkedProducts: Omit<ProductDocumentSKURequest, 'name'>[]
  ) => void;
  isLoading: boolean;
  isSuccess: boolean;
};

export type UsePersistentProductDocs = AsyncQuery & {
  data?: ProductDocumentPaginated;
  isSuccess: boolean;
  fetch: (params?: QueryParams) => void;
  refetch: () => void;
};
