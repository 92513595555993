import { useLocation } from 'react-router-dom';

import { ROUTE } from '../../routes/constants';
import { useAuth } from '../../shared/hooks';
import { Header } from '../header';
import { Navbar, NavbarToggler } from '../navbar';

type NavigationPropTypes = {
  isNavOpen: boolean;
  toggleOpenState: () => void;
};

export const Navigation = ({
  isNavOpen,
  toggleOpenState,
}: NavigationPropTypes): React.ReactElement | null => {
  const { pathname } = useLocation();
  const { token } = useAuth();

  if (!token || pathname.includes(ROUTE.LOGIN)) {
    return null;
  }

  return (
    <>
      <header>
        <NavbarToggler isOpen={isNavOpen} toggle={toggleOpenState} />
        <Header />
      </header>
      <Navbar isOpen={isNavOpen} toggle={toggleOpenState} />
    </>
  );
};
