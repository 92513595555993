import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { loginUserFromToken } from '../../../api/mutations';
import { TemporaryTokenRequest } from '../../../api/schemas';
import { useAuth } from '../../../shared/hooks';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UseLoginFromTemporaryToken } from './types';

export default function useLoginFromTemporaryToken(): UseLoginFromTemporaryToken {
  const [errorMsg, setErrorMsg] = useState('');
  const { signin } = useAuth();

  const queryClient = useQueryClient();

  const { mutate, data, isLoading, isSuccess, isError, error } = useMutation(
    (params: TemporaryTokenRequest) => loginUserFromToken(params),
    {
      onError: ({ response }) => {
        const errorFromApi = response?.data.detail;
        setErrorMsg(errorFromApi || 'Something went wrong');
      },
      onSuccess: ({ data: tokenPair }) => {
        // refetch user data
        queryClient.cancelQueries([ServerStateKeys.UserData]);
        queryClient.invalidateQueries([ServerStateKeys.UserData]);

        signin(tokenPair);
      },
    }
  );

  const forceLogin = (params: TemporaryTokenRequest) => {
    mutate(params);
  };

  return {
    forceLogin,
    data: data?.data,
    isLoading,
    isError,
    isSuccess,
    errorMsg,
    error: error?.response,
  };
}
