import { useEffect, useState } from 'react';
import { Cell, Column } from 'react-table';

import { CorporateAgreementFileNoUrl } from '../../../../api/schemas';
import { PERMISSIONS } from '../../../../shared/constants';
import usePermission from '../../../../shared/hooks/usePermission';
import { formatDateTime } from '../../../../shared/utils';
import { NOT_APPLICABLE } from '../../constants';
import DocStatus from '../DocStatus';
import SubRowsActionButtons from '../SubRowsActionButtons';

type SubrowsColumns = {
  columns: Column<CorporateAgreementFileNoUrl>[];
};

function renderDateCell(value: string) {
  if (!value || value === NOT_APPLICABLE) {
    return <div>{NOT_APPLICABLE}</div>;
  }
  return <div>{formatDateTime(value)}</div>;
}

export default function useSubrowsColumns(): SubrowsColumns {
  const [columns, setColumns] = useState<Column<CorporateAgreementFileNoUrl>[]>([
    {
      Header: 'Document Name',
      accessor: 'name',
    },
    {
      Header: 'Expiration Date',
      accessor: 'expiration_date',
      className: 'sub-row__expiration',
      Cell: ({ value }: Cell<CorporateAgreementFileNoUrl>) => renderDateCell(value),
    },
    {
      Header: 'Modified Date',
      accessor: 'status_modified_date',
      className: 'sub-row__modified',
      Cell: ({ value }: Cell<CorporateAgreementFileNoUrl>) => renderDateCell(value),
    },
    {
      Header: 'Document Status',
      accessor: 'status',
      Cell: DocStatus,
    },
  ]);

  const { isAllowed } = usePermission(PERMISSIONS.VIEW_CORP_AGREEMENTS_FILES);

  useEffect(() => {
    if (columns.length === 4 && isAllowed) {
      setColumns(prevColumns => [
        {
          Header: 'Actions',
          accessor: 'actions',
          className: 'sub-row__actions',
          disableSortBy: true,
          Cell({ row }: Cell<CorporateAgreementFileNoUrl>) {
            return <SubRowsActionButtons fileData={row.original} />;
          },
        },
        ...prevColumns,
      ]);
    }
  }, [isAllowed]);

  return { columns };
}
