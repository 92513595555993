import { useQuery } from 'react-query';

import { getCompanyData } from '../../../api/queries';
import { Company } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';

export type UseCompanyData = {
  data?: Company;
  isLoading: boolean;
};

const useVendorData = (): UseCompanyData => {
  const { data, isLoading } = useQuery([ServerStateKeys.CompanyData], getCompanyData, {
    staleTime: Infinity,
  });

  return {
    data: data?.data,
    isLoading,
  };
};

export default useVendorData;
