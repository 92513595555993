import axios from 'axios';
import { useQuery } from 'react-query';

import ServerStateKeys from '../../../utils/server-state-keys';
import { ClientIpResponse, UseClientIp } from './types';

const getData = () => axios.get<ClientIpResponse>('https://geolocation-db.com/json/');

export default function useClientIp(): UseClientIp {
  const { data } = useQuery([ServerStateKeys.ClientIp], getData, { staleTime: Infinity });

  return { ip: data?.data.IPv4 || '' };
}
