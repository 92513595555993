import { useQuery } from 'react-query';

import { getHelpSections } from '../../../api/queries';
import { HelpSectionName } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseHelpSections = {
  data?: HelpSectionName[];
  isLoading: boolean;
};

export default function useHelpSections(): UseHelpSections {
  const { data, isLoading } = useQuery([ServerStateKeys.HelpSections], getHelpSections, {
    staleTime: Infinity,
  });

  return { data: data?.data, isLoading };
}
