import { Column, useTable } from 'react-table';

import { LoaderModal } from '../../table';
import { useTableColumnLoading, useTableDataLoading } from '../../table/hooks';
import { OrderDocumentsTableData } from './types';

type OrderDocsPropTypes = {
  columns: Column<OrderDocumentsTableData>[];
};

/**
 * Need to create this extra Order Docs loading placeholder component in order to initiate
 * <Table /> only once async pageIndex is fetched;
 */
export default function OrderDocsTableLoading({ columns }: OrderDocsPropTypes): React.ReactElement {
  const { tableData, getRowId } = useTableDataLoading({
    placeholderRows: 10,
    isLoading: true,
    data: [] as OrderDocumentsTableData[],
  });
  const { tableColumns } = useTableColumnLoading({ columns, isLoading: true });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: tableColumns,
    data: tableData,
    getRowId,
  });

  return (
    <>
      <LoaderModal />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            /* eslint-disable react/jsx-key */
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <div className="header-cell">{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} className={cell.column.className ?? ''}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
