import { Product } from '../../../api/schemas';

export const HIDEABLE_COLUMNS: {
  id: keyof Product;
  label: string;
}[] = [
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'price',
    label: 'Price',
  },
  {
    id: 'lead_time_days',
    label: 'Lead Time',
  },
  {
    id: 'ytd_end_user_sales',
    label: 'YTD End User Sales',
  },
  {
    id: 'ytd_market_share',
    label: 'YTD Mkt Share',
  },
];

export const ITEMS_PER_PAGE_OPTIONS = [10, 20, 50, 100];
