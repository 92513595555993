import { useMutation } from 'react-query';

import { restoreArchivedDocuments } from '../../../../api/mutations';
import { ArchivedDocument, DocumentsRestoreRequest } from '../../../../api/schemas/archive';
import { UseRestoreDocs } from './types';

export default function useRestoreDocs(): UseRestoreDocs {
  const { mutate, isLoading, isSuccess } = useMutation((docsToRestore: DocumentsRestoreRequest[]) =>
    restoreArchivedDocuments(docsToRestore)
  );

  const restore = (files: ArchivedDocument[]) => {
    const docsToRestore = files.map(({ id, name, label, order_id }) => ({
      id,
      name,
      label,
      order_id,
    }));

    mutate(docsToRestore);
  };

  return { restore, isLoading, isSuccess };
}
