import { useMutation, useQueryClient } from 'react-query';

import { cancelUserInvite } from '../../../api/mutations';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseCancelUserInvite = {
  cancelInvite: (id: number) => void;
};

export default function useCancelUserInvite(): UseCancelUserInvite {
  const queryClient = useQueryClient();

  const { mutate } = useMutation((id: number) => cancelUserInvite(id), {
    onSuccess: () => {
      queryClient.cancelQueries(ServerStateKeys.PendingInvites);
      queryClient.invalidateQueries(ServerStateKeys.PendingInvites);
    },
  });

  return { cancelInvite: mutate };
}
