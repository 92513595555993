import { useUserData } from '../../shared/hooks';
import { useVendorData } from './hooks';

export default function UserInfo(): JSX.Element {
  const { data: { first_name, last_name, force_logged_in } = {}, isLoading } = useUserData();
  const { data: { name } = {}, isLoading: isLoadingCompanyData } = useVendorData();

  if (isLoading || isLoadingCompanyData) {
    return <div className="user-name loading" />;
  }

  return (
    <>
      {force_logged_in && (
        <>
          <div className="badge">GXP Employee</div>
          <div className="divider" />
        </>
      )}
      <div className="user-name">{`${first_name} ${last_name} - ${name}`}</div>
    </>
  );
}
