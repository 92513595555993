import { ReactComponent as AnalyticsIcon } from '../../assets/icons/analytics.svg';
import { ReactComponent as DocRepositoryIcon } from '../../assets/icons/doc-repository.svg';
import { ReactComponent as ProdCatalogIcon } from '../../assets/icons/prod-catalog.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';

import { ROUTE } from '../../routes/constants';

import { NotificationsIcon } from '../notifications';

export const HOME_SECTIONS = [
  {
    id: 'Analytics/Dashboard',
    title: 'Analytics/Dashboard',
    icon: AnalyticsIcon,
    route: ROUTE.DASHBOARD,
  },
  {
    id: 'Product Catalog',
    title: 'Product Catalog',
    icon: ProdCatalogIcon,
    route: ROUTE.PROD_CATALOG,
  },
  {
    id: 'Documents',
    title: 'Documents',
    icon: DocRepositoryIcon,
    route: ROUTE.DOC_REP,
  },
  {
    id: 'Notifications',
    title: 'Notifications',
    icon: NotificationsIcon,
    route: ROUTE.NOTIFICATIONS,
  },
  {
    id: 'Settings',
    title: 'Settings',
    icon: SettingsIcon,
    route: ROUTE.SETTINGS,
  },
  {
    id: 'Help',
    title: 'Help',
    icon: HelpIcon,
    route: ROUTE.HELP,
  },
];
