import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../routes/constants';

export default function NotFound(): React.ReactElement {
  const history = useHistory();

  const handleBack = () => {
    history.push(ROUTE.HOMEPAGE);
  };

  return (
    <section className="not_found">
      <h3>404</h3>
      <h1>Uh-Oh!</h1>
      <p>Something went wrong, or the page you were looking for cannot be found.</p>
      <button type="button" name="Back to the main page" onClick={handleBack}>
        Back to the main page
      </button>
    </section>
  );
}
