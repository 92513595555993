import { memo } from 'react';
import { ColumnInstance, HeaderProps } from 'react-table';

import { TableSorting } from '../table';
import { SORT_DIRECTIONS } from '../table/constants';
import InfoIcon from './InfoIcon';

type InfoIconHeaderPropTypes<D extends Record<string, unknown>> = Pick<
  HeaderProps<D>,
  'toggleSortBy'
> &
  Pick<ColumnInstance, 'id' | 'isSorted' | 'isSortedDesc'> & {
    name: string;
    description: string;
  };

function InfoIconHeader<D extends Record<string, unknown>>({
  id,
  name,
  description,
  isSorted,
  isSortedDesc,
  toggleSortBy,
}: InfoIconHeaderPropTypes<D>): React.ReactElement {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div className="header_info__container" onClick={() => toggleSortBy(id)}>
      {name}
      <TableSorting
        isSorted={isSorted}
        sort={isSortedDesc ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC}
      />
      <InfoIcon description={description} />
    </div>
  );
}

export default memo(InfoIconHeader);
