import { useEffect, useMemo } from 'react';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { Product } from '../../api/schemas';
import { QueryParamsFilter } from '../../shared/types';
import { LoaderModal, Pagination, TableSorting, TotalItemsCount } from '../table';
import { SORT_DIRECTIONS } from '../table/constants';
import { useTableDataLoading, useTableQueryParams } from '../table/hooks';
import { TablePropTypes } from '../table/types';
import { stringifyOrdering } from '../table/utils';
import { useFilterSkuBy } from './hooks';

type CatalogTablePropTypes = Pick<
  TablePropTypes<Product>,
  | 'columns'
  | 'data'
  | 'isLoading'
  | 'isFetching'
  | 'fetchData'
  | 'pageCount'
  | 'totalItemsCount'
  | 'search'
> & {
  hiddenColumns: Record<string, boolean>;
  pageSize: number;
};

export default function CatalogTable({
  columns,
  data,
  isLoading,
  isFetching,
  fetchData,
  pageCount,
  totalItemsCount,
  search,
  hiddenColumns,
  pageSize,
}: CatalogTablePropTypes): React.ReactElement {
  const {
    page,
    ordering,
    setPage: setPageFromQuery,
    setOrdering: setOrderingFromQuery,
  } = useTableQueryParams();

  const { filters: persistedFilters, setFilters: setPersistedFilters } = useFilterSkuBy();

  const { tableData, getRowId } = useTableDataLoading<Product>({
    placeholderRows: pageSize,
    isLoading,
    data,
  });

  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map(
            (column): Column<Product> => ({
              ...column,
              Cell() {
                if (column.accessor === 'name')
                  return (
                    <div className="product_cell loading__container">
                      <div className="loading" />
                      <div className="loading" />
                    </div>
                  );
                return <div className="loading" />;
              },
            })
          )
        : columns,
    [isLoading, columns]
  );

  const {
    headerGroups,
    rows,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex, filters, sortBy, globalFilter },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    prepareRow,
    getTableBodyProps,
    setHiddenColumns,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      pageCount,
      initialState: {
        sortBy: ordering ? [ordering] : undefined,
        pageIndex: page,
        hiddenColumns: Object.keys(hiddenColumns),
        pageSize,
        filters: persistedFilters,
        globalFilter: search,
      },
      manualFilters: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      manualPagination: true,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      getRowId,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (search !== globalFilter) {
      gotoPage(0);
    }

    setGlobalFilter(search);
  }, [search]);

  useEffect(() => {
    setHiddenColumns(Object.keys(hiddenColumns));
  }, [hiddenColumns]);

  useEffect(() => {
    setPageFromQuery(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setOrderingFromQuery(sortBy);
  }, [sortBy]);

  useEffect(() => {
    setPersistedFilters(filters);

    if (filters !== persistedFilters) {
      // reset pagination when filters change to avoid cases when user fetches 3rd page of items that have 1 page total
      gotoPage(0);
    }
  }, [filters]);

  useEffect(() => {
    const parameters = { page_size: pageSize, page: pageIndex + 1 } as QueryParamsFilter<Product>;
    if (sortBy.length && sortBy[0].id) {
      parameters.ordering = stringifyOrdering(sortBy[0]);
    }
    if (globalFilter) {
      parameters.search = globalFilter;
    }
    if (filters.length) {
      filters.forEach(({ id, value }) => {
        if (value.length) parameters[id as keyof Product] = value;
      });
    }

    fetchData(parameters);
  }, [fetchData, sortBy, pageIndex, pageSize, filters, globalFilter]);

  const isGeneralNoData = !search;

  return (
    <>
      {isFetching && <LoaderModal />}
      <table className="prod_catalog__table">
        <thead>
          {headerGroups.map(headerGroup => (
            /* eslint-disable react/jsx-key */
            // disabling the rule here due to the fact that getProps functions actually make iterated elements keyed
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={column.headerClassName ?? ''}
                >
                  <div className="header-cell">
                    {column.render('Header')}
                    {column.canSort && (
                      <TableSorting
                        isSorted={column.isSorted}
                        sort={column.isSortedDesc ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!data.length && !isLoading && (
            <tr className="no_data">
              <td colSpan={visibleColumns.length}>
                {isGeneralNoData && 'No data available'}
                {!isGeneralNoData && (
                  <div className="no_search_yielded">
                    Sorry, we couldn’t find a match for
                    <span>“{search}”</span>
                    Please try another search.
                  </div>
                )}
              </td>
            </tr>
          )}
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={`${cell.column.id}-${cell.row.id}-${cell.row.original.supplier_sku}`}
                      className={cell.column.className ?? ''}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!!pageCount && (
        <>
          <Pagination
            currentPage={pageIndex + 1}
            totalPages={pageOptions.length}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
          />
          <TotalItemsCount
            currentPage={pageIndex}
            pageSize={pageSize}
            totalItems={totalItemsCount}
            totalPages={pageCount}
          />
        </>
      )}
    </>
  );
}
