import { useEffect, useState } from 'react';
import { IdType } from 'react-table';

import { ArchivedDocument } from '../../../api/schemas/archive';
import { Modal, WarningWindow } from '../../../shared/components';
import { PERMISSIONS } from '../../../shared/constants';
import { usePermission } from '../../../shared/hooks';
import { Search, Table } from '../../table';
import ActionButtons from '../ActionButtons';
import { ACTION_BTNS, CONFIRM_RESTORE, WARN_CONTENT } from './constants';
import { useArchiveDocs, useArchiveDocsColumns, useDeleteDocs, useRestoreDocs } from './hooks';
import { ArchiveDocsTable } from './hooks/types';
import RestoreConfirmation from './RestoreConfirmation';

export default function ArchiveDocs(): React.ReactElement {
  const [search, setSearch] = useState('');
  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);
  const [filesToRestore, setFilesToRestore] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<
    Record<IdType<ArchiveDocsTable>, ArchivedDocument>
  >({});

  const { data, isLoading, isFetching, fetch, refetch } = useArchiveDocs();
  const { columns } = useArchiveDocsColumns({ handleDelete: setFilesToDelete });
  const { isAllowed } = usePermission(PERMISSIONS.SOFT_DELETE_DOCS);
  const { deleteDocs, isSuccess: isDeleteSuccess } = useDeleteDocs();
  const { restore, isLoading: isRestoreLoading, isSuccess: isRestoredSuccess } = useRestoreDocs();

  useEffect(() => {
    if (isRestoredSuccess || isDeleteSuccess) {
      setFilesToDelete([]);
      setFilesToRestore([]);
      setSelectedRows({});
      refetch();
    }
  }, [isRestoredSuccess, isDeleteSuccess]);

  const handleBulkDelete = (): void => {
    setFilesToDelete(Object.keys(selectedRows));
  };

  const handleRestore = (): void => {
    setFilesToRestore(Object.keys(selectedRows));
  };

  const handleCloseModal = (): void => {
    setFilesToDelete([]);
    setFilesToRestore([]);
  };

  const confirmDelete = () => {
    deleteDocs(filesToDelete);
  };

  const confirmRestore = () => {
    restore(Object.values(selectedRows));
  };

  return (
    <div className="doc_rep_content">
      <h3>Archived Documents</h3>
      <div className="searchbar">
        <p className="info">
          Files that have been in Archive more than <span className="marked_red">90 days</span> will
          be automatically deleted.
        </p>
        <Search
          placeholder="Search Archived Documents"
          search={setSearch}
          isSearching={false}
          showResults={false}
        />
      </div>
      <ActionButtons
        buttons={ACTION_BTNS}
        actionHandlers={{ delete: handleBulkDelete, restore: handleRestore }}
        selectedRows={selectedRows}
      />
      <div className="table-wrapper">
        <Table<ArchiveDocsTable>
          columns={columns}
          data={data?.documents || []}
          isLoading={isLoading}
          isFetching={isFetching}
          search={search}
          fetchData={fetch}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          pageCount={data?.total_pages}
          isRowSelectEnabled={isAllowed}
        />
      </div>
      <Modal show={!!filesToDelete.length} closeModal={handleCloseModal}>
        <WarningWindow content={WARN_CONTENT} confirm={confirmDelete} />
      </Modal>
      <Modal show={!!filesToRestore.length} closeModal={handleCloseModal}>
        <RestoreConfirmation
          content={CONFIRM_RESTORE}
          confirm={confirmRestore}
          isLoading={isRestoreLoading}
        />
      </Modal>
    </div>
  );
}
