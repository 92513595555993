import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { stringify } from 'qs';

import { TokenObtainPair } from '../api/schemas';
import { NODE_ENV, BE_HOST } from './runtime-config';

const baseURL = NODE_ENV === 'development' ? 'http://0.0.0.0:8000' : BE_HOST;

const API = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params: unknown) => stringify(params, { arrayFormat: 'repeat' }),
});

API.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) =>
    new Promise((resolve, reject) => {
      const originalRequest: AxiosRequestConfig = error.config;
      const tokenString = localStorage.getItem('token');

      if (!tokenString || originalRequest.url === '/api/accounts/change-password/') {
        return reject(error);
      }

      const { refresh }: TokenObtainPair = JSON.parse(tokenString);

      if (error.response?.status === 401 && error.config && refresh) {
        const response = fetch(`${baseURL}/api/token/refresh`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refresh,
          }),
        })
          .then(res => res.json())
          .then((res: { access: string }) => {
            const refreshedUserToken: TokenObtainPair = { access: res.access, refresh };
            const apiAuthToken = `Bearer ${refreshedUserToken.access}`;
            localStorage.setItem('token', JSON.stringify(refreshedUserToken));

            API.defaults.headers.common.Authorization = apiAuthToken;
            originalRequest.headers.Authorization = apiAuthToken;

            return axios(originalRequest);
          })
          .catch((refreshError: AxiosError) => {
            if (refreshError.response?.status === 401) {
              localStorage.removeItem('token');
              window.location.reload();
            }
            return reject(refreshError);
          });
        resolve(response);
      }

      return reject(error);
    })
);

export default API;
