/*
 * Todo: These are temporary measures as when the tab is a button. The drag
 * seem to be hindered. Need to explore different methods in the future.
 *
 * Hence, the linter bypass.
 */

/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Dashboard } from '../../api/schemas';
import useDashboard from './hooks/useDashboards';

type TabsPropTypes = {
  tab: number;
  setTab: (tab: Dashboard) => void;
};

export default function Tabs({ tab, setTab }: TabsPropTypes): React.ReactElement {
  const { data, isLoading, onDragEnd: handleOnDragEnd } = useDashboard();

  useEffect(() => {
    if (data && data.length && !tab) {
      setTab(data[0]);
    }
  }, [data]);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="domo-tabs" direction="horizontal">
        {provided => (
          <ul className="tabs_container" ref={provided.innerRef}>
            {isLoading ? (
              <button type="button" className="tab active">
                <div className="loading" />
              </button>
            ) : (
              data?.map(({ id, name }, index) => {
                const isSelected = id === tab;

                return (
                  <Draggable key={id} draggableId={`${id}_${name}`} index={index}>
                    {(listItemData, snapshotDrag) => {
                      /**
                       * Dev Note:
                       *
                       * 1. Since the type library couldn't be found, have opted to ignore tsc warnings.
                       *
                       * 2. The react-beautiful-dnd component is from Atlassian. Their design principles
                       * does not allow for axis locking and container locking. Thus, overriding the style
                       * to achieve requirement.
                       *
                       * @see: https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/about/design-principles.md
                       *
                       * 3. Due to the way the Tab component is setup (Tab panes are separate components/
                       * <Domoframe /> to be exact), it's impossible to display the dragging of content
                       * pane along with the tab. But partial behavior can be implemented by restricting
                       * the y-axis movement.
                       *
                       * 4. Should also note, that the current tab implementation is the correct and best
                       * React way to implement a tab system.
                       */
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      let { transform, boxShadow } = listItemData.draggableProps.style;

                      if (snapshotDrag.isDragging && transform) {
                        transform = transform.replace(/\d+px\)$/, '0)');
                        boxShadow = 'none';
                      }

                      const style = {
                        ...listItemData.draggableProps.style,
                        transform,
                        boxShadow,
                      };
                      return (
                        <li
                          role="button"
                          {...listItemData.draggableProps}
                          {...listItemData.dragHandleProps}
                          ref={listItemData.innerRef}
                          className={`tab ${isSelected ? 'active' : ''}`}
                          onMouseDown={() => setTab({ id, name })}
                          style={style}
                        >
                          {name}
                        </li>
                      );
                    }}
                  </Draggable>
                );
              })
            )}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
}
