import { useVendorData } from '../header/hooks';
import { useAvailablePlans } from './hooks';
import PlanColumn from './PlanColumn';

export default function AvailablePlans(): React.ReactElement | null {
  const { data } = useAvailablePlans();
  const { data: { tier } = {} } = useVendorData();

  if (!data) return null;

  return (
    <div className="available_plans">
      <h1>Available Plans:</h1>
      <div className="plans_wrapper">
        {data?.map(({ name, sections }) => (
          <PlanColumn key={name} name={name} sections={sections} isCurrent={name === tier?.name} />
        ))}
      </div>
    </div>
  );
}
