import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../../shared/hooks';

import { ProdDetailsTabs } from './constants';
import ProductDetailsSpaces from './ProductDetailsSpaces';
import SelectTabs from './SelectTabs';

const extractTabFromUrl = (params: URLSearchParams) =>
  (params.get('tab') as ProdDetailsTabs) || ProdDetailsTabs.DETAILS;

export default function ProductDetailsTabs(): React.ReactElement {
  const history = useHistory();
  const params = useQueryParams();

  const [activeTab, setActiveTab] = useState(extractTabFromUrl(params));

  useEffect(() => {
    const tab = extractTabFromUrl(params);
    setActiveTab(tab);
  }, [params]);

  const handleTabSelect = (tab: ProdDetailsTabs) => {
    setActiveTab(tab);

    params.set('tab', tab);
    history.push({ search: params.toString() });
  };

  return (
    <div className="documentation__container">
      <div className="header_info">
        <p className="disclaimer">**Any updates made are pending approval.</p>
        <SelectTabs activeTab={activeTab} setActiveTab={handleTabSelect} />
      </div>
      <ProductDetailsSpaces activeTab={activeTab} />
    </div>
  );
}
