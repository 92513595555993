import { HomeSections, LatestUpdateMessage } from '../../components/homepage';

export default function Homepage(): React.ReactElement {
  return (
    <section className="homepage">
      <h2>Home</h2>
      <div className="homepage_container">
        <LatestUpdateMessage />
        <HomeSections />
      </div>
    </section>
  );
}
