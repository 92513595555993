import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { loginUser } from '../../../api/mutations';
import { TokenObtainPair, TokenObtainPairRequest } from '../../../api/schemas';
import { useAuth } from '../../../shared/hooks';
import { LoginUserServerError, UseLoginReturnType } from './types';

export default function useLogin(): UseLoginReturnType {
  const [errorMsg, setErrorMsg] = useState('');
  const { signin } = useAuth();

  const { mutate, data, isLoading, isSuccess, isError, error } = useMutation<
    AxiosResponse<TokenObtainPair>,
    AxiosError<LoginUserServerError>,
    TokenObtainPairRequest
  >((params: TokenObtainPairRequest) => loginUser(params), {
    onError: () => {
      setErrorMsg('The email address or password you entered is incorrect');
    },
    onSuccess: ({ data: tokenPair }) => {
      signin(tokenPair);
    },
  });

  const login = (params: TokenObtainPairRequest) => {
    mutate(params);
  };

  return {
    login,
    data: data?.data,
    isLoading,
    isError,
    isSuccess,
    errorMsg,
    error: error?.response,
  };
}
