import { useState } from 'react';

import { Modal, WarningWindow } from '../../../shared/components';
import { useDeleteUser, useReactivateUser } from '../hooks';
import { DeactivatedUsersActionBtnsPropTypes } from './types';

const generateConfirmationContent = (action: 'REACTIVATE' | 'DELETE') => ({
  main: (
    <>
      Are you sure you want to <span className="highlighted">{action}</span> this user?
    </>
  ),
  cancel: 'No, Return To Acct Mgmt',
  confirm: (
    <>
      Yes, <span className="highlighted">{action}</span> this user
    </>
  ),
});

export default function DeactivatedUsersActionBtns({
  id,
}: DeactivatedUsersActionBtnsPropTypes): React.ReactElement {
  const [showReactivate, setShowReactivate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const { reactivateUser } = useReactivateUser();
  const { deleteUser } = useDeleteUser();

  const handleReactivate = () => {
    reactivateUser(id);
  };

  const handleDelete = () => {
    deleteUser(id);
  };

  return (
    <>
      <div className="active_users_actions_container">
        <button type="button" name="Reactivate User" onClick={() => setShowReactivate(true)}>
          Reactivate User
        </button>
        <button type="button" name="Delete User" onClick={() => setShowDelete(true)}>
          Delete User
        </button>
      </div>
      <Modal show={showReactivate} closeModal={() => setShowReactivate(false)}>
        <WarningWindow
          content={generateConfirmationContent('REACTIVATE')}
          confirm={handleReactivate}
        />
      </Modal>
      <Modal show={showDelete} closeModal={() => setShowDelete(false)}>
        <WarningWindow content={generateConfirmationContent('DELETE')} confirm={handleDelete} />
      </Modal>
    </>
  );
}
