import { useUserRoles } from '../hooks';
import { SelectRolePropTypes } from './types';

export default function SelectRole({ register, error }: SelectRolePropTypes): React.ReactElement {
  const { data } = useUserRoles();

  return (
    <>
      <label htmlFor="user_role" className="form_input_label">
        Select the users role<span className="asterix">*</span>:
        {error?.message && <p className="form_input_error">{error.message}</p>}
      </label>
      <div className="select_role_wrapper">
        {data?.map(roleName => (
          <div key={roleName} className="radio_btn__row">
            <label>
              <span className="radio">
                <input type="radio" value={roleName} {...register()} />
                {/* eslint-disable-next-line jsx-a11y/role-has-required-aria-props */}
                <span role="radio" tabIndex={0}>
                  {' '}
                </span>
              </span>
              <span className="role_label">{roleName}</span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
}
