import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow-right.svg';

import { activeProductSku, uploadAndLinkLoadingStatus } from '../../../../atoms';
import { useOutsideClick } from '../../../../shared/hooks';
import { useEditProdDoc } from '../../../document-repository/document-edit/hooks';
import { DocumentTypesPropTypes } from '../../../document-repository/file-upload/types';
import { useDocumentLabels } from '../../../document-repository/persistent-product/file-upload/hooks';
import { useLinkProducts } from '../../../document-repository/persistent-product/hooks';
import { updateObjFromIndex } from '../../../document-repository/utils/helpers';
import { useProductDetails } from '../../hooks';

export default function DocumentTypes({
  fileName,
  fileId,
  labelId,
}: DocumentTypesPropTypes): React.ReactElement {
  const [fileLabel, setFileLabel] = useState<number | undefined>(labelId);
  const [isOpen, setOpen] = useState(false);

  const { labels } = useDocumentLabels();
  const { editProdDoc } = useEditProdDoc();

  const setLoadingStatuses = useSetRecoilState(uploadAndLinkLoadingStatus);
  const gxp_sku = useRecoilValue(activeProductSku);
  const { data: { sku } = {} } = useProductDetails(gxp_sku, false);
  const { assingProducts } = useLinkProducts('link');

  const referenceRef = useRef<HTMLDivElement>();
  const popperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (fileId && sku) {
      assingProducts(fileId, [{ gxp_sku, supplier_sku: sku }]);
    }
  }, [fileId]);

  useEffect(() => {
    if (fileId && fileLabel && fileLabel !== labelId) {
      const name = fileName.replace(/\.[^/.]+$/, '');

      editProdDoc(fileId, { name, label_id: fileLabel });
      setLoadingStatuses(prevStatuses => {
        const fileIndex = prevStatuses.findIndex(({ name: existingName }) => existingName === name);

        return updateObjFromIndex(prevStatuses, fileIndex, {
          labelId: fileLabel,
        });
      });
    }
  }, [fileId, fileLabel]);

  const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [65, 0],
        },
      },
      {
        name: 'hide',
        enabled: true,
      },
    ],
  });

  useOutsideClick(referenceRef, () => {
    setOpen(false);
  });

  const handleSelectType = (selectedType: number): void => {
    setFileLabel(selectedType);
    setOpen(false);
  };

  return (
    <div ref={referenceRef as MutableRefObject<HTMLDivElement>} className="doc_types">
      <button
        type="button"
        className="doc_types__toggler"
        name="select label"
        onClick={() => setOpen(!isOpen)}
      >
        <div className="type_text">
          {labels.find(({ id }) => id === fileLabel)?.name || 'Add Doc Type'}
        </div>
        <ArrowIcon width="10px" height="6px" fill="#006298" />
      </button>
      <div
        id="popper"
        data-show={isOpen}
        ref={popperRef}
        style={styles.popper}
        {...attributes.popper}
      >
        <div style={styles.offset} className="doc_types__content">
          {labels.map(({ id, name }) => (
            <button
              type="button"
              className="doc_types__type"
              key={id}
              onClick={() => handleSelectType(id)}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
