import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-narrow.svg';

import { ModalChildPropTypes } from './Modal';

type Content = {
  title: string;
  description: string;
};

type ConfirmationWindowPropTypes = ModalChildPropTypes & {
  content: Content;
  iterable: boolean;
  actionName?: string;
  action?: () => void;
};

export default function ConfirmationWindow({
  content: { title, description },
  iterable,
  actionName,
  action,
  closeModal,
}: ConfirmationWindowPropTypes): React.ReactElement {
  return (
    <div className="confirm_window__container">
      <div className="header">
        <h1>{title}</h1>
        <button type="button" name="Close Modal" onClick={closeModal}>
          <CloseIcon fill="#fff" />
        </button>
      </div>
      <div className="content">
        <SuccessIcon width="16px" height="14px" stroke="#fff" />
        <h1>Success!</h1>
        <p>{description}</p>
        {iterable && (
          <button className="iterate" type="button" name={actionName} onClick={action}>
            {actionName}
          </button>
        )}
        <button className="close" type="button" name="Close" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
}
