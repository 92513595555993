import { ChangeEvent, useRef } from 'react';

import { ReactComponent as PersistentProduct } from '../../../../assets/icons/persistent-product.svg';
import { ALLOWED_TYPES } from '../../constants';

import { DragNDrop } from '../../file-upload';
import { UploadStepPropTypes } from './types';

const fileInputAcceptTypes = ALLOWED_TYPES.join(',');

export default function UploadStep({
  errorMsg,
  isDisabled,
  uploadFile,
}: UploadStepPropTypes): React.ReactElement {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleFileUpload = (files: FileList | null) => {
    const fileToUpload = files?.[0];
    if (fileToUpload) {
      uploadFile(fileToUpload);
    }
  };

  return (
    <div className="step__content file_upload">
      <div className="upload_btns">
        <DragNDrop disabled={isDisabled} handleDropFile={handleFileUpload}>
          <>
            <PersistentProduct width="27px" height="28px" />
            <p>Drag & drop file here to upload</p>
          </>
        </DragNDrop>
        <h3>OR</h3>
        <button
          className="add_file"
          type="button"
          name="Add File"
          onClick={handleClick}
          disabled={isDisabled}
        >
          + Add File Via Finder
        </button>
        <input
          ref={hiddenFileInput}
          type="file"
          accept={fileInputAcceptTypes}
          name="document"
          data-testid="file-uploader"
          onChange={({ target }: ChangeEvent<HTMLInputElement>) => handleFileUpload(target.files)}
        />
      </div>
      {errorMsg && (
        <div className="error">
          <span className="error__icon">X</span> = {errorMsg}
        </div>
      )}
    </div>
  );
}
