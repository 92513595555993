import { ReactComponent as DownloadIcon } from '../../../assets/icons/bold-download.svg';
import { ReactComponent as OpenIcon } from '../../../assets/icons/bold-open.svg';

import { Tooltip } from '../../../shared/components';
import { useFileDownload } from '../../../shared/hooks';
import { FileData } from '../types';

type SubRowsActionBtnsPropTypes = {
  fileData: FileData;
};

export default function SubRowsActionButtons({
  fileData: { name, url, extension },
}: SubRowsActionBtnsPropTypes): React.ReactElement {
  const { downloadSingle, isDownloading } = useFileDownload();

  const handlePreview = () => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleDownload = () => {
    const fileName = `${name}.${extension}`;
    downloadSingle(url, fileName);
  };

  return (
    <div className="action_btns__container">
      <Tooltip content="Open In A New Window">
        <button type="button" name="Preview" data-testid="preview" onClick={handlePreview}>
          <OpenIcon stroke="#FFF" />
        </button>
      </Tooltip>
      {isDownloading ? (
        <button type="button" name="Download Loading">
          <div className="loader" />
        </button>
      ) : (
        <Tooltip content="Download">
          <button type="button" name="Download" data-testid="download" onClick={handleDownload}>
            <DownloadIcon stroke="#FFF" />
          </button>
        </Tooltip>
      )}
    </div>
  );
}
