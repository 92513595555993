import { EmailInput, InviteAnotherUser, InviteMessage, RemainingLicences, SelectRole } from '.';
import { useVendorData } from '../../header/hooks';
import { InviteUserFormPropTypes } from './types';

export default function InviteUserForm({
  id,
  errors,
  invitesCount,
  register,
  addUser,
  removeUser,
}: InviteUserFormPropTypes): React.ReactElement {
  const { data: { remaining_licenses, tier } = {} } = useVendorData();

  const remainingLicences = (remaining_licenses as number) - id;
  const remainingLicencesTotal = (remaining_licenses as number) - invitesCount + 1;

  return (
    <div className="invite_user_form_wrapper">
      <div className="user_container">
        <RemainingLicences remainingLicences={remainingLicences} tierName={tier?.name} />
        <EmailInput
          id="email"
          label="Enter user email address to invite"
          placeholder="Click here to enter email address"
          register={() => register(`invites.${id}.email`)}
          removeUser={() => removeUser(id)}
          error={errors.invites?.[id]?.email}
          canDelete={invitesCount !== 1}
          required
        />
        <SelectRole
          register={() => register(`invites.${id}.role_name`)}
          error={errors.invites?.[id]?.role_name}
        />
        <InviteMessage
          id="invitation_message"
          label="Add a custom invitation message"
          placeholder="Optional: Click here to begin typing.&#10;Hello,&#10;Great News! You’ve been invited to join our Vendor Portal account by Vendor Admin Name Surname.&#10;The Vendor Portal offers unmatched insight on key data related to the business conducted between our company and GoExpedi, which is easily comprehensible and readily available at your fingertips.&#10;Access the Vendor Portal to fuel your decisions for continuous success!&#10;Admin Name/Surname"
          register={() => register(`invites.${id}.invite_text`)}
        />
        <InviteAnotherUser
          remainingLicences={remainingLicencesTotal}
          isLast={id + 1 === invitesCount}
          addUser={addUser}
        />
      </div>
    </div>
  );
}
