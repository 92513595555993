import { useMutation, useQueryClient } from 'react-query';

import { archiveNotification } from '../../../api/mutations';
import { ArchiveNotificationPayload, NotificationAction } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UseArchiveNotification } from './types';

export default function useArchiveNotification(): UseArchiveNotification {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    (payload: ArchiveNotificationPayload) => archiveNotification(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ServerStateKeys.UnreadNotificationsCount);
      },
    }
  );

  const archiveNotifications = (action: NotificationAction, ids: number[]) => {
    mutate({ notifications_ids: ids, action });
  };

  return { archiveNotifications, isLoading, isSuccess };
}
