import { useMutation } from 'react-query';

import { editOrderDocument } from '../../../../api/mutations';
import { EditFilePayload, UpdateFileData, UseEditFile } from './types';

export default function useEditOrderDoc(orderId: string): UseEditFile {
  const { mutate, isLoading, error, isSuccess } = useMutation(
    ({ fileId, payload }: EditFilePayload) => editOrderDocument(orderId, fileId, payload)
  );

  const editFile = ({ fileId, fileName, fileLabelId }: UpdateFileData) => {
    mutate({ fileId, payload: { name: fileName, label_id: fileLabelId } });
  };

  return { editFile, isLoading, isSuccess, error };
}
