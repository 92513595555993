import { ReactComponent as ArchiveIcon } from '../../../assets/icons/bold-archive.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/bold-download.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/bold-edit.svg';

import { Restricted, Tooltip } from '../../../shared/components';
import { PERMISSIONS } from '../../../shared/constants';
import { useFileDownload } from '../../../shared/hooks';
import { useArchiveOrderDocs } from './hooks';
import { SubRowsActionBtnsPropTypes } from './types';

export default function SubRowsActionButtons({
  fileData,
  handleEdit,
}: SubRowsActionBtnsPropTypes): React.ReactElement {
  const { downloadSingle, isDownloading } = useFileDownload();
  const { moveToArchive, isLoading } = useArchiveOrderDocs();

  const handleDownload = () => {
    const { name, url, extension } = fileData;
    const fileName = `${name}.${extension}`;
    downloadSingle(url, fileName);
  };

  const handleArchiveFile = () => {
    moveToArchive([fileData]);
  };

  return (
    <div className="action_btns__container">
      {isDownloading ? (
        <button type="button" name="Download Loading">
          <div className="loader" />
        </button>
      ) : (
        <Tooltip content="Download">
          <button type="button" name="Download" onClick={handleDownload}>
            <DownloadIcon stroke="#FFF" />
          </button>
        </Tooltip>
      )}
      <Restricted to={PERMISSIONS.EDIT_ORDER_DOCS}>
        <Tooltip content="Edit">
          <button type="button" name="Edit" onClick={() => handleEdit(fileData)}>
            <EditIcon stroke="#FFF" width="12px" height="12px" />
          </button>
        </Tooltip>
      </Restricted>
      {isLoading ? (
        <button type="button" name="Archive Loading">
          <div className="loader" />
        </button>
      ) : (
        <Restricted to={PERMISSIONS.ARCHIVE_ORDER_DOCS}>
          <Tooltip content="Archive">
            <button type="button" name="Archive" onClick={handleArchiveFile}>
              <ArchiveIcon stroke="#FFF" width="12px" height="12px" />
            </button>
          </Tooltip>
        </Restricted>
      )}
    </div>
  );
}
