import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { updateUserDetails } from '../../../api/mutations';
import { UserDetailsRequest } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseUpdateUserProfile = {
  updateUserProfile: (payload: UserDetailsRequest) => void;
  serverError?: Record<keyof UserDetailsRequest, string[]>;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function useUpdateUserProfile(): UseUpdateUserProfile {
  const queryClient = useQueryClient();

  const { mutate, error, isLoading, isSuccess } = useMutation<
    AxiosResponse<UserDetailsRequest>,
    AxiosError<Record<keyof UserDetailsRequest, string[]>>,
    UserDetailsRequest
  >((payload: UserDetailsRequest) => updateUserDetails(payload), {
    onSuccess: data => {
      // updating user-data object with response data instead of refetching
      queryClient.setQueryData([ServerStateKeys.UserData], data);
    },
  });

  return { updateUserProfile: mutate, serverError: error?.response?.data, isLoading, isSuccess };
}
