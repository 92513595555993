import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getPendingInvitations } from '../../../api/queries';
import { PaginatedPendingUserInvite } from '../../../api/schemas';
import { QueryParams } from '../../../shared/types';
import ServerStateKeys from '../../../utils/server-state-keys';

type UsePendingInvites = {
  fetch: (parameters?: QueryParams) => void;
  data?: PaginatedPendingUserInvite;
  isLoading: boolean;
  isFetching: boolean;
};

export default function usePendingInvites(): UsePendingInvites {
  const [params, setParams] = useState({} as QueryParams);

  const { data, isLoading, isFetching } = useQuery(
    [ServerStateKeys.PendingInvites, params],
    () => getPendingInvitations(params),
    {
      enabled: !!Object.keys(params).length,
      keepPreviousData: true,
    }
  );

  const fetch = useCallback((parameters?: QueryParams) => {
    if (parameters) {
      setParams(parameters);
    }
  }, []);

  return { fetch, data: data?.data, isLoading, isFetching };
}
