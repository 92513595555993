import { useMemo } from 'react';
import { Column } from 'react-table';

type TableColumnLoadingProps<DataType extends Record<string, unknown>> = {
  columns: Column<DataType>[];
  isLoading?: boolean;
};

type UseTableColumnLoading<DataType extends Record<string, unknown>> = {
  tableColumns: Column<DataType>[];
};

export default function useTableColumnLoading<D extends Record<string, unknown>>({
  columns,
  isLoading,
}: TableColumnLoadingProps<D>): UseTableColumnLoading<D> {
  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map(
            (column): Column<D> => ({
              ...column,
              Cell() {
                return column.id === 'selection' ? <div /> : <div className="loading" />;
              },
            })
          )
        : columns,
    [isLoading, columns]
  );

  return { tableColumns };
}
