import { ChangeEvent, useState } from 'react';

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

type SearchPropTypes = {
  placeholder: string;
  isSearching: boolean;
  showResults: boolean;
  itemCount?: number;
  defaultValue?: string;
  search: (searchValue: string) => void;
};

export default function Search({
  placeholder,
  isSearching,
  showResults,
  itemCount,
  defaultValue,
  search,
}: SearchPropTypes): React.ReactElement {
  const [searchValue, setSearchValue] = useState(defaultValue || '');

  const handleSearchValueChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);

    if (!value) {
      search(value);
    }
  };

  const handleSubmitSearch = ({ code }: React.KeyboardEvent<HTMLInputElement>) => {
    if (code === 'Enter') {
      search(searchValue);
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
    search('');
  };

  return (
    <div className="search-bar__container">
      <div className="search-bar">
        <button type="button" name="Search" onClick={() => search(searchValue)}>
          <SearchIcon fill="#fff" />
        </button>
        <input
          type="text"
          maxLength={100}
          placeholder={placeholder}
          value={searchValue}
          onKeyDown={handleSubmitSearch}
          onChange={handleSearchValueChange}
        />
      </div>
      {isSearching && <div className="loader" />}
      {showResults && (
        <>
          <div className="search_count">{itemCount} Total Results Found</div>
          <button
            className="clear_button"
            type="button"
            name="Clear Search"
            onClick={handleClearSearch}
          >
            Clear Search Results
          </button>
        </>
      )}
    </div>
  );
}
