import { useVendorData } from '../header/hooks';

export default function ServicePlanDetails(): React.ReactElement {
  const { data: { service_plan_expiration_date, tier } = {} } = useVendorData();

  return (
    <div className="service_plan_details">
      <h1>Your SERVICE plan details at a glance:</h1>
      <div className="current_plan_container">
        <div>
          Current service plan: <span className="highlighted">{tier?.name}</span>
        </div>
        <div>
          Current plan expires: <span className="highlighted">{service_plan_expiration_date}</span>
        </div>
      </div>
    </div>
  );
}
