import { AdditionalDetailsTable } from './types';

export const DIMENSIONS_TABLE: AdditionalDetailsTable = {
  HEAD: ['Product Dimensions & Weight', 'Attributes'],
  BODY: [
    {
      id: 'length',
      label: 'Length',
    },
    {
      id: 'width',
      label: 'Width',
    },
    {
      id: 'height',
      label: 'Height',
    },
    {
      id: 'weight',
      label: 'Weight',
    },
  ],
};

export const ANALYTICS_TABLE: AdditionalDetailsTable = {
  HEAD: ['Analytics', 'Data'],
  BODY: [
    { id: 'ytd_purchases', label: 'YTD Purchases', nonEditable: true },
    { id: 'ytd_end_user_sales', label: 'YTD End User Sales', nonEditable: true },
    { id: 'ytd_market_share', label: 'YTD Market Share', nonEditable: true },
    { id: 'current_inventory', label: 'Current Inventory', nonEditable: true },
    { id: 'avg_item_delivery', label: 'Average Item Delivery', nonEditable: true },
    { id: 'ytd_search_queries', label: 'YTD Search Queries ', nonEditable: true },
  ],
};

export const DESIGN_TABLE: AdditionalDetailsTable = {
  HEAD: ['Design', 'Attributes'],
  BODY: [
    { id: 'size', label: 'Size' },
    { id: 'schedule', label: 'Schedule' },
    { id: 'pressure', label: 'Pressure' },
    { id: 'pressure_class', label: 'Pressure Class' },
    { id: 'is_api_certified', label: 'Is API Certified?' },
    { id: 'end_connections', label: 'End Connections' },
    { id: 'amps', label: 'Amps' },
    { id: 'voltage', label: 'Voltage' },
    { id: 'wattage', label: 'Wattage' },
    { id: 'ip_rating', label: 'IP Rating' },
    { id: 'material', label: 'Material' },
    { id: 'color', label: 'Color' },
  ],
};
