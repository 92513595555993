import { AdditionalCompanyInfo } from '../../api/schemas';

export const COMPANY_INFO: { id: keyof AdditionalCompanyInfo; label: string }[] = [
  {
    id: 'address',
    label: 'Address',
  },
  { id: 'primary_contact', label: 'Primary Contact' },
  {
    id: 'secondary_contact',
    label: 'Secondary Contact',
  },
];

export enum AccountMmgmtTabs {
  MANAGE_USERS = 'Manage Active Users',
  DEACTIVATED_USERS = 'Deactivated Users',
  PENDING_INVITATIONS = 'Pending Invitations',
}
