import { useEffect, useState } from 'react';

import { RadioButton, SubmitButton } from '../../../shared/components';
import { useChangeRole, useUserRoles } from '../hooks';
import { ROLE_DESCRIPTION } from './constants';
import { EditUserRolePropTypes } from './types';

export default function EditUserRole({
  id,
  role,
  username,
  closeModal,
}: EditUserRolePropTypes): React.ReactElement {
  const [currentRole, setCurrentRole] = useState(role);

  const { data } = useUserRoles();
  const { changeRole, isLoading, isSuccess } = useChangeRole();

  useEffect(() => {
    if (isSuccess) {
      closeModal?.();
    }
  }, [isSuccess]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    changeRole(id, currentRole);
  };

  return (
    <form className="edit_role_container" onSubmit={handleSubmit}>
      <h6>Select new role for {username}:</h6>
      {!data && <div className="loading" />}
      {data?.map(roleName => (
        <div key={roleName} className="radio_btn__row">
          <label>
            <RadioButton
              isChecked={roleName === currentRole}
              handleChange={() => setCurrentRole(roleName)}
            />
            <span className="radio_btn__label">
              {roleName} - {ROLE_DESCRIPTION[roleName as keyof typeof ROLE_DESCRIPTION]}
            </span>
          </label>
        </div>
      ))}
      <SubmitButton name="Save Changes" isLoading={isLoading} />
      <button type="button" className="cancel_btn" onClick={closeModal}>
        Cancel
      </button>
    </form>
  );
}
