import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { ROUTE } from '../../routes/constants';
import RequestResetForm from './RequestResetForm';
import ResetForm from './ResetForm';

export default function ResetPassword(): React.ReactElement {
  const [resetToken, setResetToken] = useState('');

  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const token = new URLSearchParams(search).get('token');
    if (token) {
      setResetToken(token);
    }
  }, []);

  const clearToken = () => {
    history.replace(ROUTE.RESET_PWD);
    setResetToken('');
  };

  const successConfirm = () => {
    history.replace(ROUTE.LOGIN);
  };

  return (
    <div className="login">
      <div className="login-header">
        <div className="login_header-logo" />
      </div>
      {resetToken ? (
        <ResetForm token={resetToken} clearToken={clearToken} successConfirm={successConfirm} />
      ) : (
        <RequestResetForm />
      )}
    </div>
  );
}
