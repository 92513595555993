import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { documentToEdit, productSkusToUnlink } from '../../../../atoms';
import { SubmitButton } from '../../../../shared/components';
import { useLinkProducts } from '../../persistent-product/hooks';
import { useResetUnlinkForm } from '../hooks';
import ChooseProductsToUnlink from './ChooseProductsToUnlink';
import SelectedProductsToUnlink from './SelectedProductsToUnlink';

export default function UnlinkProducts(): React.ReactElement {
  const fileData = useRecoilValue(documentToEdit);
  const selectedProducts = useRecoilValue(productSkusToUnlink);

  const selectedProdsValues = Object.values(selectedProducts);

  const { assingProducts, isLoading, isSuccess } = useLinkProducts('unlink');
  const { resetForm } = useResetUnlinkForm();

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess]);

  const handleUnlinkProds = (e: React.FormEvent) => {
    e.preventDefault();

    assingProducts(fileData.id, selectedProdsValues);
  };

  return (
    <form onSubmit={handleUnlinkProds}>
      <p className="form_input_label">Unlink a product SKU from this document:</p>
      <ChooseProductsToUnlink />
      <SelectedProductsToUnlink />
      <SubmitButton
        name="Unlink SKU(s) & save"
        isLoading={isLoading}
        isDisabled={!selectedProdsValues.length}
      />
    </form>
  );
}
