import { ActionType, Filters, IdType, SortingRule, TableState, UseTableHooks } from 'react-table';

import { DESELECT_ALL } from '../constants';
import HeaderCheckbox from '../HeaderCheckbox';
import RowCheckbox from '../RowCheckbox';

export const getSelectedRowsFlatData = <D extends Record<string, unknown>>(
  ids: string[],
  data: D[],
  existingData?: Record<string, D>
): Record<string, D> => {
  const selectedRows = {} as Record<string, D>;

  ids.forEach((id: string) => {
    if (existingData && existingData[id]) {
      selectedRows[id] = existingData[id];
    }

    // using loose comparison because `row.id` can be int
    // eslint-disable-next-line eqeqeq
    const rowData = data.find((row: D) => row.id == id);
    if (rowData) {
      selectedRows[id] = rowData;
    }
  });

  return selectedRows;
};

export const useCustomTableHooks =
  <DataType extends Record<string, unknown>>(isRowSelectEnabled?: boolean) =>
  ({ visibleColumns }: UseTableHooks<DataType>): void => {
    if (isRowSelectEnabled) {
      visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: HeaderCheckbox,
          Cell: RowCheckbox,
        },
        ...columns,
      ]);
    }
  };

export const customTableReducer = <DataType extends Record<string, unknown>>(
  newState: TableState<DataType>,
  action: ActionType
): TableState<DataType> => {
  if (action.type === DESELECT_ALL) {
    return { ...newState, selectedRowIds: {} as Record<IdType<DataType>, boolean> };
  }

  return newState;
};

export const stringifyOrdering = ({ id, desc }: SortingRule<unknown>): string => {
  // build expected ordering string from react-table's sotring rule {id: '', desc?: boolean}
  const orderingDirection = desc ? '-' : '';
  return `${orderingDirection}${id}`;
};

export const parseOrdering = (queryParam: string | null): SortingRule<unknown> => {
  // parse ordering from query params to react-table's expected sorting rule
  if (!queryParam) return {} as SortingRule<unknown>;

  if (queryParam.indexOf('-') === 0) {
    return {
      id: queryParam.substring(1),
      desc: true,
    };
  }

  return {
    id: queryParam,
  };
};

/**
 * Calculates thead width offset to align it with scrollable tbody
 */
export const getTheadStyle = (
  tbodyRef: React.RefObject<HTMLTableSectionElement>
): React.CSSProperties => {
  if (tbodyRef.current) {
    const { scrollHeight, clientHeight } = tbodyRef.current;
    if (scrollHeight > clientHeight) {
      // if scrollbar exists
      return { width: 'calc(100% - 17px)' }; // 100% width - scrollbar width
    }
  }

  return {};
};

/**
 * Extracts filters from URL query params based on passed list of columns that can be filtered
 */
export const extractAllFilters = <D extends Record<string, unknown>>(
  searchParams: URLSearchParams,
  filterArray: IdType<D>[]
): Filters<D> => {
  return filterArray
    .map(filterKey => {
      const filterValue = searchParams.getAll(filterKey);
      return {
        id: filterKey as string,
        value: filterValue,
      };
    })
    .filter(({ value }) => value.length);
};
