import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  Dashboard,
  DocRep,
  HelpPage,
  Homepage,
  NotFound,
  NotificationsPage,
  ProdCatalog,
  SettingsPage,
  TnCPage,
} from '../pages';

import { ROUTE } from './constants';

type AppRoute = {
  path: string;
  name: string;
  component: React.JSXElementConstructor<Partial<RouteComponentProps>>;
};

const routes: AppRoute[] = [
  {
    path: ROUTE.PROD_CATALOG,
    name: 'ProductCatalog',
    component: ProdCatalog,
  },
  {
    path: ROUTE.DOC_REP,
    name: 'DocumentRepository',
    component: DocRep,
  },
  {
    path: ROUTE.DASHBOARD,
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: ROUTE.HOMEPAGE,
    name: 'Homepage',
    component: Homepage,
  },
  {
    path: ROUTE.SETTINGS,
    name: 'Settings',
    component: SettingsPage,
  },
  {
    path: ROUTE.NOTIFICATIONS,
    name: 'Notifications',
    component: NotificationsPage,
  },
  {
    path: ROUTE.TERMS_AND_CONDITIONS,
    name: 'TnC',
    component: TnCPage,
  },
  {
    path: ROUTE.HELP,
    name: 'Help',
    component: HelpPage,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

export default routes;
