import axios from 'axios';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { extractFileNameFromUrl } from '../../components/document-repository/utils/helpers';
import API from '../../utils/api';
import ServerStateKeys from '../../utils/server-state-keys';
import { DownloadZipResponse, FileDownloadParams } from './types';

type UseFileDownload = {
  downloadSingle: (fileUrl: string, fileName: string) => void;
  downloadMultiple: (url: string, ids: string[]) => void;
  isDownloading: boolean;
};

const getArchiveUrl = (url: string, ids: string[]) =>
  API.post<DownloadZipResponse>(url, { documents_ids: ids });

const downloadFile = (fileUrl: string) =>
  axios({
    url: fileUrl,
    method: 'GET',
    responseType: 'blob',
  });

export default function useFileDownload(): UseFileDownload {
  const [params, setParams] = useState({} as FileDownloadParams);

  const { isLoading } = useQuery(
    [ServerStateKeys.DownloadFile, params],
    () => downloadFile(params.fileUrl),
    {
      enabled: !!params.fileUrl,
      onSettled: () => {
        setParams({} as FileDownloadParams);
      },
      onSuccess: ({ data }) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([data]));
        link.setAttribute('download', params.fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
    }
  );
  const { mutate, isLoading: isGettingUrl } = useMutation(
    ({ url, ids }: { url: string; ids: string[] }) => getArchiveUrl(url, ids)
  );

  const downloadMultiple = (urlToGetArchive: string, filesToDownload: string[]) => {
    mutate(
      { url: urlToGetArchive, ids: filesToDownload },
      {
        onSuccess: ({ data }) => {
          const { url } = data;
          const fileName = extractFileNameFromUrl(url);

          setParams({ fileUrl: url, fileName });
        },
      }
    );
  };

  const downloadSingle = (fileUrl: string, fileName: string) => {
    setParams({ fileUrl, fileName });
  };

  return { downloadSingle, downloadMultiple, isDownloading: isGettingUrl || isLoading };
}
