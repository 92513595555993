import { LoaderModal } from '../../table';
import { ANALYTICS_TABLE, DESIGN_TABLE, DIMENSIONS_TABLE } from './constants';

export default function ProductAdditionalDetailsLoading(): React.ReactElement {
  const renderTable = (head: string[], body: { id: string; label: string }[]): JSX.Element => (
    <table className="prod_catalog__table">
      <thead>
        <tr>
          {head.map(headerCell => (
            <th key={headerCell}>{headerCell}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map(({ id, label }) => (
          <tr key={id}>
            <td>{label}</td>
            <td>
              <div className="loading" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="details__container">
      {renderTable(DIMENSIONS_TABLE.HEAD, DIMENSIONS_TABLE.BODY)}
      {renderTable(ANALYTICS_TABLE.HEAD, ANALYTICS_TABLE.BODY)}
      {renderTable(DESIGN_TABLE.HEAD, DESIGN_TABLE.BODY)}
      <LoaderModal />
    </div>
  );
}
