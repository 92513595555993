enum ServerStateKeys {
  Dashboards = 'dashboards',
  CompanyData = 'company-data',
  UserData = 'user-data',
  AccountInfo = 'account-info',
  TermsConditions = 'terms-and-conditions',
  TermsConditionsById = 'terms-and-conditions-by-id',
  DocumentLabels = 'persistent-document-labels',
  OrderDocLabels = 'order-document-labels',
  CorporateAgreements = 'corporate-agreements',
  OrderDocuments = 'order-documents',
  OrderDocsOrderList = 'order-docs-order-list',
  PersistentDocuments = 'persistent-documents',
  ArchiveDocuments = 'archive-documents',
  DownloadFile = 'download-file',
  ClientIp = 'client-ip',
  ProductList = 'products',
  SearchedProductsByField = 'search-products-by-field',
  ProductCategories = 'products-categories',
  ProductDetails = 'product-details',
  NotificationSettings = 'notification-settings',
  Notifications = 'notifications',
  NotificationsArchived = 'notifications-archived',
  UnreadNotificationsCount = 'unread-notifications-count',
  DisabledFeatureFlags = 'disabled-feature-flags',
  UserInviteDetails = 'user-invite-details',
  PurchaseOrderPageOffset = 'order-page-offset',
  OrderStatuses = 'order-statuses',
  HomePageDescriptions = 'home-page-descriptions',
  LatestUpdateMessage = 'latest-update-message',
  AvailableServicePlans = 'available-service-plans',
  HelpSections = 'help-sections',
  HelpSectionDescription = 'help-section-description',
  ActiveUsers = 'active-users',
  PendingInvites = 'pending-invites',
  UserRoles = 'user-roles',
  DeactivatedUsers = 'deactivated-users',
}

export default ServerStateKeys;
