import { useEffect } from 'react';

import { Dashboard } from '../../api/schemas';
import useDashboardConfig from './hooks/useDashboardConfig';

type DomoIframePropTypes = {
  tab: Dashboard;
};

const DomoIframe = ({ tab }: DomoIframePropTypes): React.ReactElement => {
  const { data: dashboardData, isLoading, isError, fetchDashboard } = useDashboardConfig();

  useEffect(() => {
    const handler = ({ data }: MessageEvent) => {
      // listen for 'notifyFrameSize' message and fit iframe size automatically to content
      if (data.method === 'notifyFrameSize') {
        const heightInt = Math.trunc(data.params.height);
        const domoIframe = document.getElementById('analytics_iframe');

        if (domoIframe) {
          domoIframe.style.height = `${heightInt + 20}px`;
        }
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, []);

  useEffect(() => {
    if (tab.id) {
      fetchDashboard(String(tab.id));
    }
  }, [tab.id]);

  const renderDomoAnalytics = (): { __html: string } | undefined => {
    if (!dashboardData) {
      return undefined;
    }

    return {
      __html: `<iframe id="analytics_iframe" srcdoc='
          <html>
              <body>
                  <form id="analytics_form" action="https://public.domo.com/embed/pages/${dashboardData.dashboard_id}" method="post">
                      <input type="hidden" name="embedToken" value="${dashboardData.token}">
                  </form>
                  <script>
                      document.getElementById("analytics_form").submit();
                  </script>
              </body>
          </html>' ></iframe>`,
    };
  };

  if (isLoading)
    return (
      <div className="domo_container">
        <div className="loader" />
      </div>
    );

  if (isError)
    return (
      <div className="domo_container">
        <div className="error_load_dashboard">Couldn&#39;t load dashboard.</div>
      </div>
    );

  return (
    <div className="domo_container">
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={renderDomoAnalytics()} />
    </div>
  );
};

export default DomoIframe;
