import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ProductDocumentSKURequest } from '../../../../api/schemas';

import { productSkusToUnlink, documentToEdit } from '../../../../atoms';

type UseProductListToUnlink = {
  data: ProductDocumentSKURequest[];
};

/**
 * @returns Memoized list of linked only products
 */
export default function useProductListToUnlink(): UseProductListToUnlink {
  const selectedProdsToUnlink = useRecoilValue(productSkusToUnlink);
  const file = useRecoilValue(documentToEdit);

  const data = useMemo(
    () =>
      file.products
        .filter(({ gxp_sku }) => !selectedProdsToUnlink[gxp_sku])
        .map(product => ({ id: product.gxp_sku, ...product })),
    [file.products, selectedProdsToUnlink]
  );

  return { data };
}
