const getBarColor = (completePercentage: number): string => {
  if (completePercentage === 100) {
    return '#40994E';
  }
  if (completePercentage > 74) {
    return '#A2B721';
  }
  if (completePercentage > 49) {
    return '#EA824E';
  }
  return '#D61D1D';
};

type CompletePercentagePropTypes = {
  complete: number;
};

export default function CompletePercentage({
  complete,
}: CompletePercentagePropTypes): React.ReactElement {
  const backgroundColor = getBarColor(complete);

  return (
    <div className="complete_percentage">
      <span style={{ color: backgroundColor }}>{complete || 0}%</span>
      <div className="complete_bar__container">
        <div className="complete_bar" style={{ width: `${complete}%`, backgroundColor }} />
      </div>
    </div>
  );
}
