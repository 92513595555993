import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as UnlinkIcon } from '../../../assets/icons/unlink.svg';
import { ReactComponent as PersistentIcon } from '../../../assets/icons/persistent-product.svg';

import { PERMISSIONS } from '../../../shared/constants';
import { ActionButton } from '../../document-repository/types';

export enum UploadMenus {
  UPLOAD = 'upload',
  LINK = 'link',
}

export const ACTION_BTNS: ActionButton[] = [
  {
    title: 'Download',
    icon: DownloadIcon,
    action: 'download',
    withRows: true,
    permission: '',
  },
  {
    title: 'Archive',
    icon: ArchiveIcon,
    action: 'archive',
    withRows: true,
    permission: PERMISSIONS.ARCHIVE_DOCS,
  },
  {
    title: 'Unlink',
    icon: function WrappedResporeIcon({
      stroke,
    }: React.SVGProps<SVGSVGElement>): React.ReactElement {
      return <UnlinkIcon fill={stroke} />;
    },
    action: 'unlink',
    withRows: true,
    permission: PERMISSIONS.EDIT_DOCS,
  },
];

export const WARN_CONTENT = {
  main: 'Are you sure you want to unlink the selected document(s)?',
  confirm: 'Yes, unlink the document(s)',
  cancel: 'No, Do not unlink the document(s)',
};

export const UPLOAD_AND_LINK_SPACES = [
  {
    icon: PersistentIcon,
    title: UploadMenus.UPLOAD,
    label: 'Upload Files',
  },
  {
    icon: PersistentIcon,
    title: UploadMenus.LINK,
    label: 'Link Existing Documents',
  },
];
