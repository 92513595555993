import { AxiosResponse } from 'axios';

import { QueryParamsFilter } from '../../shared/types';
import API from '../../utils/api';
import { ArchivedDocument, ArchivedDocumentsPaginated } from '../schemas';

export const getArchivedDocs = (
  params: QueryParamsFilter<ArchivedDocument>
): Promise<AxiosResponse<ArchivedDocumentsPaginated>> =>
  API.get<ArchivedDocumentsPaginated>('/api/archive/', { params });
