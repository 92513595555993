import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { ReactComponent as OpenIcon } from '../../../assets/icons/open.svg';

import { Product } from '../../../api/schemas';
import { ROUTE } from '../../../routes/constants';
import { QueryParams } from '../../../shared/types';
import { useProductCatalog } from '../../product-catalog/hooks';

import { Table } from '../../table';
import { AssociatedSkuPropTypes } from './types';

const intialTableState = { sortBy: [{ id: 'gxp_sku', desc: false }] };

const columns: Column<Product>[] = [
  {
    Header: 'Product Name',
    accessor: 'name',
    className: 'prod_name_container',
    Cell: ({ value }: Cell<Product>) => (
      <div className="prod_name" title={value}>
        {value}
      </div>
    ),
  },
  {
    Header: 'SKU',
    accessor: 'supplier_sku',
  },
  {
    Header: 'GXP SKU',
    accessor: 'gxp_sku',
    Cell: ({ value }: Cell<Product>) => (
      <div className="gxp_sku__link">
        <Link to={`${ROUTE.PROD_CATALOG}?pid=${value}`} target="_blank" rel="noopener noreferrer">
          {value}
          <OpenIcon width="12px" height="12px" stroke="#006298" />
        </Link>
      </div>
    ),
  },
];

export default function AssociatedSku({ skuIds }: AssociatedSkuPropTypes): React.ReactElement {
  const {
    fetch,
    isLoading,
    isFetching,
    data: { products, total_pages, total_items } = {},
  } = useProductCatalog();

  const handleFetchAssociatedSku = useCallback((params?: QueryParams) => {
    fetch({ ...params, gxp_sku: skuIds });
  }, []);

  return (
    <div className="associated_sku">
      <div className="content">
        <div className="table-wrapper">
          <Table<Product>
            data={products || []}
            columns={columns}
            isLoading={isLoading}
            isFetching={isFetching}
            fetchData={handleFetchAssociatedSku}
            initialState={intialTableState}
            pageCount={total_pages}
            totalItemsCount={total_items}
          />
        </div>
      </div>
    </div>
  );
}
