import { useMutation, useQueryClient } from 'react-query';

import { editProductDetails } from '../../../api/mutations';
import { ChangedProductDetailsField } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';
import { EditProductDetailsPayload, UseEditProductDetails } from './types';

const getRefinedChangedValues = (changedValues: Record<string, ChangedProductDetailsField>) => {
  const refinedValues = { ...changedValues };
  Object.keys(changedValues).forEach(key => {
    if (changedValues[key].old_value === changedValues[key].new_value) {
      delete refinedValues[key];
    }
  });

  return Object.values(refinedValues);
};

export default function useEditProductDetails(): UseEditProductDetails {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    ({ sku, changedValues }: EditProductDetailsPayload) => editProductDetails(sku, changedValues),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ServerStateKeys.UnreadNotificationsCount);
      },
    }
  );

  const editDetails = (
    productId: string,
    changedValues: Record<string, ChangedProductDetailsField>
  ) => {
    mutate({ sku: productId, changedValues: getRefinedChangedValues(changedValues) });
  };

  return { editDetails, isLoading, isSuccess, isError };
}
