import { Filters } from 'react-table';

import { Notification, NotificationType } from '../../../api/schemas';
import {
  NewProduct,
  NewPurchaseOrder,
  ProductDetailsEdit,
  TemsConditionsAcceptedNotification,
} from '../cell';
import { ProductRankChange } from '../cell/FormattedDescription';
import { NotificationsTableData } from '../types';

export const formatDescription = (notification?: Notification): React.ReactElement => {
  if (notification?.notification_type === NotificationType.TERMS_CONDITIONS) {
    const {
      acceptance_date,
      user: { first_name, last_name, email },
    } = notification.terms_and_conditions_acceptance;

    return (
      <TemsConditionsAcceptedNotification
        fullName={`${first_name} ${last_name}`}
        email={email}
        acceptanceDate={acceptance_date}
      />
    );
  }

  if (notification?.notification_type === NotificationType.PRODUCT_EDIT) {
    const { author, sku, edited_fields } = notification.product_details_edit_request;

    return <ProductDetailsEdit author={author} sku={sku} fields={edited_fields} />;
  }

  if (notification?.notification_type === NotificationType.NEW_PURCHASE) {
    const { po_number, location, status, price } = notification.purchase_order;

    return <NewPurchaseOrder order={po_number} location={location} status={status} price={price} />;
  }

  if (notification?.notification_type === NotificationType.NEW_PRODUCT) {
    const { display_name, category, supplier_sku, gxp_sku, vendor_price } =
      notification.product_item;

    return (
      <NewProduct
        name={display_name}
        category={category}
        supplier_sku={supplier_sku}
        gxp_sku={gxp_sku}
        price={vendor_price}
      />
    );
  }

  if (notification?.notification_type === NotificationType.PRODUCT_RANK_CHANGE) {
    const { old_priority, new_priority, supplier_sku, gxp_sku, display_name, category } =
      notification.rank_update;

    return (
      <ProductRankChange
        oldRank={old_priority}
        newRank={new_priority}
        name={display_name}
        supplier_sku={supplier_sku}
        gxp_sku={gxp_sku}
        category={category}
      />
    );
  }

  return <></>;
};

export const parseNotificaitonsFilters = (
  filters: Filters<NotificationsTableData>
): Filters<NotificationsTableData> => {
  let startDate: string | undefined;
  let endDate: string | undefined;

  const filteredFilters = filters.filter(({ id, value }) => {
    if (id === 'date_after') {
      [startDate] = value;
      return false;
    }
    if (id === 'date_before') {
      [endDate] = value;
      return false;
    }

    return true;
  });

  if (startDate && endDate) {
    return [...filteredFilters, { id: 'event_date', value: [startDate, endDate] }];
  }

  return filteredFilters;
};
