import { Link } from 'react-router-dom';

import { ReactComponent as OpenIcon } from '../../../../assets/icons/open.svg';
import { ROUTE } from '../../../../routes/constants';

type ProductNamePropTypes = {
  name: string;
  gxp_sku: string;
};

export default function ProductNameCell({
  name,
  gxp_sku,
}: ProductNamePropTypes): React.ReactElement {
  return (
    <div className="product_name_link">
      <Link to={`${ROUTE.PROD_CATALOG}?pid=${gxp_sku}`} target="_blank" rel="noopener noreferrer">
        {name}
        <OpenIcon />
      </Link>
    </div>
  );
}
