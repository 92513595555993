import { ModalChildPropTypes } from '../../../shared/components/Modal';

type Content = {
  main: string;
  cancel: string;
  confirm: string;
};

type RestoreConfirmPropTypes = ModalChildPropTypes & {
  content: Content;
  confirm: () => void;
  isLoading?: boolean;
};

export default function RestoreConfirmation({
  content,
  isLoading,
  confirm,
  closeModal,
}: RestoreConfirmPropTypes): React.ReactElement {
  return (
    <div className="alert_upload confirm_restore">
      <h2>Attention!</h2>
      <p>{content.main}</p>
      <button className="alert_upload__cancel" name="Confirm" type="button" onClick={confirm}>
        {isLoading ? <div className="loader" /> : content.confirm}
      </button>
      <button className="alert_upload__confirm" name="Cancel" type="button" onClick={closeModal}>
        {content.cancel}
      </button>
    </div>
  );
}
