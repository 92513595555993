import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { passwordChangeRequest } from '../../../api/mutations';
import { PasswordChangeRequest } from '../../../api/schemas';
import { ChangePwdServerError, UsePasswordChange } from './types';

export default function usePasswordChange(): UsePasswordChange {
  const { mutate, isLoading, isSuccess, isError, error } = useMutation<
    AxiosResponse,
    AxiosError<ChangePwdServerError>,
    PasswordChangeRequest
  >((payload: PasswordChangeRequest) => passwordChangeRequest(payload));

  const changePassword = (payload: PasswordChangeRequest) => {
    mutate(payload);
  };

  const errorResponse = error?.response?.data;

  return {
    changePassword,
    error: errorResponse?.detail || errorResponse?.non_field_errors?.[0],
    isLoading,
    isSuccess,
    isError,
  };
}
