import { ReactComponent as ArchivedIcon } from '../../assets/icons/archived-documents.svg';
import { ReactComponent as CorporateIcon } from '../../assets/icons/corporate-agreements.svg';
import { ReactComponent as PersistentIcon } from '../../assets/icons/persistent-product.svg';
import { ReactComponent as OrderIcon } from '../../assets/icons/order-documents.svg';
import { DocumentType } from './types';

export const DOC_REP_TYPES = {
  CORP: 'Corporate Agreements',
  PROD: 'Product Documents',
  ORDER: 'Order Documents',
  ARCH: 'Archived Documents',
};

export const DOC_REP_SPACES: DocumentType[] = [
  { icon: CorporateIcon, title: DOC_REP_TYPES.CORP, action: 'Manage Corporate Docs' },
  {
    icon: PersistentIcon,
    title: DOC_REP_TYPES.PROD,
    action: 'Manage Product Docs',
  },
  {
    icon: OrderIcon,
    title: DOC_REP_TYPES.ORDER,
    action: 'Manage Order Docs',
  },
  {
    icon: ArchivedIcon,
    title: DOC_REP_TYPES.ARCH,
    action: 'Manage Archived Docs',
  },
];

export const NOT_APPLICABLE = 'N/A';

export const ALLOWED_TYPES = [
  'image/png',
  'image/jpeg',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'application/vnd.ms-excel', // xls
  'application/pdf',
];
