import { Redirect, Route } from 'react-router-dom';

import { ROUTE } from '../../routes/constants';
import { useAuth } from '../hooks';

type ProtectedRoutePropTypes = {
  path: string;
  children: React.ReactElement;
};

const ProtectedRoute = ({ path, children }: ProtectedRoutePropTypes): React.ReactElement => {
  const auth = useAuth();

  return (
    <Route
      path={path}
      render={({ location }) =>
        auth.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTE.LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
