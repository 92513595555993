import { useCallback } from 'react';
import { Row } from 'react-table';
import { useRecoilState, useRecoilValue } from 'recoil';

import { activeProductSku, isUploadingFilesToOrder } from '../../../atoms';
import { Modal } from '../../../shared/components';
import UploadFiles from '../../document-repository/order-docs/file-upload/UploadFiles';
import { Table } from '../../table';
import { useProductPurchases, useProductPurchasesColumns } from '../hooks';
import { ProductPurchasesTableData } from '../types';
import RelatedDocumentsSubrow from './RelatedDocumentsSubrow';

const intialTableState = { sortBy: [{ id: 'order_date', desc: true }] };

export default function ProductPurchases(): React.ReactElement {
  const [orderId, setOrderId] = useRecoilState(isUploadingFilesToOrder);
  const gxp_sku = useRecoilValue(activeProductSku);

  const {
    data: { orders, total_pages, total_items } = {},
    fetch,
    refetch,
    isLoading,
    isFetching,
  } = useProductPurchases(gxp_sku);
  const { columns } = useProductPurchasesColumns();

  const hasSubrows = (row: Row<ProductPurchasesTableData>): boolean => row.original.has_documents;

  const renderRowSubComponent = useCallback(
    ({ row }) => <RelatedDocumentsSubrow orderId={row.original.purchase_order} />,
    []
  );

  const handleCloseUploadModal = () => {
    refetch();
    setOrderId('');
  };

  return (
    <>
      <div className="product_purchases">
        <Table<ProductPurchasesTableData>
          columns={columns}
          data={orders || []}
          isLoading={isLoading}
          isFetching={isFetching}
          fetchData={fetch}
          hasSubrows={hasSubrows}
          renderRowSubComponent={renderRowSubComponent}
          pageCount={total_pages}
          totalItemsCount={total_items}
          initialState={intialTableState}
        />
      </div>
      <Modal show={!!orderId} closeModal={handleCloseUploadModal} customClose>
        <UploadFiles orderId={orderId} />
      </Modal>
    </>
  );
}
