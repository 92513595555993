import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { DropResult } from 'react-beautiful-dnd';

import ServerStateKeys from '../../../utils/server-state-keys';
import { UseDashboard } from './types';
import { Dashboard } from '../../../api/schemas';
import { getDomoDashboards } from '../../../api/queries';

const KEY_DASHBOARD_TABS = 'domo-tabs';

const setDashboardTabPref = (tabs: Dashboard[]) => {
  localStorage.setItem(KEY_DASHBOARD_TABS, JSON.stringify(tabs));
};

const getDashboardTabPref = (): Dashboard[] => {
  return JSON.parse(localStorage.getItem(KEY_DASHBOARD_TABS) || '[]') as Dashboard[];
};

const clearDashboardTabPref = () => {
  localStorage.setItem(KEY_DASHBOARD_TABS, JSON.stringify([]));
};

const isEqual = (ar1: [], ar2: [], comp: string) => {
  const i = ar1.filter(v1 => ar2.find(v2 => v1[comp] === v2[comp]));
  return i.length === ar1.length && i.length === ar2.length;
};

export default function useDashboards(): UseDashboard {
  const [arrangedTabs, setArrangedTabs] = useState<Dashboard[]>([]);
  const { data, isLoading } = useQuery([ServerStateKeys.Dashboards], getDomoDashboards);

  const handleOnDragEnd = (result: DropResult) => {
    if (result.destination) {
      const items = Array.from(arrangedTabs);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setDashboardTabPref(items);
      setArrangedTabs(items);
    }
  };

  useEffect(() => {
    // Only if data is sent from the server
    if (!isLoading && data?.data) {
      // Check if data is already in the local storage
      const storedData = getDashboardTabPref();
      const serverData = data.data;

      // compare data and parsed items to check if they are the different
      // in length or content. If so use the server data. Else use stored data
      let userData = storedData;

      if (!isEqual(storedData as [], serverData as [], 'id')) {
        clearDashboardTabPref();
        userData = serverData;
      }

      setArrangedTabs(userData);
    }
  }, [data]);

  return { data: arrangedTabs, isLoading, onDragEnd: handleOnDragEnd };
}
