import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { resetPassword } from '../../../api/mutations';
import { PasswordResetRequest, TokenObtainPair } from '../../../api/schemas';
import { ChangePwdServerError, UsePasswordReset } from './types';

export default function usePasswordReset(): UsePasswordReset {
  const { mutate, isLoading, isSuccess, isError, error } = useMutation<
    AxiosResponse<TokenObtainPair>,
    AxiosError<ChangePwdServerError>,
    PasswordResetRequest
  >((payload: PasswordResetRequest) => resetPassword(payload));

  const reset = (token: string, password: string) => {
    mutate({ token, password });
  };

  const errorResponse = error?.response?.data;

  return {
    reset,
    error: errorResponse?.detail || errorResponse?.non_field_errors?.[0],
    isLoading,
    isSuccess,
    isError,
  };
}
