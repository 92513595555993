import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { catalogTableQueryParams } from '../../atoms';
import { Dropdown } from '../../shared/components';
import { useQueryParams } from '../../shared/hooks';
import { Search } from '../table';
import { useTableQueryParams } from '../table/hooks';
import CatalogTable from './CatalogTable';
import { useHiddenColumns, useProductCatalog, useProductCatalogColumns } from './hooks';
import { HideColumnsMenu, ItemsPerPage } from './table-settings';

export default function ProductCatalog(): React.ReactElement {
  const params = useQueryParams();

  const setCatalogTableQueryParams = useSetRecoilState(catalogTableQueryParams);

  const {
    searchString,
    pageSize: pageSizeFromQuery,
    setSearchString,
    setPageSize: setPageSizeFromQuery,
  } = useTableQueryParams();

  const [search, setSearch] = useState(searchString);
  const [pageSize, setPageSize] = useState(pageSizeFromQuery);

  const columns = useProductCatalogColumns(!!search);
  const {
    fetch,
    data: { products, total_pages, total_items } = {},
    isLoading,
    isFetching,
  } = useProductCatalog();

  const { hiddenColumns, toggleColumn, toggleAllColumns } = useHiddenColumns();

  useEffect(() => {
    // Persist catalog table's query params in recoil state in order to maintain sorting/pagination/filtering/search
    // when moving to different product details pages and going back to catalog
    if (!params.has('pid')) setCatalogTableQueryParams(params.toString());
  }, [params]);

  useEffect(() => {
    setSearchString(search);
  }, [search]);

  useEffect(() => {
    setPageSizeFromQuery(pageSize);
  }, [pageSize]);

  return (
    <div className="prod_catalog__container">
      <div className="prod_catalog__filtering">
        <Search
          placeholder="Search by Product, SKU, Category, Etc"
          search={setSearch}
          isSearching={!!search && isFetching}
          showResults={!!search && (!isFetching || total_items === 0)}
          itemCount={total_items}
          defaultValue={searchString}
        />

        <div className="prod_catalog__settings">
          <Dropdown name="Show/hide columns">
            <HideColumnsMenu
              hiddenColumns={hiddenColumns}
              toggleColumn={toggleColumn}
              toggleAllColumns={toggleAllColumns}
            />
          </Dropdown>
          <Dropdown name={`Showing ${pageSize}`}>
            <ItemsPerPage pageSize={pageSize} setPageSize={setPageSize} />
          </Dropdown>
        </div>
      </div>
      <CatalogTable
        columns={columns}
        data={products || []}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchData={fetch}
        pageCount={total_pages}
        totalItemsCount={total_items}
        search={search}
        hiddenColumns={hiddenColumns}
        pageSize={pageSize}
      />
    </div>
  );
}
