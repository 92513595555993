import { useQuery } from 'react-query';

import { getUserRoles } from '../../../api/queries';
import ServerStateKeys from '../../../utils/server-state-keys';
import { ROLE_DESCRIPTION } from '../edit-role/constants';

type UseUserRoles = {
  data?: string[];
  isLoading: boolean;
};

export default function useUserRoles(): UseUserRoles {
  const { data, isLoading } = useQuery([ServerStateKeys.UserRoles], getUserRoles, {
    staleTime: 60000,
    select: ({ data: response }) =>
      // need to maintain explicitly role ordering (Vendor Admin -> Full Access -> View Only)
      Object.keys(ROLE_DESCRIPTION).filter(roleName =>
        response.some(({ name }) => name === roleName)
      ),
  });

  return { data, isLoading };
}
