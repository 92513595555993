import { memo } from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';

type EditDocPropTypes = {
  id: string;
  deleteFile: (id: string[]) => void;
};

function DeleteCell({ id, deleteFile }: EditDocPropTypes): React.ReactElement {
  return (
    <div className="delete">
      <button
        type="button"
        name="Delete File"
        data-testid="delete_file"
        onClick={() => deleteFile([id])}
      >
        <DeleteIcon stroke="#006298" />
      </button>
    </div>
  );
}

export default memo(DeleteCell);
