import { useCallback, useEffect, useRef } from 'react';
import { useFilters, useRowSelect, useSortBy, useTable } from 'react-table';

import { useDebouncedCallback } from '../../shared/hooks';
import { QueryParams } from '../../shared/types';
import { DESELECT_ALL } from './constants';
import { useTableColumnLoading, useTableDataLoading } from './hooks';
import { InfiniteScrollTablePropTypes, SearchParams } from './types';
import {
  customTableReducer,
  getSelectedRowsFlatData,
  getTheadStyle,
  stringifyOrdering,
} from './utils';

/**
 * Table with infinite scroll and debounced server-side searching
 */
export default function InfiniteScrollTable<D extends Record<string, unknown>>({
  columns,
  data,
  pinnedRows,
  isLoading,
  isFetching,
  hasNextPage,
  searchParams,
  initialState,
  selectedRows,
  hasInitialData,
  fetchData,
  update,
  setSelectedRows,
  loadingMessage = 'Loading more products...',
}: InfiniteScrollTablePropTypes<D>): React.ReactElement {
  const tbodyRef = useRef<HTMLTableSectionElement>(null);

  const { tableData, getRowId } = useTableDataLoading({
    placeholderRows: 2,
    isLoading,
    data: pinnedRows ? [...pinnedRows, ...data] : data,
  });
  const { tableColumns } = useTableColumnLoading({ columns, isLoading });

  const {
    headerGroups,
    rows,
    visibleColumns,
    state: { sortBy, selectedRowIds, filters },
    dispatch,
    prepareRow,
    getTableBodyProps,
    setFilter,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      initialState,
      autoResetSelectedRows: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      manualFilters: true,
      manualSortBy: true,
      getRowId,
      stateReducer: customTableReducer,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const setSearchParams = (parameters: SearchParams<D>) => {
    Object.entries(parameters).forEach(([field, value]) => setFilter(field, value));
  };

  const debouncedSearch = useDebouncedCallback(setSearchParams, 500);

  useEffect(() => {
    debouncedSearch(searchParams);
  }, [searchParams]);

  useEffect(() => {
    // Reset selectedRowIds from parent component
    if (
      selectedRows &&
      Object.keys(selectedRows).length === 0 &&
      Object.keys(selectedRowIds).length
    ) {
      // dispatching custom action type due to 'toogleAllRowsSelected()' method can't handle unselecting pinned rows
      dispatch({ type: DESELECT_ALL });
    }
  }, [selectedRows]);

  useEffect(() => {
    if (setSelectedRows) {
      const ids = Object.keys(selectedRowIds);
      const selectedFlatRows = getSelectedRowsFlatData(ids, tableData);

      setSelectedRows(selectedFlatRows);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    const flatFilters = filters.reduce((acc, { id, value }) => ({ ...acc, [id]: value }), {});

    const parameters = {
      page_size: 20,
      ...flatFilters,
    } as QueryParams;

    if (sortBy.length) {
      parameters.ordering = stringifyOrdering(sortBy[0]);
    }

    fetchData(parameters);
  }, [fetchData, filters]);

  const onScroll = () => {
    if (tbodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tbodyRef.current;

      // 70px here is 3 rows, it allows to prefetch next page when user is 3 rows close to reach the end of current "page"
      if (scrollTop + clientHeight + 70 >= scrollHeight) {
        if (hasNextPage && !isFetching) {
          update();
        }
      }
    }
  };

  const renderLoadingRow = useCallback(
    () => (
      <tr>
        <td colSpan={visibleColumns.length}>
          <div className="loading_more__row">
            <div className="loader" />
            <p>{loadingMessage}</p>
          </div>
        </td>
      </tr>
    ),
    []
  );

  return (
    <table>
      <thead style={getTheadStyle(tbodyRef)}>
        {headerGroups.map(headerGroup => (
          /* eslint-disable react/jsx-key */
          // disabling the rule here due to the fact that getProps functions actually make iterated elements keyed
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} className={column.headerClassName ?? ''}>
                <div className="header-cell">{column.render('Header')}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody ref={tbodyRef} onScroll={onScroll} {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);

          return (
            <>
              {isLoading && index === 1 && renderLoadingRow()}
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} className={cell.column.className ?? ''}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            </>
          );
        })}
        {hasNextPage && renderLoadingRow()}
        {!data.length && !isLoading && !!filters.length && !hasInitialData && (
          <tr>
            <td colSpan={visibleColumns.length}>
              Sorry, we couldn’t find a match for “{filters.map(({ value }) => value).join(', ')}”.
              Please try another search.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
