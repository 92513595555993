import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getCorpAgreements } from '../../../../api/queries';
import { CorporateAgreement } from '../../../../api/schemas';
import { AsyncQuery, QueryParams } from '../../../../shared/types';
import ServerStateKeys from '../../../../utils/server-state-keys';
import { formatCorpAgreementFiles } from '../utils/helpers';

type CorpAgreements = AsyncQuery & {
  data?: CorporateAgreement[];
  fetch: (params?: QueryParams) => void;
};

export default function useCorpAgreements(): CorpAgreements {
  const [params, setParams] = useState<QueryParams>({} as QueryParams);

  const { data, isLoading, isFetching } = useQuery(
    [ServerStateKeys.CorporateAgreements, params],
    () => getCorpAgreements(params),
    {
      keepPreviousData: true,
      select: useCallback(
        ({ data: response }: AxiosResponse<CorporateAgreement[]>): CorporateAgreement[] =>
          response.map(({ document_type, files }) => ({
            id: document_type,
            document_type,
            files: formatCorpAgreementFiles(files),
          })),
        []
      ),
    }
  );

  const fetch = useCallback((parameters?: QueryParams) => {
    if (parameters) {
      setParams(parameters);
    }
  }, []);

  return { data, isLoading, isFetching, fetch };
}
