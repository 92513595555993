import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ServicePlans } from '../../components/service-plans';
import { AccountManagement } from '../../components/account-management';
import { AlertsSettings, ChangePassword } from '../../components/settings';
import { SETTINGS_PAGES, SETTINGS_SPACES } from '../../components/settings/constants';
import { UserProfile } from '../../components/user-profile';
import { ROUTE } from '../../routes/constants';
import { PageRoute, PageSidebar } from '../../shared/components';
import { usePermission } from '../../shared/hooks';
import { PERMISSIONS } from '../../shared/constants';

export default function SettingsPage(): React.ReactElement {
  const { search } = useLocation();
  const { isLoading, isAllowed } = usePermission(PERMISSIONS.VIEW_REGULAR_USERS);

  const [currentPage, setCurrentPage] = useState<string>(
    new URLSearchParams(search).get('type') || SETTINGS_PAGES.USER_PROFILE
  );

  useEffect(() => {
    const pageFromUrl = new URLSearchParams(search).get('type') || '';
    if (pageFromUrl) {
      setCurrentPage(pageFromUrl);
    }
  }, [search]);

  const renderScreen = useCallback((): React.ReactElement | null => {
    if (currentPage === SETTINGS_PAGES.USER_PROFILE) return <UserProfile />;
    if (currentPage === SETTINGS_PAGES.CHANGE_PWD) return <ChangePassword />;
    if (currentPage === SETTINGS_PAGES.ALERTS) return <AlertsSettings />;
    if (currentPage === SETTINGS_PAGES.SERVICE_PLANS) return <ServicePlans />;
    if (currentPage === SETTINGS_PAGES.ACC_MGMT) {
      if (isLoading) return null;
      if (isAllowed) return <AccountManagement />;
    }

    return <UserProfile />;
  }, [currentPage, isAllowed]);

  return (
    <>
      <PageRoute linkTo={ROUTE.HOMEPAGE} linkLabel="Home" currentPage={currentPage} />
      <section className="settings">
        <h2>Settings</h2>
        <div>
          <PageSidebar
            options={SETTINGS_SPACES}
            currentOption={currentPage}
            baseRoute={ROUTE.SETTINGS}
          />
          {renderScreen()}
        </div>
      </section>
    </>
  );
}
