import { ReactElement } from 'react';

import { useTermsConditionsText } from '../../components/terms_n_conditions/hooks';
import { ROUTE } from '../../routes/constants';
import { PageRoute } from '../../shared/components';

export default function TnCPage(): ReactElement {
  const { data: { text, created_ts } = {} } = useTermsConditionsText();

  return (
    <>
      <PageRoute linkTo={ROUTE.HOMEPAGE} linkLabel="Home" currentPage="Terms &amp; Conditions" />
      <section className="terms-and-conditions-page__container">
        <h2>Terms &amp; Conditions</h2>
        {created_ts && <div className="timestamp">Revised: {created_ts}</div>}
        {/* eslint-disable-next-line react/no-danger */}
        <div className="content" dangerouslySetInnerHTML={{ __html: text as string }} />
      </section>
    </>
  );
}
