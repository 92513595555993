import { useRecoilState, useSetRecoilState } from 'recoil';

import { PatchedDocumentUpdateRequest, ProductDocument } from '../../../api/schemas';
import {
  documentToEdit,
  editedDocName,
  filesToLinkToSku,
  isConfirmArchiveProductDocs,
  isUploadAndLinkOpen,
  prodDocsToUnlink,
  uploadAndLinkLoadingStatus,
} from '../../../atoms';
import { Modal, WarningWindow } from '../../../shared/components';
import EditDocName from '../../document-repository/document-edit/EditDocName';
import { WARN_CONTENT_CONFIRM_ARCHIVE } from '../../document-repository/persistent-product/constants';
import { WARN_CONTENT } from './constants';
import UploadAndLinkDocs from './UploadAndLinkDocs';

type DocumentsTabModalsPropTypes = {
  confirmArchive: () => void;
  refetch: () => void;
  linkDocs: (ids: string[]) => void;
};

export default function DocumentsTabModals({
  confirmArchive,
  refetch,
  linkDocs,
}: DocumentsTabModalsPropTypes): React.ReactElement {
  const [isConfirmArchive, setConfirmArchive] = useRecoilState(isConfirmArchiveProductDocs);
  const [isUpload, setIsUpload] = useRecoilState(isUploadAndLinkOpen);
  const [isEdit, setIsEdit] = useRecoilState(documentToEdit);
  const [docsToUnlink, setDocsToUnlink] = useRecoilState(prodDocsToUnlink);

  const setPersistedDocDetails = useSetRecoilState(editedDocName);
  const setLoadingStatus = useSetRecoilState(uploadAndLinkLoadingStatus);
  const setFilesToLink = useSetRecoilState(filesToLinkToSku);

  const handleCloseEdit = () => {
    setIsEdit({} as ProductDocument);
    setPersistedDocDetails({} as PatchedDocumentUpdateRequest);
  };

  const handleCloseUpload = () => {
    refetch();
    setIsUpload(false);
    setLoadingStatus([]);
    setFilesToLink({});
  };

  const confirmUnlink = () => {
    linkDocs(Object.keys(docsToUnlink));
    setDocsToUnlink({});
  };

  return (
    <>
      <Modal show={isUpload} closeModal={handleCloseUpload} customClose>
        <UploadAndLinkDocs />
      </Modal>
      <Modal
        show={!!isEdit?.id}
        header="Edit Document Details:"
        subheader={`Document: ${isEdit.name}`}
        closeModal={handleCloseEdit}
      >
        <div className="edit_doc__modal no_sidebar">
          <div className="edit_doc__content">
            <EditDocName autoclose />
            <button type="button" name="Close" className="close_btn" onClick={handleCloseEdit}>
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal show={!!Object.values(docsToUnlink).length} closeModal={() => setDocsToUnlink({})}>
        <WarningWindow content={WARN_CONTENT} confirm={confirmUnlink} />
      </Modal>
      <Modal show={isConfirmArchive} closeModal={() => setConfirmArchive(false)}>
        <WarningWindow content={WARN_CONTENT_CONFIRM_ARCHIVE} confirm={confirmArchive} />
      </Modal>
    </>
  );
}
