import { useState } from 'react';

import { TokenObtainPair } from '../../api/schemas';
import API from '../../utils/api';

type UseToken = {
  saveToken: (token: TokenObtainPair, cb?: () => void) => void;
  clearToken: () => void;
  token?: string;
};

const getToken = (): string | undefined => {
  const tokenString = localStorage.getItem('token');
  if (!tokenString) {
    return undefined;
  }

  const userToken: TokenObtainPair = JSON.parse(tokenString);
  if (userToken.access) {
    API.defaults.headers.common.Authorization = `Bearer ${userToken.access}`;
  }

  return userToken?.access;
};

export default function useToken(): UseToken {
  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: TokenObtainPair, cb?: () => void): void => {
    localStorage.setItem('token', JSON.stringify(userToken));
    API.defaults.headers.common.Authorization = `Bearer ${userToken.access}`;
    setToken(userToken.access);
    cb?.();
  };

  const clearToken = (cb?: () => void) => {
    saveToken({ access: '', refresh: '' }, cb);
    delete API.defaults.headers.common.Authorization;
  };

  return {
    saveToken,
    clearToken,
    token,
  };
}
