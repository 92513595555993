import { AxiosResponse } from 'axios';

import { QueryParams, QueryParamsFilter } from '../../shared/types';
import API from '../../utils/api';
import {
  Product,
  ProductAdditionalDetails,
  ProductCategory,
  ProductPaginated,
  ProductPurchaseOrdersPaginated,
} from '../schemas';

export const getProductDetails = (sku: string): Promise<AxiosResponse<ProductAdditionalDetails>> =>
  API.get<ProductAdditionalDetails>(`/api/products/${sku}/`);

export const getProducts = (
  params: QueryParamsFilter<Product>
): Promise<AxiosResponse<ProductPaginated>> =>
  API.get<ProductPaginated>('/api/products/', { params });

export const searchProductsByField = (
  params: QueryParamsFilter<Product>
): Promise<AxiosResponse<ProductPaginated>> =>
  API.get<ProductPaginated>('/api/products/search-by-fields', { params });

export const getProductPurchases = (
  sku: string,
  params: QueryParams
): Promise<AxiosResponse<ProductPurchaseOrdersPaginated>> =>
  API.get<ProductPurchaseOrdersPaginated>(`/api/products/${sku}/orders`, { params });

export const getProductCategories = (): Promise<AxiosResponse<ProductCategory[]>> =>
  API.get<ProductCategory[]>('/api/products/categories/');
