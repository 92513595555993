type SubmitButtonPropTypes = {
  name: string;
  isLoading: boolean;
  isDisabled?: boolean;
};

export default function SubmitButton({
  name,
  isLoading,
  isDisabled,
}: SubmitButtonPropTypes): React.ReactElement {
  return isLoading ? (
    <button type="button" className="submit_btn loader">
      <div />
    </button>
  ) : (
    <button type="submit" className="submit_btn" name={name} disabled={isDisabled}>
      {name}
    </button>
  );
}
