import { useMutation } from 'react-query';
import uaParser from 'ua-parser-js';

import { acceptTermsConditions } from '../../../api/mutations';
import { TermsConditionsAcceptRequest } from '../../../api/schemas';
import { UseTermsAccept } from './types';

export default function useTermsAccept(): UseTermsAccept {
  const { mutate, isLoading, isSuccess } = useMutation((userInfo: TermsConditionsAcceptRequest) =>
    acceptTermsConditions(userInfo)
  );

  const accept = () => {
    const { os, browser } = uaParser(navigator.userAgent);

    const clientOs = `${os.name} ${os.version}`;
    const clientBrowser = `${browser.name} ${browser.version}`;

    mutate({ operating_system: clientOs, browser: clientBrowser });
  };

  return {
    accept,
    isLoading,
    isSuccess,
  };
}
