import { Dropdown, RadioButton } from '../../shared/components';
import { useDocumentLabels } from './persistent-product/file-upload/hooks';
import { LabelSelectPropTypes } from './types';

export default function ProductDocLabelSelect({
  placeholder,
  fileLabelId,
  isDisabled,
  handleLabelSelect,
}: LabelSelectPropTypes): React.ReactElement {
  const { labels } = useDocumentLabels();

  const currentFileLabel = labels.find(({ id }) => id === fileLabelId)?.name;

  return (
    <Dropdown disabled={isDisabled} name={currentFileLabel || placeholder}>
      <div className="file_label_menu__container">
        {labels.map(({ id, name }) => (
          <div key={id} className="radio_btn__row">
            <label>
              <RadioButton
                isChecked={fileLabelId === id}
                handleChange={() => handleLabelSelect(id)}
              />
              <span className="radio_btn__label">{name}</span>
            </label>
          </div>
        ))}
      </div>
    </Dropdown>
  );
}
