import { object, string } from 'yup';

export const UserProfileSchema = object({
  first_name: string().required('First Name is required.'),
  last_name: string().required('Last Name is required.'),
  phone_number: string().matches(/^(\+\d{1,2}[\s.-])?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, {
    excludeEmptyString: true,
    message: 'Phone number is not valid',
  }),
  department: string().required('Department is required.'),
  position: string().required('Position is required.'),
});
