import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { ProductDocument } from '../../../../api/schemas';
import { filesToLinkToSku } from '../../../../atoms';
import SelectedProductsTable from '../../../document-repository/document-edit/unlink-products/SelectedProductsTable';
import { useFilesToLinkColumns } from '../../hooks';

export default function SelectedFilesToLink(): React.ReactElement {
  const [files, setFiles] = useRecoilState(filesToLinkToSku);

  const { columns } = useFilesToLinkColumns(true);

  const handleUnselect = useCallback((selectedRows: Record<string, ProductDocument>) => {
    setFiles(selectedRows);
  }, []);

  return (
    <div className="selected_products_wrapper">
      <SelectedProductsTable
        title="Documents to link:"
        data={Object.values(files)}
        columns={columns}
        setSelectedRows={handleUnselect}
      />
    </div>
  );
}
