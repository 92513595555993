const getConfig = name => {
  // eslint-disable-next-line no-underscore-dangle
  const { [name]: value } = window.__RUNTIME_CONFIG__;
  return value;
};

export const NODE_ENV = getConfig('NODE_ENV');
export const PUBLIC_URL = getConfig('PUBLIC_URL');

export const BE_HOST = getConfig('BE_HOST');
export const GOOGLE_ANALYTICS_ID = getConfig('GOOGLE_ANALYTICS_ID');

export default getConfig;
