import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { documentToEdit, productSkusToLink } from '../../../../atoms';
import { SubmitButton } from '../../../../shared/components';
import { useLinkProducts } from '../../persistent-product/hooks';
import { useResetLinkForm } from '../hooks';
import ChooseProductsToLink from './ChooseProductsToLink';
import SelectedProductsToLink from './SelectedProductsToLink';

export default function LinkProducts(): React.ReactElement {
  const fileData = useRecoilValue(documentToEdit);
  const selectedProducts = useRecoilValue(productSkusToLink);

  const selectedProdsValues = Object.values(selectedProducts);

  const { assingProducts, isLoading, isSuccess } = useLinkProducts('link');
  const { resetForm } = useResetLinkForm();

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess]);

  const handleUnlinkProds = (e: React.FormEvent) => {
    e.preventDefault();

    assingProducts(fileData.id, selectedProdsValues);
  };

  return (
    <form onSubmit={handleUnlinkProds}>
      <p className="form_input_label">Link a new product SKUs to this document:</p>
      <ChooseProductsToLink />
      <SelectedProductsToLink />
      <SubmitButton
        name="Link SKU(s) & save"
        isLoading={isLoading}
        isDisabled={!selectedProdsValues.length}
      />
    </form>
  );
}
