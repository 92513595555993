import { useEffect, useState } from 'react';
import { IdType } from 'react-table';

import { Notification, NotificationAction } from '../../api/schemas';
import { Modal } from '../../shared/components';
import ActionButtons from '../document-repository/ActionButtons';
import { Search, Table } from '../table';
import { ARCHIVED_NTH_ACTION_BTN, defaultTableSort } from './constants';
import {
  useArchivedNotifications,
  useArchivedNotificationsColumns,
  useArchiveNotification,
} from './hooks';

import ReadMoreWindow from './ReadMoreWindow';
import { NotificationsTableData } from './types';

export default function ArchivedNotificationsTable(): React.ReactElement {
  const [readMore, setReadMore] = useState<number | null>(null);
  const [selectedRows, setSelectedRows] = useState<Record<IdType<Notification>, Notification>>({});
  const [search, setSearch] = useState('');

  const {
    fetch,
    refetch,
    data: { count, results } = {},
    isLoading,
    isFetching,
  } = useArchivedNotifications();
  const { columns } = useArchivedNotificationsColumns({ openReadMoreModal: setReadMore });
  const {
    archiveNotifications,
    isLoading: isRestoring,
    isSuccess: isRestoreSuccess,
  } = useArchiveNotification();

  useEffect(() => {
    if (isRestoreSuccess) {
      refetch();
      setSelectedRows({});
    }
  }, [isRestoreSuccess]);

  const restoreNotifications = (status: NotificationAction) => {
    const ids = Object.values(selectedRows).map(({ id }) => id);
    archiveNotifications(status, ids);
  };

  return (
    <div className="doc_rep_content">
      <h3>Archived Notifications</h3>
      <Search
        placeholder="Search by Notification Description"
        search={setSearch}
        isSearching={!!search && isFetching}
        showResults={!!search && (!isFetching || count === 0)}
        itemCount={count}
      />
      <ActionButtons
        buttons={ARCHIVED_NTH_ACTION_BTN}
        actionHandlers={{ restore: () => restoreNotifications('restore') }}
        loading={{ restore: isRestoring }}
        selectedRows={selectedRows}
      />
      <div className="table-wrapper">
        <Table<NotificationsTableData>
          data={results || []}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          fetchData={fetch}
          search={search}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          pageCount={count && Math.ceil(count / 10)}
          totalItemsCount={count}
          initialState={defaultTableSort}
        />
      </div>
      <Modal show={!!readMore} closeModal={() => setReadMore(null)} customClose>
        <ReadMoreWindow notificationId={readMore} notificationList={results} />
      </Modal>
    </div>
  );
}
