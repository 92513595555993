import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';

import { uploadProductDocument } from '../../../api/mutations';
import { uploadAndLinkLoadingStatus } from '../../../atoms';
import isFileTypeValid from '../../document-repository/persistent-product/file-upload/validations';
import { FileData, LoadingStatus, UploadServerError } from '../../document-repository/types';
import { handleUploadFile, updateLoadingStatuses } from '../../document-repository/utils/helpers';

export type UseUploadDocument = {
  loadingStatuses: LoadingStatus[];
  upload: (files: File[]) => void;
};

export default function useUploadDocuments(): UseUploadDocument {
  const [loadingStatuses, setLoadingStatuses] = useRecoilState(uploadAndLinkLoadingStatus);

  const { mutate } = useMutation<AxiosResponse<FileData>, AxiosError<UploadServerError>, FormData>(
    (file: FormData) => uploadProductDocument(file),
    {
      onSuccess: ({ data }) => {
        setLoadingStatuses(prevStatuses => updateLoadingStatuses(data, prevStatuses));
      },
    }
  );

  const upload = (files: File[]) => {
    handleUploadFile(files, isFileTypeValid, setLoadingStatuses, mutate);
  };

  return { loadingStatuses, upload };
}
