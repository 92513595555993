import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getOrderDocuments } from '../../../../api/queries';
import ServerStateKeys from '../../../../utils/server-state-keys';
import { UsePurchaseDocs } from './types';

export default function usePurchaseDocs(): UsePurchaseDocs {
  const [orderId, setOrderId] = useState<string>('');

  const { data, isLoading, refetch } = useQuery(
    [ServerStateKeys.OrderDocsOrderList, orderId],
    () => getOrderDocuments(orderId),
    {
      enabled: !!orderId,
    }
  );

  const fetch = useCallback((id: string) => {
    setOrderId(id);
  }, []);

  return { data: data?.data, isLoading, fetch, refetch };
}
