import { useEffect } from 'react';
import { Column, IdType, useRowSelect, useTable } from 'react-table';

import { useTableDataLoading } from '../../../table/hooks';
import { getSelectedRowsFlatData } from '../../../table/utils';

type SelectedProductsPropTypes<D extends Record<string, unknown>> = {
  title: string;
  data: D[];
  columns: Column<D>[];
  setSelectedRows: (rows: Record<IdType<D>, D>) => void;
};

export default function SelectedProductsTable<D extends Record<string, unknown>>({
  title,
  data,
  columns,
  setSelectedRows,
}: SelectedProductsPropTypes<D>): React.ReactElement {
  const { getRowId } = useTableDataLoading({
    placeholderRows: 3,
    isLoading: false,
    data,
  });

  const {
    rows,
    visibleColumns,
    state: { selectedRowIds },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        // make all data rows checked by default
        selectedRowIds: Object.values(data).reduce(
          (acc, { id }) => ({ ...acc, [id as string]: true }),
          {} as Record<IdType<D>, boolean>
        ),
      },
      getRowId,
    },
    useRowSelect
  );

  useEffect(() => {
    const ids = Object.keys(selectedRowIds);

    if (setSelectedRows && ids.length !== data.length) {
      const selectedFlatRows = getSelectedRowsFlatData(ids, data);

      setSelectedRows(selectedFlatRows);
    }
  }, [selectedRowIds]);

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={visibleColumns.length}>{title}</th>
        </tr>
      </thead>
      <tbody>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={row.id}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    key={`${cell.column.id}-${cell.row.id}-${cell.row.original.supplier_sku}`}
                    className={cell.column.className ?? ''}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
