import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-narrow.svg';
import { LoadingStatus } from '../types';

export default function UploadingStatus({
  loading,
  error,
}: Pick<LoadingStatus, 'loading' | 'error'>): React.ReactElement {
  if (error) {
    return <div className="error">X</div>;
  }
  if (loading) {
    return <div className="loader" />;
  }

  return <SuccessIcon width="15px" height="10px" stroke="#40994E" />;
}
