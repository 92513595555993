import { memo } from 'react';

import { ReactComponent as OpenIcon } from '../../../assets/icons/open.svg';
import { FileData } from '../types';

function PreviewDoc({ name, url }: Pick<FileData, 'name' | 'url'>): React.ReactElement {
  const openInNewTab = (): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <button type="button" onClick={openInNewTab}>
      <span className="doc_name" title={name}>
        {name}
      </span>
      <div className="icon">
        <OpenIcon width="12px" height="12px" />
      </div>
    </button>
  );
}

export default memo(PreviewDoc);
