import { useMutation, useQueryClient } from 'react-query';

import { linkDocsToProduct } from '../../../api/mutations';
import { LinkDocumentsToProductRequest } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseLinkDocsToProduct = {
  linkDocs: (ids: string[]) => void;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function useLinkDocsToProduct(
  action: 'link' | 'unlink',
  gxp_sku: string,
  supplier_sku: string
): UseLinkDocsToProduct {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    (payload: LinkDocumentsToProductRequest) => linkDocsToProduct(payload),
    {
      onSuccess: () => {
        // need to cancel the query before invalidating due to request taking a long time to get the data
        // and invalidate queries method is not affecting active queries
        queryClient.cancelQueries(ServerStateKeys.PersistentDocuments);
        queryClient.invalidateQueries(ServerStateKeys.PersistentDocuments);
      },
    }
  );

  const linkDocs = (ids: string[]) => {
    mutate({ gxp_sku, supplier_sku, action, doc_ids: ids });
  };

  return { linkDocs, isLoading, isSuccess };
}
