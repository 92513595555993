import React, { createContext, useContext } from 'react';

import { TokenObtainPair } from '../../api/schemas';
import useToken from './useToken';

type AuthContextValue = {
  token?: string;
  signin: (token: TokenObtainPair, cb?: () => void) => void;
  signout: (cb?: () => void) => void;
};

type AuthProviderPropTypes = {
  children: React.ReactElement;
};

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

export const useAuth = (): AuthContextValue => useContext(AuthContext);

export const AuthProvider = ({ children }: AuthProviderPropTypes): React.ReactElement => {
  const { token, saveToken, clearToken } = useToken();

  return (
    <AuthContext.Provider value={{ token, signin: saveToken, signout: clearToken }}>
      {children}
    </AuthContext.Provider>
  );
};
