import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';

type DocStatusPropTypes = {
  value: string;
};

const DOCUMENT_STATUS: Record<number, string> = {
  1: 'N/A',
  2: 'Received',
  3: 'In progress',
  4: 'Accepted',
};

export default function DocStatus({ value }: DocStatusPropTypes): React.ReactElement {
  const valueNum = Number(value);

  const getStatusStyle = (): React.CSSProperties => {
    switch (valueNum) {
      case 1:
        return { color: '#d61d1d' };
      case 2:
        return { color: '#4a93d6' };
      case 3:
        return { color: '#a2b721' };
      case 4:
        return { color: '#40994e' };

      default:
        return {};
    }
  };

  return (
    <span className="document_status" style={getStatusStyle()}>
      {valueNum === 4 && <SuccessIcon fill="#FFF" />} {DOCUMENT_STATUS[valueNum]}
    </span>
  );
}
