export const UPLOAD_STEPS = [
  {
    step: 1,
    title: 'Upload a File',
  },
  {
    step: 2,
    title: 'Add to Product Record',
  },
  {
    step: 3,
    title: 'Select a File Label',
  },
];

export const ADD_FILES_WARN_CONTENT = {
  main: 'Are you sure you want to close this window? You have not added the files to this record yet. Please click button below to add your files.',
  confirm: 'Close Anyway!',
  cancel: 'Return To Upload Window',
};
