import { AxiosResponse } from 'axios';

import API from '../../utils/api';
import {
  ArchivedDocumentsResponse,
  OrderDocToArchive,
  OrderDocument,
  PatchedDocumentUpdateRequest,
} from '../schemas';

export const archiveOrderDocuments = (
  payload: OrderDocToArchive[]
): Promise<AxiosResponse<ArchivedDocumentsResponse>> =>
  API.post<ArchivedDocumentsResponse>('/api/order-documents/move-to-archive/', payload);

export const editOrderDocument = (
  orderId: string,
  fileId: string,
  payload: PatchedDocumentUpdateRequest
): Promise<AxiosResponse<OrderDocument>> =>
  API.patch<OrderDocument>(`/api/order-documents/order/${orderId}/document/${fileId}`, payload);

export const uploadOrderDocument = (
  orderId: string,
  fileData: FormData
): Promise<AxiosResponse<OrderDocument>> =>
  API.post<OrderDocument>(`/api/order-documents/order/${orderId}/upload/`, fileData);
