import { Notification } from '../../api/schemas';
import { ModalChildPropTypes } from '../../shared/components/Modal';
import { formatDateTime } from '../../shared/utils';
import { NTF_FILTER_OPTIONS } from './constants';

type ReadMoreWindowPropTypes = ModalChildPropTypes & {
  notificationId: number | null;
  notificationList?: Notification[];
};

export default function ReadMoreWindow({
  notificationId,
  notificationList,
  closeModal,
}: ReadMoreWindowPropTypes): React.ReactElement {
  const { event_date, notification_type, description } =
    notificationList?.find(({ id }) => id === notificationId) || {};

  const notificationType =
    NTF_FILTER_OPTIONS.find(({ id }) => id === notification_type)?.label || '';

  return (
    <div className="read_more">
      <div className="read_more__title">
        <h1>
          Notification: {event_date ? formatDateTime(event_date) : ''} - {notificationType}
        </h1>
      </div>
      <div className="read_more__content">
        <p>{description}</p>
        <button type="button" name="Close" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
}
