import { useUserData } from '../../shared/hooks';
import UserProfileForm from './UserProfileForm';

export default function UserProfile(): React.ReactElement {
  const { data: { first_name, last_name, position, department, phone_number } = {} } =
    useUserData();

  return (
    <div className="settings__container">
      <h3>User Profile</h3>
      <p className="description">
        Keep your personal information up to date by editing & saving the Mandatory fields. Please
        note that Mandatory fields cannot be left blank.
      </p>
      <div className="divider" />
      {first_name && (
        <UserProfileForm
          first_name={first_name}
          last_name={last_name as string}
          phone_number={phone_number}
          department={department as string}
          position={position as string}
        />
      )}
    </div>
  );
}
