import { AxiosResponse } from 'axios';

import API from '../../utils/api';
import {
  CreateUserInviteRequest,
  PasswordChangeRequest,
  PasswordResetRequest,
  PasswordResetRequestRequest,
  RegisterUserRequest,
  TemporaryTokenRequest,
  TermsConditionsAcceptRequest,
  TokenObtainPair,
  TokenObtainPairRequest,
  UserDetailsRequest,
  UserRole,
} from '../schemas';

export const passwordChangeRequest = (
  payload: PasswordChangeRequest
): Promise<AxiosResponse<void>> => API.post('/api/accounts/change-password/', payload);

export const requestPasswordReset = (
  payload: PasswordResetRequestRequest
): Promise<AxiosResponse<void>> => API.post('/api/accounts/request-password-reset/', payload);

export const resetPassword = (
  payload: PasswordResetRequest
): Promise<AxiosResponse<TokenObtainPair>> => API.post('/api/accounts/reset-password/', payload);

export const loginUserFromToken = (
  params: TemporaryTokenRequest
): Promise<AxiosResponse<TokenObtainPair>> =>
  API.post<TokenObtainPair>('/api/accounts/token-from-temporary/', params);

export const loginUser = (
  params: TokenObtainPairRequest
): Promise<AxiosResponse<TokenObtainPair>> => API.post<TokenObtainPair>('/api/token', params);

export const acceptTermsConditions = (
  payload: TermsConditionsAcceptRequest
): Promise<AxiosResponse<void>> => API.post('/api/accounts/terms-and-conditions/', payload);

export const registerUserRequest = (payload: RegisterUserRequest): Promise<AxiosResponse<void>> =>
  API.post('/api/accounts/user-register', payload);

export const updateUserDetails = (
  payload: UserDetailsRequest
): Promise<AxiosResponse<UserDetailsRequest>> => API.put('/api/accounts/user-details/', payload);

export const createUserInvites = (payload: CreateUserInviteRequest): Promise<AxiosResponse<void>> =>
  API.post('/api/accounts/create-user-invites', payload);

export const changeUserRole = (id: number, payload: UserRole): Promise<AxiosResponse<void>> =>
  API.post(`/api/accounts/user/${id}/change-role`, payload);

export const resendUserInvite = (id: number): Promise<AxiosResponse<void>> =>
  API.post(`/api/accounts/user-invite/${id}/resend`);

export const cancelUserInvite = (id: number): Promise<AxiosResponse<void>> =>
  API.post(`/api/accounts/user-invite/${id}/cancel`);

export const deactivateUser = (id: number): Promise<AxiosResponse<void>> =>
  API.post(`/api/accounts/user/${id}/deactivate`);

export const reactivateUser = (id: number): Promise<AxiosResponse<void>> =>
  API.post(`/api/accounts/user/${id}/reactivate`);

export const deleteUser = (id: number): Promise<AxiosResponse<void>> =>
  API.delete(`/api/accounts/user/${id}/`);
