import { Fragment, useEffect } from 'react';
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { LoaderModal, Pagination, TableSorting, TotalItemsCount } from '.';
import { QueryParamsFilter } from '../../shared/types';
import { SORT_DIRECTIONS } from './constants';
import { useTableColumnLoading, useTableDataLoading } from './hooks';
import { TablePropTypes } from './types';
import {
  customTableReducer,
  getSelectedRowsFlatData,
  stringifyOrdering,
  useCustomTableHooks,
} from './utils';

export default function Table<DataType extends Record<string, unknown>>({
  columns,
  data,
  isLoading,
  isFetching,
  fetchData,
  placeholderRows = 10,
  pageCount,
  totalItemsCount,
  isRowSelectEnabled,
  initialState,
  search,
  selectedRows,
  setSelectedRows,
  hasSubrows,
  renderRowSubComponent,
}: TablePropTypes<DataType>): React.ReactElement {
  const { tableData, getRowId } = useTableDataLoading<DataType>({
    placeholderRows,
    isLoading,
    data,
  });
  const { tableColumns } = useTableColumnLoading({ columns, isLoading });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setGlobalFilter,
    prepareRow,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize, sortBy, selectedRowIds, filters, globalFilter },
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      pageCount,
      manualFilters: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      manualPagination: true,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      initialState,
      stateReducer: customTableReducer,
      getRowId,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useCustomTableHooks(!isLoading && isRowSelectEnabled)
  );

  useEffect(() => {
    if (pageCount && pageCount < pageIndex + 1 && pageIndex !== 0) {
      // update current page in case it exeeds total pages count
      gotoPage(pageCount - 1);
    }
  }, [pageCount]);

  useEffect(() => {
    // Global filter manages common search input state
    if (search !== globalFilter) {
      // If a search is done, then reset the page to 0. Otherwise, if you search from 2nd page and there
      // is only 1 result the page index in react-table would be 2 showing no results.
      gotoPage(0);
    }

    setGlobalFilter(search);
  }, [search]);

  useEffect(() => {
    if (filters.length) {
      gotoPage(0);
    }
  }, [filters]);

  useEffect(() => {
    const parameters = { page_size: pageSize, page: pageIndex + 1 } as QueryParamsFilter<DataType>;
    if (sortBy.length) {
      parameters.ordering = stringifyOrdering(sortBy[0]);
    }
    if (globalFilter) {
      parameters.search = globalFilter;
    }
    if (filters.length) {
      filters.forEach(({ id, value }) => {
        if (value.length) parameters[id] = value;
      });
    }

    if (fetchData) {
      fetchData(parameters);
    }
  }, [fetchData, sortBy, pageIndex, filters, globalFilter]);

  useEffect(() => {
    if (setSelectedRows) {
      const ids = Object.keys(selectedRowIds);
      const selectedFlatRows = getSelectedRowsFlatData(ids, data, selectedRows);

      setSelectedRows(selectedFlatRows);
    }
  }, [selectedRowIds]);

  if (!data.length && !isLoading && search) {
    return (
      <table {...getTableProps()}>
        <tbody {...getTableBodyProps()}>
          <tr className="no_data">
            <td colSpan={visibleColumns.length}>
              <div className="no_search_yielded">
                Sorry, we couldn’t find a match for
                <span>“{search}”</span>
                Please try another search.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  return (
    <>
      {isFetching && <LoaderModal />}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            /* eslint-disable react/jsx-key */
            // disabling the rule here due to the fact that getProps functions actually make iterated elements keyed
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={column.headerClassName ?? ''}
                >
                  <div className="header-cell">
                    {column.render('Header')}
                    {column.canSort && (
                      <TableSorting
                        isSorted={column.isSorted}
                        sort={column.isSortedDesc ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!data.length && !isLoading && (
            <tr className="no_data">
              <td colSpan={visibleColumns.length}>No data available</td>
            </tr>
          )}

          {rows.map(row => {
            prepareRow(row);
            const rowProps = row.getRowProps();

            if (!isLoading && hasSubrows?.(row)) {
              return (
                <Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    className={row.isExpanded ? 'expandable active' : 'expandable'}
                  >
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        rowSpan={cell.column.enableRowSpan && row.isExpanded ? 2 : 1}
                        className={cell.column.className ?? ''}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                  {row.isExpanded && (
                    <tr className="sub-component">
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent?.({ row, rowProps, visibleColumns })}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            }

            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} className={cell.column.className ?? ''}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!!pageCount && (
        <>
          <Pagination
            currentPage={pageIndex + 1}
            totalPages={pageOptions.length}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
          />
          <TotalItemsCount
            currentPage={pageIndex}
            pageSize={pageSize}
            totalItems={totalItemsCount}
            totalPages={pageCount}
          />
        </>
      )}
    </>
  );
}
