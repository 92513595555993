import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { createUserInvites } from '../../../api/mutations';
import { CreateUserInviteRequest } from '../../../api/schemas';
import { useUserData } from '../../../shared/hooks';

type UseCreateUserInvites = {
  inviteUsers: (payload: CreateUserInviteRequest) => void;
  isLoading: boolean;
  isSuccess: boolean;
  error?: CreateUserInviteRequest | string[];
};

export default function useCreateUserInvites(): UseCreateUserInvites {
  const { data: { first_name, last_name } = {} } = useUserData();

  const { mutate, isLoading, isSuccess, error } = useMutation<
    AxiosResponse<void>,
    AxiosError<CreateUserInviteRequest | string[]>,
    CreateUserInviteRequest
  >((payload: CreateUserInviteRequest) => createUserInvites(payload));

  const inviteUsers = ({ invites }: CreateUserInviteRequest) => {
    const defaultInviteMessage = `Hello,\nGreat News! You’ve been invited to join our Vendor Portal account by ${first_name} ${last_name}.\nThe Vendor Portal offers unmatched insight on key data related to the business conducted between our company and GoExpedi, which is easily comprehensible and readily available at your fingertips.\nAccess the Vendor Portal to fuel your decisions for continuous success!\n${first_name} ${last_name}`;

    mutate({
      invites: invites.map(({ invite_text, ...rest }) => ({
        invite_text: invite_text || defaultInviteMessage,
        ...rest,
      })),
    });
  };

  return { inviteUsers, isLoading, isSuccess, error: error?.response?.data };
}
