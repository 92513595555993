import { cloneElement } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

type ModalPropTypes = {
  show: boolean;
  children: React.ReactElement;
  closeModal: () => void;
  customClose?: boolean;
  header?: string;
  subheader?: string;
};

export type ModalChildPropTypes = {
  closeModal?: () => void;
};

const modalRoot = document.createElement('div');
modalRoot.setAttribute('id', 'modal-root');
document.body.appendChild(modalRoot);

export default function Modal({
  show,
  header,
  subheader,
  closeModal,
  customClose,
  children,
}: ModalPropTypes): React.ReactElement | null {
  if (!show) {
    return null;
  }

  return createPortal(
    <div className="modal_wrapper">
      <div role="dialog" className="modal">
        {header && (
          <div className="header">
            <div>
              <h1>{header}</h1>
              <h5>{subheader}</h5>
            </div>
            <button type="button" name="Close Modal" onClick={closeModal}>
              <CloseIcon fill="#fff" />
            </button>
          </div>
        )}
        {!header && !customClose && (
          <div className="modal_header">
            <button type="button" name="Close Modal" onClick={closeModal}>
              <CloseIcon fill="#676767" />
            </button>
          </div>
        )}
        {cloneElement(children, { closeModal })}
      </div>
    </div>,
    modalRoot
  );
}
