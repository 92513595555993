import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import { NotificationPropType } from './types';

function createContainer() {
  const portalId = 'notify_container';
  let element = document.getElementById(portalId);

  if (element) {
    return element;
  }

  element = document.createElement('div');
  element.setAttribute('id', portalId);
  document.body.appendChild(element);
  return element;
}

const container = createContainer();

const timeToDelete = 300;
const timeToClose = 1000 * 5;

export default function Notification({
  autoClose = false,
  onDelete,
  children,
}: NotificationPropType): React.ReactElement {
  const [isClosing, setIsClosing] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onDelete, timeToDelete);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isClosing, onDelete]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (autoClose) {
      const timeoutId = setTimeout(() => setIsClosing(true), timeToClose);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [autoClose]);

  return createPortal(
    <div className={!isClosing ? 'notification slideIn' : 'notification slideOut'}>
      {children}
      <button type="button" onClick={() => setIsClosing(true)} className="close_btn">
        <CloseIcon fill="#676767" />
      </button>
    </div>,
    container
  );
}
