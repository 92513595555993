import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import { CustomHeader, CustomInput } from '.';
import { dateToIsoFormat } from './utils';

type DateRangePropTypes = {
  dateAfter?: string;
  dateBefore?: string;
  handleSelectDate: (dateAfter: string, dateBefore: string) => void;
};

const DateRange = ({
  dateAfter,
  dateBefore,
  handleSelectDate,
}: DateRangePropTypes): React.ReactElement => {
  const [startDate, setStartDate] = useState<Date | null>(
    dateAfter ? new Date(dateAfter) : new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    dateBefore ? new Date(dateBefore) : new Date()
  );

  const handleStartDateChange = (date: Date | null, e?: React.SyntheticEvent<unknown, Event>) => {
    e?.stopPropagation();
    setStartDate(date);

    const dateAfterString = dateToIsoFormat(date);
    const dateBeforeString = dateToIsoFormat(endDate);

    handleSelectDate(dateAfterString, dateBeforeString);
  };

  const handleEndDateChange = (date: Date | null, e?: React.SyntheticEvent<unknown, Event>) => {
    e?.stopPropagation();
    setEndDate(date);

    const dateAfterString = dateToIsoFormat(startDate);
    const dateBeforeString = dateToIsoFormat(date);

    handleSelectDate(dateAfterString, dateBeforeString);
  };

  return (
    <div className="date_range__container">
      <ReactDatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        renderCustomHeader={CustomHeader}
        customInput={<CustomInput />}
        placeholderText="Enter Date"
        dateFormat="MM/dd/yyyy"
      />
      <span>TO</span>
      <ReactDatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={new Date()}
        renderCustomHeader={CustomHeader}
        customInput={<CustomInput />}
        placeholderText="Enter Date"
        dateFormat="MM/dd/yyyy"
      />
    </div>
  );
};

export default DateRange;
