import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow-right.svg';

type LinkActionBtnPropTypes = {
  name: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  action: () => void;
};

export default function LinkActionBtn({
  name,
  icon: Icon,
  action,
}: LinkActionBtnPropTypes): React.ReactElement {
  return (
    <div className="link_action_wrapper">
      <div className="side_line" />
      <button type="button" className="link_action_btn" onClick={action}>
        <ArrowIcon className="arrow" fill="#fff" width="5px" height="9px" />
        <Icon width="20px" height="20px" fill="#fff" />
        <span className="name">{name}</span>
        <ArrowIcon className="arrow" fill="#fff" width="5px" height="9px" />
      </button>
      <div className="side_line" />
    </div>
  );
}
