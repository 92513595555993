import { useReducer, useState } from 'react';
import { Column, IdType } from 'react-table';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as UnlinkIcon } from '../../../../assets/icons/unlink.svg';

import { ProductDocumentSKURequest } from '../../../../api/schemas';
import { productSkusToUnlink } from '../../../../atoms';
import SearchByFields from '../../persistent-product/file-upload/SearchByFields';
import { SearchParams } from '../../persistent-product/file-upload/types';
import { useProductListToUnlink } from '../hooks';
import LinkActionBtn from './LinkActionBtn';
import UnlinkProductsTable from './UnlinkProductsTable';
import { useAddProductRecordsColumns } from '../../persistent-product/file-upload/hooks';

export default function ChooseProductsToUnlink(): React.ReactElement {
  const [searchParams, setSearchParam] = useReducer(
    (state: SearchParams, { field, value }: { field: keyof SearchParams; value: string }) => ({
      ...state,
      [field]: value,
    }),
    { gxp_sku: '', supplier_sku: '', name: '' }
  );
  const [selectedRows, setSelectedRows] = useState<
    Record<IdType<ProductDocumentSKURequest>, ProductDocumentSKURequest>
  >({});

  const setProdsToUnlink = useSetRecoilState(productSkusToUnlink);

  const { data } = useProductListToUnlink();
  const { columns } = useAddProductRecordsColumns({ isLoaded: true, isTitleLink: true });

  const addProdsToUnlink = () => {
    setProdsToUnlink(prodsToUnlink => ({ ...prodsToUnlink, ...selectedRows }));

    setSearchParam({ field: 'name', value: '' });
    setSearchParam({ field: 'supplier_sku', value: '' });
    setSearchParam({ field: 'gxp_sku', value: '' });
  };

  return (
    <div>
      <div className="product_to_unlink">
        <div className="add_product_record__container">
          <SearchByFields searchParams={searchParams} setSearchParam={setSearchParam} />
          <UnlinkProductsTable
            data={data}
            columns={columns as Column<ProductDocumentSKURequest>[]}
            setSelectedRows={setSelectedRows}
            searchParams={searchParams}
          />
        </div>
      </div>
      <LinkActionBtn name="Unlink" icon={UnlinkIcon} action={addProdsToUnlink} />
    </div>
  );
}
