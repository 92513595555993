import { Suspense } from 'react';

import { ErrorBoundary } from '../../../shared/components';
import AlertsSettingsForm from './AlertsSettingsForm';
import AlertsSettingsLoading from './AlertsSettingsLoading';

export default function AlertsSettings(): React.ReactElement {
  return (
    <div className="settings__container alerts_content">
      <h3>Alert Settings</h3>
      <p className="description">
        Turn alerts on or off according to your needs. The alerts will be sent to the email address
        that your VIP account has been registered with.
      </p>
      <div className="divider" />
      <ErrorBoundary fallback={<p className="error_msg">Alert settings couldn&#39;t be loaded</p>}>
        <Suspense fallback={<AlertsSettingsLoading />}>
          <AlertsSettingsForm />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
