export default function AlertsSettingsLoading(): React.ReactElement {
  return (
    <div className="notifications_loading">
      <h4>Turn on email notifications:</h4>
      <div className="loading" />
      <h4>Select your email frequency:</h4>
      <div className="loading" />
    </div>
  );
}
