import { useState } from 'react';
import { Cell, Column, HeaderProps } from 'react-table';

import { useOrderStatuses } from '.';
import { FilterByDate, FilterByMenu } from '../../../../shared/components';

import { formatDateTime, formatPrice } from '../../../../shared/utils';
import OrderDocsActionButtons from '../OrderDocsActionButtons';
import OrderName from '../OrderName';
import { OrderDocumentsTableData } from '../types';

type UseOrderDocsColumns = {
  columns: Column<OrderDocumentsTableData>[];
};

export default function useOrderDocsColumns(): UseOrderDocsColumns {
  const [columns] = useState<Column<OrderDocumentsTableData>[]>([
    {
      Header: () => null,
      headerClassName: 'expander_header',
      id: 'expander',
      className: 'expander_container',
      enableRowSpan: true,
      Cell: ({ row: { isExpanded, getToggleRowExpandedProps } }: Cell<OrderDocumentsTableData>) => (
        <div className="expander" {...getToggleRowExpandedProps()}>
          <div className={isExpanded ? 'close' : 'open'} />
        </div>
      ),
    },
    {
      Header: 'Purchase Order #',
      accessor: 'purchase_order',
      className: 'doc_type_container',
      Cell: ({ row: { original, toggleRowExpanded } }: Cell<OrderDocumentsTableData>) => (
        <OrderName
          purchase_order={original.purchase_order}
          has_documents={original.has_documents}
          toggleRowExpanded={toggleRowExpanded}
        />
      ),
    },
    {
      Header: 'Vendor Reference #',
      accessor: 'vendor_reference_id',
    },
    {
      Header: 'Due Date',
      accessor: 'due_date',
      Cell: ({ value }: Cell<OrderDocumentsTableData>) => formatDateTime(value),
    },
    {
      Header({ column: { filterValue, setFilter } }: HeaderProps<OrderDocumentsTableData>) {
        return <FilterByDate title="Order Date" filterValue={filterValue} setFilter={setFilter} />;
      },
      disableSortBy: true,
      accessor: 'order_date',
      className: 'order_date_container',
      Cell: ({ value }: Cell<OrderDocumentsTableData>) =>
        formatDateTime(value, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
        }).split(','),
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header({ column: { filterValue, setFilter } }: HeaderProps<OrderDocumentsTableData>) {
        const { data } = useOrderStatuses();

        return (
          <FilterByMenu
            title="Status"
            filterValue={filterValue}
            setFilter={setFilter}
            filterOptions={data}
          />
        );
      },
      accessor: 'status',
      disableSortBy: true,
      className: 'status_container',
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: ({ value }: Cell<OrderDocumentsTableData>) => formatPrice(value),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      className: 'order_docs_actions',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<OrderDocumentsTableData>) => (
        <OrderDocsActionButtons
          orderId={original.purchase_order}
          has_documents={original.has_documents}
        />
      ),
    },
  ]);

  return { columns };
}
