import { useMutation, useQueryClient } from 'react-query';

import { dismissLatestUpdateMessage } from '../../../api/mutations';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseDismissLatestUpdateMsg = {
  dismiss: () => void;
};

export default function useDismissLatestUpdateMsg(): UseDismissLatestUpdateMsg {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(() => dismissLatestUpdateMessage(), {
    onSuccess: () => {
      queryClient.invalidateQueries(ServerStateKeys.LatestUpdateMessage);
    },
  });

  return { dismiss: mutate };
}
