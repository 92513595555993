type DaysRemainingPropTypes = {
  value: string;
};

const getDaysRemaining = (archivedDate: string): number => {
  const today = +new Date();
  const archived = +new Date(archivedDate);

  const diff = archived - today;

  return Math.floor(diff / (1000 * 60 * 60 * 24)) + 90;
};

export default function DaysRemaining({ value }: DaysRemainingPropTypes): React.ReactElement {
  const daysRem = getDaysRemaining(value);

  return <div className={daysRem <= 10 ? 'remaining marked_red' : 'remaining'}>{daysRem}</div>;
}
