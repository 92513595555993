import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { PERMISSIONS } from '../../../shared/constants';
import { ActionButton } from '../types';

export const ACTION_BTNS: ActionButton[] = [
  {
    title: 'Upload A New File',
    icon: UploadIcon,
    action: 'upload',
    permission: PERMISSIONS.UPLOAD_DOCS,
  },
  {
    title: 'Download',
    icon: DownloadIcon,
    action: 'download',
    withRows: true,
    permission: '',
  },
  {
    title: 'Archive',
    icon: ArchiveIcon,
    action: 'archive',
    withRows: true,
    permission: PERMISSIONS.ARCHIVE_DOCS,
  },
  {
    title: 'Request To Add New Document Label',
    action: 'mailto',
    address: 'vendor.management@goexpedi.com',
    icon: FileIcon,
    permission: PERMISSIONS.EDIT_DOCS,
  },
];

export const WARN_CONTENT_CONFIRM_ARCHIVE = {
  main: 'Are you sure you want to Archive selected document(s)? The document(s) will be removed from all of their related SKUs',
  confirm: 'Yes, Archive!',
  cancel: 'Do Not Archive',
};

export const UPLOAD_CONFIRM_CONTENT = {
  title: 'Upload Files',
  description: 'Your file has been successfuly added.',
};
