import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Modal, SubmitButton } from '../../shared/components';
import ConfirmationWindow from './ConfirmationWindow';
import { RESET_PWD_CONFIRM } from './constants';
import Hint from './Hint';
import { usePasswordReset } from './hooks';
import PasswordInput from './PasswordInput';
import { ResetFormPropTypes, ResetPwdInputTypes } from './types';
import { ResetPwdSchema } from './validations/schema';

export default function ResetForm({
  token,
  clearToken,
  successConfirm,
}: ResetFormPropTypes): React.ReactElement {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<ResetPwdInputTypes>({
    resolver: yupResolver(ResetPwdSchema),
  });
  const { reset, isLoading, isSuccess, error } = usePasswordReset();

  useEffect(() => {
    if (isSuccess) {
      setShowConfirmation(true);
    }
  }, [isSuccess]);

  const onSubmit = ({ newPassword }: ResetPwdInputTypes) => {
    reset(token, newPassword);
  };

  const handleCancel = () => {
    clearToken();
  };

  const isSubmitDisabled = !!Object.values(errors).find(({ message }) => message);

  return (
    <div className="reset_pwd">
      <section>
        <h2>Reset your password</h2>
        <div className="reset_pwd__content">
          <form className="update_pwd__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="hint_container">
              <div className="inputs">
                <PasswordInput
                  id="new_password"
                  label="New Password"
                  register={() => register('newPassword')}
                  error={errors.newPassword}
                  touched={!!submitCount}
                />
                <PasswordInput
                  id="confirm_password"
                  label="Confirm New Password"
                  register={() => register('confirmPassword')}
                  error={errors.confirmPassword}
                  touched={!!submitCount}
                />
              </div>
              <Hint />
            </div>
            {error && <p className="server_error">{error}</p>}
            <SubmitButton
              name="Submit Password"
              isLoading={isLoading}
              isDisabled={isSubmitDisabled}
            />
          </form>
          <button type="button" name="Cancel" className="cancel_btn" onClick={handleCancel}>
            Cancel
          </button>
          <Modal show={showConfirmation} closeModal={() => setShowConfirmation(false)} customClose>
            <ConfirmationWindow content={RESET_PWD_CONFIRM} confirm={successConfirm} />
          </Modal>
        </div>
      </section>
    </div>
  );
}
