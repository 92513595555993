import { useMutation } from 'react-query';

import { requestPasswordReset } from '../../../api/mutations';
import { PasswordResetRequestRequest } from '../../../api/schemas';
import { UseRequestPasswordReset } from './types';

export default function useRequestPasswordReset(): UseRequestPasswordReset {
  const { mutate, isLoading, isSuccess } = useMutation((payload: PasswordResetRequestRequest) =>
    requestPasswordReset(payload)
  );

  const requestReset = (email: string, ip: string) => {
    mutate({ email, ip_address: ip, user_agent: navigator.userAgent });
  };

  return { requestReset, isLoading, isSuccess };
}
