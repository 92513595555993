import { MutableRefObject, useEffect, useRef, useState } from 'react';

type DragNDropPropTypes = {
  children: React.ReactElement;
  disabled?: boolean;
  handleDropFile: (filesToUpload: FileList | null) => void;
};

const getDnDClassname = (isDragging: boolean, isDisabled?: boolean) => {
  const className = 'drag_n_drop';
  if (isDisabled) return `${className} disabled`;
  if (isDragging) return `${className} dragging`;
  return className;
};

export default function DragNDrop({
  children,
  disabled,
  handleDropFile,
}: DragNDropPropTypes): React.ReactElement {
  let dragCounter: number;
  const [isDragging, setDragging] = useState(false);

  const dropRef = useRef<HTMLDivElement>();

  const handleDrag = (e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e: DragEvent): void => {
    dragCounter += 1;
    if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (_: DragEvent): void => {
    dragCounter -= 1;
    if (dragCounter === 0) {
      setDragging(false);
    }
  };
  const handleDrop = (e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      if (!disabled) handleDropFile(e.dataTransfer.files);

      dragCounter = 0;
    }
  };

  useEffect(() => {
    const { current } = dropRef;
    dragCounter = 0;
    if (current) {
      current.addEventListener('dragenter', handleDragIn);
      current.addEventListener('dragleave', handleDragOut);
      current.addEventListener('dragover', handleDrag);
      current.addEventListener('drop', handleDrop);
    }

    return () => {
      current?.removeEventListener('dragenter', handleDragIn);
      current?.removeEventListener('dragleave', handleDragOut);
      current?.removeEventListener('dragover', handleDrag);
      current?.removeEventListener('drop', handleDrop);
    };
  }, []);

  return (
    <div
      ref={dropRef as MutableRefObject<HTMLDivElement>}
      className={getDnDClassname(isDragging, disabled)}
    >
      {children}
    </div>
  );
}
