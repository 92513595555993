import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { FormInput, SubmitButton } from '../../shared/components';
import { useUpdateUserProfile } from './hooks';
import { UserProfileInputs } from './types';
import { UserProfileSchema } from './validations/schema';

export default function UserProfileForm({
  first_name,
  last_name,
  phone_number,
  department,
  position,
}: UserProfileInputs): React.ReactElement {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm<UserProfileInputs>({
    mode: 'onChange',
    defaultValues: {
      first_name,
      last_name,
      phone_number,
      department,
      position,
    },
    resolver: yupResolver(UserProfileSchema),
  });

  const { updateUserProfile, isLoading, serverError } = useUpdateUserProfile();

  const onSubmit = (fields: UserProfileInputs) => {
    updateUserProfile(fields);
    reset(fields);
  };

  return (
    <form className="update_profile--form" name="user profile" onSubmit={handleSubmit(onSubmit)}>
      <h4>Personal Information</h4>
      {!!Object.values(errors).find(({ message }) => message?.includes('required')) && (
        <p className="error">Please complete all required information</p>
      )}
      {serverError && <p className="error">{Object.values(serverError).join('. ')}</p>}
      <div className="row">
        <FormInput
          id="first_name"
          label="First Name"
          placeholder="Click here to enter your first name"
          error={errors.first_name}
          register={() => register('first_name')}
          required
        />
        <FormInput
          id="last_name"
          label="Last Name"
          placeholder="Click here to enter your last name"
          error={errors.last_name}
          register={() => register('last_name')}
          required
        />
      </div>
      <FormInput
        id="phone_number"
        label="Phone Number"
        type="tel"
        placeholder="Click here to enter your phone number"
        error={errors.phone_number}
        register={() => register('phone_number')}
      />
      <div className="row">
        <FormInput
          id="department"
          label="Department"
          placeholder="Click here to enter your department"
          error={errors.department}
          register={() => register('department')}
          required
        />
        <FormInput
          id="position"
          label="Position"
          placeholder="Click here to enter your position"
          error={errors.position}
          register={() => register('position')}
          required
        />
      </div>
      <div className="divider" />
      <SubmitButton name="Save" isDisabled={!isDirty || !isValid} isLoading={isLoading} />
    </form>
  );
}
