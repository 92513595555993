import { Link } from 'react-router-dom';

import { ROUTE } from '../../routes/constants';

export default function LoginFooterBtns(): React.ReactElement {
  return (
    <>
      <div className="footer_btns">
        <Link to={ROUTE.RESET_PWD}>Forgot Password?</Link>
      </div>
      <div className="footer_btns">
        <a href="mailto: vendor.management@goexpedi.com" target="_blank" rel="noopener noreferrer">
          Need help?
        </a>
      </div>
    </>
  );
}
