import { useRef, useEffect, forwardRef, Ref, MutableRefObject, KeyboardEvent } from 'react';
import { TableToggleAllRowsSelectedProps, TableToggleRowsSelectedProps } from 'react-table';

import { ReactComponent as CheckedIcon } from '../../assets/icons/success-narrow.svg';

function Checkbox(
  { indeterminate, ...rest }: TableToggleAllRowsSelectedProps | TableToggleRowsSelectedProps,
  ref: Ref<HTMLInputElement>
) {
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    if (indeterminate !== undefined) {
      (resolvedRef as MutableRefObject<HTMLInputElement>).current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  const onClick = () => {
    (resolvedRef as MutableRefObject<HTMLInputElement>).current.click();
  };

  const keyPressHandler = ({ key }: KeyboardEvent<HTMLSpanElement>) => {
    if (key === 'Enter') {
      onClick();
    }
  };

  return (
    <span
      className="table_row_select"
      role="checkbox"
      aria-checked={rest.checked}
      tabIndex={0}
      onKeyPress={keyPressHandler}
      onClick={onClick}
    >
      <input type="checkbox" ref={resolvedRef} {...rest} />
      <span>{rest.checked && <CheckedIcon stroke="#fff" />}</span>
    </span>
  );
}

export default forwardRef(Checkbox);
