import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IdType } from 'react-table';

import { Notification, NotificationAction, NotificationStatus } from '../../api/schemas';
import { ROUTE } from '../../routes/constants';
import { Modal } from '../../shared/components';
import ActionButtons from '../document-repository/ActionButtons';
import { Search, Table } from '../table';
import { useTableQueryParams } from '../table/hooks';
import { defaultTableSort, NTF_ACTION_BTNS } from './constants';
import {
  useArchiveNotification,
  useNotifications,
  useNotificationsColumns,
  useReadNotificationStatus,
} from './hooks';
import ReadMoreWindow from './ReadMoreWindow';
import { NotificationsTableData } from './types';
import UnreadNotificationsCount from './UnreadNotificationsCount';
import { parseNotificaitonsFilters } from './utils/helpers';

export default function NotificationsTable(): React.ReactElement {
  const [readMore, setReadMore] = useState<number | null>(null);
  const [selectedRows, setSelectedRows] = useState<
    Record<IdType<NotificationsTableData>, Notification>
  >({});
  const [search, setSearch] = useState('');

  const history = useHistory();

  const { filters } = useTableQueryParams(0, 10, '', [
    'notification_type',
    'date_after',
    'date_before',
  ]);
  const parsedFilters = parseNotificaitonsFilters(filters);

  const {
    fetch,
    refetch,
    data: { count, results } = {},
    isLoading,
    isFetching,
  } = useNotifications();
  const { columns } = useNotificationsColumns({ openReadMoreModal: setReadMore });
  const {
    setReadNotificationStatus,
    isMarkingAsRead,
    isMarkingAsUnread,
    isSuccess: isReadSuccess,
  } = useReadNotificationStatus();
  const {
    archiveNotifications,
    isLoading: isArchiving,
    isSuccess: isArchivedSuccess,
  } = useArchiveNotification();

  useEffect(() => {
    if (isReadSuccess) {
      refetch();
      setSelectedRows({});
    }
  }, [isReadSuccess]);

  useEffect(() => {
    if (isArchivedSuccess) {
      refetch();
      setSelectedRows({});
    }
  }, [isArchivedSuccess]);

  const handleSetReadStatus = (status: NotificationStatus) => {
    const ids = Object.values(selectedRows).map(({ id }) => id);
    setReadNotificationStatus(ids, status);
  };

  const handleArchiveNotifications = (status: NotificationAction) => {
    const ids = Object.values(selectedRows).map(({ id }) => id);
    archiveNotifications(status, ids);
  };

  const handleRedirectToAlerts = () => {
    history.push(`${ROUTE.SETTINGS}?type=Alerts`);
  };

  return (
    <div className="doc_rep_content">
      <div className="notifications_header">
        <h3>Notifications</h3>
        <button type="button" name="Configure Alerts" onClick={handleRedirectToAlerts}>
          Configure Alerts
        </button>
      </div>
      <p>
        This page provides notifications about new information, orders, requirements and updates
        within your company’s vendor portal account.
      </p>
      <Search
        placeholder="Search by Notification Description"
        search={setSearch}
        isSearching={!!search && isFetching}
        showResults={!!search && (!isFetching || count === 0)}
        itemCount={count}
      />
      <div className="unread__container">
        <UnreadNotificationsCount />
        <ActionButtons
          buttons={NTF_ACTION_BTNS}
          actionHandlers={{
            archive: () => handleArchiveNotifications('archive'),
            read: () => handleSetReadStatus('read'),
            unread: () => handleSetReadStatus('unread'),
          }}
          loading={{ archive: isArchiving, read: isMarkingAsRead, unread: isMarkingAsUnread }}
          selectedRows={selectedRows}
        />
      </div>
      <div className="table-wrapper">
        <Table<NotificationsTableData>
          columns={columns}
          data={results || []}
          isLoading={isLoading}
          isFetching={isFetching}
          fetchData={fetch}
          search={search}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          initialState={{ ...defaultTableSort, filters: parsedFilters }}
          pageCount={count && Math.ceil(count / 10)}
          totalItemsCount={count}
          isRowSelectEnabled
        />
      </div>
      <Modal show={!!readMore} closeModal={() => setReadMore(null)} customClose>
        <ReadMoreWindow notificationId={readMore} notificationList={results} />
      </Modal>
    </div>
  );
}
