import React, { Component } from 'react';

type ErrorToExtract = {
  hasError: boolean;
  error: Error;
};

type ErrorBoundaryPropTypes = {
  fallback: React.ReactNode;
};

type ErrorBoundaryStateTypes = {
  hasError: boolean;
};

// Error boundaries currently have to be classes.
export default class ErrorBoundary extends Component<
  ErrorBoundaryPropTypes,
  ErrorBoundaryStateTypes
> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error): ErrorToExtract {
    return {
      hasError: true,
      error,
    };
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      return fallback;
    }
    return children;
  }
}
