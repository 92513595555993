import { useMutation, useQueryClient } from 'react-query';

import { editProdDocDetails } from '../../../../api/mutations';
import { PatchedDocumentUpdateRequest } from '../../../../api/schemas';
import ServerStateKeys from '../../../../utils/server-state-keys';

type UseEditProdDoc = {
  editProdDoc: (fileId: string, fileData: PatchedDocumentUpdateRequest) => void;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function useEditProdDoc(): UseEditProdDoc {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    ({ id, payload }: { id: string; payload: PatchedDocumentUpdateRequest }) =>
      editProdDocDetails(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ServerStateKeys.PersistentDocuments);
      },
    }
  );

  const editProdDoc = (fileId: string, fileData: PatchedDocumentUpdateRequest) => {
    mutate({ id: fileId, payload: fileData });
  };

  return { editProdDoc, isLoading, isSuccess };
}
