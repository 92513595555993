import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { activeProductSku, filesToLinkToSku } from '../../../../atoms';
import { SubmitButton } from '../../../../shared/components';
import { useProductDetails, useLinkDocsToProduct } from '../../hooks';
import ChooseFilesToLink from './ChooseFilesToLink';
import SelectedFilesToLink from './SelectedFilesToLink';

export default function LinkFiles(): React.ReactElement {
  const gxp_sku = useRecoilValue(activeProductSku);
  const [files, setFiles] = useRecoilState(filesToLinkToSku);

  const fileIds = Object.keys(files);

  const { data: { sku = '' } = {} } = useProductDetails(gxp_sku, false);
  const { linkDocs, isLoading, isSuccess } = useLinkDocsToProduct('link', gxp_sku, sku);

  useEffect(() => {
    if (isSuccess) {
      setFiles({});
    }
  }, [isSuccess]);

  const handleLinkFiles = (e: React.FormEvent) => {
    e.preventDefault();

    linkDocs(fileIds);
  };

  return (
    <form onSubmit={handleLinkFiles}>
      <p className="form_input_label">Add Documents to a SKU</p>
      <ChooseFilesToLink />
      <SelectedFilesToLink />
      <SubmitButton
        name="Link Documents & Save"
        isLoading={isLoading}
        isDisabled={!fileIds.length}
      />
    </form>
  );
}
