import { memo, MutableRefObject, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';

import { ROUTE } from '../../routes/constants';
import { useOutsideClick } from '../../shared/hooks';
import ProductImg from '../product-details/ProductImg';

type ProductCellPropTypes = {
  gxp_sku: string;
  image_url: string;
  name: string;
};

function ProductCell({ gxp_sku, image_url, name }: ProductCellPropTypes): React.ReactElement {
  const [show, setShow] = useState(false);

  const previewRef = useRef<HTMLDivElement>();
  const popperRef = useRef<HTMLDivElement>(null);

  const { styles } = usePopper(previewRef.current, popperRef.current, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [50, -20],
        },
      },
    ],
  });

  useOutsideClick(previewRef, () => setShow(false));

  return (
    <div className="product_cell">
      <div className="product_img" ref={previewRef as MutableRefObject<HTMLDivElement>}>
        <ProductImg src={image_url} alt="Product" />
        <button
          className="preview_icon"
          type="button"
          name="Preview Image"
          onClick={() => setShow(!show)}
        >
          <EyeIcon width="24px" height="17px" fill="#03ABE3" />
        </button>
        <div
          id="popper"
          className="preview_img"
          data-show={show}
          ref={popperRef}
          style={styles.popper}
        >
          <ProductImg src={image_url} alt="Preview" />
          <button
            type="button"
            title="Close Preview"
            name="Close Preview"
            onClick={() => setShow(false)}
          >
            <CloseIcon width="16px" height="16px" fill="#03ABE3" />
          </button>
        </div>
      </div>
      <Link to={`${ROUTE.PROD_CATALOG}?pid=${gxp_sku}`} className="product_name">
        {name}
      </Link>
    </div>
  );
}

export default memo(ProductCell);
