import { useState } from 'react';
import { Cell, Column } from 'react-table';

import { OrderDocument } from '../../../../api/schemas';
import { formatDateTime } from '../../../../shared/utils';
import { PreviewDoc } from '../../persistent-product';
import { formatBytes } from '../../utils/helpers';
import SubRowsActionButtons from '../SubRowsActionButtons';
import { PurchaseDocsTableData } from './types';

type PurchaseDocsColumns = {
  columns: Column<PurchaseDocsTableData>[];
};

type PurchaseDocsProps = {
  handleEdit: (fileData: OrderDocument) => void;
};

export default function usePurchaseSubrowColumns({
  handleEdit,
}: PurchaseDocsProps): PurchaseDocsColumns {
  const [columns] = useState<Column<PurchaseDocsTableData>[]>([
    {
      Header: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      Cell({ row }: Cell<OrderDocument>) {
        return <SubRowsActionButtons fileData={row.original} handleEdit={handleEdit} />;
      },
    },
    {
      Header: 'Document Name',
      accessor: 'name',
      disableSortBy: true,
      className: 'doc_name_container',
      Cell: ({ row: { original } }: Cell<OrderDocument>) => (
        <PreviewDoc name={original.name} url={original.url} />
      ),
    },
    {
      Header: 'Label',
      accessor: 'label',
      disableSortBy: true,
    },
    {
      Header: 'Type',
      accessor: 'extension',
      disableSortBy: true,
      Cell: ({ value }: Cell<OrderDocument>) => `.${value.toUpperCase()}`,
    },
    {
      Header: 'Size',
      accessor: 'size',
      disableSortBy: true,
      Cell: ({ value }: Cell<OrderDocument>) => formatBytes(value, 0),
    },
    {
      Header: 'Upload Date',
      accessor: 'modified_date',
      disableSortBy: true,
      Cell: ({ value }: Cell<OrderDocument>) => formatDateTime(value),
    },
  ]);

  return { columns };
}
