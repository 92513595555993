import { useReducer } from 'react';
import { useRecoilState } from 'recoil';

import { Product } from '../../../../api/schemas';
import { productSkusToLink } from '../../../../atoms';
import { InfiniteScrollTable } from '../../../table';
import { useProductListToLink } from '../../document-edit/hooks';
import { useAddProductRecordsColumns, useSearchProductsByField } from './hooks';
import SearchByFields from './SearchByFields';
import { SearchParams } from './types';

const initialTableState = { sortBy: [{ id: 'gxp_sku', desc: false }] };

export default function AddProductRecordStep(): React.ReactElement {
  const [searchParams, setSearchParam] = useReducer(
    (state: SearchParams, { field, value }: { field: keyof SearchParams; value: string }) => ({
      ...state,
      [field]: value,
    }),
    { gxp_sku: '', supplier_sku: '', name: '' }
  );

  const [selectedProdsToLink, setSelectedProdsToLink] = useRecoilState(productSkusToLink);

  const { data, isLoading, isFetching, isSuccess, hasNextPage, fetch, fetchNextPage } =
    useSearchProductsByField();
  const { columns } = useAddProductRecordsColumns({ isLoaded: isSuccess });

  const { productsToLink } = useProductListToLink(data || []);

  return (
    <div className="step__content">
      <div className="add_product_record__container">
        <SearchByFields searchParams={searchParams} setSearchParam={setSearchParam} />
        <InfiniteScrollTable<Product>
          data={productsToLink}
          pinnedRows={Object.values(selectedProdsToLink)}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          fetchData={fetch}
          update={fetchNextPage}
          setSelectedRows={setSelectedProdsToLink}
          searchParams={searchParams}
          initialState={initialTableState}
          hasInitialData={!!data?.length}
        />
      </div>
    </div>
  );
}
