import { useCallback } from 'react';

import { ServicePlanSection } from '../../api/schemas';

import FeatureSection from './FeatureSection';

type PlanColumnsPropTypes = {
  name: string;
  isCurrent?: boolean;
  sections: ServicePlanSection[];
};

export default function PlanColumn({
  name,
  isCurrent,
  sections,
}: PlanColumnsPropTypes): React.ReactElement {
  const renderRequestMoreInfoBtn = useCallback(() => {
    return isCurrent ? (
      <h6 className="current_plan">YOUR CURRENT PLAN</h6>
    ) : (
      <button className="request_more_info" type="button">
        <a
          href={`mailto: vendor.management@goexpedi.com?subject=Request More Information on Service Plans - ${name}&body=I am interested in finding out more details about the ${name} Plan and how it compares to my current plan.%0D%0ALooking forward to your reply. `}
          target="_blank"
          rel="noopener noreferrer"
        >
          Request more info
        </a>
      </button>
    );
  }, []);

  return (
    <div className="plan_column_container">
      <div className="title">{name}</div>
      <div>{renderRequestMoreInfoBtn()}</div>
      <div>
        {sections.length
          ? sections.map(({ title, features }) => (
              <FeatureSection key={title} title={title} features={features} />
            ))
          : 'No plan features specified'}
      </div>
      <div>{renderRequestMoreInfoBtn()}</div>
    </div>
  );
}
