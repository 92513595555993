import { useState } from 'react';

import { ModalChildPropTypes } from '../../../shared/components/Modal';
import { EDIT_DOC_SPACES } from './constants';
import EditDocName from './EditDocName';
import EditSubMenu from './EditSideMenu';
import { LinkProducts } from './link-products';
import { EditDocMenus } from './types';
import { UnlinkProducts } from './unlink-products';

export default function EditDocumentDetails({
  closeModal,
}: ModalChildPropTypes): React.ReactElement {
  const [currentMenu, setCurrentMenu] = useState(EditDocMenus.EDIT);

  const renderEditDocContent = (menu: EditDocMenus) => {
    switch (menu) {
      case EditDocMenus.EDIT:
        return <EditDocName />;

      case EditDocMenus.UNLINK:
        return <UnlinkProducts />;

      case EditDocMenus.LINK:
        return <LinkProducts />;

      default:
        return <EditDocName />;
    }
  };

  return (
    <div className="edit_doc__modal">
      <EditSubMenu
        options={EDIT_DOC_SPACES}
        currentMenu={currentMenu}
        selectMenu={setCurrentMenu}
      />
      <div className="edit_doc__content">
        {renderEditDocContent(currentMenu)}
        <button type="button" name="Close" className="close_btn" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
}
