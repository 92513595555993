import { useQuery } from 'react-query';

import { getOrderDocLabels } from '../../../../api/queries';
import { DocumentLabel } from '../../../../api/schemas';
import ServerStateKeys from '../../../../utils/server-state-keys';

type UseOrderLabels = {
  labels: DocumentLabel[];
};

export default function useOrderLabels(): UseOrderLabels {
  const { data } = useQuery(ServerStateKeys.OrderDocLabels, getOrderDocLabels, {
    staleTime: 60000,
  });

  return { labels: data?.data || [] };
}
