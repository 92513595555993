import { useQuery } from 'react-query';

import { getProductDetails } from '../../../api/queries';
import { formatDateTime, formatPrice } from '../../../shared/utils';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UseProductDetails } from './types';

export default function useProductDetails(sku: string, suspense = true): UseProductDetails {
  const { data, isLoading } = useQuery(
    [ServerStateKeys.ProductDetails, sku],
    () => getProductDetails(sku),
    {
      enabled: !!sku,
      suspense,
      staleTime: 60000,
      select: ({
        data: { price, price_expiration, ytd_purchases, ytd_end_user_sales, ...rest },
      }) => ({
        price: formatPrice(price),
        price_expiration: formatDateTime(price_expiration),
        ytd_purchases: formatPrice(ytd_purchases),
        ytd_end_user_sales: formatPrice(ytd_end_user_sales),
        ...rest,
      }),
    }
  );

  return { data, isLoading };
}
