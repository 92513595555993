import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { FetchNextPageOptions, InfiniteData, useInfiniteQuery } from 'react-query';

import { getProductDocuments } from '../../../api/queries';
import { ProductDocument, ProductDocumentPaginated } from '../../../api/schemas';
import { QueryParams, QueryParamsFilter } from '../../../shared/types';
import ServerStateKeys from '../../../utils/server-state-keys';

type ProductDocsInfiniteScroll = {
  fetch: (parameters?: QueryParams) => void;
  fetchNextPage: (options?: FetchNextPageOptions) => void;
  data?: ProductDocument[];
  hasNextPage?: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isFetching: boolean;
};

const concatPages = (data?: InfiniteData<AxiosResponse<ProductDocumentPaginated>>) =>
  data?.pages.reduce(
    (allPages, { data: { documents } }) => allPages.concat(...documents),
    [] as ProductDocument[]
  );

export default function useProductDocsInfiniteScroll(): ProductDocsInfiniteScroll {
  const [params, setParams] = useState({} as QueryParamsFilter<ProductDocument>);

  const { data, isLoading, isFetching, isSuccess, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [ServerStateKeys.PersistentDocuments, params],
    ({ pageParam = 1 }) => {
      // re-map 'search' param to meet BE's expected param name
      const { name, ...restParams } = params;
      return getProductDocuments({
        ...restParams,
        page: pageParam,
        search_string: name,
      });
    },
    {
      staleTime: 30000,
      getNextPageParam: ({ data: { page, total_pages } }) =>
        total_pages > page ? page + 1 : undefined,
    }
  );

  const fetch = useCallback((parameters?: QueryParams) => {
    if (parameters) {
      setParams(parameters);
    }
  }, []);

  return {
    fetch,
    fetchNextPage,
    data: concatPages(data),
    isLoading,
    isFetching,
    hasNextPage,
    isSuccess,
  };
}
