import { AxiosResponse } from 'axios';

import API from '../../utils/api';
import {
  DocumentsDeleteRequest,
  DocumentsRestoreRequest,
  ResoredDocumentsResponse,
} from '../schemas';

export const deleteArchivedDocuments = (payload: DocumentsDeleteRequest): Promise<void> =>
  API.post('/api/archive/delete/', payload);

export const restoreArchivedDocuments = (
  payload: DocumentsRestoreRequest[]
): Promise<AxiosResponse<ResoredDocumentsResponse>> =>
  API.post<ResoredDocumentsResponse>('/api/archive/restore/', payload);
