import { Suspense } from 'react';

import { ErrorBoundary } from '../../shared/components';
import ProductDescription from './ProductDescription';
import ProductDescriptionLoading from './ProductDescriptionLoading';
import { ProductDetailsTabs } from './tabs';

export default function ProductDetails(): React.ReactElement {
  return (
    <div className="product_details__container">
      <ErrorBoundary fallback={<>Couldn&#39;t load Product Details</>}>
        <Suspense fallback={<ProductDescriptionLoading />}>
          <ProductDescription />
        </Suspense>
      </ErrorBoundary>
      <div className="transition" />
      <ProductDetailsTabs />
    </div>
  );
}
