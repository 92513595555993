import { Link, useHistory } from 'react-router-dom';

import Logo from '../../assets/images/logo.svg';
import { ROUTE } from '../../routes/constants';
import { useAuth } from '../../shared/hooks';
import UserInfo from './UserInfo';
import VendorLogo from './VendorLogo';

export default function Header(): React.ReactElement {
  const history = useHistory();
  const { signout } = useAuth();

  const handleLogout = (): void => {
    signout(() => history.push(ROUTE.LOGIN));
  };

  return (
    <div className="nav-bar" role="heading" aria-level={1}>
      <div className="header">
        <Link className="logo" to={ROUTE.HOMEPAGE}>
          <img src={Logo} alt="logo" />
        </Link>
        <div className="divider" />
        <VendorLogo />
      </div>
      <div className="actions">
        <UserInfo />
        <div className="divider" />
        <Link to={ROUTE.HELP}>Help</Link>
        <div className="divider" />
        <a href={ROUTE.LOGIN} className="log-out" onClick={handleLogout}>
          Log Out
        </a>
      </div>
    </div>
  );
}
