import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getProducts } from '../../../api/queries';
import { Product, ProductPaginated } from '../../../api/schemas';
import { QueryParamsFilter } from '../../../shared/types';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UseProductCatalog } from './types';

export default function useProductCatalog(): UseProductCatalog {
  const [params, setParams] = useState<QueryParamsFilter<Product>>({});

  const { data, isLoading, isFetching, isSuccess } = useQuery(
    [ServerStateKeys.ProductList, params],
    () => getProducts(params),
    {
      enabled: !!Object.keys(params).length,
      keepPreviousData: true,
      /* 
        This is due to, when the window looses focus and gains it back it re-fetches
        data causing search filter to be hidden for some time, until the refetch
        is complete. This is a hackish solution as there might be stale data which
        needs to be updated in some cases.
        
        Todo: To avoid this the search UI and strategy needs to be reworked.
      */
      refetchOnWindowFocus: false,
      select: useCallback(
        // append ID property to product entities for react-table to identify unique rows
        ({ data: response }: AxiosResponse<ProductPaginated>): ProductPaginated => ({
          ...response,
          products: response.products.map(product => ({
            ...product,
            id: product.gxp_sku,
          })),
        }),
        []
      ),
    }
  );

  const fetch = useCallback((parameters?: QueryParamsFilter<Product>) => {
    if (parameters) {
      setParams(parameters);
    }
  }, []);

  return { fetch, data, isLoading, isFetching, isSuccess };
}
