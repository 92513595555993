import { useQuery } from 'react-query';

import { getTermsConditions } from '../../../api/queries';
import { formatDateTime } from '../../../shared/utils';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UseTermsConditionsText } from './types';

export default function useTermsConditionsText(): UseTermsConditionsText {
  const { data, isLoading } = useQuery([ServerStateKeys.TermsConditions], getTermsConditions, {
    select: ({ data: { text, created_ts } }) => ({
      text,
      created_ts: formatDateTime(new Date(created_ts * 1000).toISOString()),
    }),
  });

  return { data, isLoading };
}
