import { useQuery } from 'react-query';

import { getUsersNotificationSettings } from '../../../api/queries';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UseNotificationSettings } from './types';

export default function useNotificationsSettings(): UseNotificationSettings {
  const { data } = useQuery([ServerStateKeys.NotificationSettings], getUsersNotificationSettings, {
    // using suspense mode here for Fetch-Then-Render approach (need to initiate the form's state with values from BE)
    suspense: true,
  });

  return { data: data?.data };
}
