import { Fragment } from 'react';

import { Restricted } from '../../shared/components';
import { ActionButton } from './types';

type ActionButtonsPropTypes = {
  buttons: ActionButton[];
  selectedRows: Record<number, unknown>;
  actionHandlers: Record<string, () => void>;
  loading?: Record<string, boolean>;
};

export default function ActionButtons({
  buttons,
  selectedRows,
  actionHandlers,
  loading,
}: ActionButtonsPropTypes): React.ReactElement {
  const getActionButton = ({
    icon: Icon,
    title,
    action,
    address,
    withRows,
  }: ActionButton): JSX.Element => {
    const isDisabled = withRows && !Object.keys(selectedRows).length;
    const colorToFill = isDisabled ? '#BCC4D8' : '#006298';

    if (action === 'mailto') {
      return (
        <a className="btn" href={`mailto:${address}`} target="_blank" rel="noopener noreferrer">
          <Icon stroke="#006298" />
          {title}
        </a>
      );
    }

    return (
      <button
        className="btn"
        type="button"
        name={title}
        disabled={isDisabled}
        onClick={actionHandlers[action]}
      >
        <Icon stroke={colorToFill} />
        {title}
        {loading?.[action] && <div className="loading" />}
      </button>
    );
  };

  return (
    <div className="table_action_btns">
      {buttons.map((button: ActionButton): JSX.Element => {
        const { title, permission } = button;

        return permission ? (
          <Restricted key={title} to={permission}>
            {getActionButton(button)}
          </Restricted>
        ) : (
          <Fragment key={title}>{getActionButton(button)}</Fragment>
        );
      })}
    </div>
  );
}
