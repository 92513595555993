import { ReactComponent as PersistentProduct } from '../../../../assets/icons/persistent-product.svg';

import { ModalChildPropTypes } from '../../../../shared/components/Modal';
import { DragNDrop, FileList, Footer } from '../../../document-repository/file-upload';
import { useUploadDocuments } from '../../hooks';
import DocumentTypes from './DocumentTypes';

export default function UploadFiles({ closeModal }: ModalChildPropTypes): React.ReactElement {
  const { loadingStatuses, upload } = useUploadDocuments();

  const handleFileUploading = (filesToUpload: FileList | null): void => {
    if (filesToUpload) {
      const fileList = Object.values(filesToUpload);
      upload(fileList);
    }
  };

  return (
    <div className="upload_file">
      <p>Drag and drop or click the link below to add files via the finder.</p>
      <label className="add_btn">
        <input
          type="file"
          name="document"
          multiple
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
            handleFileUploading(target.files)
          }
          data-testid="file-uploader"
        />
        + <span>Add files via the finder</span>
      </label>
      <div className="boxes">
        <DragNDrop handleDropFile={handleFileUploading}>
          <>
            <p>Drag & drop files here to upload</p>
            <div>
              <PersistentProduct width="195px" height="202px" fill="#F3F5F8" />
            </div>
          </>
        </DragNDrop>
        <FileList loadingStatuses={loadingStatuses} documentTypes={DocumentTypes} />
      </div>
      <Footer loadingStatuses={loadingStatuses} closeModal={closeModal} />
    </div>
  );
}
