import { useCallback } from 'react';

import { AccountMmgmtTabs } from './constants';
import { DeactivatedUsers } from './deactivated-users';
import { ManageActiveUsers } from './manage-active-users';
import { PendingInvites } from './pending-invites';

type AccMgmtSpacesPropTypes = {
  activeTab: AccountMmgmtTabs;
};

export default function AccountManagementSpaces({
  activeTab,
}: AccMgmtSpacesPropTypes): React.ReactElement {
  const renderTabContent = useCallback((tab: AccountMmgmtTabs) => {
    if (tab === AccountMmgmtTabs.DEACTIVATED_USERS) {
      return <DeactivatedUsers />;
    }

    if (tab === AccountMmgmtTabs.PENDING_INVITATIONS) {
      return <PendingInvites />;
    }

    return <ManageActiveUsers />;
  }, []);

  return <div className="acc_mgmt_space">{renderTabContent(activeTab)}</div>;
}
