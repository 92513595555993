import { useEffect, useState } from 'react';
import { Cell, Column, HeaderProps } from 'react-table';

import { Product } from '../../../../../api/schemas';
import Checkbox from '../../../../table/Checkbox';
import ProductNameCell from '../../../document-edit/unlink-products/ProductNameCell';

type useAddProductRecordsColumnsProps = {
  isLoaded: boolean;
  isTitleLink?: boolean;
};

type UseAddProductRecordsColumns = {
  columns: Column<Product>[];
};

export default function useAddProductRecordsColumns({
  isLoaded,
  isTitleLink,
}: useAddProductRecordsColumnsProps): UseAddProductRecordsColumns {
  const [columns, setColumns] = useState<Column<Product>[]>([
    {
      Header: 'Description',
      accessor: 'name',
      headerClassName: 'product_sku_desc_column',
      className: 'product_sku_desc_column',
      Cell: ({
        row: {
          original: { name, gxp_sku },
        },
      }: Cell<Product>) => (isTitleLink ? <ProductNameCell name={name} gxp_sku={gxp_sku} /> : name),
    },
    {
      Header: 'Supplier SKU',
      accessor: 'supplier_sku',
    },
    {
      Header: 'GXP SKU',
      accessor: 'gxp_sku',
    },
  ]);

  useEffect(() => {
    if (columns.length === 3 && isLoaded) {
      setColumns([
        {
          id: 'selection',
          headerClassName: 'row_select__container',
          className: 'row_select__container',
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<Product>) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: Cell<Product>) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ]);
    }
  }, [isLoaded]);

  return { columns };
}
