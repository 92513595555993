export default function CreateAccountLoading(): React.ReactElement {
  return (
    <form className="create_acc--form loading">
      <h3>Personal Information</h3>
      <div className="loading" />
      <div className="divider" />
      <h3>Create a Password</h3>
      <div className="loading" />
    </form>
  );
}
