export default function ProductDescriptionLoading(): React.ReactElement {
  return (
    <div className="product_description">
      <div className="smooth_image_wrapper loading" />
      <div className="product_description__content">
        <h3>
          <div className="loading" />
        </h3>
        <div className="sub_header loading" />
        <h6>Key Product Details:</h6>
        <div className="details loading" />
        <h6>Product Description:</h6>
        <div className="description_container loading" />
      </div>
    </div>
  );
}
