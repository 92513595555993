import { forwardRef, MouseEvent, Ref } from 'react';

import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';

const CustomInput = (
  {
    onClick,
    ...props
  }: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  ref: Ref<HTMLInputElement>
) => (
  <div className="datepicker_input">
    <input ref={ref} {...props} onClick={onClick} />
    <button type="button" onClick={e => onClick?.(e as MouseEvent<HTMLInputElement>)}>
      <CalendarIcon stroke="#006298" />
    </button>
  </div>
);

export default forwardRef(CustomInput);
