import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/icons/archive.svg';
import { ReactComponent as EyeSlashIcon } from '../../assets/icons/eye-slash.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as RestoreIcon } from '../../assets/icons/restore.svg';

import { PERMISSIONS } from '../../shared/constants';

import { ActionButton } from '../document-repository/types';

export enum NOTIFICATION_PAGES {
  NOTIFICATIONS = 'Notifications',
  ARCH_NOTIFICATIONS = 'Archived Notifications',
}

export const NOTIFICATION_SPACES = [
  { icon: AlertIcon, title: NOTIFICATION_PAGES.NOTIFICATIONS, className: 'strokedIcon' },
  { icon: ArchiveIcon, title: NOTIFICATION_PAGES.ARCH_NOTIFICATIONS, className: 'strokedIcon' },
];

export const NTF_ACTION_BTNS: ActionButton[] = [
  {
    title: 'Archive',
    icon: ArchiveIcon,
    action: 'archive',
    withRows: true,
    permission: PERMISSIONS.ARCHIVE_NOTIFICATIONS,
  },
  {
    title: 'Mark As Read',
    icon: function WrappedEyeIcon({ stroke }: React.SVGProps<SVGSVGElement>): React.ReactElement {
      return <EyeIcon fill={stroke} />;
    },
    action: 'read',
    withRows: true,
    permission: '',
  },
  {
    title: 'Mark As Unread',
    icon: function WrappedEyeIcon({ stroke }: React.SVGProps<SVGSVGElement>): React.ReactElement {
      return <EyeSlashIcon fill={stroke} />;
    },
    action: 'unread',
    withRows: true,
    permission: '',
  },
];

export const ARCHIVED_NTH_ACTION_BTN: ActionButton[] = [
  {
    title: 'Restore',
    icon: function WrappedResporeIcon({
      stroke,
    }: React.SVGProps<SVGSVGElement>): React.ReactElement {
      return <RestoreIcon fill={stroke} />;
    },
    action: 'restore',
    withRows: true,
    permission: PERMISSIONS.ARCHIVE_NOTIFICATIONS,
  },
];

export const NTF_FILTER_OPTIONS = [
  { id: 'Terms and Conditions Accepted', label: 'Accepted T&C’s' },
  { id: 'Product Item New Rank', label: 'GXP Rank Change' },
  { id: 'New Product Item', label: 'New Product Item' },
  { id: 'New Purchase Order', label: 'New Purchase Order' },
  { id: 'Product Details Edit Request', label: 'Product Details Edit' },
];

export const defaultTableSort = { sortBy: [{ id: 'event_date', desc: true }] };
