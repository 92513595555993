import { array, object, string } from 'yup';

export const InviteUserSchema = object({
  invites: array()
    .of(
      object({
        email: string()
          .required('Email is required.')
          .email('Invalid email address, please correct.'),
        role_name: string().required('User role is required.'),
        invite_text: string(),
      })
    )
    .required(),
});
