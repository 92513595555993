import { useMutation, useQueryClient } from 'react-query';

import { deactivateUser } from '../../../api/mutations';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseDeactivateUser = {
  deactivateUser: (id: number) => void;
};

export default function useDeactivateUser(): UseDeactivateUser {
  const queryClient = useQueryClient();

  const { mutate } = useMutation((id: number) => deactivateUser(id), {
    onSuccess: () => {
      queryClient.cancelQueries(ServerStateKeys.ActiveUsers);
      queryClient.invalidateQueries(ServerStateKeys.ActiveUsers);
    },
  });

  return { deactivateUser: mutate };
}
