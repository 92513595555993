import ReactDOM from 'react-dom';
import { Popover } from '@typeform/embed-react';
import GA4React from 'ga-4-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GOOGLE_ANALYTICS_ID } from './utils/runtime-config';
import reportWebVitals from './reportWebVitals';

import './assets/css/index.scss';

import App from './App';

// get Google Analytics ID from process envs or use dev mode as a fallback
new GA4React(GOOGLE_ANALYTICS_ID || 'G-F9XQR00C6F').initialize();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
    <Popover
      id="gvLKbN5W"
      buttonColor="#FFFFFF"
      customIcon="https://images.typeform.com/images/7iL8qakhCBtE"
      tooltip="Got feedback? 👋&nbsp;&nbsp;Let us know!"
      chat
    />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
