import { useCallback, useEffect, useRef } from 'react';

export default function useDebouncedCallback(
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback: Function,
  delay: number,
  dependencies?: unknown[]
): (...args: unknown[]) => void {
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const comboDeps = dependencies ? [callback, delay, ...dependencies] : [callback, delay];

  useEffect(() => {
    return () => {
      if (timeout.current != null) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return useCallback((...args) => {
    if (timeout.current != null) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, comboDeps);
}
