import { useEffect, useState } from 'react';
import { IdType } from 'react-table';
import { useSetRecoilState } from 'recoil';

import { ActionButtons } from '..';
import { ProductDocument } from '../../../api/schemas';
import { isConfirmArchiveProductDocs } from '../../../atoms';
import { useFileDownload } from '../../../shared/hooks';
import { Table, Search } from '../../table';
import { ACTION_BTNS } from './constants';
import {
  useMoveToArchiveDocs,
  usePersistentProductDocs,
  useProductDocsColumns,
  useUploadModalState,
} from './hooks';
import ProductDocModals from './ProductDocModals';
import { PersistentProdDocsTable } from './types';

const intialTableState = { sortBy: [{ id: 'modified_date', desc: true }] };

export default function ProductDocuments(): React.ReactElement {
  const setConfirmArchive = useSetRecoilState(isConfirmArchiveProductDocs);
  const [selectedRows, setSelectedRows] = useState<
    Record<IdType<PersistentProdDocsTable>, ProductDocument>
  >({});
  const [search, setSearch] = useState('');

  const { openUpload } = useUploadModalState();
  const { columns } = useProductDocsColumns();
  const {
    fetch,
    refetch,
    isLoading,
    isFetching,
    data: { documents, total_items, total_pages } = {},
  } = usePersistentProductDocs();
  const { itemsNotArchived, moveToArchive, isArchiving, isMovedToArchive } = useMoveToArchiveDocs();
  const { downloadSingle, downloadMultiple, isDownloading } = useFileDownload();

  useEffect(() => {
    if (isMovedToArchive) {
      setSelectedRows(itemsNotArchived);
      refetch();
    }
  }, [isMovedToArchive]);

  const requestArchiveConfirmation = () => {
    setConfirmArchive(true);
  };

  const handleArchive = () => {
    setConfirmArchive(false);
    moveToArchive(selectedRows);
  };

  const handleDownload = (): void => {
    const ids = Object.keys(selectedRows);
    if (ids.length === 1) {
      const selectedId = ids[0];
      const { name, extension, url } = selectedRows[selectedId];
      const fileName = `${name}.${extension}`;

      return downloadSingle(url, fileName);
    }

    return downloadMultiple('/api/persistent-documents/download/', ids);
  };

  return (
    <div className="doc_rep_content">
      <h3>Product Documents</h3>
      <p>
        Accepted document types for upload: PDF, Excel, PNG, JPG. Download or archive multiple and
        single files by selecting the associated checkbox in the far left column.
      </p>
      <Search
        placeholder="Search by Docs, SKUs, Etc"
        search={setSearch}
        isSearching={!!search && isFetching}
        showResults={!!search && (!isFetching || total_items === 0)}
        itemCount={total_items}
      />
      <ActionButtons
        buttons={ACTION_BTNS}
        actionHandlers={{
          upload: openUpload,
          download: handleDownload,
          archive: requestArchiveConfirmation,
        }}
        loading={{ download: isDownloading, archive: isArchiving }}
        selectedRows={selectedRows}
      />
      <div className="table-wrapper">
        <Table<PersistentProdDocsTable>
          columns={columns}
          data={documents || []}
          isLoading={isLoading}
          isFetching={isFetching}
          search={search}
          fetchData={fetch}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          initialState={intialTableState}
          pageCount={total_pages}
          totalItemsCount={total_items}
          isRowSelectEnabled
        />
      </div>
      <ProductDocModals handleArchive={handleArchive} refetch={refetch} />
    </div>
  );
}
