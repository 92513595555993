import { memo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

type InfoIconPropTypes = {
  description: string;
};

function InfoIcon({ description }: InfoIconPropTypes): React.ReactElement {
  const [show, setShow] = useState(false);

  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const arrowRef = useRef(null);
  const { styles, attributes } = usePopper(referenceElement.current, popperElement.current, {
    placement: 'bottom',
    modifiers: [
      { name: 'offset', options: { offset: [0, 5] } },
      { name: 'arrow', options: { element: arrowRef.current } },
    ],
  });

  return (
    <div
      className="info_icon__container"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <span className="info_icon" ref={referenceElement}>
        ?
      </span>
      <div
        id="popper"
        className="description"
        data-show={show}
        ref={popperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {description}
        <div id="arrow" ref={arrowRef} data-popper-arrow style={styles.arrow} />
      </div>
    </div>
  );
}

export default memo(InfoIcon);
