import { useRecoilState, useSetRecoilState } from 'recoil';

import { ProductDocument, PatchedDocumentUpdateRequest } from '../../../api/schemas';
import {
  documentToEdit,
  productSkusToUnlink,
  productSkusToLink,
  editedDocName,
  isConfirmArchiveProductDocs,
} from '../../../atoms';
import { ConfirmationWindow, Modal, WarningWindow } from '../../../shared/components';
import { EditDocumentDetails } from '../document-edit';
import AssociatedSku from './AssociatedSku';
import { UPLOAD_CONFIRM_CONTENT, WARN_CONTENT_CONFIRM_ARCHIVE } from './constants';
import UploadProductDocs from './file-upload/UploadProductDocs';
import { useUploadModalState, useViewAllSku } from './hooks';
import { ProductDocModalsPropTypes } from './types';

export default function ProductDocModals({
  handleArchive,
  refetch,
}: ProductDocModalsPropTypes): React.ReactElement {
  const [isEdit, setIsEdit] = useRecoilState(documentToEdit);
  const [isConfirmArchive, setConfirmArchive] = useRecoilState(isConfirmArchiveProductDocs);

  const setProdsToUnlink = useSetRecoilState(productSkusToUnlink);
  const setProdsToLink = useSetRecoilState(productSkusToLink);
  const setPersistedDocDetails = useSetRecoilState(editedDocName);

  const {
    isUpload,
    isUploadSuccess,
    closeUpload,
    showSuccessConfirmation,
    closeSuccessConfirmation,
    uploadAnotherFile,
  } = useUploadModalState();
  const { skuIds, documentName, skuType, closeWindow } = useViewAllSku();

  const handleCloseModal = () => {
    refetch();
    closeSuccessConfirmation();
  };

  const handleCloseEdit = () => {
    setIsEdit({} as ProductDocument);
    setProdsToUnlink({});
    setProdsToLink({});
    setPersistedDocDetails({} as PatchedDocumentUpdateRequest);
  };

  const closeArchiveConfirm = () => {
    setConfirmArchive(false);
  };

  return (
    <>
      <Modal show={isUpload} closeModal={closeUpload} customClose>
        <UploadProductDocs showConfirmation={showSuccessConfirmation} />
      </Modal>
      <Modal show={isUploadSuccess} closeModal={handleCloseModal} customClose>
        <ConfirmationWindow
          content={UPLOAD_CONFIRM_CONTENT}
          iterable
          actionName="Upload Another File"
          action={uploadAnotherFile}
        />
      </Modal>
      <Modal
        show={!!isEdit?.id}
        header="Edit Document Details:"
        subheader={`Document: ${isEdit.name}`}
        closeModal={handleCloseEdit}
      >
        <EditDocumentDetails />
      </Modal>
      <Modal
        show={!!skuIds?.length}
        header={`Associated ${skuType}s:`}
        subheader={`Document: ${documentName}`}
        closeModal={closeWindow}
      >
        <AssociatedSku skuIds={skuIds} />
      </Modal>
      <Modal show={isConfirmArchive} closeModal={closeArchiveConfirm}>
        <WarningWindow content={WARN_CONTENT_CONFIRM_ARCHIVE} confirm={handleArchive} />
      </Modal>
    </>
  );
}
