import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getProductDocuments } from '../../../../api/queries';
import { QueryParams } from '../../../../shared/types';
import ServerStateKeys from '../../../../utils/server-state-keys';
import { UsePersistentProductDocs } from './types';

export default function usePersistentProductDocs(sku?: string): UsePersistentProductDocs {
  const [params, setParams] = useState<QueryParams>({} as QueryParams);

  const { data, isLoading, isFetching, isSuccess, refetch } = useQuery(
    [ServerStateKeys.PersistentDocuments, params, sku],
    () => {
      // re-map 'search' param to meet BE's expected param name
      const { search, ...restParams } = params;
      return getProductDocuments({ ...restParams, sku, search_string: search });
    },
    {
      enabled: !!Object.keys(params).length,
      keepPreviousData: true,
    }
  );

  const fetch = useCallback((parameters?: QueryParams) => {
    if (parameters) {
      setParams(parameters);
    }
  }, []);

  return { data: data?.data, isLoading, isFetching, isSuccess, fetch, refetch };
}
