import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { uploadProductDocument } from '../../../../../api/mutations';
import { FileData, UploadServerError } from '../../../types';
import isFileTypeValid from '../validations';
import { UploadedFile, UseFileUpload } from './types';

export default function useFileUpload(): UseFileUpload {
  const [loadingStatus, setLoadingStatus] = useState({} as UploadedFile);

  const { mutate } = useMutation<AxiosResponse<FileData>, AxiosError<UploadServerError>, FormData>(
    (file: FormData) => uploadProductDocument(file),
    {
      onSuccess: ({ data: { id, name } }) => {
        setLoadingStatus({ id, name, loading: false });
      },
    }
  );

  const upload = (file: File) => {
    const { name, type } = file;

    if (!isFileTypeValid(type)) {
      setLoadingStatus({
        name,
        loading: false,
        error: new Error(
          `Sorry, file ${name} appears to be an unsupported file type. Only the following file types are supported: pdf, excel, png, jpg.`
        ),
      });
      return;
    }

    setLoadingStatus({ name, loading: true });

    const formPayload = new FormData();
    formPayload.append('document', file);

    mutate(formPayload, {
      onError: ({ response }) => {
        const errorMsg = response?.data?.document[0] || 'Something went wrong.';
        setLoadingStatus({
          name,
          error: new Error(errorMsg),
          loading: false,
        });
      },
    });
  };

  return { loadingStatus, upload };
}
