import { ReactComponent as AlertIcon } from '../../assets/icons/alert-circle.svg';
import { Modal } from '../../shared/components';

type AlertWindowPropTypes = {
  show: boolean;
  message: string;
};

export default function AlertWindow({ show, message }: AlertWindowPropTypes): React.ReactElement {
  return (
    <Modal
      show={show}
      closeModal={() => {
        /** */
      }}
      customClose
    >
      <div className="alert_window">
        <div className="icon">
          <AlertIcon width="50px" height="50px" stroke="#D61D1D" />
        </div>
        <div className="message">{message}</div>
      </div>
    </Modal>
  );
}
