import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-right.svg';

type SidebarOption<D> = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: D;
  label: string;
  className?: string;
};

type SidebarPropTypes<D> = {
  options: SidebarOption<D>[];
  currentMenu: D;
  selectMenu: (option: D) => void;
};

export default function EditSideMenu<D>({
  options,
  currentMenu,
  selectMenu,
}: SidebarPropTypes<D>): React.ReactElement {
  return (
    <div className="page_sidebar__container open submenu">
      {options.map(({ icon: Icon, title, label, className }) => (
        <button
          key={`${title}`}
          className={title === currentMenu ? 'sidebar_btn active' : 'sidebar_btn'}
          type="button"
          onClick={() => selectMenu(title)}
        >
          <Icon className={className} width="21px" height="22px" />
          <span>{label}</span>
          <ArrowIcon width="18px" height="10px" fill="#006298" />
        </button>
      ))}
    </div>
  );
}
