import { object, string, ref } from 'yup';

export const LoginSchema = object({
  email: string().required('Please enter an email to log-in.').email('Must be a valid email.'),
  password: string().required('Please enter a password to log-in.'),
}).required();

export const ChangePwdSchema = object({
  oldPassword: string()
    .required('Password is required.')
    .min(8, 'Must contain at least 8 characters.'),
  newPassword: string()
    .required('New Password is required.')
    .min(8, 'Must contain at least 8 characters.')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/, 'Must be alphanumeric.')
    .notOneOf([ref('oldPassword'), null], 'New password must not match old password.'),
  confirmPassword: string()
    .required('Password Confirmation is required.')
    .min(8, 'Must contain at least 8 characters.')
    .oneOf([ref('newPassword'), null], 'Passwords must match.'),
}).required();

export const RequestResetPwdSchema = object({
  email: string().required('Please enter an email.').email('Must be a valid email.'),
}).required();

export const ResetPwdSchema = object({
  newPassword: string()
    .required('New Password is required.')
    .min(8, 'Must contain at least 8 characters.')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/, 'Must be alphanumeric.')
    .notOneOf([ref('oldPassword'), null], 'New password must not match old password.'),
  confirmPassword: string()
    .required('Password Confirmation is required.')
    .min(8, 'Must contain at least 8 characters.')
    .oneOf([ref('newPassword'), null], 'Passwords must match.'),
});
