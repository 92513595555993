import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { getServicePlanDescription } from '../../../api/queries';
import { ServicePlan } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseServicePlans = {
  data?: ServicePlan[];
  isLoading: boolean;
};

export default function useAvailablePlans(): UseServicePlans {
  const { data, isLoading } = useQuery(
    [ServerStateKeys.AvailableServicePlans],
    getServicePlanDescription,
    {
      staleTime: Infinity,
      select: useCallback(
        // sort service plans by number of licenses ascending
        ({ data: response }: AxiosResponse<ServicePlan[]>): ServicePlan[] =>
          response.sort((planA, planB) => {
            if (planA.licenses < planB.licenses) {
              return -1;
            }
            if (planA.licenses > planB.licenses) {
              return 1;
            }

            return 0;
          }),
        []
      ),
    }
  );

  return { data, isLoading };
}
