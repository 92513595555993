export const formatDateTime = (dateTime: string, options?: Intl.DateTimeFormatOptions): string => {
  if (!dateTime) return '';

  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    ...options,
  }).format(new Date(dateTime));
};

export const formatPrice = (value: number): string => {
  if (!value) return '';

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};
