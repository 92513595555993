import { memo } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as OpenIcon } from '../../../assets/icons/open.svg';

import { ProductDocument } from '../../../api/schemas';
import { ProductId } from '../../product-catalog/hooks/types';
import { useViewAllSku } from './hooks';
import { SkuType } from './hooks/types';
import { ROUTE } from '../../../routes/constants';

type ViewAllCellPropTypes = Pick<ProductDocument, 'name' | 'products'> & {
  skuType: SkuType;
};

const SKU_TYPE = {
  [SkuType.SKU]: 'supplier_sku',
  [SkuType.GXP_SKU]: 'gxp_sku',
};

function ViewAllCell({ skuType, name, products }: ViewAllCellPropTypes): React.ReactElement | null {
  const { viewAllSku } = useViewAllSku();

  const skuId = products?.[0]?.[SKU_TYPE[skuType] as keyof ProductId];

  if (!skuId) {
    return null;
  }

  if (products.length > 1) {
    // Multiple SKUs btn
    const skuIds = products.map(product => product.gxp_sku);

    return (
      <button
        className="view_all__btn"
        type="button"
        name="View All"
        onClick={() => viewAllSku(skuIds, name, skuType)}
      >
        Multiple {skuType}s View All
      </button>
    );
  }

  if (skuType === SkuType.GXP_SKU) {
    // GXP SKU hyperlink
    return (
      <div className="gxp_sku__link">
        <Link to={`${ROUTE.PROD_CATALOG}?pid=${skuId}`} target="_blank" rel="noopener noreferrer">
          {skuId}
          <OpenIcon width="12px" height="12px" stroke="#006298" />
        </Link>
      </div>
    );
  }

  // Supplier SKU plain text
  return <div>{skuId}</div>;
}

export default memo(ViewAllCell);
