import { useNotificationsCount } from './hooks';

export default function UnreadNotificationsCount(): React.ReactElement {
  const { data: { count } = {} } = useNotificationsCount();

  return (
    <div className="unread_notif">
      <h6>Unread Notifications: </h6>
      <span className="qty">{count}</span>
    </div>
  );
}
