import { useState } from 'react';
import { Cell, Column } from 'react-table';

import { formatDateTime } from '../../../shared/utils';
import { Search, Table } from '../../table';
import { usePendingInvites } from '../hooks';
import PendingInvitesActionBtns from './PendingInvitesActionBtns';
import { PendingInvitesTable } from './types';

const initialTableState = { sortBy: [{ id: 'email', desc: false }] };

const columns: Column<PendingInvitesTable>[] = [
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Role',
    accessor: 'role',
    Cell: ({ value: { name } }: Cell<PendingInvitesTable>) => name,
  },
  {
    Header: 'Last Invited',
    accessor: 'invite_sent_on',
    Cell: ({ value }: Cell<PendingInvitesTable>) => formatDateTime(value),
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true,
    Cell: ({ row: { original } }: Cell<PendingInvitesTable>) => (
      <PendingInvitesActionBtns id={original.id} />
    ),
  },
];

export default function PendingInvites(): React.ReactElement {
  const [search, setSearch] = useState('');

  const { fetch, isLoading, isFetching, data: { results, count } = {} } = usePendingInvites();

  return (
    <>
      <Search
        placeholder="Search Pending Invites"
        search={setSearch}
        isSearching={!!search && isFetching}
        showResults={!!search && (!isFetching || count === 0)}
        itemCount={count}
      />
      <div className="acc_mgmt__table">
        <Table<PendingInvitesTable>
          data={results || []}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          search={search}
          totalItemsCount={count}
          pageCount={count && Math.ceil(count / 10)}
          initialState={initialTableState}
          fetchData={fetch}
        />
      </div>
    </>
  );
}
