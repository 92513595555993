import { Link } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-right.svg';

import { useFeature, usePermission, useSidebar } from '../hooks';

type SidebarOption = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  className?: string;
  permission?: string;
};

type SidebarPropTypes = {
  options: SidebarOption[];
  currentOption: string;
  baseRoute: string;
};

export default function PageSidebar({
  options,
  currentOption,
  baseRoute,
}: SidebarPropTypes): React.ReactElement {
  const { isExpanded, toggleSidebar } = useSidebar();

  return (
    <div className={isExpanded ? 'page_sidebar__container open' : 'page_sidebar__container'}>
      <button
        type="button"
        name="Toggle Sidebar"
        className="sidebar_btn active toggler"
        onClick={toggleSidebar}
      >
        <span className="sidebar_burger" />
      </button>
      {options.map(({ icon: Icon, title, className, permission }) => {
        const { disabled } = useFeature(title);
        const { isAllowed } = usePermission(permission || '');

        if (!isAllowed || disabled) return null;

        return (
          <Link
            key={title}
            to={`${baseRoute}?${new URLSearchParams({ type: title })}`}
            className={title === currentOption ? 'sidebar_btn active' : 'sidebar_btn'}
          >
            <Icon className={className} width="31px" height="32px" />
            <span>{title}</span>
            <ArrowIcon width="18px" height="10px" fill="#006298" />
          </Link>
        );
      })}
    </div>
  );
}
