import { useState } from 'react';
import { useQuery } from 'react-query';

import { getOrderDocPositionInList } from '../../../../api/queries';
import ServerStateKeys from '../../../../utils/server-state-keys';

type UseOrderPageOffset = {
  data?: { page: number };
  isLoading: boolean;
  getOrderPosition: (purchaseOrder: string) => void;
};

export default function useOrderPageOffset(): UseOrderPageOffset {
  const [order, setOrder] = useState<string>();

  const { data, isLoading } = useQuery(
    [ServerStateKeys.PurchaseOrderPageOffset, order],
    () => getOrderDocPositionInList({ purchase_order: order as string, page_size: 10 }),
    {
      enabled: !!order,
      staleTime: Infinity,
      refetchInterval: false,
      select: ({ data: { page } }) => ({ page: page - 1 }),
    }
  );

  const getOrderPosition = (purchaseOrder: string) => {
    setOrder(purchaseOrder);
  };

  return { getOrderPosition, data, isLoading };
}
