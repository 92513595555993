import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import { CustomHeader, CustomInput } from '.';
import { dateToIsoFormat } from './utils';

type DatePickerPropTypes = {
  initialDate?: string;
  handleSelectDate: (dateAfter: string, dateBefore: string) => void;
};

const DatePicker = ({ initialDate, handleSelectDate }: DatePickerPropTypes): React.ReactElement => {
  const [startDate, setStartDate] = useState<Date | null>(
    initialDate ? new Date(initialDate) : new Date()
  );

  const handleDateChange = (date: Date | null, e?: React.SyntheticEvent<unknown, Event>) => {
    e?.stopPropagation();
    setStartDate(date);

    // dates must be kept as yyyy-mm-dd format
    const dateString = dateToIsoFormat(date);

    handleSelectDate(dateString, dateString);
  };

  return (
    <ReactDatePicker
      selected={startDate}
      onChange={handleDateChange}
      renderCustomHeader={CustomHeader}
      customInput={<CustomInput />}
      maxDate={new Date()}
      placeholderText="Enter Date"
      dateFormat="MM/dd/yyyy"
    />
  );
};

export default DatePicker;
