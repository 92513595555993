import { Cell } from 'react-table';

import { CorporateAgreement } from '../../../api/schemas';

export default function RowExpanding({ row }: Cell<CorporateAgreement>): JSX.Element | null {
  const {
    original: { files },
  } = row;
  if (!files?.length) {
    return null;
  }

  return (
    <div className="expander" {...row.getToggleRowExpandedProps()}>
      <div className={row.isExpanded ? 'close' : 'open'} />
    </div>
  );
}
