import { ModalChildPropTypes } from './Modal';

type Content = {
  main: string | JSX.Element;
  cancel: string | JSX.Element;
  confirm: string | JSX.Element;
};

type WarningWindowPropTypes = ModalChildPropTypes & {
  content: Content;
  confirm: () => void;
};

export default function WarningWindow({
  content,
  confirm,
  closeModal,
}: WarningWindowPropTypes): React.ReactElement {
  return (
    <div className="alert_upload">
      <h2>Attention!</h2>
      <p>{content.main}</p>
      <button className="alert_upload__cancel" name="cancel" type="button" onClick={closeModal}>
        {content.cancel}
      </button>
      <button className="alert_upload__confirm" name="confirm" type="button" onClick={confirm}>
        {content.confirm}
      </button>
    </div>
  );
}
