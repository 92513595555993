import { KeyboardEvent } from 'react';

import { ReactComponent as CheckedIcon } from '../../assets/icons/success-narrow.svg';

type CheckboxPropTypes = { isChecked: boolean; handleChange: () => void; disabled?: boolean };

export default function Checkbox({
  isChecked,
  disabled,
  handleChange,
}: CheckboxPropTypes): JSX.Element {
  const toggleCheckbox = () => {
    handleChange();
  };

  const keyPressHandler = ({ key }: KeyboardEvent<HTMLSpanElement>) => {
    if (key === 'Enter') {
      toggleCheckbox();
    }
  };

  return (
    <span className="checkbox">
      <input type="checkbox" checked={isChecked} onChange={toggleCheckbox} disabled={disabled} />
      <span role="checkbox" tabIndex={0} aria-checked={isChecked} onKeyPress={keyPressHandler}>
        <CheckedIcon width="15px" height="10px" stroke="#fff" />
      </span>
    </span>
  );
}
