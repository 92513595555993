import { useMutation, useQueryClient } from 'react-query';

import { updateSettings } from '../../../api/mutations';
import { FrequencyTypes, NotificationSettings, WeekDays } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UseUpdateNotifications } from './types';

export default function useUpdateNotifications(): UseUpdateNotifications {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (payload: NotificationSettings) => updateSettings(payload),
    {
      onSuccess: data => {
        // updating notification-settings object with response data instead of refetching
        queryClient.setQueryData([ServerStateKeys.NotificationSettings], data);
      },
    }
  );

  const updateNotifications = (enabled: boolean, frequency: FrequencyTypes, weekday: WeekDays) => {
    mutate({ enabled, frequency, frequency_weekly_day: weekday });
  };

  return { updateNotifications, isLoading, isSuccess, isError };
}
