import { useMutation, useQueryClient } from 'react-query';

import { linkProductsToDoc } from '../../../../api/mutations';
import { LinkProductToDocumentRequest, ProductDocumentSKURequest } from '../../../../api/schemas';
import ServerStateKeys from '../../../../utils/server-state-keys';
import { UseLinkProducts } from './types';

export default function useLinkProducts(action: 'link' | 'unlink'): UseLinkProducts {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    (payload: LinkProductToDocumentRequest) => linkProductsToDoc(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ServerStateKeys.PersistentDocuments);
      },
    }
  );

  const assingProducts = (
    docId: string,
    linkedProducts: Omit<ProductDocumentSKURequest, 'name'>[]
  ) => {
    mutate({ doc_id: docId, skus: linkedProducts, action });
  };

  return {
    assingProducts,
    isLoading,
    isSuccess,
  };
}
