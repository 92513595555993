import { SearchParams } from './types';

type SearchByFieldPropTypes = {
  searchParams: SearchParams;
  setSearchParam: ({ field, value }: { field: keyof SearchParams; value: string }) => void;
};

export default function SearchByFields({
  searchParams: { gxp_sku, supplier_sku, name },
  setSearchParam,
}: SearchByFieldPropTypes): React.ReactElement {
  const handleValueChange =
    (field: keyof SearchParams) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setSearchParam({ field, value });

  return (
    <div className="searching">
      <label>
        Product name or description:
        <input
          type="text"
          value={name}
          placeholder="Enter Product Name"
          onChange={handleValueChange('name')}
        />
      </label>
      <label>
        Supplier SKU
        <input
          type="text"
          value={supplier_sku}
          placeholder="Enter SKU"
          onChange={handleValueChange('supplier_sku')}
        />
      </label>
      <label>
        GXP SKU
        <input
          type="text"
          value={gxp_sku}
          placeholder="Enter GXP SKU"
          onChange={handleValueChange('gxp_sku')}
        />
      </label>
    </div>
  );
}
