import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getPurchaseOrders } from '../../../../api/queries';
import { Order, PurchaseOrdersResponse } from '../../../../api/schemas';
import { QueryParamsFilter } from '../../../../shared/types';
import ServerStateKeys from '../../../../utils/server-state-keys';
import { UseOrderDocs } from './types';

export default function useOrderDocuments(): UseOrderDocs {
  const [params, setParams] = useState({} as QueryParamsFilter<Order>);

  const { data, isLoading, isFetching, refetch } = useQuery(
    [ServerStateKeys.OrderDocuments, params],
    () => {
      const { order_date, search, ...rest } = params;

      return getPurchaseOrders({
        ...rest,
        search_string: search,
        date_after: order_date && order_date[0],
        date_before: order_date && order_date[1],
      });
    },
    {
      enabled: !!Object.keys(params).length,
      keepPreviousData: true,
      select: useCallback(
        ({ data: response }: AxiosResponse<PurchaseOrdersResponse>): PurchaseOrdersResponse => ({
          ...response,
          orders: response.orders.map(order => ({ ...order, id: order.purchase_order })),
        }),
        []
      ),
    }
  );

  const fetch = useCallback((parameters?: QueryParamsFilter<Order>) => {
    if (parameters) {
      setParams(parameters);
    }
  }, []);

  return { data, isLoading, isFetching, fetch, refetch };
}
