import { createContext, useContext } from 'react';

import { useDisabledFeatures } from '.';
import { FeatureFlag } from '../../api/schemas';

type FeatureFlags = Record<string, boolean>;

type FeatureFlagsContextValue = {
  features: FeatureFlags;
  isLoading: boolean;
};

/**
 * This hideableFeatures[] list is added in order to know which components are hideable and to fix
 * flickering/choppy behavior on the pages with both hideable and non-hideable components while feature-flags
 * are being loaded; must match BE list and have to be updated for any new features that can be hidden.
 * This extra dependency can be avoided if we come with designed loading states for hideable features.
 * Now due to missing design solutions hideable components simply won't be rendered while feature-flags were not loaded
 */
const hideableFeatures = ['Product Documents', 'Product Catalog', 'Account Management'];

const transformFlags = (features: FeatureFlag[]): FeatureFlags =>
  features.reduce(
    (transformedFeatures, { feature_name, active }) => ({
      ...transformedFeatures,
      [feature_name]: active,
    }),
    {}
  );

const FeatureFlagsContext = createContext({} as FeatureFlagsContextValue);

export const FeatureFlagsProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { features, isLoading } = useDisabledFeatures();

  return (
    <FeatureFlagsContext.Provider value={{ features: transformFlags(features || []), isLoading }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeature = (name: string): { disabled: boolean; isLoading: boolean } => {
  const { features, isLoading } = useContext(FeatureFlagsContext);

  const disabled = hideableFeatures.includes(name) && !features[name];

  return {
    disabled,
    isLoading,
  };
};
