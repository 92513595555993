import { AxiosResponse } from 'axios';

import API from '../../utils/api';
import {
  ArchivedDocumentsResponse,
  LinkProductToDocumentRequest,
  PatchedDocumentUpdateRequest,
  ProductDocToArchive,
  ProductDocument,
} from '../schemas';

export const linkProductsToDoc = (
  payload: LinkProductToDocumentRequest
): Promise<AxiosResponse<void>> => API.post('/api/persistent-documents/link-product', payload);

export const editProdDocDetails = (
  id: string,
  payload: PatchedDocumentUpdateRequest
): Promise<AxiosResponse<ProductDocument>> =>
  API.patch<ProductDocument>(`/api/persistent-documents/${id}`, payload);

export const archiveProductDocuments = (
  payload: ProductDocToArchive[]
): Promise<AxiosResponse<ArchivedDocumentsResponse>> =>
  API.post<ArchivedDocumentsResponse>('/api/persistent-documents/move-to-archive/', payload);

export const uploadProductDocument = (
  fileData: FormData
): Promise<AxiosResponse<ProductDocument>> =>
  API.post<ProductDocument>('/api/persistent-documents/upload/', fileData);
