import { memo } from 'react';

import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';

import { formatDateTime } from '../../../shared/utils';
import { DateCellPropTypes } from './types';

function DateCell({ event_date, was_read }: DateCellPropTypes): React.ReactElement {
  const formattedDate = formatDateTime(event_date);

  if (!was_read) {
    return (
      <div className="unread_notification">
        <WarningIcon fill="#D61D1D" />
        <span>{formattedDate}</span>
      </div>
    );
  }

  return <>{formattedDate}</>;
}

export default memo(DateCell);
