import { CompletePercentage } from '../product-catalog';
import InfoIcon from '../product-catalog/InfoIcon';
import { COMPLETE_PERC_DESCRIPTION, GXP_RANK_DESCRIPTION } from './constants';

type ProductRankingPropTypes = {
  priority?: number;
  percents?: number;
};

export default function ProductRanking({
  priority,
  percents = 0,
}: ProductRankingPropTypes): React.ReactElement {
  return (
    <div className="sub_header">
      <span>GXP Ranking Priority {priority}</span>
      <InfoIcon description={GXP_RANK_DESCRIPTION} />
      <CompletePercentage complete={percents} />
      <InfoIcon description={COMPLETE_PERC_DESCRIPTION} />
    </div>
  );
}
