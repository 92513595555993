import { ModalChildPropTypes } from '../../../shared/components/Modal';
import { LoadingStatus } from '../types';

type FooterPropTypes = ModalChildPropTypes & {
  loadingStatuses: LoadingStatus[];
};

export default function Footer({
  loadingStatuses,
  closeModal,
}: FooterPropTypes): React.ReactElement {
  const error: string | undefined = loadingStatuses.find(
    ({ error: _err }: LoadingStatus) => _err?.message
  )?.error?.message;

  const isSubmitDisabled = !!loadingStatuses.find(({ loading }) => loading);

  return (
    <div className="footer">
      {error && (
        <div className="error">
          <span className="error__icon">X</span> = {error}
        </div>
      )}
      <button type="button" name="submit" disabled={isSubmitDisabled} onClick={closeModal}>
        Close Upload Window
      </button>
    </div>
  );
}
