import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { isUploadProductDocs } from '../../../../atoms';

type UseUploadModalState = {
  isUpload: boolean;
  isUploadSuccess: boolean;
  openUpload: () => void;
  closeUpload: () => void;
  showSuccessConfirmation: () => void;
  closeSuccessConfirmation: () => void;
  uploadAnotherFile: () => void;
};

export default function useUploadModalState(): UseUploadModalState {
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);

  const [isUpload, setIsUpload] = useRecoilState(isUploadProductDocs);

  const openUpload = () => {
    setIsUpload(true);
  };

  const closeUpload = () => {
    setIsUpload(false);
  };

  const showSuccessConfirmation = () => {
    setIsUpload(false);
    setIsUploadSuccess(true);
  };

  const closeSuccessConfirmation = () => {
    setIsUploadSuccess(false);
  };

  const uploadAnotherFile = () => {
    setIsUploadSuccess(false);
    setIsUpload(true);
  };

  return {
    isUpload,
    isUploadSuccess,
    openUpload,
    closeUpload,
    showSuccessConfirmation,
    closeSuccessConfirmation,
    uploadAnotherFile,
  };
}
