import { AxiosResponse } from 'axios';

import API from '../../utils/api';
import { HelpSection, HelpSectionName, HomePageSection, ServicePlan } from '../schemas/help';

export const getHomePageDescription = (): Promise<AxiosResponse<HomePageSection[]>> =>
  API.get('/api/help/home-page-descriptions/');

export const getHelpSections = (): Promise<AxiosResponse<HelpSectionName[]>> =>
  API.get('/api/help/help-sections/');

export const getHelpSectionDescription = (id: number): Promise<AxiosResponse<HelpSection>> =>
  API.get(`/api/help/help-section/${id}`);

export const getServicePlanDescription = (): Promise<AxiosResponse<ServicePlan[]>> =>
  API.get('/api/help/service-plans/');
