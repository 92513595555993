import { useState } from 'react';
import { Cell, Column, HeaderProps } from 'react-table';

import { useCategories } from '.';
import { CompletePercentage, InfoIconHeader, ProductCell } from '..';
import { Product } from '../../../api/schemas';
import { FilterByMenu } from '../../../shared/components';
import { formatPrice } from '../../../shared/utils';
import { COMPLETE_PERC_DESCRIPTION, GXP_RANK_DESCRIPTION } from '../../product-details/constants';

export default function useProductCatalogColumns(isSearch: boolean): Column<Product>[] {
  const [columns] = useState<Column<Product>[]>([
    {
      Header: 'Product',
      accessor: 'name',
      className: 'product_name__container',
      Cell({ row: { original } }: Cell<Product>) {
        return (
          <ProductCell
            gxp_sku={original.gxp_sku}
            image_url={original.image_url}
            name={original.name}
          />
        );
      },
    },
    {
      Header: 'SKU',
      accessor: 'supplier_sku',
    },
    {
      Header: 'GXP SKU',
      accessor: 'gxp_sku',
    },
    {
      Header({ column: { filterValue, setFilter } }: HeaderProps<Product>) {
        const { categories } = useCategories();

        return (
          <FilterByMenu
            title="Category"
            filterValue={filterValue}
            setFilter={setFilter}
            filterOptions={categories}
            disabled={isSearch}
          />
        );
      },
      accessor: 'category',
      disableSortBy: true,
      headerClassName: 'category_filter__container',
    },
    {
      Header: 'Price',
      accessor: 'price',
      className: 'price__container',
      Cell: ({ value }: Cell<Product>) => formatPrice(value),
    },
    {
      Header: 'Lead Time',
      accessor: 'lead_time_days',
      Cell: ({ value }: Cell<Product>) => {
        if (!value) return 'None';
        return `${value} ${value > 1 ? 'Days' : 'Day'}`;
      },
    },
    {
      Header: 'YTD End User Sales',
      accessor: 'ytd_end_user_sales',
      Cell: ({ value }: Cell<Product>) => formatPrice(value),
    },
    {
      Header: 'YTD Mkt Share',
      accessor: 'ytd_market_share',
      Cell: ({ value }: Cell<Product>) => (value ? `${Math.round(value)}%` : '0%'),
    },
    {
      Header({ column, toggleSortBy }: HeaderProps<Product>) {
        return (
          <InfoIconHeader
            id={column.id}
            isSorted={column.isSorted}
            isSortedDesc={column.isSortedDesc}
            toggleSortBy={toggleSortBy}
            name="GXP Rank"
            description={GXP_RANK_DESCRIPTION}
          />
        );
      },
      accessor: 'gxp_priority',
      disableSortBy: true, // disable sort by since sorting is handled from header component
      Cell: ({ value }: Cell<Product>) => (value ? `Priority ${value}` : ''),
    },
    {
      Header({ column, toggleSortBy }: HeaderProps<Product>) {
        return (
          <InfoIconHeader
            id={column.id}
            isSorted={column.isSorted}
            isSortedDesc={column.isSortedDesc}
            toggleSortBy={toggleSortBy}
            name="% Complete"
            description={COMPLETE_PERC_DESCRIPTION}
          />
        );
      },

      accessor: 'percents_complete',
      disableSortBy: true, // disable sort by since sorting is handled from header component
      Cell({ value }: Cell<Product>) {
        return <CompletePercentage complete={value} />;
      },
    },
  ]);

  return columns;
}
