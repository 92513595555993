import { useState } from 'react';
import { Cell, Column } from 'react-table';

import { formatDateTime } from '../../../shared/utils';

import { Search, Table } from '../../table';
import ActiveUsersActionBtns from './ActiveUsersActionBtns';
import { useActiveUsers } from '../hooks';
import { ActiveUsersTable } from './types';

const initialTableState = { sortBy: [{ id: 'name', desc: false }] };

const columns: Column<ActiveUsersTable>[] = [
  {
    Header: 'User Name',
    accessor: 'name',
  },
  { Header: 'Email', accessor: 'email', className: 'email_container' },
  { Header: 'Department', accessor: 'department' },
  { Header: 'Position', accessor: 'position' },
  {
    Header: 'Role',
    accessor: 'role',
    Cell: ({ value: { name } }: Cell<ActiveUsersTable>) => name,
  },
  {
    Header: '# Visits',
    accessor: 'number_of_visits',
    Cell: ({ value }: Cell<ActiveUsersTable>) => <div className="visits_container">{value}</div>,
  },
  {
    Header: 'Last Visit',
    accessor: 'last_api_login',
    Cell: ({ value }: Cell<ActiveUsersTable>) =>
      formatDateTime(value, { dateStyle: 'short', timeStyle: 'short' }),
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true,
    Cell: ({
      row: {
        original: { id, name, role },
      },
    }: Cell<ActiveUsersTable>) => <ActiveUsersActionBtns id={id} name={name} role={role.name} />,
  },
];

export default function ManageActiveUsers(): React.ReactElement {
  const [search, setSearch] = useState('');

  const { fetch, isLoading, isFetching, data: { results, count } = {} } = useActiveUsers();

  return (
    <>
      <Search
        placeholder="Search Active Users"
        search={setSearch}
        isSearching={!!search && isFetching}
        showResults={!!search && (!isFetching || count === 0)}
        itemCount={count}
      />
      <div className="acc_mgmt__table">
        <Table<ActiveUsersTable>
          data={results || []}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          search={search}
          totalItemsCount={count}
          pageCount={count && Math.ceil(count / 10)}
          initialState={initialTableState}
          fetchData={fetch}
        />
      </div>
    </>
  );
}
