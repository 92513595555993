import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { object, string } from 'yup';

import { PatchedDocumentUpdateRequest, ProductDocument } from '../../../api/schemas';
import { documentToEdit, editedDocName } from '../../../atoms';
import { FormInput, SubmitButton } from '../../../shared/components';
import { useDocumentLabels } from '../persistent-product/file-upload/hooks';
import ProductDocLabelSelect from '../ProductDocLabelSelect';
import { useEditProdDoc } from './hooks';
import { EditDocNameInputs, EditDocNamePropTypes } from './types';

export default function EditDocName({ autoclose }: EditDocNamePropTypes): React.ReactElement {
  const [fileData, setFileData] = useRecoilState(documentToEdit);
  const [persistedDocDetails, setPersistedDocDetails] = useRecoilState(editedDocName);

  const [fileLabelId, setFileLabelId] = useState<number | null>(null);

  const {
    register,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EditDocNameInputs>({
    resolver: yupResolver(
      object({
        name: string().required('Document name is required'),
      })
    ),
  });

  const watchName = watch('name');

  const { labels } = useDocumentLabels();
  const { editProdDoc, isLoading, isSuccess } = useEditProdDoc();

  useEffect(() => {
    // initiate fileLabelId with default value once product doc labels are fetched
    if (labels.length) {
      const initialLabelId = labels.find(({ name }) => name === fileData.label)?.id || null;
      setFileLabelId(initialLabelId);
    }
  }, [labels]);

  useEffect(() => {
    setPersistedDocDetails(prevData => ({ ...prevData, name: watchName }));
  }, [watchName]);

  useEffect(() => {
    if (isSuccess) {
      if (autoclose) {
        setFileData({} as ProductDocument);
        setPersistedDocDetails({} as PatchedDocumentUpdateRequest);
        return;
      }

      const { name } = getValues();
      // convert labelId int to label sting and update fileData that's stored in recoil state
      const label = labels.find(({ id }) => id === fileLabelId)?.name || null;

      setFileData(prevValue => ({ ...prevValue, name, label }));
    }
  }, [isSuccess]);

  const handleLabelSelect = (labelId: number) => {
    setFileLabelId(labelId);
    setPersistedDocDetails(prevData => ({ ...prevData, label_id: labelId }));
  };

  const onSubmit = ({ name }: EditDocNameInputs) => {
    editProdDoc(fileData.id, {
      name,
      label_id: fileLabelId || 0,
    });
  };

  const isFormSame =
    watchName === fileData.name &&
    fileData.label === (labels.find(({ id }) => id === fileLabelId)?.name || null);
  // disable Submit Btn if no changes were done or there are errors
  const isSubmitDisabled = isFormSame || !!Object.values(errors).find(({ message }) => message);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="edit_doc__name">
        <FormInput
          id="name"
          label="Change the document name:"
          error={errors.name}
          register={() => register('name')}
          defaultValue={persistedDocDetails.name || fileData.name}
        />
        <div>
          <label htmlFor="label" className="form_input_label">
            Change document label:
          </label>
          <ProductDocLabelSelect
            placeholder={fileData.label || 'NEW DOCUMENT LABEL'}
            fileLabelId={persistedDocDetails.label_id || fileLabelId}
            handleLabelSelect={handleLabelSelect}
          />
        </div>
      </div>
      <SubmitButton name="Save Update" isLoading={isLoading} isDisabled={isSubmitDisabled} />
    </form>
  );
}
