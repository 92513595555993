import { AxiosResponse } from 'axios';

import API from '../../utils/api';
import {
  NotificationsCount,
  NotificationSettings,
  NotificationsParams,
  PaginatedNotifications,
} from '../schemas';

export const getNotifications = (
  params: NotificationsParams
): Promise<AxiosResponse<PaginatedNotifications>> =>
  API.get<PaginatedNotifications>('/api/notifications/', { params });

export const getArchivedNotifications = (
  params: NotificationsParams
): Promise<AxiosResponse<PaginatedNotifications>> =>
  API.get<PaginatedNotifications>('/api/notifications/archived/', { params });

export const getUnreadCount = (): Promise<AxiosResponse<NotificationsCount>> =>
  API.get<NotificationsCount>('/api/notifications/unread-count/');

export const getUsersNotificationSettings = (): Promise<AxiosResponse<NotificationSettings>> =>
  API.get<NotificationSettings>('/api/notifications/settings/');

export const getLatestUpdateMessage = (): Promise<AxiosResponse<{ text: string }>> =>
  API.get('/api/notifications/latest-update-message/');
