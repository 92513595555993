import { memo } from 'react';
import { Link } from 'react-router-dom';

type PageCardPropTypes = {
  title: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  description: string;
  route: string;
  isLoading: boolean;
};

function PageCard({
  title,
  icon: Icon,
  description,
  route,
  isLoading,
}: PageCardPropTypes): React.ReactElement {
  return (
    <div className="page_card__container">
      <Icon width="60px" height="60px" fill="#006298" />
      <h5 className="title">{title}</h5>
      {isLoading && <p className="description loading" />}
      <p className="description">{description}</p>
      <Link to={route}>
        <button className="review" type="button" name="Review Page">
          Review
        </button>
      </Link>
    </div>
  );
}

export default memo(PageCard);
