import { useSetRecoilState } from 'recoil';

import { changedProductDetails, isEditMode } from '../../../atoms';
import { BlockNavigationPropTypes } from './types';

export default function BlockNavigationWindow({
  save,
  closeModal,
  confirmNavigation,
}: BlockNavigationPropTypes): React.ReactElement {
  const setEditMode = useSetRecoilState(isEditMode);
  const setEditedProductDetails = useSetRecoilState(changedProductDetails);

  const handleClearProgress = () => {
    setEditMode(false);
    setEditedProductDetails({});
    confirmNavigation?.();
  };

  return (
    <div className="alert_upload">
      <h2>Attention!</h2>
      <p>You will lose your progress if you leave this page without saving.</p>
      <button
        className="alert_upload__cancel"
        type="button"
        name="Cancel navigation"
        onClick={closeModal}
      >
        Continue Editing
      </button>
      <button className="save_progress__btn" name="Save progress" type="button" onClick={save}>
        Save progress
      </button>
      <button
        className="alert_upload__confirm"
        name="Confirm navigation"
        type="button"
        onClick={handleClearProgress}
      >
        Do not save progress
      </button>
    </div>
  );
}
