import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { getDeactivatedUsers } from '../../../api/queries';
import { PaginatedUserDetails } from '../../../api/schemas';
import { QueryParams } from '../../../shared/types';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseDeactivatedUsers = {
  fetch: (parameters?: QueryParams) => void;
  data?: PaginatedUserDetails;
  isLoading: boolean;
  isFetching: boolean;
};

export default function useDeactivatedUsers(): UseDeactivatedUsers {
  const [params, setParams] = useState({} as QueryParams);

  const { data, isLoading, isFetching } = useQuery(
    [ServerStateKeys.DeactivatedUsers, params],
    () => getDeactivatedUsers(params),
    {
      enabled: !!Object.keys(params).length,
      keepPreviousData: true,
    }
  );

  const fetch = useCallback((parameters?: QueryParams) => {
    if (parameters) {
      setParams(parameters);
    }
  }, []);

  return { fetch, data: data?.data, isLoading, isFetching };
}
