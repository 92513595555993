import { useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowRight } from '../../assets/icons/polygon.svg';
import { useFeature, usePermission } from '../../shared/hooks';
import { SubmenuItemsPropType } from './types';

export default function SubmenuItems({
  options,
  delay = 400,
  children,
  ...rest
}: SubmenuItemsPropType): React.ReactElement {
  let timeout: ReturnType<typeof setTimeout>;
  const [show, setShow] = useState(false);

  const showTip = (): void => {
    timeout = setTimeout(() => {
      setShow(true);
    }, delay);
  };

  const hideTip = (): void => {
    clearInterval(timeout);
    setShow(false);
  };

  const setTopOffset = () => {
    // vertically center tooltip with multiple sub-menu items inside
    const itemsQty = options.length;
    return itemsQty > 1 ? `-${itemsQty * 10}px` : '0';
  };

  return (
    <div
      className={show ? 'tooltip-container active' : 'tooltip-container'}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      <div className="tooltip-box" style={{ top: setTopOffset() }} {...rest}>
        {options.map(({ name, path, permission }): JSX.Element | null => {
          const { disabled } = useFeature(name);
          const { isAllowed } = usePermission(permission || '');

          if (!isAllowed || disabled) return null;

          return (
            <button key={name} onClick={hideTip} type="button">
              <Link to={path}>
                <ArrowRight width="8px" height="7px" fill="#FFFFFF" />
                {name}
              </Link>
            </button>
          );
        })}
      </div>
      {children}
    </div>
  );
}
