import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getProductPurchases } from '../../../api/queries';
import { ProductPurchaseOrdersPaginated } from '../../../api/schemas';
import { QueryParams } from '../../../shared/types';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UsePurchaseOrders } from './types';

export default function useProductPurchases(sku: string): UsePurchaseOrders {
  const [params, setParams] = useState({} as QueryParams);

  const { data, isLoading, isFetching, refetch } = useQuery(
    [ServerStateKeys.OrderDocuments, { sku, params }],
    () => getProductPurchases(sku, params),
    {
      enabled: !!Object.keys(params).length,
      keepPreviousData: true,
      select: useCallback(
        ({
          data: response,
        }: AxiosResponse<ProductPurchaseOrdersPaginated>): ProductPurchaseOrdersPaginated => ({
          ...response,
          orders: response.orders.map(order => ({ ...order, id: order.purchase_order })),
        }),
        []
      ),
    }
  );

  const fetch = useCallback((parameters?: QueryParams) => {
    if (parameters) {
      setParams(parameters);
    }
  }, []);

  return { data, isLoading, isFetching, fetch, refetch };
}
