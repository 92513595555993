import { ReactComponent as PreviewIcon } from '../../../assets/icons/open.svg';
import { InviteAnotherUserPropTypes } from './types';

export default function InviteAnotherUser({
  remainingLicences,
  isLast,
  addUser,
}: InviteAnotherUserPropTypes): React.ReactElement | null {
  // only last <InviteUserForm /> should render Invite Users button
  if (!isLast) {
    return null;
  }

  // if there are no remaining licences
  if (remainingLicences <= 1) {
    return (
      <a
        className="request_more_licences"
        href="mailto: vendor.management@goexpedi.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>Request More Licences to add additional users</span>
        <PreviewIcon width="14px" height="14px" stroke="#D61D1D" />
      </a>
    );
  }

  return (
    <button className="add_user" type="button" name="add another user" onClick={addUser}>
      <span className="plus_sign">+</span>
      <span className="label">Add another user</span>
    </button>
  );
}
