import { AxiosResponse } from 'axios';

import API from '../../utils/api';
import { DocumentLabel, ProductDocumentPaginated, ProductDocumentParams } from '../schemas';

export const getProductDocuments = (
  params: ProductDocumentParams
): Promise<AxiosResponse<ProductDocumentPaginated>> =>
  API.get('/api/persistent-documents/', { params });

export const getProductLabels = (): Promise<AxiosResponse<DocumentLabel[]>> =>
  API.get<DocumentLabel[]>('/api/persistent-documents/labels/');
