import { useEffect, useReducer, useState } from 'react';
import { IdType } from 'react-table';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';

import { Product } from '../../../../api/schemas';
import { productSkusToLink } from '../../../../atoms';
import { InfiniteScrollTable } from '../../../table';
import {
  useSearchProductsByField,
  useAddProductRecordsColumns,
} from '../../persistent-product/file-upload/hooks';
import SearchByFields from '../../persistent-product/file-upload/SearchByFields';
import { SearchParams } from '../../persistent-product/file-upload/types';
import LinkActionBtn from '../unlink-products/LinkActionBtn';
import { useProductListToLink } from '../hooks';

const initialTableState = { sortBy: [{ id: 'gxp_sku', desc: false }] };

export default function ChooseProductsToLink(): React.ReactElement {
  const [searchParams, setSearchParam] = useReducer(
    (state: SearchParams, { field, value }: { field: keyof SearchParams; value: string }) => ({
      ...state,
      [field]: value,
    }),
    { gxp_sku: '', supplier_sku: '', name: '' }
  );

  const [selectedRows, setSelectedRows] = useState<Record<IdType<Product>, Product>>({});
  const setProdsToLink = useSetRecoilState(productSkusToLink);

  const addProdsToLink = () => {
    setProdsToLink(prodsToLink => ({ ...prodsToLink, ...selectedRows }));

    setSearchParam({ field: 'name', value: '' });
    setSearchParam({ field: 'supplier_sku', value: '' });
    setSearchParam({ field: 'gxp_sku', value: '' });

    setSelectedRows({});
  };

  const { data, isLoading, isFetching, isSuccess, hasNextPage, fetch, fetchNextPage } =
    useSearchProductsByField();
  const { columns } = useAddProductRecordsColumns({ isLoaded: isSuccess, isTitleLink: true });

  const { productsToLink } = useProductListToLink(data || []);

  useEffect(() => {
    // fetch next page when all items from current pages were filtered out
    if (data && productsToLink.length < 5 && !isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [productsToLink, data]);

  return (
    <div>
      <div className="product_to_unlink">
        <div className="add_product_record__container">
          <SearchByFields searchParams={searchParams} setSearchParam={setSearchParam} />
          <InfiniteScrollTable<Product>
            data={productsToLink}
            columns={columns}
            isLoading={isLoading}
            isFetching={isFetching}
            hasNextPage={hasNextPage}
            fetchData={fetch}
            update={fetchNextPage}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            searchParams={searchParams}
            initialState={initialTableState}
          />
        </div>
      </div>
      <LinkActionBtn name="Link" icon={LinkIcon} action={addProdsToLink} />
    </div>
  );
}
