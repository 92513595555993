import { ChangedProductDetailsField, Order, Product, TermsAndConditions, User } from '.';
import { QueryParamsFilter } from '../../shared/types';

export type NotificationsCount = {
  count: number;
};

export enum NotificationType {
  TERMS_CONDITIONS = 'Terms and Conditions Accepted',
  PRODUCT_EDIT = 'Product Details Edit Request',
  NEW_PRODUCT = 'New Product Item',
  NEW_PURCHASE = 'New Purchase Order',
  PRODUCT_RANK_CHANGE = 'Product Item New Rank',
}

export enum FrequencyTypes {
  REALTIME = 'real_time',
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

export enum WeekDays {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
}

export type NotificationStatus = 'read' | 'unread';

export type NotificationAction = 'archive' | 'restore';

type TermsConditionsAcceptanceNotification = {
  id: number;
  terms_and_conditions: TermsAndConditions;
  acceptance_date: string;
  user: Pick<User, 'email' | 'first_name' | 'last_name'>;
};

type ProductDetailsEditRequest = {
  id: number;
  sku: string;
  author: Pick<User, 'email' | 'first_name' | 'last_name'> | null;
  created_date: string;
  edited_fields: ChangedProductDetailsField[];
};

type NewPurchaseOrderNotification = Pick<Order, 'location' | 'status'> & {
  po_number: string;
  price: number;
};

type NewProductNotification = Pick<Product, 'gxp_sku' | 'supplier_sku' | 'category'> & {
  display_name: string;
  vendor_price: number;
};

type ProductRankChange = Pick<Product, 'gxp_sku' | 'supplier_sku' | 'category'> & {
  display_name: string;
  old_priority: number;
  new_priority: number;
};

export type Notification = {
  id: number;
  event_date: string;
  read_date: string;
  description: React.ReactElement;
  notification_type: NotificationType;
  was_read: boolean;
  terms_and_conditions_acceptance: TermsConditionsAcceptanceNotification;
  product_details_edit_request: ProductDetailsEditRequest;
  purchase_order: NewPurchaseOrderNotification;
  product_item: NewProductNotification;
  rank_update: ProductRankChange;
  date_after?: string;
  date_before?: string;
};

export type PaginatedNotifications = NotificationsCount & {
  next: string;
  previous: string;
  results: Notification[];
};

export type NotificationSettings = {
  enabled: boolean;
  frequency: FrequencyTypes;
  frequency_weekly_day: WeekDays;
};

export type ReadNotificationPayload = {
  marked_ids: number[];
  mark_as: NotificationStatus;
};

export type ArchiveNotificationPayload = {
  notifications_ids: number[];
  action: NotificationAction;
};

export type NotificationsParams = QueryParamsFilter<Notification> & {
  search_string?: string;
};
