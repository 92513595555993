import { useEffect, useState } from 'react';

import useUserData from './useUserData';

type UsePermission = {
  isLoading: boolean;
  isAllowed?: boolean;
};

const usePermission = (permission: string): UsePermission => {
  const { data: { permissions } = {}, isLoading } = useUserData();

  const [isAllowed, setAllowed] = useState(!permission || permissions?.includes(permission));

  useEffect(() => {
    if (permission && permissions) {
      setAllowed(permissions.includes(permission));
    }
  }, [permissions]);

  return { isLoading, isAllowed };
};

export default usePermission;
