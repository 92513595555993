export default function Hint(): React.ReactElement {
  return (
    <div className="hint">
      <p>Your new password:</p>
      <ul>
        <li>Must contain at least 8 characters.</li>
        <li>Must be alphanumeric.</li>
        <li>Can’t use info similar to your personal info.</li>
        <li>Can’t be a commonly used password.</li>
      </ul>
    </div>
  );
}
