import React, { useState } from 'react';

import DomoIframe from '../../components/dashboard/DomoIframe';
import { Dashboard as DashboardType } from '../../api/schemas';
import Tabs from '../../components/dashboard/Tabs';
import { ROUTE } from '../../routes/constants';
import { PageRoute } from '../../shared/components';

const Dashboard = (): React.ReactElement => {
  const [currentTab, setCurrentTab] = useState({} as DashboardType);

  return (
    <>
      <PageRoute linkTo={ROUTE.HOMEPAGE} linkLabel="Home" currentPage="Dashboard" />
      <div className="dashboard">
        <Tabs tab={currentTab.id} setTab={setCurrentTab} />
        <DomoIframe tab={currentTab} />
      </div>
    </>
  );
};

export default Dashboard;
