import { useQuery } from 'react-query';

import { useAuth } from '.';
import { getUserData } from '../../api/queries';
import { User } from '../../api/schemas';

import ServerStateKeys from '../../utils/server-state-keys';

type UseUserData = {
  data?: User;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function useUserData(): UseUserData {
  const { token } = useAuth();

  const { data, isLoading, isSuccess } = useQuery([ServerStateKeys.UserData], getUserData, {
    enabled: !!token,
    staleTime: 30000, // 30 sec stale time
  });

  return {
    data: data?.data,
    isLoading,
    isSuccess,
  };
}
