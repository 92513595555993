import { CorporateAgreement, CorporateAgreementFileNoUrl } from '../../../../api/schemas';
import { NOT_APPLICABLE } from '../../constants';

export const formatCorpAgreementFiles = (
  files: CorporateAgreementFileNoUrl[]
): CorporateAgreementFileNoUrl[] =>
  files.map(({ status, ...rest }): CorporateAgreementFileNoUrl => {
    if (status === '1') {
      return {
        status,
        ...rest,
        status_modified_date: NOT_APPLICABLE,
        modified_date: NOT_APPLICABLE,
        expiration_date: NOT_APPLICABLE,
      };
    }

    return {
      status,
      ...rest,
    };
  });

export const getFileIdsToDownload = (data: CorporateAgreement[] = []): string[] => {
  return data.reduce((acc: string[], cur: CorporateAgreement) => {
    const ids = cur.files.map(({ id }) => id);

    return [...acc, ...ids];
  }, []);
};
