import { AxiosResponse } from 'axios';

import { QueryParams } from '../../shared/types';
import API from '../../utils/api';
import { CorporateAgreement } from '../schemas';

export const getCorpAgreements = (
  params: QueryParams
): Promise<AxiosResponse<CorporateAgreement[]>> =>
  API.get<CorporateAgreement[]>('/api/corporate-agreements/', { params });
