import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload-arrows.svg';

import { AddProductRecordStep, ModalLoading, SelectFileLabelStep, UploadStep } from '.';
import { productSkusToLink } from '../../../../atoms';
import { Modal, WarningWindow } from '../../../../shared/components';
import { WARN_CONTENT } from '../../file-upload/constants';
import { ADD_FILES_WARN_CONTENT, UPLOAD_STEPS } from './constants';
import { useFileUpload } from './hooks';
import { UploadProductDocsPropTypes } from './types';
import { useLinkProducts } from '../hooks';

export default function UploadProductDocs({
  showConfirmation,
  closeModal,
}: UploadProductDocsPropTypes): React.ReactElement {
  const [isDocLoadingAlert, setIsDocLoadingAlert] = useState(false);
  const [isNoProductAlert, setIsNoProductAlert] = useState(false);

  const [productsToLink, setProdsToLink] = useRecoilState(productSkusToLink);
  const productToLinkSize = Object.keys(productsToLink).length;

  const {
    loadingStatus: { id: uploadedFileId, name, loading, error },
    upload,
  } = useFileUpload();
  const { assingProducts, isLoading, isSuccess } = useLinkProducts('link');

  useEffect(() => {
    if (isSuccess) {
      showConfirmation();
    }
  }, [isSuccess]);

  // close upload window and reset selected SKUs to link
  const closeAndResetState = () => {
    setProdsToLink({});
    closeModal?.();
  };

  const handleCloseClick = () => {
    if (loading) {
      return setIsDocLoadingAlert(true);
    }
    if (uploadedFileId) {
      return setIsNoProductAlert(true);
    }

    return closeAndResetState();
  };

  const closeAlertWindow = () => {
    setIsDocLoadingAlert(false);
    setIsNoProductAlert(false);
  };

  const handleSubmit = () => {
    if (uploadedFileId) {
      assingProducts(uploadedFileId, Object.values(productsToLink));
    }
  };

  const getStepsContent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <UploadStep isDisabled={!!uploadedFileId} errorMsg={error?.message} uploadFile={upload} />
        );
      case 2:
        return <AddProductRecordStep />;
      case 3:
        return <SelectFileLabelStep isLoading={loading} fileId={uploadedFileId} fileName={name} />;

      default:
        return null;
    }
  };

  const isSubmitDisabled = !(productToLinkSize && uploadedFileId);

  return (
    <div className="upload_product_docs">
      <div className="header">
        <UploadIcon fill="#fff" />
        <h1>Upload Files</h1>
        <button type="button" name="Close Modal" onClick={handleCloseClick}>
          <CloseIcon fill="#fff" />
        </button>
      </div>
      <div className="content">
        {UPLOAD_STEPS.map(({ step, title }) => (
          <div key={step} className="step__container">
            <div className="step_title">
              <div className="step">{step}</div>
              <div className="description">
                {step === 3 && <p>optional:</p>}
                <h2>{title}</h2>
              </div>
            </div>
            {getStepsContent(step)}
          </div>
        ))}
        <p className="footer_text">
          Steps 1 and 2 must be completed before a file can be added to a product record.
        </p>
        <button
          className="submit_btn"
          type="button"
          name="add file"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Add File to Product Record
        </button>
        {isLoading && <ModalLoading description="Adding Files To Product Record" />}
      </div>
      <Modal show={isDocLoadingAlert || isNoProductAlert} closeModal={closeAlertWindow}>
        <WarningWindow
          content={isDocLoadingAlert ? WARN_CONTENT : ADD_FILES_WARN_CONTENT}
          confirm={closeAndResetState}
        />
      </Modal>
    </div>
  );
}
