export const ROUTE = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  DOC_REP: '/document-repository',
  PROD_CATALOG: '/product-catalog',
  CHANGE_PWD: '/change-password',
  RESET_PWD: '/reset',
  SETTINGS: '/settings',
  NOTIFICATIONS: '/notifications',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  WELCOME: '/welcome',
  HOMEPAGE: '/homepage',
  HELP: '/help',
};
