import { useSortBy, useTable } from 'react-table';

import { CorporateAgreement, CorporateAgreementFileNoUrl } from '../../../api/schemas';
import { SORT_DIRECTIONS } from '../../table/constants';
import TableSorting from '../../table/TableSorting';
import { TableSubRow } from '../../table/types';
import useSubrowsColumns from './hooks/useSubrowsColumns';

export default function SubRows({
  row: {
    original: { files },
  },
}: TableSubRow<CorporateAgreement>): React.ReactElement {
  const { columns } = useSubrowsColumns();
  const { prepareRow, getTableBodyProps, headerGroups, rows } =
    useTable<CorporateAgreementFileNoUrl>(
      {
        columns,
        data: files || [],
      },
      useSortBy
    );

  return (
    <table className="sub-row mid_size">
      <thead>
        {headerGroups.map(headerGroup => (
          /* eslint-disable react/jsx-key */
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div className="header-cell">
                  {column.render('Header')}
                  {column.canSort && (
                    <TableSorting
                      isSorted={column.isSorted}
                      sort={column.isSortedDesc ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <>
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} className={cell.column.className ?? ''}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
}
