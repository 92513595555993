import { ChangeEvent } from 'react';
import { useSetRecoilState } from 'recoil';

import { changedProductDetails } from '../../../atoms';
import { DescriptionFieldPropTypes } from './types';

export default function ProductDescriptionField({
  isEdit,
  description,
}: DescriptionFieldPropTypes): React.ReactElement {
  const setEditedProductDetails = useSetRecoilState(changedProductDetails);

  const handleChangeDescription = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
    setEditedProductDetails(prevDetails => ({
      ...prevDetails,
      description: { name: 'description', old_value: description, new_value: value },
    }));
  };

  return isEdit ? (
    <textarea
      rows={5}
      cols={100}
      className="edit_info_input"
      defaultValue={description}
      onBlur={handleChangeDescription}
    />
  ) : (
    // eslint-disable-next-line react/no-danger
    <div className="description" dangerouslySetInnerHTML={{ __html: description || 'N/A' }} />
  );
}
