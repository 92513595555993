import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ArchivedNotificationsTable, NotificationsTable } from '../../components/notifications';
import { NOTIFICATION_PAGES, NOTIFICATION_SPACES } from '../../components/notifications/constants';
import { ROUTE } from '../../routes/constants';
import { PageRoute, PageSidebar } from '../../shared/components';

export default function NotificationsPage(): React.ReactElement {
  const { search } = useLocation();

  const [currentPage, setCurrentPage] = useState(
    (new URLSearchParams(search).get('type') as NOTIFICATION_PAGES) ||
      NOTIFICATION_PAGES.NOTIFICATIONS
  );

  useEffect(() => {
    const pageFromUrl = new URLSearchParams(search).get('type') as NOTIFICATION_PAGES;
    if (pageFromUrl) {
      setCurrentPage(pageFromUrl);
    }
  }, [search]);

  const renderScreen = useCallback((): React.ReactElement => {
    switch (currentPage) {
      case NOTIFICATION_PAGES.NOTIFICATIONS:
        return <NotificationsTable />;

      case NOTIFICATION_PAGES.ARCH_NOTIFICATIONS:
        return <ArchivedNotificationsTable />;

      default:
        return <NotificationsTable />;
    }
  }, [currentPage]);

  return (
    <>
      <PageRoute linkTo={ROUTE.HOMEPAGE} linkLabel="Home" currentPage={currentPage} />
      <section className="notifications">
        <h2>Notifications</h2>
        <div className="notifications_page__container">
          <PageSidebar
            options={NOTIFICATION_SPACES}
            baseRoute={ROUTE.NOTIFICATIONS}
            currentOption={currentPage}
          />
          {renderScreen()}
        </div>
      </section>
    </>
  );
}
