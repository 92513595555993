import { IdType } from 'react-table';
import { atom } from 'recoil';

import { ProductDocument, ChangedProductDetailsField } from '../api/schemas';
import { LoadingStatus } from '../components/document-repository/types';

export const catalogTableQueryParams = atom({
  key: 'catalogTableQueryParams',
  default: '',
});

export const activeProductSku = atom({
  key: 'activeProductDetails',
  default: '',
});

export const isEditMode = atom({
  key: 'isEditMode',
  default: false,
});

export const changedProductDetails = atom<Record<string, ChangedProductDetailsField>>({
  key: 'changedProductDetails',
  default: {},
});

export const prodDocsToUnlink = atom({
  key: 'productsToUnlink',
  default: {} as Record<string, ProductDocument>,
});

export const isUploadAndLinkOpen = atom({
  key: 'isUploadAndLinkOpen',
  default: false,
});

export const uploadAndLinkLoadingStatus = atom({
  key: 'uploadAndLinkLoadingStatus',
  default: [] as LoadingStatus[],
});

export const filesToLinkToSku = atom({
  key: 'filesToLinkToSku',
  default: {} as Record<IdType<ProductDocument>, ProductDocument>,
});
