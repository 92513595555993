import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../../routes/constants';
import { Modal } from '../../../shared/components';
import ChangePasswordForm from '../../login/ChangePasswordForm';
import ConfirmationWindow from '../../login/ConfirmationWindow';
import { CHANGE_PWD_CONFIRM } from '../../login/constants';

export default function ChangePassword(): React.ReactElement {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const history = useHistory();

  const handleConfirmation = () => {
    setShowConfirmation(false);
    history.replace(ROUTE.HOMEPAGE);
  };

  return (
    <div className="settings__container update_pwd__content">
      <h3>Change Password</h3>
      <div className="divider" />
      <h4>Feel free to update your password so your vip account stays secure</h4>
      <ChangePasswordForm
        showConfirmation={() => {
          setShowConfirmation(true);
        }}
      />
      <Modal show={showConfirmation} closeModal={() => setShowConfirmation(false)} customClose>
        <ConfirmationWindow content={CHANGE_PWD_CONFIRM} confirm={handleConfirmation} />
      </Modal>
    </div>
  );
}
