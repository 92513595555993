import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

type PageRoutePropTypes = {
  linkTo: string | string[];
  linkLabel: string | string[];
  currentPage: string | JSX.Element;
};

export default function PageRoute({
  linkTo,
  linkLabel,
  currentPage,
}: PageRoutePropTypes): React.ReactElement {
  if (typeof linkTo === 'string') {
    return (
      <div className="page_route__container">
        <Link to={linkTo}>{linkLabel}</Link>
        <span>/</span>
        {currentPage}
      </div>
    );
  }

  return (
    <div className="page_route__container">
      {linkTo.map((link, idx) => (
        <Fragment key={link}>
          <Link to={link}>{linkLabel?.[idx]}</Link>
          <span>/</span>
        </Fragment>
      ))}
      {currentPage}
    </div>
  );
}
