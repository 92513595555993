import { useQuery } from 'react-query';

import { getHomePageDescription } from '../../../api/queries';
import { HomePageSection } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseHomePageSections = { data?: HomePageSection[]; isLoading: boolean };

export default function useHomePageSections(): UseHomePageSections {
  const { data, isLoading } = useQuery(
    [ServerStateKeys.HomePageDescriptions],
    getHomePageDescription,
    {
      staleTime: Infinity,
    }
  );

  return { data: data?.data, isLoading };
}
