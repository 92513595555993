type NavbarTogglerPropTypes = {
  isOpen: boolean;
  toggle: () => void;
};

export default function NavbarToggler({
  isOpen,
  toggle,
}: NavbarTogglerPropTypes): React.ReactElement {
  return (
    <button type="button" className={isOpen ? 'menu-btn hidden' : 'menu-btn'} onClick={toggle}>
      <span className="menu-btn__burger" />
    </button>
  );
}
