import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';

import { TokenObtainPairRequest } from '../../api/schemas';
import { useLogin } from '../../pages/login/hooks';
import { LoginSchema } from './validations/schema';
import { AlertWindow } from '../welcome';

type LoginFormPropTypes = {
  isLoading: boolean;
  forceLoginErrorMsg: string;
};

export default function LoginForm({
  isLoading,
  forceLoginErrorMsg,
}: LoginFormPropTypes): React.ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TokenObtainPairRequest>({
    resolver: yupResolver(LoginSchema),
  });

  const { login, isLoading: isLoginingIn, error, errorMsg } = useLogin();

  const onSubmit = ({ email, password }: TokenObtainPairRequest): void => {
    login({ email, password });
  };

  const validationError = Object.values(errors).find(({ message }) => message)?.message;

  return (
    <>
      {(validationError || errorMsg || forceLoginErrorMsg) && (
        <div className="form-error">{validationError || errorMsg || forceLoginErrorMsg}</div>
      )}
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="email" className="form-label">
          Email Address
        </label>
        <input id="email" type="email" className="form-input" {...register('email')} />
        <label htmlFor="password" className="form-label">
          Password
        </label>
        <input id="password" type="password" className="form-input" {...register('password')} />
        {isLoading || isLoginingIn ? (
          <div className="form-button">
            <div className="loader" />
          </div>
        ) : (
          <button type="submit" className="form-button" name="Log In">
            Log In <ArrowRightIcon width="10px" height="10px" fill="#fff" />
          </button>
        )}
      </form>
      {error?.status === 403 && <AlertWindow show message={error.data.detail || ''} />}
    </>
  );
}
