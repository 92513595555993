import { yupResolver } from '@hookform/resolvers/yup';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '../../routes/constants';
import { Modal, SubmitButton } from '../../shared/components';
import { useOutsideClick } from '../../shared/hooks';
import ConfirmationWindow from './ConfirmationWindow';
import { REQUEST_RESET_PASSWORD_CONFIRM } from './constants';
import { useClientIp, useRequestPasswordReset } from './hooks';
import { RequestResetPwdInputTypes } from './types';
import { RequestResetPwdSchema } from './validations/schema';

export default function RequestResetForm(): React.ReactElement {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const ref = useRef<HTMLDivElement>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestResetPwdInputTypes>({
    resolver: yupResolver(RequestResetPwdSchema),
  });
  const history = useHistory();
  const { requestReset, isLoading, isSuccess } = useRequestPasswordReset();
  const { ip } = useClientIp();

  useEffect(() => {
    if (isSuccess) {
      setShowConfirmation(true);
    }
  }, [isSuccess]);

  useOutsideClick(ref, () => {
    setShowConfirmation(false);
  });

  const onSubmit = ({ email }: RequestResetPwdInputTypes) => {
    requestReset(email, ip);
  };

  const handleReturnBack = () => {
    history.push(ROUTE.LOGIN);
  };

  return (
    <section className="login-container">
      <div className="login-container-form">
        <div className="user-login">Reset</div>
        <div className="access">PASSWORD</div>
        <p>
          Enter the email address you use to sign in to the GoExpedi Vendor Portal and we’ll send
          you a link to reset your password.
        </p>
        {errors.email?.message && <p className="form-error">{errors.email.message}</p>}
        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email" className="form-label">
            Email Address:
          </label>
          <input
            id="email"
            type="email"
            className="form-input"
            placeholder="Click here to enter email address"
            {...register('email')}
          />
          <SubmitButton name="Submit Request" isLoading={isLoading} />
        </form>
        <div>
          <button
            className="footer_btns"
            type="button"
            name="Return to login"
            onClick={handleReturnBack}
          >
            Return To Login
          </button>
        </div>
      </div>
      <div className="login-container-image" />
      <Modal show={showConfirmation} closeModal={() => setShowConfirmation(false)} customClose>
        <div ref={ref as MutableRefObject<HTMLDivElement>}>
          <ConfirmationWindow
            content={REQUEST_RESET_PASSWORD_CONFIRM}
            confirm={() => setShowConfirmation(false)}
          />
        </div>
      </Modal>
    </section>
  );
}
