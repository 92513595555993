import { useEffect, useState } from 'react';

type UseSidebar = {
  isExpanded: boolean;
  toggleSidebar: () => void;
};

export default function useSidebar(): UseSidebar {
  const [isExpanded, setExpanded] = useState<boolean>(
    localStorage.getItem('sidebarState') !== 'false'
  );

  useEffect(() => {
    localStorage.setItem('sidebarState', `${isExpanded}`);
  }, [isExpanded]);

  const toggleSidebar = () => {
    setExpanded(!isExpanded);
  };

  return { isExpanded, toggleSidebar };
}
