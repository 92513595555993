import { useMutation, useQueryClient } from 'react-query';

import { deleteUser } from '../../../api/mutations';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseDeleteUser = {
  deleteUser: (id: number) => void;
};

export default function useDeleteUser(): UseDeleteUser {
  const queryClient = useQueryClient();

  const { mutate } = useMutation((id: number) => deleteUser(id), {
    onSuccess: () => {
      queryClient.cancelQueries(ServerStateKeys.DeactivatedUsers);
      queryClient.invalidateQueries(ServerStateKeys.DeactivatedUsers);
    },
  });

  return { deleteUser: mutate };
}
