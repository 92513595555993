import { memo } from 'react';

import { Checkbox } from '../../../shared/components';
import { HIDEABLE_COLUMNS } from './constants';
import { HideColumnsPropTypes } from './types';

function HideColumnsMenu({
  hiddenColumns,
  toggleColumn,
  toggleAllColumns,
}: HideColumnsPropTypes): React.ReactElement {
  const hiddenColumnsQty = Object.keys(hiddenColumns).length;

  return (
    <div className="hide_columns__container">
      {HIDEABLE_COLUMNS.map(({ id, label }) => (
        <div key={id} className="category_row">
          <label className="category_checkbox">
            <Checkbox isChecked={!hiddenColumns[id]} handleChange={() => toggleColumn(id)} />
            <span className="category_name">{label}</span>
          </label>
        </div>
      ))}
      <div className="toggle_all">
        <button
          className="hide"
          type="button"
          name="Hide All Columns"
          disabled={hiddenColumnsQty === HIDEABLE_COLUMNS.length}
          onClick={() => toggleAllColumns(false)}
        >
          Hide All Columns
        </button>
        <button
          className="show"
          type="button"
          name="Show All Columns"
          disabled={hiddenColumnsQty === 0}
          onClick={() => toggleAllColumns(true)}
        >
          Show All Columns
        </button>
      </div>
    </div>
  );
}

export default memo(HideColumnsMenu);
