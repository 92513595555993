import { useEffect, useRef, useState } from 'react';

import { useReadNotificationStatus } from '../hooks';

type DescriptionPropTypes = {
  id: number;
  isRead: boolean;
  description: React.ReactElement;
  openReadMoreModal: (notificationId: number) => void;
};

export default function DescriptionCell({
  id,
  isRead,
  description,
  openReadMoreModal,
}: DescriptionPropTypes): React.ReactElement {
  const [isOverflowing, setIsOverflowing] = useState(false);

  const textRef = useRef<HTMLSpanElement>(null);
  const { setReadNotificationStatus } = useReadNotificationStatus();

  useEffect(() => {
    if (textRef.current) {
      const { scrollHeight, clientHeight } = textRef.current;

      setIsOverflowing(scrollHeight > clientHeight);
    }
  }, [textRef]);

  const handleShowMoreClick = () => {
    if (!isRead) {
      setReadNotificationStatus([id], 'read');
    }

    openReadMoreModal(id);
  };

  return (
    <>
      <span ref={textRef}>{description}</span>
      {isOverflowing && (
        <button
          className="read_more__btn"
          type="button"
          name="Show More"
          onClick={handleShowMoreClick}
        >
          Show more
        </button>
      )}
    </>
  );
}
