import AvailablePlans from './AvailablePlans';
import ServicePlanDetails from './ServicePlanDetails';

export default function ServicePlans(): React.ReactElement {
  return (
    <div className="service_plans_container">
      <ServicePlanDetails />
      <AvailablePlans />
    </div>
  );
}
