import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getArchivedNotifications } from '../../../api/queries';
import { Notification, PaginatedNotifications } from '../../../api/schemas';
import { QueryParamsFilter } from '../../../shared/types';
import ServerStateKeys from '../../../utils/server-state-keys';
import { formatDescription } from '../utils/helpers';
import { UseNotifications } from './types';

export default function useArchivedNotifications(): UseNotifications {
  const [params, setParams] = useState<QueryParamsFilter<Notification>>({});

  const { data, isLoading, isFetching, refetch } = useQuery(
    [ServerStateKeys.NotificationsArchived, params],
    () => {
      const { event_date, search, ...rest } = params;

      return getArchivedNotifications({
        ...rest,
        search_string: search,
        date_after: event_date && event_date[0],
        date_before: event_date && event_date[1],
      });
    },
    {
      enabled: !!Object.keys(params).length,
      keepPreviousData: true,
      select: useCallback(
        ({ data: response }: AxiosResponse<PaginatedNotifications>): PaginatedNotifications => ({
          ...response,
          results: response.results.map(notification => ({
            ...notification,
            description: formatDescription(notification),
          })),
        }),
        []
      ),
    }
  );

  const fetch = useCallback((parameters?: QueryParamsFilter<Notification>) => {
    if (parameters) {
      setParams(parameters);
    }
  }, []);

  return { fetch, refetch, data, isLoading, isFetching };
}
