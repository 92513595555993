import usePermission from '../hooks/usePermission';

type RestrictedPropTypes = {
  to: string;
  fallback?: JSX.Element | string;
  loadingComponent?: JSX.Element | string;
  children: React.ReactElement;
};

const Restricted = ({
  to,
  fallback,
  loadingComponent,
  children,
}: RestrictedPropTypes): React.ReactElement => {
  const { isLoading, isAllowed } = usePermission(to);

  if (isLoading) {
    return <>{loadingComponent}</>;
  }

  if (isAllowed) {
    return children;
  }

  return <>{fallback}</>;
};

export default Restricted;
