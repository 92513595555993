import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Filters, IdType, SortingRule } from 'react-table';

import { extractAllFilters, parseOrdering, stringifyOrdering } from '../utils';

type UseTableQueryParams<D extends Record<string, unknown>> = {
  page: number;
  pageSize: number;
  ordering: SortingRule<unknown> | null;
  searchString: string;
  filters: Filters<D>;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setOrdering: (toOrder: SortingRule<unknown>[]) => void;
  setSearchString: (search: string) => void;
};

enum TableQueryParams {
  PAGE = 'page',
  PAGE_SIZE = 'page_size',
  ORDERING = 'ordering',
  SEARCH = 'search',
}

export default function useTableQueryParams<D extends Record<string, unknown>>(
  initialPage = 0,
  initialPageSize = 10,
  initialSearchString = '',
  filterArray = [] as IdType<D>[]
): UseTableQueryParams<D> {
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  // init query params with values from URL || default values
  const [page] = useState(Number(searchParams.get(TableQueryParams.PAGE)) || initialPage);
  const [pageSize] = useState(
    Number(searchParams.get(TableQueryParams.PAGE_SIZE)) || initialPageSize
  );
  const [ordering] = useState<SortingRule<unknown> | null>(
    parseOrdering(searchParams.get(TableQueryParams.ORDERING))
  );
  const [searchString] = useState(searchParams.get(TableQueryParams.SEARCH) || initialSearchString);
  const [filters] = useState(extractAllFilters<D>(searchParams, filterArray));

  // query update method
  // TODO: ideally it should be used for all url query params, to be refactored
  const pushQueryParams =
    (param: TableQueryParams, fallbackValue: number | string) => (value: number | string) => {
      searchParams.set(param, (value || fallbackValue).toString());

      if (value === '' && param === TableQueryParams.SEARCH) {
        searchParams.delete(param);
      }

      history.push({ search: searchParams.toString() });
    };

  const setOrdering = (toOrder: SortingRule<unknown>[]) => {
    if (toOrder.length && toOrder[0].id) {
      searchParams.set(TableQueryParams.ORDERING, stringifyOrdering(toOrder[0]));
    } else {
      searchParams.delete(TableQueryParams.ORDERING);
    }
    history.push({ search: searchParams.toString() });
  };

  return {
    page,
    pageSize,
    ordering,
    searchString,
    filters,
    setPage: pushQueryParams(TableQueryParams.PAGE, initialPage),
    setPageSize: pushQueryParams(TableQueryParams.PAGE_SIZE, initialPageSize),
    setOrdering,
    setSearchString: pushQueryParams(TableQueryParams.SEARCH, initialSearchString),
  };
}
