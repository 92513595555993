import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { markReadStatus } from '../../../api/mutations';
import { NotificationStatus, ReadNotificationPayload } from '../../../api/schemas';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UseReadNotificationStatus } from './types';

export default function useReadNotificationStatus(): UseReadNotificationStatus {
  const [status, setStatus] = useState<NotificationStatus>();

  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    (payload: ReadNotificationPayload) => markReadStatus(payload),
    {
      // refetch unread notifications count and notifications list
      onSuccess: () => {
        queryClient.invalidateQueries(ServerStateKeys.UnreadNotificationsCount);
        queryClient.invalidateQueries(ServerStateKeys.Notifications);
      },
    }
  );

  const setReadNotificationStatus = (ids: number[], markAsStatus: NotificationStatus) => {
    setStatus(status);
    mutate({ mark_as: markAsStatus, marked_ids: ids });
  };

  return {
    setReadNotificationStatus,
    isMarkingAsRead: isLoading && status === 'read',
    isMarkingAsUnread: isLoading && status === 'unread',
    isSuccess,
  };
}
