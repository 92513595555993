import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { NotFound } from '..';
import { activeProductSku, catalogTableQueryParams } from '../../atoms';
import { ProductCatalog } from '../../components/product-catalog';
import { ProductDetails, ProductDetailsPageRoute } from '../../components/product-details';
import { ROUTE } from '../../routes/constants';
import { PageRoute } from '../../shared/components';
import { useFeature, useQueryParams } from '../../shared/hooks';

export default function ProdCatalog(): React.ReactElement | null {
  const queryParams = useQueryParams();
  const setCurrentProductSku = useSetRecoilState(activeProductSku);
  const prodCatalogParams = useRecoilValue(catalogTableQueryParams);

  const { disabled, isLoading } = useFeature('Product Catalog');

  const extractProductId = (): string => {
    const pid = queryParams.get('pid') || '';
    if (pid) setCurrentProductSku(pid);

    return pid;
  };

  const [currentProduct, setCurrentProduct] = useState(extractProductId());

  useEffect(() => {
    const productId = extractProductId();
    setCurrentProduct(productId);
  }, [queryParams]);

  // TODO: find a more generic approach to hide entire routes
  if (isLoading) return null;
  if (disabled) return <NotFound />;

  if (currentProduct) {
    return (
      <>
        <PageRoute
          linkTo={[ROUTE.HOMEPAGE, `${ROUTE.PROD_CATALOG}?${prodCatalogParams}`]}
          linkLabel={['Home', 'Product Catalog']}
          currentPage={<ProductDetailsPageRoute sku={currentProduct} />}
        />
        <section>
          <h2>Product Details</h2>
          <ProductDetails />
        </section>
      </>
    );
  }
  return (
    <>
      <PageRoute linkTo={ROUTE.HOMEPAGE} linkLabel="Home" currentPage="Product Catalog" />
      <section className="product_catalog">
        <h2>Product Catalog</h2>
        <ProductCatalog />
      </section>
    </>
  );
}
