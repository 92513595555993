import { useQuery } from 'react-query';

import { getAdditionalCompanyDetails } from '../../../../api/queries';
import ServerStateKeys from '../../../../utils/server-state-keys';
import { UseAccountInfo } from './types';

export default function useAccountInfo(): UseAccountInfo {
  const { data, isLoading } = useQuery([ServerStateKeys.AccountInfo], getAdditionalCompanyDetails, {
    staleTime: Infinity,
  });

  return { data: data?.data, isLoading };
}
