import { FAQs, Overview } from '.';
import { HelpFaqQuestion } from '../../api/schemas';
import { useHelpSectionDescription } from './hooks';
import { HelpPagePropTypes } from './types';

export default function HelpPageContent({ activeTab }: HelpPagePropTypes): React.ReactElement {
  if (!activeTab) {
    return <div className="help_page_content" />;
  }

  const { data: { name, description, faqs } = {}, isLoading } =
    useHelpSectionDescription(activeTab);

  if (isLoading) {
    return <div className="help_page_content" />;
  }

  return (
    <div className="help_page_content">
      <Overview name={name as string} description={description as string} />
      <FAQs name={name as string} faqs={faqs as HelpFaqQuestion[]} />
    </div>
  );
}
