import { useEffect, useState } from 'react';
import { Cell, Column, HeaderProps } from 'react-table';

import { FilterByDate, FilterByMenu } from '../../../shared/components';
import { PERMISSIONS } from '../../../shared/constants';
import { usePermission } from '../../../shared/hooks';
import { formatDateTime } from '../../../shared/utils';
import HeaderCheckbox from '../../table/HeaderCheckbox';
import RowCheckbox from '../../table/RowCheckbox';
import { DescriptionCell } from '../cell';
import { NTF_FILTER_OPTIONS } from '../constants';
import { NotificationsTableData } from '../types';
import { NotificationsColumnsPropTypes, UseNotificationsColumns } from './types';

export default function useArchivedNotificationsColumns({
  openReadMoreModal,
}: NotificationsColumnsPropTypes): UseNotificationsColumns {
  const [columns, setColumns] = useState<Column<NotificationsTableData>[]>([
    {
      Header({ column: { filterValue, setFilter } }: HeaderProps<NotificationsTableData>) {
        return (
          <FilterByDate title="Notification Date" filterValue={filterValue} setFilter={setFilter} />
        );
      },
      accessor: 'event_date',
      disableSortBy: true,
      className: 'ntf_date__container',
      Cell: ({ value }: Cell<NotificationsTableData>) => formatDateTime(value),
    },
    {
      Header({ column: { filterValue, setFilter } }: HeaderProps<NotificationsTableData>) {
        return (
          <FilterByMenu
            title="Type"
            filterOptions={NTF_FILTER_OPTIONS}
            filterValue={filterValue}
            setFilter={setFilter}
          />
        );
      },
      accessor: 'notification_type',
      disableSortBy: true,
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
      className: 'ntf_description__container',
      Cell({
        row: {
          original: { id, was_read, description },
        },
      }: Cell<NotificationsTableData>) {
        return (
          <DescriptionCell
            id={id}
            isRead={was_read}
            openReadMoreModal={openReadMoreModal}
            description={description}
          />
        );
      },
    },
  ]);

  const { isAllowed } = usePermission(PERMISSIONS.ARCHIVE_NOTIFICATIONS);

  useEffect(() => {
    if (columns.length === 3 && isAllowed) {
      setColumns(prevColumns => [
        {
          id: 'selection',
          Header: HeaderCheckbox,
          Cell: RowCheckbox,
        },
        ...prevColumns,
      ]);
    }
  }, [isAllowed]);

  return { columns };
}
