import { memo } from 'react';

import { RadioButton } from '../../../shared/components';
import { ITEMS_PER_PAGE_OPTIONS } from './constants';
import { ItemsPerPagePropTypes } from './types';

function ItemsPerPage({ pageSize, setPageSize }: ItemsPerPagePropTypes): React.ReactElement {
  return (
    <div className="items_per_page__container">
      {ITEMS_PER_PAGE_OPTIONS.map((value: number) => (
        <div key={value} className="radio_btn__row">
          <label>
            <RadioButton isChecked={pageSize === value} handleChange={() => setPageSize(value)} />
            <span className="radio_btn__label">{value}</span>
          </label>
        </div>
      ))}
    </div>
  );
}

export default memo(ItemsPerPage);
