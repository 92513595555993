import { useQuery } from 'react-query';

import { getFeatureFlags } from '../../api/queries';
import { FeatureFlag } from '../../api/schemas';
import ServerStateKeys from '../../utils/server-state-keys';
import { useAuth } from './useAuth';

type UseDisabledFeatures = {
  features?: FeatureFlag[];
  isLoading: boolean;
};

export default function useDisabledFeatures(): UseDisabledFeatures {
  const { token } = useAuth();

  const { data, isLoading } = useQuery([ServerStateKeys.DisabledFeatureFlags], getFeatureFlags, {
    enabled: !!token,
    staleTime: Infinity,
  });

  return {
    features: data?.data,
    isLoading,
  };
}
