import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { getOrderStatuses } from '../../../../api/queries';
import { OrderStatus } from '../../../../api/schemas';
import ServerStateKeys from '../../../../utils/server-state-keys';
import { UseOrderStatuses } from './types';

export default function useOrderStatuses(): UseOrderStatuses {
  const { data, isLoading } = useQuery([ServerStateKeys.OrderStatuses], getOrderStatuses, {
    staleTime: 60000,
    select: useCallback(
      ({ data: response }: AxiosResponse<OrderStatus[]>) =>
        response.map(({ name }) => ({ id: name, label: name })),
      []
    ),
  });

  return { data, isLoading };
}
