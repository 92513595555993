import { memo } from 'react';
import { Row } from 'react-table';
import { useSetRecoilState } from 'recoil';

import { ProductDocument } from '../../../api/schemas';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { documentToEdit } from '../../../atoms';

type EditDocPropTypes = {
  row: Row<ProductDocument>;
};

function EditDoc({ row }: EditDocPropTypes): React.ReactElement {
  const setDocumentToEdit = useSetRecoilState(documentToEdit);

  return (
    <div className="actions">
      <button type="button" name="edit file" onClick={() => setDocumentToEdit(row.original)}>
        <EditIcon />
      </button>
    </div>
  );
}

export default memo(EditDoc);
