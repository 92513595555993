import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'react-table';

import { ReactComponent as OpenIcon } from '../../../assets/icons/open.svg';

import { Order } from '../../../api/schemas';
import { ROUTE } from '../../../routes/constants';

type PurchaseNamePropTypes = Pick<Row, 'getToggleRowExpandedProps' | 'isExpanded'> &
  Pick<Order, 'purchase_order' | 'has_documents'>;

function PurchaseName({
  purchase_order,
  has_documents,
  isExpanded,
  getToggleRowExpandedProps,
}: PurchaseNamePropTypes): React.ReactElement {
  const renderLinkBtn = () => (
    <Link
      className="purchase_name__link"
      to={`${ROUTE.DOC_REP}?type=Order+Documents&purchase_order=${purchase_order}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {purchase_order}
      <OpenIcon />
    </Link>
  );

  if (!has_documents) {
    return <div className="document_name">{renderLinkBtn()}</div>;
  }

  return (
    <div className="expander" {...getToggleRowExpandedProps()}>
      <div className={isExpanded ? 'close' : 'open'} /> {renderLinkBtn()}
    </div>
  );
}

export default memo(PurchaseName);
