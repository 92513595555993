import { ReactComponent as ArrowIcon } from '../../assets/icons/polygon.svg';
import { SORT_DIRECTIONS } from './constants';

type SortingPropTypes = {
  isSorted: boolean;
  sort?: string;
};

const COLORS = {
  WHITE: '#FFF',
  BLUE: '#03ABE3',
};

export default function TableSorting({ isSorted, sort }: SortingPropTypes): React.ReactElement {
  const getColorToFill = (order: string): string => {
    if (!isSorted) {
      return COLORS.BLUE;
    }
    if (sort === order) {
      return COLORS.BLUE;
    }

    return COLORS.WHITE;
  };

  return (
    <div className="sorting">
      <ArrowIcon fill={getColorToFill(SORT_DIRECTIONS.ASC)} />
      <ArrowIcon fill={getColorToFill(SORT_DIRECTIONS.DESC)} />
    </div>
  );
}
