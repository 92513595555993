import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Cell } from 'react-table';

import { ReactComponent as OpenIcon } from '../../../assets/icons/open.svg';

import { NotificationType } from '../../../api/schemas';
import { ROUTE } from '../../../routes/constants';
import { useReadNotificationStatus } from '../hooks';
import { NotificationsTableData } from '../types';

export default function VisitCell({
  row: { original },
}: Cell<NotificationsTableData>): React.ReactElement {
  const history = useHistory();
  const { setReadNotificationStatus } = useReadNotificationStatus();

  const getUrlForRedirect = useCallback(() => {
    if (original.notification_type === NotificationType.PRODUCT_EDIT) {
      return `${ROUTE.PROD_CATALOG}?pid=${original.product_details_edit_request.sku}`;
    }

    if (original.notification_type === NotificationType.TERMS_CONDITIONS) {
      return `${ROUTE.TERMS_AND_CONDITIONS}`;
    }

    if (original.notification_type === NotificationType.NEW_PRODUCT) {
      return `${ROUTE.PROD_CATALOG}?pid=${original.product_item.gxp_sku}`;
    }

    if (original.notification_type === NotificationType.NEW_PURCHASE) {
      return `${ROUTE.DOC_REP}?type=Order+Documents`;
    }

    if (original.notification_type === NotificationType.PRODUCT_RANK_CHANGE) {
      return `${ROUTE.PROD_CATALOG}?pid=${original.rank_update.gxp_sku}`;
    }

    return '';
  }, []);

  const visitLocation = () => {
    if (!original.was_read) {
      setReadNotificationStatus([original.id], 'read');
    }

    const url = getUrlForRedirect();
    if (url) history.push(url);
  };

  return (
    <button className="visit_btn" type="button" name="Visit" onClick={visitLocation}>
      <OpenIcon width="12px" height="12px" stroke="#006298" />
      <span>Visit</span>
    </button>
  );
}
