import { useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';

import { InviteUserForm } from '.';
import { SubmitButton } from '../../../shared/components';
import { ModalChildPropTypes } from '../../../shared/components/Modal';
import { InviteUserInputs } from './types';
import { InviteUserSchema } from './validations/schema';
import { useCreateUserInvites } from '../hooks';
import { useVendorData } from '../../header/hooks';
import { CreateUserInviteRequest } from '../../../api/schemas';

export default function InviteUsers({ closeModal }: ModalChildPropTypes): React.ReactElement {
  const { data: { remaining_licenses } = {} } = useVendorData();
  const { inviteUsers, isLoading, isSuccess, error } = useCreateUserInvites();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<InviteUserInputs>({
    defaultValues: { invites: [{ email: '', role_name: '', invite_text: '' }] },
    resolver: yupResolver(InviteUserSchema),
  });
  const { fields, append, remove } = useFieldArray({ name: 'invites', control });

  useEffect(() => {
    if (isSuccess) {
      closeModal?.();
    }
  }, [isSuccess]);

  const addUserInvite = useCallback(() => {
    append({ email: '', role_name: '', invite_text: '' });
  }, []);

  const removeUserInvite = useCallback((index: number) => {
    remove(index);
  }, []);

  const onSubmit = (data: InviteUserInputs) => {
    inviteUsers(data);
  };

  return (
    <form className="invite_users__container" onSubmit={handleSubmit(onSubmit)}>
      {error && !(error as CreateUserInviteRequest).invites && (
        <p className="error">{(error as string[])[0]}</p>
      )}
      {fields.map((item, index) => (
        <InviteUserForm
          key={item.id}
          id={index}
          register={register}
          errors={errors}
          invitesCount={fields.length}
          addUser={addUserInvite}
          removeUser={removeUserInvite}
        />
      ))}
      <SubmitButton
        name="Send Invitation(s)"
        isLoading={isLoading}
        isDisabled={remaining_licenses === 0}
      />
      <button className="cancel_btn" type="button" onClick={closeModal}>
        Cancel
      </button>
    </form>
  );
}
