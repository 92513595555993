import { useState } from 'react';

import { ReactComponent as PeekEyeIcon } from '../../assets/icons/eye-slash.svg';
import { ReactComponent as HideEyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-narrow.svg';

import { PasswordInputPropTypes } from './types';

export default function PasswordInput({
  id,
  label,
  error,
  touched,
  register,
}: PasswordInputPropTypes): React.ReactElement {
  const [isVisible, setVisibility] = useState(false);

  const errorMsg = typeof error === 'string' ? error : error?.message;

  return (
    <>
      <label htmlFor={id} className="form_input_label">
        {label}
        <span className="asterix">*</span>
        {errorMsg && <p className="form_input_error">{errorMsg}</p>}
      </label>
      <div className="field__row">
        <div className={errorMsg ? 'pwd_input invalid' : 'pwd_input'}>
          <input
            type={isVisible ? 'text' : 'password'}
            id={id}
            placeholder="Click here to enter password"
            {...register()}
          />
          <button
            type="button"
            className="toggle_visibility"
            onClick={() => setVisibility(!isVisible)}
          >
            {isVisible ? <HideEyeIcon fill="#006298" /> : <PeekEyeIcon fill="#03ABE3" />}
          </button>
        </div>
        {(errorMsg || touched) &&
          (errorMsg ? (
            <CloseIcon fill="#fff" className="pin invalid" />
          ) : (
            <SuccessIcon stroke="#fff" className="pin" />
          ))}
      </div>
    </>
  );
}
