import { RemainingLicencesPropTypes } from './types';

export default function RemainingLicences({
  remainingLicences,
  tierName,
}: RemainingLicencesPropTypes): React.ReactElement {
  const fewLicencesLeft = tierName === 'Basic' ? remainingLicences <= 1 : remainingLicences <= 3;

  return (
    <p className={`remaining_licences ${fewLicencesLeft ? 'warning' : ''}`}>
      {remainingLicences} remaining licences
    </p>
  );
}
