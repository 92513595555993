import { useEffect, useRef } from 'react';
import { Column, IdType, useFilters, useRowSelect, useSortBy, useTable } from 'react-table';

import { ProductDocumentSKURequest } from '../../../../api/schemas';
import { useTableDataLoading } from '../../../table/hooks';
import { getSelectedRowsFlatData, getTheadStyle } from '../../../table/utils';
import { SearchParams } from '../../persistent-product/file-upload/types';

type UnlinkProductsTablePropTypes = {
  columns: Column<ProductDocumentSKURequest>[];
  data: ProductDocumentSKURequest[];
  searchParams: SearchParams;
  setSelectedRows?: (
    rows: Record<IdType<ProductDocumentSKURequest>, ProductDocumentSKURequest>
  ) => void;
};

/**
 * Table that operates searching and ordering on the client side
 */
export default function UnlinkProductsTable({
  columns,
  data,
  searchParams,
  setSelectedRows,
}: UnlinkProductsTablePropTypes): React.ReactElement {
  const tbodyRef = useRef<HTMLTableSectionElement>(null);

  const { getRowId } = useTableDataLoading({
    placeholderRows: 3,
    isLoading: false,
    data,
  });

  const {
    headerGroups,
    rows,
    visibleColumns,
    state: { selectedRowIds, filters },
    prepareRow,
    getTableBodyProps,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      getRowId,
      initialState: { sortBy: [{ id: 'gxp_sku', desc: false }] },
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    Object.entries(searchParams).forEach(([field, value]) => setFilter(field, value));
  }, [searchParams]);

  useEffect(() => {
    if (setSelectedRows) {
      const ids = Object.keys(selectedRowIds);
      const selectedFlatRows = getSelectedRowsFlatData(ids, data);

      setSelectedRows(selectedFlatRows);
    }
  }, [selectedRowIds]);

  return (
    <table>
      <thead style={getTheadStyle(tbodyRef)}>
        {headerGroups.map(headerGroup => (
          /* eslint-disable react/jsx-key */
          // disabling the rule here due to the fact that getProps functions actually make iterated elements keyed
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} className={column.headerClassName ?? ''}>
                <div className="header-cell">{column.render('Header')}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody ref={tbodyRef} {...getTableBodyProps()}>
        {!rows.length && !!filters.length && (
          <tr>
            <td colSpan={visibleColumns.length}>
              Sorry, we couldn’t find a match for “{filters.map(({ value }) => value).join(', ')}”.
              Please try another search.
            </td>
          </tr>
        )}
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()} className={cell.column.className ?? ''}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
