import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../routes/constants';

export default function Footer(): ReactElement {
  return (
    <footer className="terms-and-conditions">
      &copy; {new Date().getFullYear()} GoExpedi. All Rights Reserved&nbsp;|&nbsp;
      <Link to={ROUTE.TERMS_AND_CONDITIONS}>Terms and Conditions</Link>&nbsp;|&nbsp;
      <a href="mailto: vendor.management@goexpedi.com" target="_blank" rel="noopener noreferrer">
        Contact Us
      </a>
      &nbsp;|
    </footer>
  );
}
