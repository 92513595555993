import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-right.svg';

import { formatDateTime } from '../../utils';

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps): React.ReactElement => (
  <div className="datepicker_header">
    <button
      type="button"
      name="Previous Month"
      className="change_month prev_page"
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
    >
      <ArrowIcon width="12px" height="12px" fill="#03ABE3" />
    </button>
    <div className="current_date_info">
      <div className="year">{date.getFullYear()}</div>
      <div className="date">
        {formatDateTime(date.toDateString(), {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // overwrite UTC timezone
          weekday: 'short',
          month: 'long',
          day: 'numeric',
        })}
      </div>
    </div>
    <button
      type="button"
      name="Next Month"
      className="change_month"
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
    >
      <ArrowIcon width="12px" height="12px" fill="#03ABE3" />
    </button>
  </div>
);

export default CustomHeader;
