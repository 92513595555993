import { Cell } from 'react-table';

import { CorporateAgreement } from '../../../api/schemas';

export default function DocName<D extends CorporateAgreement>({
  row,
}: Cell<D>): React.ReactElement {
  const {
    toggleRowExpanded,
    original: { document_type, files },
  } = row;
  if (files?.length) {
    const docName = `${document_type} (${files.length})`;
    return (
      <button type="button" onClick={() => toggleRowExpanded()}>
        <span className="doc_name">{docName}</span>
      </button>
    );
  }

  return <div className="document_name">{document_type}</div>;
}
