import { useRecoilState } from 'recoil';

import { isViewingAllSkus } from '../../../../atoms';
import { SkuType, UseViewAllSku } from './types';

export default function useViewAllSku(): UseViewAllSku {
  const [viewAllSkus, setViewAllSkus] = useRecoilState(isViewingAllSkus);

  const viewAllSku = (skuId: string[], name: string, sku: SkuType) => {
    setViewAllSkus({ skuIds: skuId, documentName: name, skuType: sku });
  };

  const closeWindow = () => {
    setViewAllSkus({ skuIds: [], documentName: '', skuType: undefined });
  };

  return {
    skuType: viewAllSkus.skuType,
    skuIds: viewAllSkus.skuIds,
    documentName: viewAllSkus.documentName,
    viewAllSku,
    closeWindow,
  };
}
