import { memo } from 'react';

import { AdditionalCompanyInfo } from '../../../api/schemas';
import { NOT_APPLICABLE } from '../constants';
import { ACCOUNT_INFO } from './constants';
import useAccountInfo from './hooks/useAccountInfo';

type AccountInfoPropTypes = {
  header: string;
  sections?: { id: keyof AdditionalCompanyInfo; label: string }[];
};

const getBoxData = (
  id: keyof AdditionalCompanyInfo,
  isLoading: boolean,
  data?: AdditionalCompanyInfo
) => {
  if (isLoading || !data) {
    return <div className="loading" />;
  }

  if (id === 'payment_terms') {
    return (
      <>
        <p>{data[id] || NOT_APPLICABLE}</p>
        <h5>Pricing Structure</h5>
        <p>{data.pricing_structure || NOT_APPLICABLE}</p>
      </>
    );
  }

  if (!data[id]) {
    return <p>{NOT_APPLICABLE}</p>;
  }

  if (typeof data[id] === 'object') {
    return Object.entries(data[id]).map(([field, value]) => {
      if (field === 'email') {
        return (
          <a
            key={`${id}_${field}`}
            href={`mailto: ${value}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a>
        );
      }

      return <p key={`${id}_${field}`}>{value}</p>;
    });
  }

  return <p>{data[id]}</p>;
};

function AccountInfo({
  header,
  sections = ACCOUNT_INFO,
}: AccountInfoPropTypes): React.ReactElement {
  const { data, isLoading } = useAccountInfo();

  return (
    <div className="account_info">
      <h4>{header}</h4>
      <div className="account_info__container">
        {sections.map(({ id, label }) => (
          <div className="info_box" key={id}>
            <h5>{label}</h5>
            {getBoxData(id, isLoading, data)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(AccountInfo);
