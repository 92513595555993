import { OrderNamePropTypes } from './types';

export default function OrderName({
  purchase_order,
  has_documents,
  toggleRowExpanded,
}: OrderNamePropTypes): React.ReactElement {
  if (!has_documents) {
    return <div className="document_name">{purchase_order}</div>;
  }

  return (
    <button type="button" className="expander" onClick={() => toggleRowExpanded()}>
      <span className="doc_name">{purchase_order}</span>
    </button>
  );
}
