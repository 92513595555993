import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { FormInput, SubmitButton } from '../../../../shared/components';
import { EditDocNameInputs } from '../../document-edit/types';
import { useEditOrderDoc, useOrderLabels } from '../hooks';
import OrderDocLabelSelect from './OrderDocLabelSelect';
import { FileEditPropTypes } from './types';

export default function EditOrderDoc({
  orderId,
  fileId,
  fileName,
  fileLabel,
  closeModal,
}: FileEditPropTypes): React.ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EditDocNameInputs>({
    resolver: yupResolver(
      object({
        name: string().required('Document name is required'),
      })
    ),
  });

  const watchName = watch('name');

  const [fileLabelId, setFileLabelId] = useState<number | null>(null);

  const { labels } = useOrderLabels();
  const { editFile, isLoading, isSuccess } = useEditOrderDoc(orderId);

  useEffect(() => {
    // initiate fileLabelId with default value once product doc labels are fetched
    if (labels.length) {
      const initialLabelId = labels.find(({ name }) => name === fileLabel)?.id || null;
      setFileLabelId(initialLabelId);
    }
  }, [labels]);

  useEffect(() => {
    if (isSuccess && closeModal) {
      closeModal();
    }
  }, [isSuccess]);

  const onSubmit = ({ name }: EditDocNameInputs) => {
    editFile({ fileId, fileName: name, fileLabelId: fileLabelId as number });
  };

  const isFormSame =
    watchName === fileName &&
    fileLabel === (labels.find(({ id }) => id === fileLabelId)?.name || null);
  // disable Submit Btn if no changes were done or there are errors
  const isSubmitDisabled = isFormSame || !!Object.values(errors).find(({ message }) => message);

  return (
    <div className="edit_doc__modal no_sidebar">
      <div className="edit_doc__content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="edit_doc__name">
            <FormInput
              id="name"
              label="Change the document name:"
              error={errors.name}
              register={() => register('name')}
              defaultValue={fileName}
            />
            <div>
              <label htmlFor="label" className="form_input_label">
                Change document label:
              </label>
              <OrderDocLabelSelect
                placeholder={fileLabel || 'NEW DOCUMENT LABEL'}
                fileLabelId={fileLabelId}
                handleLabelSelect={setFileLabelId}
              />
            </div>
          </div>
          <SubmitButton name="Save Update" isLoading={isLoading} isDisabled={isSubmitDisabled} />
        </form>
        <button type="button" name="Close" className="close_btn" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
}
