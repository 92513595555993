import { useRecoilState } from 'recoil';

import { documentToEdit, productSkusToUnlink } from '../../../../atoms';

type UseResetUnlinkFrom = {
  resetForm: () => void;
};

/**
 * Document's linked products come in document's instance and can't be refetched/updated.
 * They and entire document object are both stored on client side as a recoil atom,
 * thus on successful link/unlink actions store must be updated manually.
 */
export default function useResetUnlinkForm(): UseResetUnlinkFrom {
  const [fileData, setFileData] = useRecoilState(documentToEdit);
  const [selectedProducts, setSelectedProducts] = useRecoilState(productSkusToUnlink);

  const updateFileData = () => {
    const newLinkedProducts = fileData.products.filter(({ gxp_sku }) => !selectedProducts[gxp_sku]);
    setFileData({ ...fileData, products: newLinkedProducts });
  };

  const resetForm = () => {
    updateFileData();
    setSelectedProducts({});
  };

  return { resetForm };
}
