import { useEffect } from 'react';

import { useHelpSections } from './hooks';
import { HelpTabsPropTypes } from './types';

export default function HelpTabs({
  activeTab,
  setActiveTab,
}: HelpTabsPropTypes): React.ReactElement | null {
  const { data, isLoading } = useHelpSections();

  useEffect(() => {
    if (data && data.length && !activeTab) {
      setActiveTab(data[0].id);
    }
  }, [data]);

  if (data && data.length === 0) {
    return (
      <div className="tabs_container">
        <p>No help sections configured</p>
      </div>
    );
  }
  if (isLoading) {
    return (
      <div className="tabs_container">
        <button type="button" className="tab active">
          <div className="loading" />
        </button>
      </div>
    );
  }

  return (
    <div className="tabs_container">
      {data?.map(({ id, name }) => {
        const isSelected = id === activeTab;

        return (
          <button
            key={id}
            name={name}
            type="button"
            className={`tab ${isSelected ? 'active' : ''}`}
            onClick={() => setActiveTab(id)}
          >
            {name}
          </button>
        );
      })}
    </div>
  );
}
