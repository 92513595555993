import { useState } from 'react';

import { HelpQuestionRowPropTypes } from './types';

export default function HelpQuestionRow({
  question,
  short_answer,
  answer,
}: HelpQuestionRowPropTypes): React.ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAnswerDisplay = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="question_row_wrapper">
      <button type="button" className="question_row" onClick={toggleAnswerDisplay}>
        <div className={isExpanded ? 'expander' : 'expander open'} />
        <span className="highlighted">Q:</span>
        {question}
      </button>
      <div className={isExpanded ? 'answer_row show' : 'answer_row'}>
        <div className="short_answer">
          <span className="highlighted">A:</span>
          {short_answer}
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="long_answer" dangerouslySetInnerHTML={{ __html: answer }} />
      </div>
    </div>
  );
}
