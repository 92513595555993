import { useEffect, useState } from 'react';
import { Cell, Column, HeaderProps } from 'react-table';

import { formatDateTime } from '../../../shared/utils';
import { PreviewDoc, LabelCell } from '../../document-repository/persistent-product';
import { PersistentProdDocsTable } from '../../document-repository/persistent-product/types';
import { formatBytes } from '../../document-repository/utils/helpers';
import Checkbox from '../../table/Checkbox';
import ActionButtonsCell from '../documents/ActionButtonsCell';

type ProductDocumentsColumns = {
  columns: Column<PersistentProdDocsTable>[];
};

export default function useProductDocumentsColumns(isLoaded: boolean): ProductDocumentsColumns {
  const [columns, setColumns] = useState<Column<PersistentProdDocsTable>[]>([
    {
      Header: 'Product Documentation',
      accessor: 'name',
      className: 'doc_name_container',
      Cell: ({ row: { original } }: Cell<PersistentProdDocsTable>) => (
        <PreviewDoc name={original.name} url={original.url} />
      ),
    },
    {
      Header: 'Label',
      accessor: 'label',
      className: 'doc_label_container',
      Cell: LabelCell,
    },
    {
      Header: 'Date',
      accessor: 'modified_date',
      Cell: ({ value }: Cell<PersistentProdDocsTable>) => formatDateTime(value),
    },
    {
      Header: 'Size',
      accessor: 'size',
      Cell: ({ value }: Cell<PersistentProdDocsTable>) => formatBytes(value, 0),
    },
    {
      Header: 'Type',
      accessor: 'extension',
      Cell: ({ value }: Cell<PersistentProdDocsTable>) => `.${value.toUpperCase()}`,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      className: 'order_docs_actions',
      disableSortBy: true,
      Cell({ row }: Cell<PersistentProdDocsTable>) {
        return <ActionButtonsCell {...row.original} />;
      },
    },
  ]);

  useEffect(() => {
    if (isLoaded && columns.length === 6) {
      setColumns([
        ...columns,
        {
          id: 'selection',
          headerClassName: 'row_select__container',
          className: 'row_select__container',
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<PersistentProdDocsTable>) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: Cell<PersistentProdDocsTable>) => (
            <Checkbox {...row.getToggleRowSelectedProps()} />
          ),
        },
      ]);
    }
  }, [isLoaded]);

  return { columns };
}
