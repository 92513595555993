import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { getProductCategories } from '../../../api/queries';
import { ProductCategory } from '../../../api/schemas';
import { FilterOption } from '../../../shared/types';
import ServerStateKeys from '../../../utils/server-state-keys';
import { UseCategories } from './types';

export default function useCategories(): UseCategories {
  const { data } = useQuery([ServerStateKeys.ProductCategories], getProductCategories, {
    staleTime: 60000,
    select: useCallback(
      ({ data: response }: AxiosResponse<ProductCategory[]>): FilterOption[] =>
        response.map(({ name }) => ({ id: name, label: name })),
      []
    ),
  });

  return { categories: data };
}
