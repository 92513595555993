import { Suspense } from 'react';

import { ErrorBoundary } from '../../shared/components';
import { useProductDetails } from './hooks';

type PageRoutePropTypes = {
  sku: string;
};

const SuspendedProductName = ({ sku }: PageRoutePropTypes) => {
  const { data: { name } = {} } = useProductDetails(sku);

  return <>{name}</>;
};

const ProductDetailsPageRoute = ({ sku }: PageRoutePropTypes): React.ReactElement => (
  <ErrorBoundary fallback={<>Product Item Details</>}>
    <Suspense fallback={<>Product Item Details</>}>
      <SuspendedProductName sku={sku} />
    </Suspense>
  </ErrorBoundary>
);

export default ProductDetailsPageRoute;
