import { MutableRefObject, useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

export default function useInsideClick<T extends HTMLElement = HTMLElement>(
  ref: MutableRefObject<T | undefined>,
  callback: () => void
): void {
  const handleClick = (e: AnyEvent) => {
    if (ref.current && ref.current.contains(e.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [ref, callback]);
}
