type ModalLoadingPropTypes = {
  description: string;
};

export default function ModalLoading({ description }: ModalLoadingPropTypes): React.ReactElement {
  return (
    <div className="modal_loading__container">
      <div>
        <h1>{description}</h1>
        <div className="loader" />
        <div className="divider" />
        <button className="cancel" type="button" name="Cancel">
          Cancel
        </button>
      </div>
    </div>
  );
}
