import { useEffect, useState } from 'react';
import { Cell, Column, HeaderProps } from 'react-table';

import { ProductDocument } from '../../../api/schemas';
import { PreviewDoc } from '../../document-repository/persistent-product';
import Checkbox from '../../table/Checkbox';

type UseFilesToLinkColumns = {
  columns: Column<ProductDocument>[];
};

export default function useFilesToLinkColumns(isLoaded: boolean): UseFilesToLinkColumns {
  const [columns, setColumns] = useState<Column<ProductDocument>[]>([
    {
      Header: 'Document Name',
      accessor: 'name',
      headerClassName: 'product_sku_desc_column',
      className: 'product_sku_desc_column',
      Cell: ({ row: { original } }: Cell<ProductDocument>) => (
        <PreviewDoc name={original.name} url={original.url} />
      ),
    },
    {
      Header: 'Label',
      accessor: 'label',
      Cell: ({ value }: Cell<ProductDocument>) => value || 'N/A',
    },
    {
      Header: 'Type',
      accessor: 'extension',
      Cell: ({ value }: Cell<ProductDocument>) => `.${value.toUpperCase()}`,
    },
  ]);

  useEffect(() => {
    if (columns.length === 3 && isLoaded) {
      setColumns([
        {
          id: 'selection',
          headerClassName: 'row_select__container',
          className: 'row_select__container',
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<ProductDocument>) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: Cell<ProductDocument>) => (
            <Checkbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  }, [isLoaded]);

  return { columns };
}
