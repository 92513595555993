import { useMutation, useQueryClient } from 'react-query';

import { resendUserInvite } from '../../../api/mutations';
import ServerStateKeys from '../../../utils/server-state-keys';

type UseResendUserInvite = {
  resend: (id: number) => void;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function useResendUserInvite(): UseResendUserInvite {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation((id: number) => resendUserInvite(id), {
    onSuccess: () => {
      queryClient.cancelQueries(ServerStateKeys.PendingInvites);
      queryClient.invalidateQueries(ServerStateKeys.PendingInvites);
    },
  });

  return { resend: mutate, isLoading, isSuccess };
}
