import { useMutation } from 'react-query';

import { deleteArchivedDocuments } from '../../../../api/mutations';
import { DocumentsDeleteRequest } from '../../../../api/schemas';
import { UseDeleteDocs } from './types';

export default function useDeleteDocs(): UseDeleteDocs {
  const { mutate, isLoading, isSuccess } = useMutation((docsToDelete: DocumentsDeleteRequest) =>
    deleteArchivedDocuments(docsToDelete)
  );

  const deleteDocs = (files: string[]) => {
    mutate({ documents_ids: files });
  };

  return { deleteDocs, isLoading, isSuccess };
}
