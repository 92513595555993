import { RadioButton } from '../../../shared/components';
import { RadioOption } from '../types';

type AlertsMenuSectionPropTypes<D> = {
  title?: string;
  currentSelect: D;
  options: RadioOption<D>[];
  disabled?: boolean;
  select: (value: D) => void;
};

export default function AlertsMenuSection<D>({
  title,
  currentSelect,
  options,
  disabled,
  select,
}: AlertsMenuSectionPropTypes<D>): React.ReactElement {
  return (
    <>
      {title && <h4>{title}</h4>}
      <div className="menu_section__container">
        {options.map(({ value, label }) => (
          <div key={label} className={disabled ? 'radio_btn__row disabled' : 'radio_btn__row'}>
            <label>
              <RadioButton
                isChecked={currentSelect === value}
                handleChange={() => select(value)}
                disabled={disabled}
              />
              <span className="radio_btn__label">{label}</span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
}
