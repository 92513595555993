import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { AlertWindow, CreateAccountLoading } from '.';
import { ROUTE } from '../../routes/constants';
import { FormInput, Modal, SubmitButton } from '../../shared/components';
import { ConfirmationWindow, Hint, PasswordInput } from '../login';
import { REGISTER_USER_CONFIRM } from './constants';
import { useRegisterUser, useUserInviteDetails } from './hooks';
import { CreateAccountFormPropTypes, CreateAccountInputs } from './types';
import { CreateAccSchema } from './validations/schema';

export default function CreateAccountForm({
  code,
}: CreateAccountFormPropTypes): React.ReactElement {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const {
    data: { first_name, last_name, phone_number: phoneNumber, department, position, state } = {},
    isLoading,
    errorMsg,
  } = useUserInviteDetails(code);
  const { registerUser, serverError, isLoading: isRegistering, isSuccess } = useRegisterUser();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreateAccountInputs>({
    resolver: yupResolver(CreateAccSchema),
  });

  const history = useHistory();

  useEffect(() => {
    if (state && first_name && last_name) {
      setValue('first_name', first_name);
      setValue('last_name', last_name);
      setValue('phone_number', phoneNumber);
      setValue('department', department || '');
      setValue('position', position || '');
    }

    if (state === 'accptd') {
      history.replace(ROUTE.LOGIN);
    }
  }, [state]);

  useEffect(() => {
    if (isSuccess) {
      setShowConfirmation(true);
    }
  }, [isSuccess]);

  const onSubmit = ({ confirm_password, phone_number = '', ...rest }: CreateAccountInputs) => {
    registerUser({ code, phone_number, ...rest });
  };

  if (isLoading) {
    return <CreateAccountLoading />;
  }

  return (
    <form
      className="create_acc--form"
      name="create account"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      {!!Object.values(errors).find(({ message }) => message?.includes('required')) && (
        <p className="error">
          A required field has been left blank. Please complete all required information in order to
          create your account.
        </p>
      )}
      <h3>Personal Information</h3>
      <div className="row">
        <FormInput
          id="first_name"
          label="First Name"
          placeholder="Click here to enter your first name"
          error={errors.first_name || serverError?.first_name?.[0]}
          register={() => register('first_name')}
          required
        />
        <FormInput
          id="last_name"
          label="Last Name"
          placeholder="Click here to enter your last name"
          error={errors.last_name || serverError?.last_name?.[0]}
          register={() => register('last_name')}
          required
        />
      </div>
      <FormInput
        id="phone_number"
        label="Phone Number"
        type="tel"
        placeholder="Click here to enter your phone number"
        error={errors.phone_number || serverError?.phone_number?.[0]}
        register={() => register('phone_number')}
      />
      <div className="row">
        <FormInput
          id="department"
          label="Department"
          placeholder="Click here to enter your department"
          error={errors.department || serverError?.department?.[0]}
          register={() => register('department')}
          required
        />
        <FormInput
          id="position"
          label="Position"
          placeholder="Click here to enter your position"
          error={errors.position || serverError?.position?.[0]}
          register={() => register('position')}
          required
        />
      </div>
      <div className="divider" />
      <h3>Create a Password</h3>
      <div className="hint_container">
        <div className="inputs">
          <PasswordInput
            id="password"
            label="New Password"
            register={() => register('password')}
            error={errors.password || serverError?.password?.[0]}
          />
          <PasswordInput
            id="confirm_password"
            label="Confirm Password"
            register={() => register('confirm_password')}
            error={errors.confirm_password}
          />
        </div>
        <Hint />
      </div>
      <div className="divider" />
      <SubmitButton name="Create Your Account!" isLoading={isRegistering} />
      {errorMsg && <AlertWindow show message={errorMsg} />}
      <Modal show={showConfirmation} closeModal={() => setShowConfirmation(false)} customClose>
        <ConfirmationWindow
          content={REGISTER_USER_CONFIRM}
          confirm={() => history.replace(ROUTE.LOGIN)}
        />
      </Modal>
    </form>
  );
}
