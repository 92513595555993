import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { SubmitButton } from '../../shared/components';
import Hint from './Hint';
import usePasswordChange from './hooks/usePasswordChange';
import PasswordInput from './PasswordInput';
import { ChangePwdFormPropTypes, ChangePwdInputs } from './types';
import { ChangePwdSchema } from './validations/schema';

export default function ChangePasswordForm({
  showConfirmation,
}: ChangePwdFormPropTypes): React.ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<ChangePwdInputs>({
    resolver: yupResolver(ChangePwdSchema),
  });
  const { changePassword, error, isLoading, isSuccess } = usePasswordChange();

  useEffect(() => {
    if (isSuccess) {
      showConfirmation();
    }
  }, [isSuccess]);

  const onSubmit = ({ oldPassword, newPassword }: ChangePwdInputs) => {
    changePassword({ old_password: oldPassword, new_password: newPassword });
  };

  const isSubmitDisabled = !!Object.values(errors).find(({ message }) => message);

  return (
    <form className="update_pwd__form" onSubmit={handleSubmit(onSubmit)}>
      <PasswordInput
        id="old_password"
        label="Old Password"
        register={() => register('oldPassword')}
        error={errors.oldPassword}
        touched={!!submitCount}
      />
      <div className="hint_container">
        <div className="inputs">
          <PasswordInput
            id="new_password"
            label="New Password"
            register={() => register('newPassword')}
            error={errors.newPassword}
            touched={!!submitCount}
          />
          <PasswordInput
            id="confirm_password"
            label="Confirm New Password"
            register={() => register('confirmPassword')}
            error={errors.confirmPassword}
            touched={!!submitCount}
          />
        </div>
        <Hint />
      </div>
      {error && <p className="server_error">{error}</p>}
      <SubmitButton name="Submit Password" isLoading={isLoading} isDisabled={isSubmitDisabled} />
    </form>
  );
}
