import { ReactComponent as PersistentIcon } from '../../../assets/icons/persistent-product.svg';
import { ReactComponent as UnlinkIcon } from '../../../assets/icons/unlink.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/link.svg';

import { EditDocMenus } from './types';

export const EDIT_DOC_SPACES = [
  {
    icon: PersistentIcon,
    title: EditDocMenus.EDIT,
    label: 'Edit Doc Name',
  },
  {
    icon: UnlinkIcon,
    title: EditDocMenus.UNLINK,
    label: 'Unlink SKUs',
  },
  {
    icon: LinkIcon,
    title: EditDocMenus.LINK,
    label: 'Link New SKUs',
  },
];
