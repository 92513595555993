import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTE } from '../../routes/constants';
import { useFeature } from '../../shared/hooks';
import { DOC_REP_SPACES } from './constants';

export default function Spaces(): React.ReactElement {
  return (
    <>
      <div className="doc_rep__spaces">
        {DOC_REP_SPACES.map(({ icon: Icon, title, action }): JSX.Element | null => {
          const { disabled } = useFeature(title);

          if (disabled) return null;

          return (
            <React.Fragment key={title}>
              <div className="doc_rep__option">
                <Icon width="60px" height="62px" fill="#006298" />
                <p>{title}</p>
                <Link
                  to={`${ROUTE.DOC_REP}?${new URLSearchParams({ type: title })}`}
                  className="manage_btn"
                >
                  {action}
                </Link>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <p>
        Here is a shared storage space where you can upload and store documents related to your
        business with GoExpedi as well as any necessary Product Documents that you might need to
        provide together with your products.
      </p>
    </>
  );
}
