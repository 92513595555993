import { useVendorData } from './hooks';

export default function VendorLogo(): JSX.Element {
  const { data: { logo_url } = {}, isLoading } = useVendorData();

  if (isLoading) {
    return <div className="vendor-logo loading" />;
  }

  return (
    <div className="vendor-logo">
      <img src={logo_url} height="40px" alt="vendor-logo" />
    </div>
  );
}
