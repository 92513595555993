import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { getDashboardConfig } from '../../../api/queries';
import { UseDashboardConfig } from './types';

export default function useDashboardConfig(): UseDashboardConfig {
  const { mutate, data, isLoading, isError } = useMutation((id: string) => getDashboardConfig(id));

  const fetchDashboard = useCallback((id: string) => {
    mutate(id);
  }, []);

  return { fetchDashboard, data: data?.data, isLoading, isError };
}
