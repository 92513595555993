import { formatDateTime, formatPrice } from '../../../shared/utils';
import {
  ANALYTICS_TABLE,
  DESIGN_TABLE,
  DIMENSIONS_TABLE,
} from '../../product-details/additional-details/constants';
import { DETAILS_LIST } from '../../product-details/constants';
import {
  NewProductPropTypes,
  NewPurchaseOrderPropTypes,
  NotificationPropTypes,
  ProductDetailsEditPropTypes,
  ProductRankChangePropTypes,
} from './types';

const PRODUCT_DETAILS_LABELS = [
  ...DETAILS_LIST,
  ...DIMENSIONS_TABLE.BODY,
  ...ANALYTICS_TABLE.BODY,
  ...DESIGN_TABLE.BODY,
].reduce((acc, { id, label }) => {
  return { ...acc, [id]: label };
}, {});

export const TemsConditionsAcceptedNotification = ({
  fullName,
  email,
  acceptanceDate,
}: NotificationPropTypes): React.ReactElement => {
  return (
    <>
      {fullName},{' '}
      <a href={`mailto: ${email}`} target="_blank" rel="noopener noreferrer">
        <b>{email}</b>
      </a>
      , has accepted VIP T&Cs on{' '}
      {formatDateTime(acceptanceDate, { dateStyle: 'medium', timeStyle: 'short' })}
    </>
  );
};

export const ProductDetailsEdit = ({
  author,
  sku,
  fields,
}: ProductDetailsEditPropTypes): React.ReactElement => {
  return (
    <>
      Vendor User: <b>{author ? `${author.first_name} ${author.last_name}` : '[removed user]'}</b>{' '}
      with the email:{' '}
      {author ? (
        <a href={`mailto: ${author.email}`} target="_blank" rel="noopener noreferrer">
          <b>{author.email}</b>
        </a>
      ) : (
        '[removed user]'
      )}
      , has proposed an update to the <b>GoExpedi SKU: {sku}</b>:{' '}
      {fields.map(({ name, old_value, new_value }) => (
        <>
          <br />
          Field:{' '}
          <b>{PRODUCT_DETAILS_LABELS[name as keyof typeof PRODUCT_DETAILS_LABELS] || name}</b> / Old
          Value: <b>{old_value || '-'}</b> / New Value: <b>{new_value || '-'}</b>
        </>
      ))}
      <br />
      <i>
        Note: Thanks for the proposed updates! We have saved your updates and we’ll review and
        publish them once approved.
      </i>
    </>
  );
};

export const NewPurchaseOrder = ({
  order,
  location,
  status,
  price,
}: NewPurchaseOrderPropTypes): React.ReactElement => {
  return (
    <>
      A new Purchase Order <b>{order}</b> appeared in the Order Documents Page with the Location:{' '}
      <b>{location}</b>, Status: <b>{status}</b>, Price: <b>{formatPrice(price)}</b>. We encourage
      you to check the Order Documents Page and view and/or upload any relevant documents for that
      Purchase Order (i.e., COCs, Invoices, MTRs etc.).
    </>
  );
};

export const NewProduct = ({
  name,
  category,
  supplier_sku,
  gxp_sku,
  price,
}: NewProductPropTypes): React.ReactElement => {
  return (
    <>
      A new Product Catalog Item <b>{name}</b>, <b>{category}</b> category, appeared in the Product
      Catalog Page with the SKUs: Supplier: <b>{supplier_sku}</b>, GoExpedi SKU: <b>{gxp_sku}</b>,
      and Price: <b>{formatPrice(price)}</b>.<br />
      We encourage you to check the Product Catalog that portrays the information GoExpedi has at
      hand for that Product Item. If you find any information that is not aligned with your most
      current data, please propose updates on that Page and we will publish them once approved.
    </>
  );
};

export const ProductRankChange = ({
  oldRank,
  newRank,
  name,
  supplier_sku,
  gxp_sku,
  category,
}: ProductRankChangePropTypes): React.ReactElement => (
  <>
    The GXP Rank changed from Priority <b>{oldRank}</b> to Priority <b>{newRank}</b> for item{' '}
    <b>{name}</b>, Supplier SKU: <b>{supplier_sku}</b>, GoExpedi SKU: <b>{gxp_sku}</b>,{' '}
    <b>{category}</b> category.
    <br />
    We encourage you to check the Product Catalog that portrays the information GoExpedi has at hand
    for that Product Item. If you find any information that is not aligned with your most current
    data, please propose updates on that Page and we will publish them once approved.
  </>
);
