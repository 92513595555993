import { useEffect, useState } from 'react';
import { Filters } from 'react-table';

import { Product } from '../../../api/schemas';
import { UseFilterSkuBy } from './types';

export default function useFilterSkuBy(): UseFilterSkuBy {
  const [filters, setFilters] = useState<Filters<Product>>(
    JSON.parse(localStorage.getItem('filterSkuByCategory') || '[]')
  );

  useEffect(() => {
    localStorage.setItem(
      'filterSkuByCategory',
      JSON.stringify(filters.filter(({ value }) => !!value?.length))
    );
  }, [filters]);

  return { filters, setFilters };
}
