import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { User } from '../../api/schemas';
import { LoginFooterBtns, LoginForm } from '../../components/login';
import TermsConditions from '../../components/terms_n_conditions/TermsConditions';
import { ROUTE } from '../../routes/constants';
import { Modal } from '../../shared/components';
import { useAuth, useUserData } from '../../shared/hooks';
import { useLoginFromTemporaryToken } from './hooks';

export default function Login(): React.ReactElement {
  const [showTnC, setShowTnC] = useState(false);

  const { forceLogin, isLoading: isForceLoginingIn, errorMsg } = useLoginFromTemporaryToken();
  const { data: userData, isLoading: isLoadingUserData, isSuccess } = useUserData();

  const { search, state } = useLocation<{ from: string }>();
  const history = useHistory();
  const { token, signout } = useAuth();

  useEffect(() => {
    const tokenForceLogin = new URLSearchParams(search).get('token');
    if (tokenForceLogin) {
      signout();
      forceLogin({ token: tokenForceLogin });
    }
  }, []);

  useEffect(() => {
    if (userData && token) {
      // disabling rule to hoist the `handleLoginFlow()` func and keep all useEffects together
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleLoginFlow(userData);
    }
  }, [isSuccess, token]);

  const redirectToPortal = () => {
    const { from } = state || { from: { pathname: ROUTE.HOMEPAGE } };
    history.replace(from);
  };

  function handleLoginFlow({ force_logged_in, accepted_latest_terms_and_conditions }: User): void {
    if (force_logged_in) {
      return redirectToPortal();
    }

    if (!accepted_latest_terms_and_conditions) {
      return setShowTnC(true);
    }

    return redirectToPortal();
  }

  const handleAcceptedTerms = () => {
    setShowTnC(false);

    redirectToPortal();
  };

  const handleDeclineTerms = () => {
    signout(() => setShowTnC(false));
  };

  return (
    <div className="login">
      <div className="login-header">
        <div className="login_header-logo" />
      </div>
      <section className="login-container">
        <div className="login-container-form">
          <div className="user-login">User login</div>
          <div className="access">Expedi.Access</div>
          <LoginForm
            isLoading={isForceLoginingIn || isLoadingUserData}
            forceLoginErrorMsg={errorMsg}
          />
          <LoginFooterBtns />
        </div>
        <div className="login-container-image" />
      </section>
      <Modal show={showTnC} closeModal={handleDeclineTerms} header="Terms & Conditions">
        <TermsConditions acceptedTerms={handleAcceptedTerms} />
      </Modal>
    </div>
  );
}
