import { AxiosResponse } from 'axios';

import { QueryParams } from '../../shared/types';
import API from '../../utils/api';
import {
  AdditionalCompanyInfo,
  Company,
  FeatureFlag,
  PaginatedPendingUserInvite,
  PaginatedUserDetails,
  TermsAndConditions,
  User,
  UserInviteDetails,
  UserRole,
} from '../schemas/accounts';

export const getAdditionalCompanyDetails = (): Promise<AxiosResponse<AdditionalCompanyInfo>> =>
  API.get<AdditionalCompanyInfo>('/api/accounts/additional-company-details/');

export const getCompanyData = (): Promise<AxiosResponse<Company>> =>
  API.get<Company>('/api/accounts/company-details/');

export const getFeatureFlags = (): Promise<AxiosResponse<FeatureFlag[]>> =>
  API.get<FeatureFlag[]>('/api/accounts/feature-flags/');

export const getUserData = (): Promise<AxiosResponse<User>> =>
  API.get<User>('/api/accounts/user-details/');

export const getTermsConditions = (): Promise<AxiosResponse<TermsAndConditions>> =>
  API.get<TermsAndConditions>('/api/accounts/terms-and-conditions/');

export const getUserInviteDetails = (code: string): Promise<AxiosResponse<UserInviteDetails>> =>
  API.get(`/api/accounts/user-invite/${code}`);

export const getActiveUsers = (params: QueryParams): Promise<AxiosResponse<PaginatedUserDetails>> =>
  API.get('/api/accounts/active-users', { params });

export const getPendingInvitations = (
  params: QueryParams
): Promise<AxiosResponse<PaginatedPendingUserInvite>> =>
  API.get('/api/accounts/user-invite', { params });

export const getUserRoles = (): Promise<AxiosResponse<UserRole[]>> =>
  API.get('/api/accounts/user-roles');

export const getDeactivatedUsers = (
  params: QueryParams
): Promise<AxiosResponse<PaginatedUserDetails>> =>
  API.get('/api/accounts/deactivated-users/', { params });
