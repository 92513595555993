import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
import { PERMISSIONS } from '../../../shared/constants';

export const ACTION_BTNS = [
  {
    title: 'Request To Add New Document Label',
    action: 'mailto',
    address: 'vendor.management@goexpedi.com',
    icon: FileIcon,
    permission: PERMISSIONS.EDIT_ORDER_DOCS,
  },
];
