import { useRecoilState } from 'recoil';

import { documentToEdit, productSkusToLink } from '../../../../atoms';

type UseResetLinkForm = {
  resetForm: () => void;
};

/**
 * Reset picked SKUs to link and update document's linked products list
 */
export default function useResetLinkForm(): UseResetLinkForm {
  const [fileData, setFileData] = useRecoilState(documentToEdit);
  const [selectedProducts, setSelectedProducts] = useRecoilState(productSkusToLink);

  const updateFileData = () => {
    const selectedSkus = Object.values(selectedProducts).map(({ name, supplier_sku, gxp_sku }) => ({
      name,
      supplier_sku,
      gxp_sku,
    }));
    const newLinkedProducts = [...fileData.products, ...selectedSkus];

    setFileData({ ...fileData, products: newLinkedProducts });
  };

  const resetForm = () => {
    updateFileData();
    setSelectedProducts({});
  };

  return { resetForm };
}
