import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import { useNotificationsCount } from './hooks';

export default function NotificationsIcon({
  fill,
  width,
  height,
}: React.SVGProps<SVGSVGElement>): React.ReactElement {
  const { data: { count } = {} } = useNotificationsCount();

  return (
    <div className="bell_icon__container">
      <BellIcon fill={fill} width={width} height={height} />
      {!!count && <div className="qty__container">{count > 99 ? '99+' : count}</div>}
    </div>
  );
}
