import { useDismissLatestUpdateMsg, useLatestUpdateMessage } from './hooks';

export default function LatestUpdateMessage(): React.ReactElement | null {
  const { data: { text } = {} } = useLatestUpdateMessage();
  const { dismiss } = useDismissLatestUpdateMsg();

  if (!text) return null;

  return (
    <div className="update_container">
      <span className="vip_update">VIP Update: </span>
      {text}{' '}
      <button className="dismiss" type="button" onClick={dismiss}>
        Dismiss
      </button>
    </div>
  );
}
