import { useEffect, useState } from 'react';
import { Cell, Column } from 'react-table';

import { EditDoc, LabelCell, PreviewDoc, ViewAllCell } from '..';
import { PERMISSIONS } from '../../../../shared/constants';
import usePermission from '../../../../shared/hooks/usePermission';
import { formatDateTime } from '../../../../shared/utils';
import { formatBytes } from '../../utils/helpers';
import { PersistentProdDocsTable } from '../types';
import { ProductDocsColumns, SkuType } from './types';

export default function useProductDocsColumns(): ProductDocsColumns {
  const [columns, setColumns] = useState<Column<PersistentProdDocsTable>[]>([
    {
      Header: 'Document Name',
      accessor: 'name',
      className: 'doc_name_container',
      Cell: ({ row: { original } }: Cell<PersistentProdDocsTable>) => (
        <PreviewDoc name={original.name} url={original.url} />
      ),
    },
    {
      Header: 'SKU',
      accessor: 'supplier_sku',
      headerClassName: 'sku_container',
      className: 'sku_container',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<PersistentProdDocsTable>) => (
        <ViewAllCell skuType={SkuType.SKU} name={original.name} products={original.products} />
      ),
    },
    {
      Header: 'GXP SKU',
      accessor: 'gxp_sku',
      headerClassName: 'sku_container',
      className: 'sku_container',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<PersistentProdDocsTable>) => (
        <ViewAllCell skuType={SkuType.GXP_SKU} name={original.name} products={original.products} />
      ),
    },
    {
      Header: 'Label',
      accessor: 'label',
      className: 'doc_label_container',
      Cell: LabelCell,
    },
    {
      Header: 'Date',
      accessor: 'modified_date',
      Cell: ({ value }: Cell<PersistentProdDocsTable>) => formatDateTime(value),
    },
    {
      Header: 'Size',
      accessor: 'size',
      Cell: ({ value }: Cell<PersistentProdDocsTable>) => formatBytes(value, 0),
    },
    {
      Header: 'Type',
      accessor: 'extension',
      Cell: ({ value }: Cell<PersistentProdDocsTable>) => `.${value.toUpperCase()}`,
    },
  ]);

  const { isAllowed } = usePermission(PERMISSIONS.EDIT_DOCS);

  useEffect(() => {
    if (columns.length === 7 && isAllowed) {
      setColumns(prevColumns => [
        ...prevColumns,
        {
          Header: 'Edit Doc Info',
          accessor: 'actions',
          disableSortBy: true,
          Cell: ({ row }: Cell<PersistentProdDocsTable>) => <EditDoc row={row} />,
        },
      ]);
    }
  }, [isAllowed]);

  return { columns };
}
