import { useEffect, useState } from 'react';

type TooltipPropType = {
  children: React.ReactElement;
  content: string;
  delay?: number;
  direction?: string;
};

export default function Tooltip({
  content,
  delay = 400,
  direction = 'top',
  children,
}: TooltipPropType): React.ReactElement {
  let timeout: ReturnType<typeof setTimeout>;
  const [show, setShow] = useState(false);

  useEffect(() => {
    return () => {
      // cleanup on unmount
      clearInterval(timeout);
    };
  }, []);

  const showTip = (): void => {
    timeout = setTimeout(() => {
      setShow(true);
    }, delay);
  };

  const hideTip = (): void => {
    clearInterval(timeout);
    setShow(false);
  };

  return (
    <div className="tooltip-wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {show && <div className={`tooltip-tip ${direction}`}>{content}</div>}
    </div>
  );
}
