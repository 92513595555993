import { MutableRefObject, useRef, useState } from 'react';
import { FilterValue } from 'react-table';

import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { useOutsideClick } from '../hooks';
import { FilterOption } from '../types';
import Checkbox from './Checkbox';

type FilterByPropTypes = {
  title: string;
  filterOptions?: FilterOption[];
  filterValue: FilterValue;
  setFilter: (value: FilterValue) => void;
  disabled?: boolean;
};

export default function FilterByMenu({
  title,
  filterOptions,
  filterValue,
  setFilter,
  disabled = false,
}: FilterByPropTypes): React.ReactElement {
  const [show, setShow] = useState(false);

  const ref = useRef<HTMLDivElement>();

  useOutsideClick(ref, () => setShow(false));

  const toggleCategory = (categoryId: string) => {
    if (!filterValue) {
      return setFilter([categoryId]);
    }
    if (filterValue.includes(categoryId)) {
      const restCategories = filterValue.filter((id: string) => id !== categoryId);
      return setFilter(restCategories);
    }

    return setFilter([...filterValue, categoryId]);
  };

  const handleClearAll = () => {
    setFilter([]);
  };

  const isClearAllFiltersDisabled = !filterValue || !filterValue.length;
  const activeFilters = (filterValue || []).length;

  return (
    <div ref={ref as MutableRefObject<HTMLDivElement>} className="filter_by__container">
      <button
        type="button"
        className={`filter_toggler ${show ? 'open' : ''} ${
          activeFilters && !disabled ? 'active' : ''
        }`}
        name="Category Filter"
        title="Toggle Filter By"
        onClick={() => !disabled && setShow(!show)}
        disabled={disabled}
      >
        {title}
        <FilterIcon className={disabled ? 'disabled' : ''} />
        {!!activeFilters && <div className="active_filters">{activeFilters}</div>}
      </button>
      {show && (
        <div className="filter_menu category_menu">
          <h5>Filter By:</h5>
          <button
            className="clear_all"
            type="button"
            name="Clear All Filters"
            disabled={isClearAllFiltersDisabled}
            onClick={handleClearAll}
          >
            Clear All Filters
          </button>
          <div className="divider" />
          <div className="filter_categories">
            {filterOptions &&
              filterOptions.map(({ id, label }) => (
                <div key={id} className="category_row">
                  <label className="category_checkbox">
                    <Checkbox
                      isChecked={filterValue?.includes(id)}
                      handleChange={() => toggleCategory(id)}
                    />
                    <span className="category_name">{label}</span>
                  </label>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
