import { useRecoilValue } from 'recoil';

import { changedProductDetails, activeProductSku, isEditMode } from '../../../atoms';
import { PERMISSIONS } from '../../../shared/constants';
import { usePermission } from '../../../shared/hooks';
import { DetailsField } from '../edit-details';
import { useProductDetails } from '../hooks';
import { ANALYTICS_TABLE, DESIGN_TABLE, DIMENSIONS_TABLE } from './constants';
import { ProductDetailsField } from '../types';

export default function ProductAdditionalDetails(): React.ReactElement {
  const gxp_sku = useRecoilValue(activeProductSku);
  const isEdit = useRecoilValue(isEditMode);
  const editedFields = useRecoilValue(changedProductDetails);

  const { data } = useProductDetails(gxp_sku);
  const { isAllowed } = usePermission(PERMISSIONS.EDIT_PRODUCTS);

  const renderTable = (head: string[], body: ProductDetailsField[]): JSX.Element => (
    <table className="prod_catalog__table">
      <thead>
        <tr>
          {head.map(headerCell => (
            <th key={headerCell}>{headerCell}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map(({ id, label, nonEditable }) => (
          <tr key={id}>
            <td>{label}</td>
            <td>
              <DetailsField
                field={id}
                value={data?.[id]}
                isAllowedToEdit={!nonEditable && isAllowed}
                isEdit={isEdit || !!editedFields[id]}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  return (
    <div className="details__container">
      {renderTable(DIMENSIONS_TABLE.HEAD, DIMENSIONS_TABLE.BODY)}
      {renderTable(ANALYTICS_TABLE.HEAD, ANALYTICS_TABLE.BODY)}
      {renderTable(DESIGN_TABLE.HEAD, DESIGN_TABLE.BODY)}
    </div>
  );
}
