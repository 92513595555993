import React, { useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { Footer } from './components/footer';
import { ResetPassword } from './components/login';
import { Navigation } from './components/navigation/Navigation';

import { Login, WelcomePage } from './pages';

import routes from './routes';
import { ROUTE } from './routes/constants';

import { ProtectedRoute } from './shared/components';
import { AuthProvider, FeatureFlagsProvider } from './shared/hooks';

export default function App(): React.ReactElement {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(true);

  const toggleOpenState = (): void => {
    setIsNavOpen(!isNavOpen);
  };

  const getPrivateRoutes = () =>
    routes.map(({ name, path, component: Component }): JSX.Element | null => {
      return (
        <ProtectedRoute key={name} path={path}>
          <main className={isNavOpen ? 'main-pannel nav-open' : 'main-pannel'}>
            <Component />
            <Footer />
          </main>
        </ProtectedRoute>
      );
    });

  return (
    <RecoilRoot>
      <AuthProvider>
        <FeatureFlagsProvider>
          <BrowserRouter>
            <Navigation isNavOpen={isNavOpen} toggleOpenState={toggleOpenState} />

            <Switch>
              <Route path={ROUTE.LOGIN}>
                <Login />
              </Route>
              <Route path={ROUTE.RESET_PWD}>
                <ResetPassword />
              </Route>
              <Route path={ROUTE.WELCOME}>
                <WelcomePage />
              </Route>
              <Route exact path="/">
                <Redirect to={ROUTE.HOMEPAGE} />
              </Route>
              {getPrivateRoutes()}
            </Switch>
          </BrowserRouter>
        </FeatureFlagsProvider>
      </AuthProvider>
    </RecoilRoot>
  );
}
