import { AdditionalCompanyInfo } from '../../../api/schemas';

export const ACCOUNT_INFO: { id: keyof AdditionalCompanyInfo; label: string }[] = [
  {
    id: 'address',
    label: 'Address',
  },
  { id: 'primary_contact', label: 'Primary Contact' },
  {
    id: 'secondary_contact',
    label: 'Secondary Contact',
  },
  { id: 'payment_terms', label: 'Payment Terms' },
  { id: 'delivery_terms', label: 'Delivery Terms' },
];
