import { useState } from 'react';

import { Modal, WarningWindow } from '../../../shared/components';
import { EditUserRole } from '../edit-role';
import { useDeactivateUser } from '../hooks';
import { DEACTIVATE_USER_CONTENT } from './constants';
import { ActiveUsersActionBtnsPropTypes } from './types';

export default function ActiveUsersActionBtns({
  id,
  role,
  name,
}: ActiveUsersActionBtnsPropTypes): React.ReactElement {
  const [showEditRole, setShowEditRole] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);

  const { deactivateUser } = useDeactivateUser();

  const handleDeactivate = () => {
    deactivateUser(id);
    setShowDeactivate(false);
  };

  return (
    <>
      <div className="active_users_actions_container">
        <button type="button" name="Edit Role" onClick={() => setShowEditRole(true)}>
          Edit Role
        </button>
        <button type="button" name="Deactivate User" onClick={() => setShowDeactivate(true)}>
          Deactivate User
        </button>
      </div>
      <Modal show={showEditRole} header="Edit Users Role" closeModal={() => setShowEditRole(false)}>
        <EditUserRole id={id} username={name} role={role} />
      </Modal>
      <Modal show={showDeactivate} closeModal={() => setShowDeactivate(false)}>
        <WarningWindow content={DEACTIVATE_USER_CONTENT} confirm={handleDeactivate} />
      </Modal>
    </>
  );
}
