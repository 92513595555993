import { useEffect, useState } from 'react';
import { Cell, Column } from 'react-table';

import { PERMISSIONS } from '../../../../shared/constants';
import usePermission from '../../../../shared/hooks/usePermission';
import { LabelCell, PreviewDoc } from '../../persistent-product';
import { formatBytes } from '../../utils/helpers';
import DaysRemaining from '../DaysRemaining';
import DeleteCell from '../DeleteCell';
import { ArchiveDocsTable } from './types';

type Locations = 'Transaction' | 'Persistent';

type ArchiveDocsColumns = {
  columns: Column<ArchiveDocsTable>[];
};

type UseArchiveDocsColumnsProps = {
  handleDelete: (ids: string[]) => void;
};

const LocationMap: Map<Locations, string> = new Map<Locations, string>([
  ['Transaction', 'Order Document'],
  ['Persistent', 'Product Document'],
]);

export default function useArchiveDocsColumns({
  handleDelete,
}: UseArchiveDocsColumnsProps): ArchiveDocsColumns {
  const [columns, setColumns] = useState<Column<ArchiveDocsTable>[]>([
    {
      Header: 'Document Name',
      accessor: 'name',
      className: 'doc_name_container',
      Cell: ({ row: { original } }: Cell<ArchiveDocsTable>) => (
        <PreviewDoc name={original.name} url={original.url} />
      ),
    },
    {
      Header: 'Label',
      accessor: 'label',
      className: 'doc_label_container',
      Cell: LabelCell,
    },
    {
      Header: 'Original Location',
      accessor: 'group',
      Cell: ({ value }: Cell<ArchiveDocsTable>) => LocationMap.get(value),
    },
    {
      Header: 'Days Remaining',
      accessor: 'archived_date',
      Cell: DaysRemaining,
    },
    {
      Header: 'Size',
      accessor: 'size',
      Cell: ({ value }: Cell<ArchiveDocsTable>) => formatBytes(value, 0),
    },
    {
      Header: 'Type',
      accessor: 'extension',
      Cell: ({ value }: Cell<ArchiveDocsTable>) => `.${value.toUpperCase()}`,
    },
  ]);

  const { isAllowed } = usePermission(PERMISSIONS.SOFT_DELETE_DOCS);

  useEffect(() => {
    if (columns.length === 6 && isAllowed) {
      setColumns(prevColumns => [
        ...prevColumns,
        {
          Header: 'Delete',
          accessor: 'actions',
          className: 'delete',
          disableSortBy: true,
          Cell({
            row: {
              original: { id },
            },
          }: Cell<ArchiveDocsTable>) {
            return <DeleteCell id={id} deleteFile={handleDelete} />;
          },
        },
      ]);
    }
  }, [isAllowed]);

  return { columns };
}
