import React, { useCallback } from 'react';
import { Column, Row } from 'react-table';

import { AccountInfo, DocName, RowExpanding, SubRows } from '.';
import { CorporateAgreement } from '../../../api/schemas';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/bold-download.svg';
import Restricted from '../../../shared/components/Restricted';
import { PERMISSIONS } from '../../../shared/constants';
import { useFileDownload } from '../../../shared/hooks';
import Table from '../../table/Table';
import useCorpAgreements from './hooks/useCorpAgreements';
import { getFileIdsToDownload } from './utils/helpers';

const columns: Column<CorporateAgreement>[] = [
  {
    Header: () => null,
    headerClassName: 'expander_header',
    id: 'expander',
    className: 'expander_container',
    enableRowSpan: true,
    Cell: RowExpanding,
  },
  {
    Header: 'Document Types',
    accessor: 'document_type',
    className: 'doc_type_container',
    Cell: DocName,
  },
];

export default function CorpAgreement(): React.ReactElement {
  const { data, isLoading, isFetching, fetch } = useCorpAgreements();
  const { downloadMultiple, isDownloading } = useFileDownload();

  const hasSubrows = (row: Row<CorporateAgreement>): boolean => !!row.original.files.length;

  const handleDownloadAll = () => {
    const ids = getFileIdsToDownload(data);
    downloadMultiple('/api/corporate-agreements/download/', ids);
  };

  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRows row={row} rowProps={rowProps} visibleColumns={visibleColumns} />
    ),
    []
  );

  return (
    <div className="doc_rep_content">
      <AccountInfo header="Account Information" />
      <h3>Corporate Agreements</h3>
      <div className="header_info">
        <p>
          Please be sure to check you provided GoExpedi the following signed corporate agreement
          documents. You can follow the progress status updates here:
        </p>
        {!!data?.length && (
          <Restricted to={PERMISSIONS.VIEW_CORP_AGREEMENTS_FILES}>
            <button
              type="button"
              name="Download All"
              className="download_all"
              onClick={handleDownloadAll}
            >
              Download All Documents
              <span id="icon">
                {isDownloading ? <div className="loader" /> : <DownloadIcon stroke="#FFF" />}
              </span>
            </button>
          </Restricted>
        )}
      </div>
      <div className="table-wrapper">
        <Table<CorporateAgreement>
          columns={columns}
          data={data || []}
          isLoading={isLoading}
          isFetching={isFetching}
          placeholderRows={4}
          fetchData={fetch}
          hasSubrows={hasSubrows}
          renderRowSubComponent={renderRowSubComponent}
        />
      </div>
    </div>
  );
}
