import { useState } from 'react';

import { Modal } from '../../../shared/components';
import Table from '../../table/Table';
import { FileData } from '../types';
import EditOrderDoc from './file-edit/EditOrderDoc';
import { usePurchaseDocs, usePurchaseSubrowColumns } from './hooks';
import { PurchaseDocsTableData } from './hooks/types';
import { OrderDocsPropTypes } from './types';

export default function AsyncOrderDocsList({ orderId }: OrderDocsPropTypes): React.ReactElement {
  const [editFile, setEditFile] = useState({} as FileData);

  const { data, isLoading, fetch, refetch } = usePurchaseDocs();
  const { columns } = usePurchaseSubrowColumns({
    handleEdit: setEditFile,
  });

  const handleFetchData = () => {
    fetch(orderId);
  };

  const handleCloseEdit = () => {
    setEditFile({} as FileData);
    refetch();
  };

  return (
    <>
      <div className="sub-row mid_size">
        <Table<PurchaseDocsTableData>
          data={data?.documents || []}
          columns={columns}
          fetchData={handleFetchData}
          isLoading={isLoading}
          placeholderRows={1}
        />
      </div>
      <Modal
        show={!!editFile.id}
        header="Edit Document Details:"
        subheader={`Document: ${editFile.name}`}
        closeModal={handleCloseEdit}
      >
        <EditOrderDoc
          orderId={orderId}
          fileId={editFile.id}
          fileName={editFile.name}
          fileLabel={editFile.label}
        />
      </Modal>
    </>
  );
}
