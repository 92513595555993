import { KeyboardEvent } from 'react';

type RadioPropTypes = {
  isChecked: boolean;
  disabled?: boolean;
  handleChange: () => void;
};

export default function RadioButton({
  isChecked,
  disabled,
  handleChange,
}: RadioPropTypes): JSX.Element {
  const toggleCheckbox = () => {
    handleChange();
  };

  const keyPressHandler = ({ key }: KeyboardEvent<HTMLSpanElement>) => {
    if (key === 'Enter') {
      toggleCheckbox();
    }
  };

  return (
    <span className="radio">
      <input type="radio" checked={isChecked} onChange={toggleCheckbox} disabled={disabled} />
      <span role="radio" tabIndex={0} aria-checked={isChecked} onKeyPress={keyPressHandler}>
        {' '}
      </span>
    </span>
  );
}
