import { useState } from 'react';
import { useEditProdDoc } from '../../document-edit/hooks';

import ProductDocLabelSelect from '../../ProductDocLabelSelect';

import { SelectFileLabelStepPropTypes } from './types';

export default function SelectFileLabelStep({
  fileName,
  fileId,
  isLoading,
}: SelectFileLabelStepPropTypes): React.ReactElement {
  const [fileLabel, setFileLabel] = useState<number | null>(null);

  const { editProdDoc } = useEditProdDoc();

  const handleLabelSelect = (labelId: number) => {
    setFileLabel(labelId);
    if (fileId) {
      editProdDoc(fileId, { name: fileName, label_id: labelId });
    }
  };

  return (
    <div className="step__content">
      <ProductDocLabelSelect
        placeholder="File Label"
        fileLabelId={fileLabel}
        isDisabled={!fileId}
        handleLabelSelect={handleLabelSelect}
      />
      <div className="uploaded_file">
        {isLoading && <div className="loader" />}
        {fileId && <p>Uploaded File: {fileName}</p>}
      </div>
    </div>
  );
}
