import { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-table';
import { useRecoilState } from 'recoil';
import { isUploadingFilesToOrder } from '../../../atoms';

import Modal from '../../../shared/components/Modal';
import { useQueryParams } from '../../../shared/hooks';
import { Search, Table } from '../../table';
import ActionButtons from '../ActionButtons';
import AsyncOrderDocsList from './AsyncOrderDocsList';
import { ACTION_BTNS } from './constants';
import UploadFiles from './file-upload/UploadFiles';
import { useOrderDocsColumns, useOrderDocuments, useOrderPageOffset } from './hooks';
import OrderDocsTableLoading from './OrderDocsTableLoading';
import { OrderDocumentsTableData } from './types';

export default function OrderDocuments(): React.ReactElement {
  const queryParams = useQueryParams();
  const [visitedPO] = useState(queryParams.get('purchase_order'));
  const [search, setSearch] = useState('');

  const [orderId, setOrderId] = useRecoilState(isUploadingFilesToOrder);

  const {
    data: { orders, total_items, total_pages } = {},
    isLoading,
    isFetching,
    fetch,
    refetch,
  } = useOrderDocuments();
  const { columns } = useOrderDocsColumns();
  const { getOrderPosition, data: { page } = {} } = useOrderPageOffset();

  useEffect(() => {
    if (visitedPO) {
      getOrderPosition(visitedPO);
    }
  }, [visitedPO]);

  const hasSubrows = (row: Row<OrderDocumentsTableData>): boolean => row.original.has_documents;

  const renderRowSubComponent = useCallback(
    ({ row }) => <AsyncOrderDocsList orderId={row.original.purchase_order} />,
    []
  );

  const handleCloseUploadModal = () => {
    refetch();
    setOrderId('');
  };

  return (
    <div className="doc_rep_content">
      <h3>Order Documents</h3>
      <p>
        Accepted document types for upload: PDF, Excel, PNG, JPG, EML. Download or archive multiple
        and single files by clicking the associated buttons in the actions column.
      </p>
      <Search
        placeholder="Search by Purchase Order #"
        search={setSearch}
        isSearching={!!search && isFetching}
        showResults={!!search && (!isFetching || total_items === 0)}
        itemCount={total_items}
      />
      <ActionButtons buttons={ACTION_BTNS} selectedRows={{}} actionHandlers={{}} />
      <div className="table-wrapper">
        {visitedPO && page === undefined ? (
          <OrderDocsTableLoading columns={columns} />
        ) : (
          <Table<OrderDocumentsTableData>
            columns={columns}
            data={orders || []}
            isLoading={isLoading}
            isFetching={isFetching}
            search={search}
            fetchData={fetch}
            hasSubrows={hasSubrows}
            renderRowSubComponent={renderRowSubComponent}
            pageCount={total_pages}
            totalItemsCount={total_items}
            initialState={{ pageIndex: page || 0, globalFilter: '' }} // set globalFilter's initial value that matches search
          />
        )}
      </div>
      <Modal show={!!orderId} closeModal={handleCloseUploadModal} customClose>
        <UploadFiles orderId={orderId} />
      </Modal>
    </div>
  );
}
