import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getArchivedDocs } from '../../../../api/queries/archive';
import { ArchivedDocument, ArchivedDocumentsPaginated } from '../../../../api/schemas/archive';
import { AsyncQuery, QueryParamsFilter } from '../../../../shared/types';
import ServerStateKeys from '../../../../utils/server-state-keys';

type UseArchivedDocs = AsyncQuery & {
  data?: ArchivedDocumentsPaginated;
  fetch: (params?: QueryParamsFilter<ArchivedDocument>) => void;
  refetch: () => void;
};

export default function useArchiveDocs(): UseArchivedDocs {
  const [params, setParams] = useState({} as QueryParamsFilter<ArchivedDocument>);

  const { data, isLoading, isFetching, refetch } = useQuery(
    [ServerStateKeys.ArchiveDocuments, params],
    () => {
      // re-map 'search' param to meet BE's expected param name
      const { search, ...restParams } = params;
      return getArchivedDocs({ ...restParams, name: search });
    },
    { keepPreviousData: true }
  );

  const fetch = useCallback((parameters?: QueryParamsFilter<ArchivedDocument>) => {
    if (parameters) {
      setParams(parameters);
    }
  }, []);

  return { data: data?.data, isLoading, isFetching, fetch, refetch };
}
