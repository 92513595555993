import { useState } from 'react';
import { Cell, Column } from 'react-table';

import { formatDateTime, formatPrice } from '../../../shared/utils';
import OrderDocsActionButtons from '../../document-repository/order-docs/OrderDocsActionButtons';
import PurchaseName from '../product-purchase-orders/PurchaseName';
import { ProductPurchasesTableData } from '../types';

type UseProductPurchasesColumns = {
  columns: Column<ProductPurchasesTableData>[];
};

export default function useProductPurchasesColumns(): UseProductPurchasesColumns {
  const [columns] = useState<Column<ProductPurchasesTableData>[]>([
    {
      Header: 'Purchase Order #',
      accessor: 'purchase_order',
      className: 'doc_type_container',
      Cell: ({
        row: { original, isExpanded, getToggleRowExpandedProps },
      }: Cell<ProductPurchasesTableData>) => (
        <PurchaseName
          purchase_order={original.purchase_order}
          has_documents={original.has_documents}
          isExpanded={isExpanded}
          getToggleRowExpandedProps={getToggleRowExpandedProps}
        />
      ),
    },
    {
      Header: 'Due Date',
      accessor: 'due_date',
      Cell: ({ value }: Cell<ProductPurchasesTableData>) => formatDateTime(value),
    },

    {
      Header: 'Order Date',
      accessor: 'order_date',
      className: 'order_date_container',
      Cell: ({ value }: Cell<ProductPurchasesTableData>) =>
        formatDateTime(value, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
        }).split(','),
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Unit Measure',
      accessor: 'units',
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: ({ value }: Cell<ProductPurchasesTableData>) => formatPrice(value),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      className: 'order_docs_actions',
      disableSortBy: true,
      Cell: ({ row: { original } }: Cell<ProductPurchasesTableData>) => (
        <OrderDocsActionButtons
          has_documents={original.has_documents}
          orderId={original.purchase_order}
        />
      ),
    },
  ]);

  return { columns };
}
