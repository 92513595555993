import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { Product } from '../../../../api/schemas';
import { documentToEdit, productSkusToLink } from '../../../../atoms';

type UseProductListToLink = {
  productsToLink: Product[];
};

/**
 * @returns Memoized list of products not linked to a document
 */
export default function useProductListToLink(products: Product[]): UseProductListToLink {
  const selectedProdsToLink = useRecoilValue(productSkusToLink);
  const file = useRecoilValue(documentToEdit);

  const productsToLink = useMemo(
    () =>
      products.filter(
        ({ gxp_sku }) =>
          !selectedProdsToLink[gxp_sku] &&
          !file.products?.find(({ gxp_sku: linkedSku }) => linkedSku === gxp_sku)
      ),
    [products, file.products, selectedProdsToLink]
  );

  return { productsToLink };
}
