import { useState } from 'react';

import { Modal, WarningWindow } from '../../../shared/components';
import { useResendUserInvite } from '../hooks';
import useCancelUserInvite from '../hooks/useCanceUserInvite';
import { PendingInvitesActionBtnsPropTypes } from './types';

const generateConfirmationContent = (action: 'RESEND' | 'CANCEL') => ({
  main: (
    <>
      Are you sure you want to <span className="highlighted">{action}</span> this invitation?
    </>
  ),
  cancel: 'No, Return To Acct Mgmt',
  confirm: (
    <>
      Yes, <span className="highlighted">{action}</span> this invitation
    </>
  ),
});

export default function PendingInvitesActionBtns({
  id,
}: PendingInvitesActionBtnsPropTypes): React.ReactElement {
  const [showResend, setShowResend] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

  const { resend } = useResendUserInvite();
  const { cancelInvite } = useCancelUserInvite();

  const handleResend = () => {
    resend(id);
    setShowResend(false);
  };

  const handleCancel = () => {
    cancelInvite(id);
    setShowCancel(false);
  };

  return (
    <>
      <div className="active_users_actions_container">
        <button type="button" name="Resend Invitation" onClick={() => setShowResend(true)}>
          Resend Invitation
        </button>
        <button type="button" name="Cancel Invitation" onClick={() => setShowCancel(true)}>
          Cancel Invitation
        </button>
      </div>
      <Modal show={showResend} closeModal={() => setShowResend(false)}>
        <WarningWindow content={generateConfirmationContent('RESEND')} confirm={handleResend} />
      </Modal>
      <Modal show={showCancel} closeModal={() => setShowCancel(false)}>
        <WarningWindow content={generateConfirmationContent('CANCEL')} confirm={handleCancel} />
      </Modal>
    </>
  );
}
