import { AxiosResponse } from 'axios';

import API from '../../utils/api';
import { Dashboard, DomoData } from '../schemas';

export const getDomoDashboards = (): Promise<AxiosResponse<Dashboard[]>> =>
  API.get('/domo/dashboards/');

export const getDashboardConfig = (id: string): Promise<AxiosResponse<DomoData>> =>
  API.post(`/domo/dashboard/${id}`);
