import { MutableRefObject, useRef, useState } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-right.svg';
import { useOutsideClick } from '../hooks';

type TableSettingsButtonPropTypes = {
  name: string;
  children: React.ReactElement;
  disabled?: boolean;
};

export default function Dropdown({
  name,
  disabled,
  children,
}: TableSettingsButtonPropTypes): React.ReactElement {
  const [show, setShow] = useState(false);

  const referenceRef = useRef<HTMLDivElement>();

  useOutsideClick(referenceRef, () => setShow(false));

  const toggleMenu = () => setShow(!show);

  return (
    <div className="dropdown_btn" ref={referenceRef as MutableRefObject<HTMLDivElement>}>
      <button name={name} type="button" onClick={toggleMenu} disabled={disabled}>
        <span className="name">{name}</span>
        <ArrowIcon width="15px" height="16px" fill="#FFFFFF" />
      </button>
      {show && <div className="menu__container">{children}</div>}
    </div>
  );
}
