import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Product } from '../../../../api/schemas';
import { productSkusToLink } from '../../../../atoms';
import { useAddProductRecordsColumns } from '../../persistent-product/file-upload/hooks';
import SelectedProductsTable from '../unlink-products/SelectedProductsTable';

export default function SelectedProductsToLink(): React.ReactElement {
  const [prodsToUnlink, setProdsToUnlink] = useRecoilState(productSkusToLink);

  const { columns } = useAddProductRecordsColumns({ isLoaded: true, isTitleLink: true });

  const handleUnselect = useCallback((selectedRows: Record<string, Product>) => {
    setProdsToUnlink(selectedRows);
  }, []);

  return (
    <div className="selected_products_wrapper">
      <SelectedProductsTable
        title="SKUs to link:"
        data={Object.values(prodsToUnlink)}
        columns={columns}
        setSelectedRows={handleUnselect}
      />
    </div>
  );
}
