import { useState } from 'react';

import { AccountManagementSpaces, ManageUsersTabs } from '.';
import { AccountInfo } from '../document-repository/corp-agreement';
import { AccountMmgmtTabs, COMPANY_INFO } from './constants';

export default function AccountManagement(): React.ReactElement {
  const [activeTab, setActiveTab] = useState(AccountMmgmtTabs.MANAGE_USERS);

  return (
    <div className="settings__container account_mgmt">
      <h3>Account Management</h3>
      <div className="divider" />
      <AccountInfo header="Company Information" sections={COMPANY_INFO} />
      <h4>Manage Users</h4>
      <ManageUsersTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <AccountManagementSpaces activeTab={activeTab} />
    </div>
  );
}
