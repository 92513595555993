import { ConfirmationWindowPropTypes } from './types';

export default function ConfirmationWindow({
  content: { message, action },
  confirm,
}: ConfirmationWindowPropTypes): React.ReactElement {
  return (
    <div className="confirm_window">
      <h1>{message}</h1>
      <button type="button" name="Confirm" onClick={confirm}>
        {action}
      </button>
    </div>
  );
}
