import { HOME_SECTIONS } from './constants';
import useHomePageSections from './hooks/useHomePageSections';
import PageCard from './PageCard';

export default function HomeSections(): React.ReactElement {
  const { data, isLoading } = useHomePageSections();

  const description = data?.find(({ name }) => name === 'Home page')?.description || '';

  return (
    <>
      <h3>Welcome to Vendor Portal!</h3>
      <p className="home_description">{description}</p>
      <div className="sections">
        {HOME_SECTIONS.map(({ id, title, icon, route }) => (
          <PageCard
            key={id}
            title={title}
            icon={icon}
            description={data?.find(({ name }) => name === id)?.description || ''}
            route={route}
            isLoading={isLoading}
          />
        ))}
      </div>
    </>
  );
}
